import { IAutoGRForm } from "../../../../types/Inventory/goodsReceive";
import dayjs from "dayjs";
import * as Yup from "yup";

export const autoGRDefaultValue: IAutoGRForm = {
  source_warehouse: {},
  source_warehouse_unique_id: "",
  source_bin_location_id: 0,
  source_bin_location: undefined,
  posted_date: dayjs(),
  trace_entry_list: [],
};

export const autoGRValidation = Yup.object().shape({
  posted_date: Yup.date().required("กรุณาระบุวันที่นำเข้า"),
});
