import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Typography } from "@mui/material";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  control: IDefaultForm<IGoodsAdjustment>["control"];
  nestedIndex: number;
}

const NewTotalStockQuantityCell = ({ control, nestedIndex }: Props) => {
  const watchSerialList =
    useWatch({
      control,
      name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
    }) || [];

  const totalNewStockQuantity = watchSerialList.reduce((prev, curr) => {
    if (curr.qty && curr.stock_qty) {
      if (typeof curr.qty === "string") {
        return prev + (parseInt(curr.qty) + curr.stock_qty);
      } else {
        return prev + (curr.qty + curr.stock_qty);
      }
    } else if (curr.stock_qty) {
      return prev + curr.stock_qty;
    } else {
      if (typeof curr.qty === "string") {
        return prev + parseInt(curr.qty);
      } else {
        return prev + curr.qty;
      }
    }
  }, 0);

  return (
    <Typography fontSize={14}>{formatNumber(totalNewStockQuantity)}</Typography>
  );
};

export default NewTotalStockQuantityCell;
