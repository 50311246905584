import {
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
// import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";

// import AgGrid from "../../../UI/AgGrid";
import { MANUFACTURE_ORDER_AGGRID } from "../../../../services/AgGrid/ManufactureAgGrid";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useManufactureOrderColumnDef } from "./columnDefs";
import { useStateContext } from "../../../../contexts/auth-context";

import {
  ManufactureEntityType,
  ManufactureTagsFindByEntityQuery,
  useManufactureTagsFindByEntityQuery,
} from "../../../../generated/manufacture";
import ConfigurableAgGrid, {
  ConfigurableAgGridReact,
} from "../../../UI/ConfigurableAgGrid/ConfigurableAgGrid";
interface Props {
  isFilter?: string | null;
  referenceUniqueId?: string;
}

export type ManufactureOrderTableType = {
  setMode: (mode: "everyone" | "onlyme") => void;
};

const ManufactureOrderTable = forwardRef(
  ({ isFilter, referenceUniqueId }: Props, ref) => {
    const navigate = useNavigate();
    const gridRef = useRef<ConfigurableAgGridReact<any>>(null);

    useImperativeHandle(ref, () => {
      return {
        setMode: (mode: "everyone" | "onlyme") => {
          gridRef.current?.setMode(mode);
        },
      };
    });

    const {
      state: { permissions, authUser },
    } = useStateContext();
    const onFilterChanged = useCallback(
      (params: any) => {
        debugger;
        const instance = params.api.getFilterInstance("aggrid_status");
        switch (isFilter) {
          case "draft":
            instance?.setModel({ values: ["draft"] });
            break;
          case "pending_manu":
            instance?.setModel({ values: ["pending_manu"] });
            break;
          case "in_progress":
            instance?.setModel({ values: ["in_progress"] });
            break;
          case "finished":
            instance?.setModel({ values: ["finished"] });
            break;
          case "cancelled":
            instance?.setModel({ values: ["cancelled"] });
            break;
          default:
            instance?.setModel({});
            break;
        }
        params.api.onFilterChanged();
      },
      [isFilter]
    );

    useEffect(() => {
      if (gridRef.current && gridRef.current.api) {
        onFilterChanged(gridRef.current);
      }
    }, [gridRef, onFilterChanged]);

    const graphQLClientWithHeaderManufacture: GraphQLClient =
      createGraphQLClientWithMiddleware("manufacture");

    const { data: tagList } =
      useManufactureTagsFindByEntityQuery<ManufactureTagsFindByEntityQuery>(
        graphQLClientWithHeaderManufacture,
        {
          entityName: ManufactureEntityType.ManufactureOrder,
        }
      );

    const columnDef: ColDef[] = useManufactureOrderColumnDef(
      false,
      tagList?.manufactureTagsFindByEntity ?? []
    );

    const datasource = {
      async getRows(params: IServerSideGetRowsParams) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;

        const {
          created_date,
          issue_date,
          production_date,
          production_completion_date,
          delivery_date,
          created_by,
          is_active,
          tag_list,
          ...otherFilterModel
        } = filterModel;

        const formatFilter = {
          ...otherFilterModel,
          created_date: dateFilterModel(created_date),
          issue_date: dateFilterModel(issue_date),
          production_date: dateFilterModel(production_date),
          production_completion_date: dateFilterModel(
            production_completion_date
          ),
          delivery_date: dateFilterModel(delivery_date),
          created_by:
            permissions?.manufacture_order.view === "SELF"
              ? {
                  filterType: "json",
                  type: "equals",
                  filter: authUser?.unique_id,
                  path: ["user_unique_id"],
                }
              : created_by && {
                  filterType: "json",
                  type: created_by.type,
                  filter: created_by.filter,
                  path: ["first_name"],
                },
          reference_unique_id_list: referenceUniqueId
            ? {
                filterType: "array",
                type: "hasSome",
                values: [referenceUniqueId],
              }
            : undefined,
          tag_list: tag_list && {
            filterType: "objectArray",
            type: "some",
            filter: {
              name: {
                filterType: "set",
                values: tag_list.values,
              },
            },
          },
        };
        try {
          const { manufactureOrdersFindManyAggrid } =
            await graphQLClientWithHeaderManufacture.request(
              MANUFACTURE_ORDER_AGGRID,
              {
                aggridInput: {
                  startRow,
                  endRow,
                  filterModel: formatFilter,
                  sortModel,
                },
              }
            );

          params.success({
            rowData: manufactureOrdersFindManyAggrid.data as any[],
            rowCount: manufactureOrdersFindManyAggrid.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      onFilterChanged(params);
      params.api.setServerSideDatasource(datasource);
    };

    const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
      navigate(
        `/manufacture/order/${encodeURIComponent(params.data.unique_id)}`
      );
    };

    return (
      <ConfigurableAgGrid
        ref={gridRef}
        columnDefs={columnDef}
        height={665}
        onRowDoubleClicked={onRowDoubleClicked}
        onGridReady={onGridReady}
        path={"/manufacture/order"}
        persistKey="manufacture-order-table"
        ignoreFilterKey={["aggrid_status"]}
      />
    );
  }
);

export default ManufactureOrderTable;
