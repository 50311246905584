import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IDefaultForm, ISelectOption } from "../../../../types/global";
import {
  InventoryUniqueIdQuery,
  ModelType,
  useInventoryUniqueIdQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useCallback, useEffect, useState } from "react";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useParams } from "react-router-dom";
import Confirmation from "../../../UI/Confirmation";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import { Controller } from "react-hook-form";
import { GraphQLClient } from "graphql-request";
import { IWarehouse } from "../../../../types/Setting/inventory";

type Props = IDefaultForm & {
  status?: string | null;
  allWarehouses?: IWarehouse[];
};

const GoodsAdjustmentDetail = ({
  control,
  errors,
  disabled,
  getValues,
  setValue,
  status,
  allWarehouses,
  reset,
}: Props) => {
  const { t } = useTranslation();
  const [currentWarehouse, setCurrentWarehouse] = useState<string>("");
  const { id } = useParams();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch } = useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
    graphQLClientWithHeaderItem,
    {
      modelType: ModelType.GoodsAdjust,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const resetTraceEntry = () => {
    if (reset) {
      reset((prev: IGoodsAdjustment) => ({
        ...prev,
        trace_entry_list: [],
      }));
    }
  };

  const onWarehouseChangeHandler = () => {
    setValue("source_warehouse_unique_id", currentWarehouse);
    resetTraceEntry();
  };

  const {
    confirmation: warehouseConfirmation,
    openConfirmationHandler: openWarehouseConfirmationHandler,
    closeConfirmationHandler: closeWarehouseConfirmationHandler,
    submitConfirmationHandler: submitWarehouseConfirmationHandler,
  } = useConfirmation(onWarehouseChangeHandler);

  const warehouseOptions: ISelectOption[] =
    allWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  const generateUniqueId = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, getValues, id]);

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Typography fontWeight="bold">{t("sentence.detail")}</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ControlledTextField
              control={control}
              name="unique_id"
              error={Boolean(errors && errors.unique_id)}
              helperText={errors && errors.unique_id?.message}
              label={t("sentence.unique_id")}
              disabled={disabled || status === "draft"}
              required
              onChange={(e, field) => {
                const trimmedValue = e.target.value
                  .replaceAll(" ", "")
                  .replaceAll(/\u00a0/g, "");
                field.onChange(trimmedValue);
              }}
            />
            {!id && (
              <CustomizedTooltip
                title="เรียกเลขที่เอกสารใหม่"
                enterNextDelay={200}
              >
                <IconButton onClick={generateUniqueId} color="primary">
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="source_warehouse_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                label={t("inventory.goods_transfer.source_warehouse")}
                error={Boolean(errors && errors.source_warehouse_unique_id)}
                helperText={
                  errors && errors.source_warehouse_unique_id?.message
                }
                disabled={disabled}
                options={warehouseOptions}
                onChange={(e: any) => {
                  setCurrentWarehouse(e.target.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openWarehouseConfirmationHandler();
                  } else {
                    field.onChange(e);
                  }
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            error={Boolean(errors && errors.remark)}
            helperText={errors && errors.remark?.message}
            label={t("sentence.remark")}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนคลัง"
        message="หากเปลี่ยนคลังแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={warehouseConfirmation}
        handleClose={closeWarehouseConfirmationHandler}
        action={submitWarehouseConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default GoodsAdjustmentDetail;
