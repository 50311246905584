import { Dispatch, SetStateAction } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
// import FilterListIcon from "@mui/icons-material/FilterList";

import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import { itemTypeEngToThai } from "../../../../utils/dataTransformer";
import { IDefaultForm } from "../../../../types/global";
import { ItemQuery } from "../../../../generated/inventory";

type ExtendedProps = IDefaultForm & {
  item?: ItemQuery["item"];
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setFilterModal: Dispatch<SetStateAction<boolean>>;
  openDeleteConfirmation: () => void;
};

const ItemHeader = ({
  control,
  getValues,
  item,
  setDisabled,
  openDeleteConfirmation,
}: ExtendedProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">
          {item?.name ??
            t("button.create") + itemTypeEngToThai(getValues("type") ?? "")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display="flex" gap={2} justifyContent="flex-end">
          <Box width={152}>
            <ControlledDatePicker
              label={t("date.created_date")}
              control={control}
              open={false}
              onClose={() => {}}
              onOpen={() => {}}
              name="created_date"
              disabled
            />
          </Box>

          {id && !["currentstock", "transaction", null].includes(tab) && (
            <CustomizedMenuOptions
              label="ตัวเลือก"
              options={[
                {
                  value: "แก้ไข",
                  // disabled: !editPermission,
                },
                {
                  value: "ลบ",
                  // disabled: !deletePermission,
                },
              ]}
              size="medium"
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerHTML) {
                  case "แก้ไข":
                    setDisabled(false);
                    break;
                  case "ลบ":
                    openDeleteConfirmation();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
          {/* {id && tab === "currentstock" && (
            <IconButton onClick={() => setFilterModal(true)}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          )} */}
          <CustomizedTooltip title="ดูการเคลื่อนไหว">
            <IconButton
              color="primary"
              // onClick={() => setOpenDrawer(true)}
            >
              <HistoryIcon fontSize="small" />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ItemHeader;
