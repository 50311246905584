import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  useFormContext,
} from "react-hook-form";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import TocIcon from "@mui/icons-material/Toc";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import CheckedUserModal from "../../CheckedUserModal";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

import { IAvatar } from "../../../../types/global";
import { IRouting } from "../../../../types/Manufacture/routing";

import { formatNumber } from "../../../../utils/dataTransformer";
import { EntityTypeEnum } from "../../../../generated/creatable";
import RelatedEmployeeIcon from "../../../Form/EmployeeList/RelatedEmployeeIcon";
import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const formatExportedWorkOrderStatus = (status?: string) => {
  switch (status) {
    case "day":
      return "วัน";
    case "hour":
      return "ชั่วโมง";
    case "minute":
      return "นาที";
    default:
      return "วัน";
  }
};

const timeUnit = [
  {
    value: "minute",
    label: "นาที",
  },
  {
    value: "hour",
    label: "ชั่วโมง",
  },
  {
    value: "day",
    label: "วัน",
  },
];

type Props = {
  fields: FieldArrayWithId<IRouting, "work_order_list", "id">[];
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  update: UseFieldArrayUpdate<IRouting, "work_order_list">;
  replace: UseFieldArrayReplace<IRouting, "work_order_list">;
};

const RoutingWorkOrderTable = ({
  fields,
  remove,
  update,
  replace,
  move,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<
    { label: string; width?: number; align?: TableCellProps["align"] }[]
  >([]);
  const [woIndex, setWoIndex] = useState<number>(0);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState<string[]>([]);
  const [disabled] = useDisable();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IRouting>();

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  useEffect(() => {
    if (!disabled)
      setHeaders([
        { label: t("manufacture.workOrder.no"), width: 70 },
        { label: t("manufacture.workOrder.user"), width: 100 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.production_center"), width: 150 },
        {
          label: t("manufacture.workOrder.duration"),
          width: 120,
          align: "right",
        },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        {
          label: t("manufacture.workOrder.cost_price"),
          width: 130,
          align: "right",
        },
        { label: t("manufacture.workOrder.remark"), width: 150 },
        { label: "", width: 20 },
      ]);
    else
      setHeaders([
        { label: t("manufacture.workOrder.no"), width: 70 },
        { label: t("manufacture.workOrder.user"), width: 100 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.production_center"), width: 150 },
        {
          label: t("manufacture.workOrder.duration"),
          width: 120,
          align: "right",
        },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        {
          label: t("manufacture.workOrder.cost_price"),
          width: 130,
          align: "right",
        },
        { label: t("manufacture.workOrder.remark"), width: 150 },
      ]);
  }, [disabled, t, id]);

  const {
    modal: responsibleModal,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  const openEmployeeTable = async (index: number) => {
    const workOrder = fields[index];
    setWoIndex(index);
    setEmployeeIds(
      workOrder.responsible_user_list?.map(
        (list: any) => list.user_unique_id
      ) || []
    );
    setEmployeeIdsSnapshot(
      workOrder.responsible_user_list?.map(
        (list: any) => list.user_unique_id
      ) || []
    );
    openModalHandler();
  };

  const finishUsersSelect = (data: any) => {
    const workOrder = fields[woIndex];

    const formatData = [
      {
        user_unique_id: data.unique_id,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        img_url: data.img_url,
      },
    ];

    update(woIndex, {
      ...workOrder,
      responsible_user_list: formatData,
    });
    closeModalHandler();
  };

  const removeEmployee = (index: number, listIndex: number) => {
    const woList = fields[index];
    const remove = woList.responsible_user_list?.filter(
      (_: any, userInd: number) => userInd !== listIndex
    );

    update(index, {
      ...woList,
      responsible_user_list: remove,
    });
  };

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  width={header.width}
                >
                  <Box minWidth={header.width}>
                    <Typography
                      textAlign={!header.align ? "center" : header.align}
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable
              droppableId="droppable-ingredient"
              isDropDisabled={disabled}
            >
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((row, index) => (
                    <Draggable
                      key={row.id}
                      draggableId={"work-info-" + row.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>

                          {disabled ? (
                            <TableCell align="center">
                              <Grid container sx={{ placeContent: "center" }}>
                                <CustomizedAvatar
                                  avatars={
                                    (row.responsible_user_list as IAvatar[]) ||
                                    []
                                  }
                                />
                              </Grid>
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <Grid
                                container
                                spacing={1}
                                sx={{ placeContent: "center" }}
                              >
                                {row.responsible_user_list?.map(
                                  (item, listIndex) => {
                                    return (
                                      <RelatedEmployeeIcon
                                        isMain={false}
                                        key={item.user_unique_id}
                                        first_name={item.first_name || ""}
                                        last_name={item.last_name}
                                        index={index}
                                        img_url={
                                          item.img_url ? item?.img_url[0] : ""
                                        }
                                        remove={() =>
                                          removeEmployee(index, listIndex)
                                        }
                                        disabled={disabled}
                                      />
                                    );
                                  }
                                )}
                                {!disabled &&
                                  row.responsible_user_list?.length === 0 && (
                                    <Grid item>
                                      <CustomizedTooltip
                                        title="เพิ่มผู้รับผิดชอบ"
                                        enterNextDelay={200}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            openEmployeeTable(index)
                                          }
                                          color="primary"
                                        >
                                          <ControlPointOutlinedIcon />
                                        </IconButton>
                                      </CustomizedTooltip>
                                    </Grid>
                                  )}
                              </Grid>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {disabled ? (
                              <Typography
                                fontSize={14}
                                onClick={() =>
                                  window.open(
                                    `/manufacture/work-order/${row.id}`,
                                    "_blank"
                                  )
                                }
                                paragraph
                                component="span"
                                color="primary"
                                sx={{ cursor: "pointer" }}
                              >
                                {row.name}
                              </Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_name"
                                name={`work_order_list[${index}].name`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]?.name
                                )}
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>{row.plant}</Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_plant"
                                name={`work_order_list[${index}].plant`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]?.plant
                                )}
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {row.production_center}
                              </Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_production_center"
                                name={`work_order_list[${index}].production_center`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]
                                    ?.production_center
                                )}
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(row.duration ?? 0)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                name={`work_order_list[${index}].duration`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]?.duration
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatExportedWorkOrderStatus(
                                  row.duration_unit
                                )}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                options={timeUnit}
                                name={`work_order_list[${index}].duration_unit`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]
                                    ?.duration_unit
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(row.cost_price ?? 0)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                name={`work_order_list[${index}].cost_price`}
                                control={control}
                                error={Boolean(
                                  errors?.work_order_list?.[index]?.cost_price
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {row.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                name={`work_order_list[${index}].remark`}
                                control={control}
                              />
                            )}
                          </TableCell>
                          {!disabled && (
                            <TableCell align="center">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <CheckedUserModal
        showSelectUser={responsibleModal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        userIds={employeeIds}
        setUserIds={setEmployeeIds}
        userIdsSnapshot={employeeIdsSnapshot}
        setUserIdsSnapshot={setEmployeeIdsSnapshot}
        // lockRows={[authUser?.unique_id]}
        rowSelection="single"
      />
    </Box>
  );
};

export default RoutingWorkOrderTable;
