import { Box, CircularProgress } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { useParams } from "react-router-dom";
import {
  useDeliveryOrderQuery,
  DeliveryOrderQuery,
} from "../../../generated/logistic";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import DOPdfLayout from "../../../components/UI/Pdf/LogisticPdf/DeliveryOrderPdf/PDFLayout";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { useRef, ReactInstance } from "react";
import PDFTable from "../../../components/Table/Pdf/NewDeliveryOrderPdfTable";
import { formatDate } from "../../../utils/Formatter/Date";
import { formatTaxId } from "../../../utils/Formatter/Global";
import PDFWrapper from "../../../components/UI/Pdf/PDFWrapper";

export const renderAddressString = (addressValues: any) => {
  if (addressValues) {
    const { address, sub_district, district, province, postal_code, country } =
      addressValues;

    const orderedValues = {
      address,
      sub_district,
      district,
      province,
      postal_code,
      country,
    };
    let result = "";

    if (addressValues && orderedValues) {
      if (Array.isArray(addressValues)) {
        addressValues.forEach((item) => {
          if (item?.length > 0) {
            result = result + item + " ";
          }
        });
      } else {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          const newValue = orderedValues[key as keyof typeof orderedValues];
          if (newValue && newValue.length > 0) {
            result = result + newValue + " ";
          }
        });
      }
      if (result?.trim().length === 0) {
        return "-";
      }
    }
    return result?.trim();
  } else return "";
};

const DeliveryOrderPDF = () => {
  const { id } = useParams();
  const documentType = "delivery_order";

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");
  const { data, isLoading } = useDeliveryOrderQuery<DeliveryOrderQuery>(
    graphQLClientWithHeaderLogistic,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );
  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.deliveryOrder,
    295,
    790,
    480,
    213,
    169
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.deliveryOrder?.unique_id || "",
  });

  const leftHeader = [
    "ลูกค้า",
    "ที่อยู่",
    "เลขประจำตัวผู้เสียภาษี",
    "เบอร์โทรศัพท์",
    "โทรสาร",
    "อีเมล",
  ];

  const leftInfo = [
    data?.deliveryOrder?.customer_contact_unique_id +
      " - " +
      data?.deliveryOrder?.customer_contact?.name,
    renderAddressString(
      data?.deliveryOrder?.customer_contact?.billing_address || {}
    ) || "-",
    formatTaxId(
      data?.deliveryOrder?.customer_contact?.identity_no || "",
      data?.deliveryOrder?.customer_contact?.branch || ""
    ) || "-",
    data?.deliveryOrder?.customer_contact?.phone || "-",
    data?.deliveryOrder?.customer_contact?.fax || "-",
    data?.deliveryOrder?.customer_contact?.email || "-",
  ];

  const highlightHeader = ["วันที่ออกเอกสาร", "วันกำหนดส่งของ"];

  const highlightInfo = [
    data?.deliveryOrder?.issue_date
      ? formatDate(data?.deliveryOrder?.issue_date)
      : "-",
    formatDate(data?.deliveryOrder?.delivery_date) || "-",
  ];

  const rightHeader = ["อ้างอิงถึง", "ที่อยู่จัดส่ง"];

  const rightInfo = [
    data?.deliveryOrder?.reference_unique_id_list &&
    data?.deliveryOrder?.reference_unique_id_list.length > 0
      ? data?.deliveryOrder?.reference_unique_id_list.join(", ")
      : "-",
    renderAddressString(
      data?.deliveryOrder?.customer_contact?.delivery_address || {}
    ) || "",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFWrapper
      documentName="ใบส่งของ"
      documentId={id}
      handlePrint={handlePrint}
    >
      <Box ref={componentRef} sx={{ margin: "auto auto auto auto" }}>
        {paginatedItems.map((item, index) => (
          <DOPdfLayout
            documentName="ใบส่งของ/Delivery Order"
            data={data?.deliveryOrder}
            documentType={documentType}
            page={index + 1}
            allPage={paginatedItems.length}
            footer={index === paginatedItems.length - 1}
            leftHeader={leftHeader}
            leftInfo={leftInfo}
            highlightHeader={highlightHeader}
            highlightInfo={highlightInfo}
            rightHeader={rightHeader}
            rightInfo={rightInfo}
          >
            <PDFTable data={item} start_no={startNumbers[index]} />
          </DOPdfLayout>
        ))}
      </Box>
    </PDFWrapper>
  );
};

export default DeliveryOrderPDF;
