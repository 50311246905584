import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid, Typography, FormControlLabel, Radio } from "@mui/material";
import { useForm } from "react-hook-form";
import { IExporter } from "../../types/global";
import ControlledDatePicker from "../Controller/ControlledDatePicker";
import CustomizedButton from "../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  exportHandler: (data: IExporter) => void;
};

const LogisticExporterModal = ({
  open,
  closeModalHandler,
  exportHandler,
}: Props) => {
  const exporterSchena: IExporter = {
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  const exporterValidation = Yup.object().shape({
    export_type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    start_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
    end_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IExporter>({
    defaultValues: exporterSchena,
    resolver: yupResolver<any>(exporterValidation),
  });

  const onClose = () => {
    reset(exporterSchena);
    closeModalHandler();
  };

  const onSubmit = (data: IExporter) => {
    if (data.export_type === "date_range") {
      data.start_date = dayjs(data.start_date).startOf("day").toDate();
      data.end_date = dayjs(data.end_date).endOf("day").toDate();
    }
    exportHandler(data);
    onClose();
  };

  const watchExportType = watch("export_type");
  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            size="medium"
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.confirm")}
            size="medium"
            variant="contained"
            onClick={() => {
              handleSubmit(onSubmit)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography fontWeight={600} mb={2}>
        ช่วงเวลา
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Grid container spacing={1.5}>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    onClick={() => {
                      setValue("export_type", "all");
                      setValue("start_date", null);
                      setValue("end_date", null);
                    }}
                    checked={watchExportType === "all"}
                  />
                }
                label={t("global.all")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    onClick={() => {
                      setValue("export_type", "date_range");
                    }}
                    checked={watchExportType === "date_range"}
                  />
                }
                label={t("global.time_range")}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.all")}
            onClick={() => {
              setValue("export_type", "all");
              setValue("start_date", null);
              setValue("end_date", null);
            }}
            checked={watchExportType === "all"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.date_range")}
            onClick={() => {
              setValue("export_type", "date_range");
            }}
            checked={watchExportType === "date_range"}
          />
        </Grid> */}
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.started_date")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                required
                label={t("date.ended_date")}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default LogisticExporterModal;
