import { IApproval } from "../../../types/global";
import * as Yup from "yup";

export const approvalSchema: IApproval = {
  is_have_approval: false,
  approval_templates: [],
};

const approvalTemplateValidation = Yup.object().shape({
  approver_list: Yup.array()
    .min(0, "กรุณาเลือกผู้อนุมัติ")
    .max(5, "สามารถเลือกผู้อนุมัติได้ไม่เกิน 5 คน") 
    .transform((value) => (Array.isArray(value) ? value.slice(0, 5) : value)),
});

export const approvalValidation = Yup.object().shape({
  is_have_approval: Yup.boolean(),
  approval_templates: Yup.array().when("is_have_approval", {
    is: true,
    then: (schema) =>
      schema
        .of(approvalTemplateValidation)
        .min(0, "กรุณาเลือกผู้อนุมัติ"),
    otherwise: (schema) => schema,
  }),
});
