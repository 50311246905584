import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Box, Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/dataTransformer";
import { IPurchaseItemList } from "../../../../types/Purchase";

interface Props {
  control: IDefaultForm["control"];
}

const SumPreVatAmount = ({ control }: Props) => {
  const sumPreVatAmount = useWatch({
    control,
    name: "item_list",
  }).reduce(
    (acc: number, item: IPurchaseItemList) => acc + item.pre_discount_amount,
    0
  );

  const currency = useWatch({
    control,
    name: "currency",
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 3,
          mr: 0.5,
        }}
      >
        <>
          <Typography
            color={"primary.main"}
            fontWeight={600}
            textAlign={"right"}
            flex={2}
          >
            มูลค่ารวมก่อนภาษี
          </Typography>
          <Typography
            color={"primary.main"}
            fontWeight={600}
            sx={{ minWidth: 150, textAlign: "right", mr: 1.5, flex: 1 }}
          >
            {formatNumber(sumPreVatAmount)}
          </Typography>
        </>
        <Typography
          color={"primary.main"}
          fontWeight={600}
          sx={{ ml: 2.5, mr: 4 }}
        >
          {currency === "THB" ? "บาท" : currency}
        </Typography>
      </Box>
    </Box>
  );
};

export default SumPreVatAmount;
