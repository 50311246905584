import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm, IMenuOption } from "../../../../types/global";
import { useTranslation } from "react-i18next";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useWatch } from "react-hook-form";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useCallback, useEffect, useState } from "react";
import { useQuotationOption } from "../../../../hooks/Sales/use-quotation-option";
import { useQuotationCreate } from "../../../../hooks/Sales/use-quotation-create";
import ControlledTextField from "../../../Controller/ControlledTextField";
import EmployeeListForm from "../../EmployeeList";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { Theme } from "@mui/system";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import {
  ActivityType,
  GetSalesWorkflowProgressQuery,
  ModelType,
  QuotationQuery,
  SalesActivityLogDocumentType,
  SalesApprovalTemplatesQuery,
  SalesDocumentType,
  SalesUniqueIdQuery,
  Status,
  useGetSalesWorkflowProgressQuery,
  useSalesApprovalTemplatesQuery,
  useSalesDocumentCancelMutation,
  useSalesUniqueIdQuery,
} from "../../../../generated/sales";
import { useSnackbar } from "notistack";
import {
  copyQuotationFormatter,
  createSalesOrderFromQuotation,
} from "../../../../utils/Formatter/Quotation";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ModalUI from "../../../UI/ModalUI";
import AcceptForm from "./Accept";
import SalesEmployeeListForm from "../../EmployeeList/SalesEmployeeList";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";
import dayjs from "dayjs";
import { ActivityLog } from "../../../UI/SlideInPanel/ActivityLog";
import { useActivityLogCreate } from "../../../../hooks/use-global-activity-log";
import ApprovalListForm from "../../ApprovalList";
import showApprovalList from "../../ApprovalList/ApproverList";

type Props = IDefaultForm & {
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<QuotationQuery, unknown>>;
  onQuotationAcceptHandler: () => void;
};

const QuotationHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  editClickHandler,
  refetch,
  onQuotationAcceptHandler,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState<boolean>(false);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data: approvalTemplatesData } = useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
    graphQLClientWithHeaderSales, {}
  );
  const { refetch: getUniqueId } = useSalesUniqueIdQuery<SalesUniqueIdQuery>(
    graphQLClientWithHeaderSales,
    {
      modelType: ModelType.Quotation,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { data: workflowProgressData, refetch: refetchWorkflowProgress } = useGetSalesWorkflowProgressQuery<GetSalesWorkflowProgressQuery>(
    graphQLClientWithHeaderSales,
    {
      input: {
        reference_document_type: SalesDocumentType.Quotation,
        reference_unique_id: getValues("unique_id")
      }
    },
    {
      enabled: !!getValues("unique_id"),
    }
  )

  const { mutateAsync: cancel } = useSalesDocumentCancelMutation<Error>(
    graphQLClientWithHeaderSales,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const status = useWatch({
    control,
    name: "aggrid_status",
  });
  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.quotation.index")}`,
      disabled: !id,
    },
  ];

  const selectModifyOptions = useQuotationOption(status);
  const { selectCreateOptions, disabledCreateOptions } =
    useQuotationCreate(status);

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { salesActivityLogCreate } = useActivityLogCreate();

  const cancelDocumentHandler = async () => {
    try {
      await cancel({
        documentInput: {
          reference_document_type: SalesDocumentType.Quotation,
          unique_id: id ? id : "",
        },
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.Quotation,
          reference_id: getValues("id"),
          activity_detail: {
            curr_status: Status.Cancelled,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) { }
  };

  const copyDocumentHandler = () => {
    const quotation = getValues();
    navigate("/sales/quotation/add", {
      state: copyQuotationFormatter(quotation),
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: acceptConfirmation,
    openConfirmationHandler: openAcceptConfirmation,
    closeConfirmationHandler: closeAcceptConfirmation,
    submitConfirmationHandler: submitAcceptConfirmation,
  } = useConfirmation(onQuotationAcceptHandler);

  const watchMainStatus = useWatch({ control, name: "main_status" });

  useEffect(() => {
    if (status === "approved" || status === "ejected") {
      refetchWorkflowProgress();
    }
  }, [status, refetchWorkflowProgress]);

  useEffect(() => {
  }, [approvalTemplatesData]);
  const isApprovalEnabled = approvalTemplatesData?.approvalTemplates && approvalTemplatesData.approvalTemplates.length > 0
    ? approvalTemplatesData.approvalTemplates.some(
      (template) =>
        template?.is_have_approval && template.document_type === "quotation"
    )
    : false;

  const showApprovalLists = showApprovalList({
    workflowProgressData,
    isApprovalEnabled,
    documentType: "sales",
  });

  const steps = isApprovalEnabled
    ? [
      {
        label: t("status.draft"),
        value: "draft",
      },
      {
        label: t("status.wait_approve"),
        value: "wait_approve",
      },
      {
        label: t("status.wait_accept"),
        value: "wait_accept",
      },
      {
        label: t("status.accepted"),
        value: "accept",
      },
      {
        label: t("status.finished"),
        value: "finished",
      },
    ]
    : [
      {
        label: t("status.draft"),
        value: "draft",
      },
      {
        label: t("status.wait_accept"),
        value: "wait_accept",
      },
      {
        label: t("status.accepted"),
        value: "accept",
      },
      {
        label: t("status.finished"),
        value: "finished",
      },
    ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(status, watchMainStatus, SalesDocumentType.Quotation, undefined, isApprovalEnabled);

  return (
    <>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("sales.quotation.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <Grid
              item
              xs={5.5}
              sm={5.5}
              md={status === "wait_accept" ? 3 : 4}
              lg={status === "wait_accept" ? 3 : 4}
              xl={status === "wait_accept" ? 3 : 4}
            >
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (
                    value.innerText === `พิมพ์${t("sales.quotation.index")}`
                  ) {
                    navigate(
                      `/sales/quotation/${encodeURIComponent(id || "")}/pdf`
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid
              item
              xs={5.5}
              sm={5.5}
              md={status === "wait_accept" ? 2.5 : 4}
              lg={status === "wait_accept" ? 2.5 : 4}
              xl={status === "wait_accept" ? 2.5 : 4}
            >
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid
              item
              xs={5.5}
              sm={5.5}
              md={status === "wait_accept" ? 2.5 : 3}
              lg={status === "wait_accept" ? 2.5 : 3}
              xl={status === "wait_accept" ? 2.5 : 3}
            >
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("sales.order.index")}`:
                      const quotation = getValues();
                      navigate("/sales/order/add", {
                        state: createSalesOrderFromQuotation(quotation),
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            {status === "wait_accept" && (
              <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
                <CustomizedButton
                  title="ยอมรับใบเสนอราคา"
                  onClick={() => {
                    setValue("accepted_date", dayjs());
                    openAcceptConfirmation();
                  }}
                  variant="contained"
                  fullWidth
                />
              </Grid>
            )}
            {id && (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <CustomizedTooltip
                  title="ดูความเคลื่อนไหว"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                    color="primary"
                  >
                    <RestoreOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={disabled || status === "draft"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
            {showApprovalLists && (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography fontWeight="bold">
                    {t("sales.approver_list")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
                  <ApprovalListForm
                    control={control}
                    documentType="quotation"
                    approvalTemplates={approvalTemplatesData?.approvalTemplates || []}
                    workflowProgress={workflowProgressData?.salesWorkflowProgressByDocument || []}
                    data={getValues("flag_status")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.sales_contact_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <SalesEmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                error={errors.due_date}
                open={dueDateIsOpen}
                onClose={() => setDueDateIsOpen(false)}
                onOpen={() => setDueDateIsOpen(true)}
                label={t("date.due_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 0.5 }}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ModalUI
        title="ยอมรับใบเสนอราคา"
        open={acceptConfirmation}
        handleClose={() => {
          setValue("accepted_date", null);
          closeAcceptConfirmation();
        }}
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={() => {
                setValue("accepted_date", null);
                closeAcceptConfirmation();
              }}
            />
            <CustomizedButton
              title="บันทึก"
              variant="contained"
              onClick={submitAcceptConfirmation}
            />
          </Box>
        }
      >
        <AcceptForm
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          isModal
        />
      </ModalUI>
      <ActivityLog
        service="sales"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        documentId={getValues("id")}
        documentType="quotation"
      />
    </>
  );
};

export default QuotationHeader;
