import { useTranslation } from "react-i18next";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Grid } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import { currencyOptions } from "../../../utils/Formatter/Global";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  multiCurrency?: boolean;
}

const PriceVatType = ({
  control,
  errors,
  disabled,
  setValue,
  multiCurrency,
}: Props) => {
  const { t } = useTranslation();
  const priceVatTypeOption: ISelectOption[] = [
    {
      label: t("sales.included_vat"),
      value: "included_vat",
    },
    {
      label: t("sales.excluded_vat"),
      value: "excluded_vat",
    },
  ];

  const currency = useWatch({ control, name: "currency" });

  return (
    <Grid container mb={2} spacing={2}>
      <Grid item xs={4}>
        <ControlledSelect
          label={t("sales.price_vat_type")}
          control={control}
          name="price_vat_type"
          options={priceVatTypeOption}
          disabled={disabled}
          error={Boolean(errors?.price_vat_type)}
          helperText={errors?.price_vat_type && errors?.price_vat_type?.message}
          sx={{ width: "100%" }}
        />
      </Grid>
      {multiCurrency && (
        <>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="currency"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("global.currency")}
                  options={currencyOptions}
                  error={Boolean(errors.currency)}
                  // helperText={errors.currency?.message?.toString()}
                  onChange={(e, value) => {
                    field.onChange(value?.value || "");
                    if (value?.value === "THB") setValue("exchange_rate", "");
                  }}
                  value={
                    currencyOptions.find(
                      (option) => option.value === field.value
                    )?.label || ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  disabled={disabled}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledNumberTextField
              control={control}
              name="exchange_rate"
              label={t("global.exchange_rate")}
              error={Boolean(errors.exchange_rate)}
              // helperText={errors.exchange_rate?.message?.toString()}
              disabled={currency === "THB" || disabled}
              sx={{ width: "100%" }}
              decimalScale={4}
              required
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PriceVatType;
