import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../utils/dataTransformer";
import { customHeaderColRender } from "../utils/customHeaderColumn";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";

const customCellStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 6px",
};

const customCellStyleRight = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 6px",
  paddingRight: "14px",
};

export const useItemListColumns = (documentType: string, disabled: boolean) => {
  const { t } = useTranslation();

  const defaultColumns = useMemo(
    () =>
      [
        {
          headerComponentParams: {
            template: customHeaderColRender("No.", "รายการ"),
          },
          valueGetter: (params: ValueGetterParams) => {
            if (params && params.node && params.node.id) {
              return parseInt(params.node.id) + 1;
            }
          },
          cellStyle: customCellStyle,
          width: 90,
          hide: documentType === "item" ? true : false,
          checkboxSelection: disabled ? false : true,
        },
        {
          field: "item_name",
          headerName: "สินค้า",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Name", "ชื่อสินค้า"),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "stretch",
                  flex: 1,
                  textAlign: "left",
                }}
              >
                <Typography variant="body2" align="left" mt={1} color="primary">
                  {params.data.item_name}
                </Typography>
                <Typography
                  variant="overline"
                  sx={{
                    my: 0,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {params.data.item_unique_id}
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  gutterBottom
                  sx={{
                    whiteSpace: "normal",
                  }}
                >
                  {params.data.item_sku_desc}
                </Typography>
              </Box>
            </Box>
          ),
          autoHeight: true,
          cellStyle: {
            padding: "0 6px",
          },
          width: 250,
          checkboxSelection:
            (documentType !== "item" && disabled) ||
            documentType === "sales_return"
              ? false
              : true,
        },
        {
          field: "qty",
          headerName: "จำนวน",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Qty", "จำนวน"),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Typography fontSize={14} align="center">
              {formatNumber(params.data.qty)}
            </Typography>
          ),
          width: 120,
          cellStyle: customCellStyle,
        },
        {
          field: "uom",
          headerName: "หน่วย",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Unit", "หน่วย"),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Typography fontSize={14} align="center">
              {params.data.uom}
            </Typography>
          ),
          cellStyle: customCellStyle,
          width: 130,
        },
        {
          field: "stock_qty",
          headerName: "จำนวนคลัง",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender(
              "Stock Qty",
              "จำนวนคลัง",
              false,
              "right"
            ),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Box>
              <Typography fontSize={14} align="right">
                {formatNumber(params.data.stock_qty)}
              </Typography>
              <Typography
                align="center"
                sx={{ fontSize: "10px", whiteSpace: "normal" }}
              >
                {params.data.uom_group?.base_uom?.name}
              </Typography>
            </Box>
          ),
          cellStyle: customCellStyleRight,
          autoHeight: true,
          width: 120,
        },
        {
          field: "price_per_unit",
          headerName: "ราคา/หน่วย",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Price/Unit", "ราคาต่อหน่วย"),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Box>
              <Typography fontSize={14} align="center">
                {formatNumber(params.data.price_per_unit)}
              </Typography>
              {documentType !== "purchase_return" &&
                params.data.purchase_standard_price && (
                  <Typography
                    align="center"
                    sx={{ fontSize: "10px", whiteSpace: "normal" }}
                  >
                    ราคาทุน {formatNumber(params.data.purchase_standard_price)}
                  </Typography>
                )}
            </Box>
          ),
          cellStyle: customCellStyle,
          width: 130,
        },
        {
          field: "discount",
          headerName: "ส่วนลด",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Discount", "ส่วนลด"),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Typography fontSize={14} align="center">
              {formatNumber(params.value)}
            </Typography>
          ),
          cellStyle: customCellStyle,
          width: 125,
        },
        {
          field: "vat_percentage",
          headerName: "ภาษี (%)",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Vat (%)", `ภาษี (%)`),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Typography fontSize={14} align="center">
              {params.value} {params.value === "ไม่มี" ? "" : "%"}
            </Typography>
          ),
          cellStyle: customCellStyle,
          width: 120,
        },
        {
          field: "pre_vat_amount",
          headerName: "จำนวนเงิน",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender("Pre-vat Amount", "จำนวนเงิน"),
          },
          valueFormatter: (params: ValueFormatterParams) =>
            formatNumber(params.data.pre_vat_amount),
          cellStyle: customCellStyle,
          width: 180,
        },
        {
          field: "withholding_tax_type",
          headerName: "หัก ณ ที่จ่าย (%)",
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender(
              "Withholding Tax (%)",
              `หัก ณ ที่จ่าย (%)`
            ),
          },
          cellRenderer: (params: ICellRendererParams) => (
            <Typography fontSize={14} align="center">
              {params.value} {params.value !== "ยังไม่ระบุ" && "%"}
            </Typography>
          ),
          cellStyle: customCellStyle,
          width: 180,
        },
        {
          field: "remark",
          headerName: t("sentence.remark"),
          headerComponentParams: documentType !== "item" && {
            template: customHeaderColRender(
              "Product Remark",
              t("sentence.remark")
            ),
          },
          cellRenderer: (params: any) => (
            <Typography fontSize={14} align="center">
              {params.data.remark}
            </Typography>
          ),
          cellStyle: customCellStyle,
          width: 220,
        },
      ].filter((col) => col),
    [documentType, t, disabled]
  );

  const [columnDefs, setColumnDefs] = useState<ColDef<any>[]>(defaultColumns);

  useEffect(() => {
    if (documentType === "quotation" || documentType === "sales_order") {
      const newColumns = [...defaultColumns];
      newColumns.splice(
        1,
        0,
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          headerComponentParams: {
            template: customHeaderColRender(
              "Document Id",
              t("sales.unique_id")
            ),
          },
          cellRenderer: (params) => (
            <Typography fontSize={14} align="center" color="primary">
              {params.data.unique_id}
            </Typography>
          ),
          cellStyle: {
            padding: "0 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          width: 200,
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          headerComponentParams: {
            template: customHeaderColRender("Issue Date", t("date.issue_date")),
          },
          valueFormatter: (params) => params.data.issue_date,
          cellStyle: customCellStyle,
          width: 150,
        }
      );

      newColumns.splice(7, 4);
      newColumns.splice(9, 4);
      setColumnDefs(newColumns);
    } else if (
      documentType === "purchase_return" ||
      documentType === "manufacture_order"
    ) {
      const newColumns = [...defaultColumns];
      newColumns.splice(2, 1, {
        field: "po_qty",
        headerName: "จำนวนสั่งซื้อ",
        headerComponentParams: {
          template: customHeaderColRender(
            "PO Qty",
            "จำนวนสั่งซื้อ",
            false,
            "right"
          ),
        },
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {formatNumber(params.data.po_qty)}
          </Typography>
        ),
        cellStyle: customCellStyleRight,
        width: 120,
      });
      newColumns.splice(5, 4);
      newColumns.splice(10, 1);
      setColumnDefs(newColumns);
    } else if (documentType === "sales_return") {
      const newColumns = [...defaultColumns];
      newColumns.splice(2, 1, {
        field: "so_qty",
        headerComponentParams: {
          template: customHeaderColRender(
            "Sales Qty",
            "จำนวนขาย",
            false,
            "right"
          ),
        },
        headerName: "จำนวนขาย",
        cellRenderer: (params: ICellRendererParams) => (
          <Typography fontSize={14} align="center">
            {formatNumber(params.data.so_qty)}
          </Typography>
        ),
        cellStyle: customCellStyleRight,
        width: 120,
      });
      newColumns.splice(5, 4);
      newColumns.splice(10, 1);
      setColumnDefs(newColumns);
    } else if (documentType === "item") {
      setColumnDefs(defaultColumns);
    }
  }, [defaultColumns, documentType, t]);

  return columnDefs;
};
