export const documentTypeFormatter = (type: string) => {
  switch (type) {
    case "sales_order":
      return "ขาย";
    case "purchase_order":
      return "ซื้อ";
    case "manufacture_order":
      return "ผลิต";
    case "purchase_return":
      return "ส่งคืน";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};
