import { Avatar, Box, Grid, Divider, Typography } from "@mui/material";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../Custom/CustomizedLetterAvatar";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

type ApproverListProps = {
  isMain: boolean;
  first_name: string;
  last_name?: string;
  index: number;
  img_url?: string;
  remove?: (index: number) => void;
  disabled?: boolean;
  status?: "approved" | "ejected";
  approverList?: any[];
};

const ApproverListIcon = ({
  isMain,
  first_name,
  last_name,
  index,
  img_url,
  remove,
  disabled,
  status,
  approverList,
}: ApproverListProps) => {
  const renderTooltipContent = () => (
    <Box>
      {approverList
        ?.sort((a, b) => {
          if (a.first_name === first_name && status) return -1;
          if (b.first_name === first_name && status) return 1;
          return 0;
        }).map((approver: any, index: number) => {
          return (
            <Box
              key={approver.unique_id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  opacity: (
                    (approver.first_name === first_name && status === "approved") ||
                    (approver.first_name === first_name && status === "ejected")
                  ) ? 1 : 0.5,
                }}
              >
                {(approver.img_url).length > 0 ? (
                  <Avatar
                    alt={`img${index}`}
                    src={approver.img_url}
                    sx={{
                      mt: 1,
                      ml: 1,
                      mr: 1,
                      border: approver.first_name === first_name && status === "approved"
                        ? "2.5px solid #22915A"
                        : approver.first_name === first_name && status === "ejected"
                          ? "2.5px solid red"
                          : "none",
                    }}
                  />
                ) : (
                  <CustomizedLetterAvatar
                    name={approver.first_name + " " + approver.last_name}
                    sx={{
                      width: 35,
                      height: 35,
                      mt: 1,
                      ml: 1,
                      mr: 1,
                    }}
                  />
                )}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline-flex",
                      fontWeight: approver.first_name === first_name ? "bold" : "normal",
                      mt: 1
                    }}
                  >
                    {approver.first_name === first_name && status === "approved" && (
                      <DoneIcon fontSize="medium" sx={{ color: "#22915A" }} />
                    )}
                    {approver.first_name === first_name && status === "ejected" && (
                      <CloseIcon fontSize="medium" sx={{ color: "red" }} />
                    )}
                    {approver.first_name} {approver.last_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "grey" }}
                  >
                    {approver.email || ""}
                  </Typography>
                </Box>
              </Box>
              {index < approverList.length - 1 && (
                <Divider sx={{ width: "100%" }} />
              )}

            </Box>
          )
        })}
    </Box>
  );
  return (
    <>
      {(
        <CustomizedTooltip
          title={renderTooltipContent()}
          enterNextDelay={200}
          backgroundColor={status === "approved" || status === "ejected" ? "white" : ""}
          borderRadius={6}
          sx={{
            opacity: status === "approved" || status === "ejected" ? 1 : 0.5,
          }}
        >
          <Grid item sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                position: "relative",
                border: "2.5px solid",
                borderColor:
                  status === "approved"
                    ? "#22915A"
                    : status === "ejected"
                      ? "red"
                      : "white",
                borderRadius: "50%",
                display: "inline-flex",
              }}
            >
              {img_url && <Avatar alt={`img${index}`} src={img_url} />}
              {!img_url && (
                <CustomizedLetterAvatar name={first_name + " " + last_name} />
              )}
            </Box>
          </Grid>
        </CustomizedTooltip>
      )}
      {isMain && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
    </>
  );
};

export default ApproverListIcon;
