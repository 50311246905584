import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import {
  InventoryTagsFindByEntityQuery,
  ItemEntityType,
  ItemType,
  useInventoryTagsFindByEntityQuery,
} from "../../../../generated/inventory";
import {
  formatNumber,
  itemTypeEngToThai,
} from "../../../../utils/dataTransformer";
import { Box, Typography } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import SkuQtyChecker from "./SkuQtyChecker";
import { formatDate } from "../../../../utils/Formatter/Date";

export const useItemViewColumnDefs = () => {
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch } =
    useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
      graphQLClientWithHeaderItem,
      {
        entityName: ItemEntityType.Item,
      }
    );

  const colDefs: ColDef[] = [
    {
      field: "sku_name",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
      lockVisible: true,
    },
    {
      field: "img_url",
      headerName: "รูป",
      filter: false,
      floatingFilter: false,
      width: 66.5,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              noTooltip
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] || "",
                  unique_id: params?.data?.sku_name || "",
                  first_name: params?.data?.sku_name || "",
                  last_name: "",
                },
              ]}
              sx={{
                width: 32,
                height: 32,
              }}
            />
          );
        } else
          return (
            <CustomizedAvatar
              noTooltip
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.sku_name || "",
                  first_name: params?.data?.sku_name || "",
                  last_name: "",
                },
              ]}
              sx={{
                width: 32,
                height: 32,
              }}
            />
          );
      },
      cellStyle: {
        paddingTop: "6px",
      },
      headerClass: "hide-header-text",
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "desc",
      headerName: t("inventory.items.desc"),
      filter: "agTextColumnFilter",
      cellStyle: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const maxLength = 200;
        const text = params.value || "";
        const truncatedText =
          text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

        return (
          <Typography
            sx={{
              whiteSpace: "nowrap",
              fontSize: 14,
            }}
            // title={text}
          >
            {truncatedText}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: t("inventory.items.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          ItemType.Normal,
          ItemType.Variant,
          ItemType.Bundle,
          ItemType.Service,
        ],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case ItemType.Normal:
              return "สินค้าธรรมดา";
            case ItemType.Variant:
              return "สินค้ามีตัวเลือก";
            case ItemType.Bundle:
              return "สินค้าจัดเซ็ต";
            case ItemType.Service:
              return "บริการ";
            default:
              break;
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) =>
        itemTypeEngToThai(params.value),
      hide: true,
    },
    {
      field: "seller_sku_name",
      headerName: t("inventory.items.seller_sku"),
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      headerClass: "ag-end-header",
      filter: "agNumberColumnFilter",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        paddingTop: "12px",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.value;
        if (value === 0) {
          return (
            <Typography color={"#000E58"} fontSize={14}>
              {formatNumber(value)}
            </Typography>
          );
        } else {
          return (
            <CustomizedTooltip
              borderRadius={5}
              placement="right"
              enterDelay={700}
              title={<SkuQtyChecker sku_name={params.data.sku_name} />}
            >
              <Typography color={"#000E58"} fontSize={14}>
                {formatNumber(value)}
              </Typography>
            </CustomizedTooltip>
          );
        }
      },
      width: 175,
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      headerClass: "ag-end-header",
      filter: "agNumberColumnFilter",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        paddingTop: "12px",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.value;
        if (value === 0) {
          return (
            <Typography color={"#000E58"} fontSize={14}>
              {formatNumber(value)}
            </Typography>
          );
        } else {
          return (
            <CustomizedTooltip
              borderRadius={5}
              placement="right"
              enterDelay={700}
              title={<SkuQtyChecker sku_name={params.data.sku_name} />}
            >
              <Typography color={"#000E58"} fontSize={14}>
                {formatNumber(value)}
              </Typography>
            </CustomizedTooltip>
          );
        }
      },
      width: 175,
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const { data } = await refetch();
          const values = data?.itemTagsFindByEntity;
          const formatValues =
            values && values.length > 0
              ? values
                  .filter((tag) => tag.entity === "item")
                  .map((tag) => tag.name)
              : [];
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        const tag_list = params.data.tag_list;
        if (tag_list && tag_list.length > 0) {
          return tag_list.map((tag: string, index: number) => (
            <Box key={index} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={tag} value={tag} />
            </Box>
          ));
        }
      },
      width: 160,
    },
    {
      field: "item_level_1_name",
      headerName: `${t("inventory.items.item_group")} 1`,
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
      width: 160,
    },
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      sort: "desc",
      width: 160,
      hide: true,
    },
    {
      field: "created_by",
      headerName: t("sentence.created_by"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams) => {
        return <CustomizedAvatar avatars={[params.data.created_by_object]} />;
      },
      width: 135,
      cellStyle: {
        display: "flex",
        alignItems: "flex-start",
        justifyContents: "center",
      },
      hide: true,
    },
  ];

  return colDefs;
};
