import { Box, Grid, IconButton, Tab, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";
import { IDefaultForm } from "../../../types/global";
import EmployeeListForm from "../../Form/EmployeeList";
import ControlledTextField from "../../Controller/ControlledTextField";
import DocDropzoneUI from "../DocDropzoneUI";
import CustomizedChips from "../../Custom/CustomizedChips";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../utils/Formatter/Date";
import { Control, Controller, useWatch } from "react-hook-form";
import Confirmation from "../Confirmation";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { EventStatus, IEvent, IProject } from "../../../types/Project";
import { eventSchema } from "../../Form/Project/Event/schema";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useEventStatusOption } from "../../../hooks/Projects/Event/use-event-status-option";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import ControlledServerSideCreatable from "../../Controller/ControlledSSCreatable";
import { EntityTypeEnum } from "../../../generated/creatable";

import {
  ProjectsQuery,
  useEventCheckInEndMutation,
  useEventCheckInStartMutation,
  useEventCreateMutation,
  useEventDeleteMutation,
  useEventUpdateMutation,
  useProjectsQuery,
} from "../../../generated/project";
import { enqueueSnackbar } from "notistack";
import {
  errorMessageFormatter,
  errorMessageOnDeleteFormatter,
} from "../../../utils/Formatter/Global";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ControlledDateTimePicker from "../../Controller/ControlledDateTimePicker";
import { useEventProjectListOption } from "../../../hooks/Projects/Event/use-event-project-list-option";
import {
  eventCreatePayloadFormatter,
  eventUpdatePayloadFormatter,
} from "../../../utils/Formatter/Project";
import { ContactsQuery, useContactsQuery } from "../../../generated/contact";
import { IContact } from "../../../types/Contact";
import { useEventContactListOption } from "../../../hooks/Projects/Event/use-event-contact-list-option";
import { useEventContactPersonListOption } from "../../../hooks/Projects/Event/use-event-contact-person-list-option";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import dayjs from "dayjs";

type Props = IDefaultForm & {
  eventModal: boolean;
  closeEventModalHandler: () => void;
  project?: IProject;
  refetchEvents?: () => void;
};

const EventModal = ({
  eventModal,
  closeEventModalHandler,
  project,
  refetchEvents,
  control,
  setValue,
  errors,
  getValues,
  reset,
  handleSubmit,
}: Props) => {
  const [planningStartedDateIsOpen, setPlanningStartedDateIsOpen] =
    useState(false);
  const [planningEndedDateIsOpen, setPlanningEndedDateIsOpen] = useState(false);
  const [appointmentStartedDateIsOpen, setAppointmentStartedDateIsOpen] =
    useState(false);
  const [appointmentEndedDateIsOpen, setAppointmentEndedDateIsOpen] =
    useState(false);
  const [deleteEventConfirmation, setDeleteEventConfirmation] = useState(false);
  const [cancelEventConfirmation, setCancelEventConfirmation] = useState(false);

  const [tab, setTab] = useState("1");
  const [isEdit, setIsEdit] = useState(false);
  const watchEventId = useWatch({ control, name: "id" });
  const watchEventAggridStatus = useWatch({ control, name: "aggrid_status" });
  const watchEventStatus = useWatch({ control, name: "main_status" });
  const { selectStatusOptions } = useEventStatusOption(
    watchEventAggridStatus,
    watchEventId
  );
  const [isCheckingIn, setIsCheckingIn] = useState(false);

  const disableEdit =
    watchEventAggridStatus === EventStatus.Finished ||
    watchEventAggridStatus === EventStatus.Cancelled;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const { data: projectsQuery, isLoading: isLoadingProjects } =
    useProjectsQuery<ProjectsQuery>(
      graphQLClientWithHeaderProject,
      {},
      { enabled: eventModal }
    );

  const { projectListOptions } = useEventProjectListOption(
    (projectsQuery?.projects as IProject[]) ?? []
  );

  const { data: contactsQuery, isLoading: isLoadingContacts } =
    useContactsQuery<ContactsQuery>(
      graphQLClientWithHeaderContact,
      {},
      { enabled: eventModal }
    );

  const { contactListOptions } = useEventContactListOption(
    (contactsQuery?.contacts as IContact[]) ?? []
  );

  const watchContactName = useWatch({ control: control, name: "contact_name" });

  const { contactPersonListOptions } = useEventContactPersonListOption(
    (contactsQuery?.contacts as IContact[]) ?? [],
    watchContactName?.id
  );

  const { isLoading: isCreatingEvent, mutate: createEvent } =
    useEventCreateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: ({ createEvent }) => {
        if (createEvent) {
          enqueueSnackbar("สร้างเหตุการณ์สำเร็จ", {
            variant: "success",
          });
        }
        refetchEvents && refetchEvents();
        closeEventModalHandler();
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "event");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างเหตุการณ์ไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdatingEvent, mutate: updateEvent } =
    useEventUpdateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: ({ updateEvent }) => {
        if (updateEvent) {
          setValue("aggrid_status", updateEvent.aggrid_status);

          if (updateEvent.aggrid_status === "cancelled") {
            setValue("cancellation_date", updateEvent.cancellation_date);
          }
        }
        enqueueSnackbar("แก้ไขเหตุการณ์สำเร็จ", {
          variant: "success",
        });
        refetchEvents && refetchEvents();
        setIsEdit(false);
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "event");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขเหตุการณ์ไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { mutate: deleteEvent } = useEventDeleteMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: () => {
        enqueueSnackbar("ลบเหตุการณ์สำเร็จ", {
          variant: "success",
        });
        refetchEvents && refetchEvents();
        closeEventModalHandler();
      },
      onError: (err) => {
        const errorMessage = errorMessageOnDeleteFormatter(err, "event");
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      },
    }
  );

  const { mutate: checkInStartEvent } = useEventCheckInStartMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: ({ checkInStartEvent }) => {
        enqueueSnackbar("เช็คอินสำเร็จ", {
          variant: "success",
        });
        reset && reset(checkInStartEvent);

        refetchEvents && refetchEvents();
        setIsEdit(false);
        setIsCheckingIn(false);
      },
      onError: (err) => {
        setIsCheckingIn(false);
        enqueueSnackbar("เช็คอินไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: checkInEndEvent } = useEventCheckInEndMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: ({ checkInEndEvent }) => {
        enqueueSnackbar("เช็คอินสำเร็จ", {
          variant: "success",
        });
        reset && reset(checkInEndEvent);

        refetchEvents && refetchEvents();
        setIsCheckingIn(false);
        setIsEdit(false);
      },
      onError: (err) => {
        setIsCheckingIn(false);
        enqueueSnackbar("เช็คอินไม่สำเร็จ", {
          variant: "error",
        });

        console.log(err);
      },
    }
  );

  const createEventHandler = async (data: IEvent) => {
    data.contact_name = watchContactName?.value;
    const eventCreatePayload = await eventCreatePayloadFormatter(data);
    console.log(eventCreatePayload);

    createEvent({ data: eventCreatePayload });
  };

  const updateEventHandler = async (data: IEvent) => {
    data.contact_name = watchContactName?.value || watchContactName;
    const eventUpdatePayload = await eventUpdatePayloadFormatter(data);
    console.log(eventUpdatePayload);

    updateEvent({
      data: eventUpdatePayload,
      where: { name: data.name },
    });
  };

  const deleteEventHandler = (eventId: string) => {
    setDeleteEventConfirmation(false);
    deleteEvent({ where: { id: parseInt(eventId) } });
    handleCloseModal();
  };

  const cancelEventHandler = () => {
    setCancelEventConfirmation(true);
  };

  const handleCloseModal = () => {
    closeEventModalHandler();
    reset && reset(eventSchema);
    setTab("1");
  };

  const mapStatusToThai = (status: string) => {
    switch (status) {
      case "planning":
        return t("status.planning");
      case "appointment":
        return t("status.appointment");
      case "check_in":
        return t("status.check_in");
      case "finished":
        return t("status.finished");
      case "cancelled":
        return t("status.cancelled");

      default:
        return "";
    }
  };

  const checkInStart = async (data: IEvent) => {
    if (navigator.geolocation) {
      setIsCheckingIn(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const { coords } = position;
        console.log(coords);

        checkInStartEvent({
          where: { name: data.name },
          lat: coords.latitude.toString(),
          lng: coords.longitude.toString(),
          expectedStatus: "check_in",
        });
      });
    } else {
      enqueueSnackbar("เบราว์เซอร์นี้ไม่รองรับการระบุพิกัด", {
        variant: "warning",
      });
    }
  };

  const checkInDest = (data: IEvent) => {
    if (navigator.geolocation) {
      setIsCheckingIn(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const { coords } = position;

        checkInEndEvent({
          where: { name: data.name },
          lat: coords.latitude.toString(),
          lng: coords.longitude.toString(),
          expectedStatus: "finished",
        });
      });
    } else {
      enqueueSnackbar("เบราว์เซอร์นี้ไม่รองรับการระบุพิกัด", {
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    if (!watchEventId) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [setValue, watchEventId]);

  //autofills project name for an event
  useEffect(() => {
    if (project && eventModal) {
      setValue("project_name", project.name);
    }
  }, [eventModal, project, setValue]);

  //resets date input fields when changing status
  useEffect(() => {
    if (!watchEventId) {
      if (watchEventStatus === EventStatus.Planning) {
        setValue("planning_started_date", dayjs().toDate());
        setValue("planning_ended_date", dayjs().add(1, "h").toDate());
      } else {
        setValue("planning_started_date", null);
        setValue("planning_ended_date", null);
      }

      if (watchEventStatus !== EventStatus.Appointment) {
        setValue("appointment_started_date", null);
        setValue("appointment_ended_date", null);
      }
    }
  }, [setValue, watchEventId, watchEventStatus]);

  const renderEventInfoView = () => {
    return (
      <Grid container spacing={2}>
        <Grid container item spacing={1}>
          <Grid item width="180px">
            <Typography color={"#2167D3"}>
              {t("project.event.event_name")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{getValues("name")}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item width="180px">
            <Typography color={"#2167D3"}>
              {t("project.project.main_status")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{mapStatusToThai(getValues("main_status"))}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>
            <AccessTimeOutlinedIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">วันที่และเวลา</Typography>
          </Grid>
        </Grid>
        {getValues("planning_started_date") && (
          <Grid container item>
            <Grid item>
              <Typography color={"#2167D3"}>
                {t("project.event.status.planning")}
              </Typography>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.started_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("planning_started_date"))}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.ended_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("planning_ended_date"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {getValues("appointment_started_date") && (
          <Grid container item>
            <Grid item>
              <Typography color={"#2167D3"}>
                {t("project.event.status.appointment")}
              </Typography>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.started_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("appointment_started_date"))}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.ended_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("appointment_ended_date"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {getValues("check_in_start_date") && (
          <Grid container item>
            <Grid item>
              <Typography color={"#2167D3"}>
                {t("project.event.status.check_in")}
              </Typography>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.started_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("check_in_start_date"))}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.ended_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("check_in_end_date"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {getValues("cancellation_date") && (
          <Grid container item spacing={0}>
            <Grid item>
              <Typography color={"#2167D3"}>
                {t("project.event.status.cancelled")}
              </Typography>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.cancelled_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDateTimeNoAMPM(getValues("cancellation_date"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item spacing={1} alignItems={"center"}>
          <Grid item>
            <PeopleAltIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">
              {t("project.project.related_user_list")}
            </Typography>
          </Grid>
          <Grid item ml={8}>
            <EmployeeListForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              disabled={true}
              // hideCreator
            />
          </Grid>
        </Grid>
        {(getValues("project_name") || getValues("task_status")) && (
          <Grid container item spacing={1}>
            <Grid item>
              <HomeWorkOutlinedIcon />
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">โครงการ</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("project_name") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>โครงการ</Typography>
            </Grid>
            <Grid item>
              <Typography> {getValues("project_name")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("task_status") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.project.main_status")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("task_status")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("contact_name") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.contact_name")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("contact_name")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("contact_person_name") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.contact_person_name")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("contact_person_name")}</Typography>
            </Grid>
          </Grid>
        )}
        {(getValues("location_name") || getValues("location_link")) && (
          <Grid container item spacing={1}>
            <Grid item>
              <PlaceIcon />
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">สถานที่</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("location_name") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.location_name")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("location_name")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("location_link") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.location_link")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("location_link")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("start_check_in_point.address") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.start_check_point")}
              </Typography>
            </Grid>
            <Grid item xs>
              <>
                <Typography>
                  {getValues("start_check_in_point.address")}
                </Typography>
                <Typography>{`พิกัด : (${getValues(
                  "start_check_in_point.latitude"
                )}, ${getValues(
                  "start_check_in_point.longitude"
                )})`}</Typography>
              </>
            </Grid>
          </Grid>
        )}
        {getValues("destination_check_point.address") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.destination_check_point")}
              </Typography>
            </Grid>
            <Grid item>
              <>
                <Typography>
                  {getValues("destination_check_point.address")}
                </Typography>
                <Typography>{`พิกัด : (${getValues(
                  "destination_check_point.latitude"
                )}, ${getValues(
                  "destination_check_point.longitude"
                )})`}</Typography>
              </>
            </Grid>
          </Grid>
        )}
        {Boolean(getValues("distance")) && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.distance")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("distance")}</Typography>
            </Grid>
          </Grid>
        )}
        {Boolean(getValues("period")) && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.event.period")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("period")}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1} item>
          <Grid item xs={12} sm={"auto"}>
            <CustomizedChips
              value={`${t("project.project.created_date")} : ${formatDate(
                getValues("created_date")
              )}`}
            />
          </Grid>
          <Grid item xs={12} sm>
            <CustomizedChips
              value={`${t("project.task.updated_date")} : ${formatDate(
                getValues("updated_date")
              )}`}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderEventDescriptionView = () => {
    return (
      <Grid item container spacing={2}>
        {getValues("desc") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.task.description")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("desc")}</Typography>
            </Grid>
          </Grid>
        )}
        {getValues("remark") && (
          <Grid container item spacing={1}>
            <Grid item width="180px">
              <Typography color={"#2167D3"}>
                {t("project.project.remark")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{getValues("remark")}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs>
          <DocDropzoneUI
            isModal
            control={control as unknown as Control}
            name="attachment_list"
            setValue={setValue}
            disabled={true}
            multiple
          />
        </Grid>
      </Grid>
    );
  };

  const renderEventInfoForm = () => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              required
              control={control}
              name="name"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              label={t("project.event.event_name")}
              disabled={disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledSelect
              control={control}
              name={"main_status"}
              error={Boolean(errors.main_status)}
              helperText={errors.main_status?.message}
              options={selectStatusOptions}
              label={"สถานะ"}
              required
              disabled={disableEdit}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>
            <AccessTimeOutlinedIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">วันที่และเวลา</Typography>
          </Grid>
        </Grid>
        {((!watchEventId && watchEventStatus === EventStatus.Planning) ||
          (watchEventId && getValues("planning_started_date"))) && (
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ControlledDateTimePicker
                name="planning_started_date"
                control={control}
                error={errors.planning_started_date}
                open={planningStartedDateIsOpen}
                onClose={() => setPlanningStartedDateIsOpen(false)}
                onOpen={() => setPlanningStartedDateIsOpen(true)}
                label={t("project.event.planning_started_date")}
                required={watchEventStatus === EventStatus.Planning || false}
                disabled={disableEdit}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledDateTimePicker
                name="planning_ended_date"
                control={control}
                error={errors.planning_ended_date}
                open={planningEndedDateIsOpen}
                onClose={() => setPlanningEndedDateIsOpen(false)}
                onOpen={() => setPlanningEndedDateIsOpen(true)}
                label={t("project.event.planning_ended_date")}
                required={watchEventStatus === EventStatus.Planning || false}
                disabled={disableEdit}
              />
            </Grid>
          </Grid>
        )}
        {((!watchEventId && watchEventStatus === EventStatus.Appointment) ||
          (watchEventId &&
            (getValues("appointment_started_date") ||
              watchEventStatus === EventStatus.Appointment))) && (
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ControlledDateTimePicker
                name="appointment_started_date"
                control={control}
                error={errors.appointment_started_date}
                open={appointmentStartedDateIsOpen}
                onClose={() => setAppointmentStartedDateIsOpen(false)}
                onOpen={() => setAppointmentStartedDateIsOpen(true)}
                label={t("project.event.appointment_started_date")}
                required={watchEventStatus === EventStatus.Appointment || false}
                disabled={disableEdit}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledDateTimePicker
                name="appointment_ended_date"
                control={control}
                error={errors.appointment_ended_date}
                open={appointmentEndedDateIsOpen}
                onClose={() => setAppointmentEndedDateIsOpen(false)}
                onOpen={() => setAppointmentEndedDateIsOpen(true)}
                label={t("project.event.appointment_ended_date")}
                required={watchEventStatus === EventStatus.Appointment || false}
                disabled={disableEdit}
              />
            </Grid>
          </Grid>
        )}
        {(getValues("check_in_start_date") ||
          getValues("check_in_end_date")) && (
          <Grid container item>
            <Grid item>
              <Typography color={"#2167D3"}>
                {t("project.event.status.check_in")}
              </Typography>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.started_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDate(getValues("check_in_start_date"))}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item width="180px">
                <Typography>{t("date.ended_date")}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {formatDate(getValues("check_in_end_date"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item spacing={1} alignItems={"center"}>
          <Grid item>
            <PeopleAltIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">
              {t("project.project.related_user_list")}
            </Typography>
          </Grid>
          <Grid item ml={2}>
            <EmployeeListForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              disabled={disableEdit}
              // hideCreator
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>
            <HomeWorkOutlinedIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">โครงการ</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={"project_name"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={isLoadingProjects}
                label={t("project.project.name")}
                options={projectListOptions}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value.value);
                  } else {
                    field.onChange(null);
                  }
                }}
                disabled={disableEdit}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledServerSideCreatable
            title={t("project.event.task_status")}
            control={control}
            name="task_status"
            documentType={EntityTypeEnum.Event}
            defaultOptions={[]}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={"contact_name"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={isLoadingContacts}
                label={t("project.event.contact_name")}
                options={contactListOptions}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
                disabled={disableEdit}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={"contact_person_name"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                // loading={isLoadingAllProjects}
                label={t("project.event.contact_person_name")}
                options={contactPersonListOptions}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value.value);
                  } else {
                    field.onChange(null);
                  }
                }}
                disabled={disableEdit}
              />
            )}
          />
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>
            <PlaceIcon />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">สถานที่</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name="location_name"
            label={t("project.event.location_name")}
            disabled={disableEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            name="location_link"
            label={t("project.event.location_link")}
            disabled={disableEdit}
          />
        </Grid>
        {watchEventId && (
          <>
            <Grid
              container
              item
              spacing={3}
              alignItems={"flex-start"}
              // justifyContent={"flex-start"}
            >
              {(EventStatus.Planning === watchEventAggridStatus ||
                EventStatus.Appointment === watchEventAggridStatus ||
                getValues("start_check_in_point.address")) && (
                <Grid item>
                  <Typography fontWeight="bold">
                    {t("project.event.start_check_point")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs>
                {getValues("start_check_in_point.address") ? (
                  <>
                    <Typography>
                      {getValues("start_check_in_point.address")}
                    </Typography>
                    <Typography>{`พิกัด : (${getValues(
                      "start_check_in_point.latitude"
                    )}, ${getValues(
                      "start_check_in_point.latitude"
                    )})`}</Typography>
                  </>
                ) : (
                  (EventStatus.Planning === watchEventAggridStatus ||
                    EventStatus.Appointment === watchEventAggridStatus) && (
                    <CustomizedButton
                      title={t("button.check_in")}
                      onClick={handleSubmit && handleSubmit(checkInStart)}
                      variant="outlined"
                      size="small"
                      disabled={isCheckingIn}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </>
        )}
        {watchEventId &&
          getValues("start_check_in_point.address") &&
          watchEventAggridStatus === EventStatus.CheckIn && (
            <Grid container item spacing={3} alignItems={"flex-start"}>
              <Grid item>
                <Typography fontWeight="bold">
                  {t("project.event.destination_check_point")}
                </Typography>
              </Grid>
              <Grid item xs>
                {getValues("destination_check_point.address") ? (
                  <>
                    <Typography>
                      {getValues("destination_check_point.address")}
                    </Typography>
                    <Typography>{`พิกัด : (${getValues(
                      "destination_check_point.latitude"
                    )}, ${getValues(
                      "destination_check_point.latitude"
                    )})`}</Typography>
                  </>
                ) : (
                  <CustomizedButton
                    title={t("button.check_in")}
                    onClick={handleSubmit && handleSubmit(checkInDest)}
                    variant="outlined"
                    size="small"
                    disabled={isCheckingIn}
                  />
                )}
              </Grid>
            </Grid>
          )}
        <Grid container item spacing={1}>
          <Grid item xs={12} sm={"auto"}>
            <CustomizedChips
              value={`${t("project.project.created_date")} : ${formatDate(
                getValues("created_date")
              )}`}
            />
          </Grid>
          <Grid item xs={12} sm>
            <CustomizedChips
              value={`${t("project.task.updated_date")} : ${formatDate(
                getValues("updated_date")
              )}`}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderEventDescriptionForm = () => {
    return (
      <Grid container spacing={2} alignItems="center" mb={3}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="desc"
            helperText={errors.name?.message}
            label={t("project.task.description")}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="remark"
            helperText={errors.name?.message}
            label={t("project.project.remark")}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs>
          <DocDropzoneUI
            isModal
            control={control as unknown as Control}
            name="attachment_list"
            setValue={setValue}
            disabled={disableEdit}
            multiple
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalUI
      open={eventModal}
      handleClose={handleCloseModal}
      title={watchEventId ? getValues("name") : "สร้างเหตุการณ์"}
      maxWidth="sm"
      fullScreen
      action={
        isEdit && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.save")}
              onClick={() => {
                if (watchEventId) {
                  if (getValues("main_status") === EventStatus.Cancelled) {
                    cancelEventHandler();
                  } else {
                    handleSubmit && handleSubmit(updateEventHandler)();
                  }
                } else {
                  handleSubmit && handleSubmit(createEventHandler)();
                }
              }}
              variant="contained"
              size="medium"
              disabled={isCreatingEvent || isUpdatingEvent}
            />
            <CustomizedButton
              title={t("button.cancel")}
              onClick={() => {
                reset && reset();
                setIsEdit(false);
              }}
              variant="outlined"
              size="medium"
              // disabled={isUpdatingEvent || isDeletingEvent}
            />
            {watchEventId &&
              watchEventAggridStatus !== EventStatus.Finished &&
              watchEventAggridStatus !== EventStatus.Cancelled && (
                <CustomizedButton
                  title={t("button.delete")}
                  onClick={() => setDeleteEventConfirmation(true)}
                  variant="outlined"
                  size="medium"
                  disabled={
                    isUpdatingEvent
                    // ||
                    // isDeletingEvent
                  }
                />
              )}
          </Box>
        )
      }
      status={
        watchEventId && <CustomizedStatus status={watchEventAggridStatus} />
      }
      actionIcon={
        watchEventId && (
          <CustomizedTooltip title="แก้ไข">
            <IconButton
              onClick={() => setIsEdit(true)}
              sx={{ ml: "auto", mr: 6 }}
            >
              <CreateOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )
      }
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab}>
            <Tab label="ข้อมูลทั่วไป" value={"1"} />
            <Tab label="รายละเอียด" value={"2"} />
          </TabList>
        </Box>
        <TabPanel value={"1"}>
          {isEdit ? renderEventInfoForm() : renderEventInfoView()}
        </TabPanel>
        <TabPanel value={"2"}>
          {isEdit ? renderEventDescriptionForm() : renderEventDescriptionView()}
        </TabPanel>
      </TabContext>

      <Confirmation
        title="ยืนยันการลบเหตุการณ์"
        open={deleteEventConfirmation}
        handleClose={() => setDeleteEventConfirmation(false)}
        action={() => deleteEventHandler(watchEventId)}
      />
      <Confirmation
        title="ยืนยันการยกเลิกเหตุการณ์"
        open={cancelEventConfirmation}
        handleClose={() => setCancelEventConfirmation(false)}
        action={() => {
          handleSubmit && handleSubmit(updateEventHandler)();
          setCancelEventConfirmation(false);
        }}
      />
    </ModalUI>
  );
};

export default EventModal;
