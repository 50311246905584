import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { Box, Grid, IconButton, Typography } from "@mui/material";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import CustomizedMenuOptions from "../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";

import { IMenuOption } from "../../../types/global";
import { useRoleDeleteMutation } from "../../../generated/rbac";

import { useDisable } from "../../../hooks/use-disable";
import { useConfirmation } from "../../../hooks/use-confrimation";
import Confirmation from "../../UI/Confirmation";

const RbacHeader = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setDisabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();

  const { getValues } = useFormContext();

  const options: IMenuOption[] = [
    { value: t("sentence.edit") },
    { value: t("button.delete") },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { mutateAsync: roleDelete } = useRoleDeleteMutation<Error>(
    graphQLClient,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess() {
        navigate("/user/rbac");
        enqueueSnackbar("ลบสิทธิ์สำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ลบสิทธิ์ไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const deleteDocumentHandler = async () => {
    const id = getValues("id");
    await roleDelete({
      where: { id: id },
    });
  };

  const {
    confirmation: deleteConfirmation,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(deleteDocumentHandler);

  return (
    <Box mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={6} alignSelf="center">
          <Typography variant="h5">{t("user.rbac.index")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1.5} justifyContent="end" alignSelf="center">
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={t("button.option")}
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("sentence.edit")}`:
                      setDisabled(false);
                      break;
                    case `${t("button.delete")}`:
                      openDeleteConfirmation();
                      break;

                    default:
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  //   onClick={() => {
                  //     setOpenDrawer(true);
                  //   }}
                  // sx={{
                  //   color: (theme) => theme.palette.grey[500],
                  // }}
                  color="primary"
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title={t("sentence.delete_role_title")}
        message={t("sentence.delete_role_message")}
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
      />
    </Box>
  );
};

export default RbacHeader;
