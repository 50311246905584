// import AgGrid from "../../Table/AgGrid";
// import ModalUI from "../../UI/ModalUI";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
// import { useSelector } from "react-redux";
import { useEffect, useState, useCallback, useRef } from "react";
import CustomizedButton from "../../../Custom/CustomizedButton";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { IDefaultForm, IHeaderTable } from "../../../../types/global";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  AttributesQuery,
  useAttributesQuery,
} from "../../../../generated/inventory";
import { IAttribute, IItem } from "../../../../types/Inventory/item";
import ModalUI from "../../../UI/ModalUI";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
};

type FieldArrayType = {
  attribute_list: IItem["attribute_list"];
};

const SpecificInfo = ({
  control,
  errors,
  getValues,
  disabled,
  setValue,
  isInventoryPage,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const attributeListGridRef: any = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (disabled)
      setHeaders([
        { label: t("inventory.attribute.attributeName"), width: 40 },
        { label: t("inventory.attribute.acronym"), width: 200 },
        { label: t("inventory.attribute.attributeValue"), width: 150 },
      ]);
    else
      setHeaders([
        { label: t("inventory.attribute.attributeName"), width: 40 },
        { label: t("inventory.attribute.acronym"), width: 200 },
        { label: t("inventory.attribute.attributeValue"), width: 150 },
        { label: "", width: 150 },
      ]);
  }, [disabled, t]);

  const { fields, append } = useFieldArray<
    FieldArrayType,
    "attribute_list",
    "id"
  >({
    control,
    name: "attribute_list",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data } = useAttributesQuery<AttributesQuery>(
    graphQLClientWithHeaderItem
  );

  const handleSelectAttribute = () => {
    const selectedInfo = attributeListGridRef.current.api.getSelectedRows();
    const findId = fields.map((field) => field.name);
    selectedInfo.forEach((info: IAttribute) => {
      if (!findId.includes(info.name)) append(info);
    });
    setOpenModal(false);
  };

  const handleRemoveAttribute = useCallback(
    (index: number) => {
      const allValue = getValues("attribute_list");
      const filterValue = allValue.filter(
        (_: any, ind: number) => index !== ind
      );
      setValue("attribute_list", filterValue);
    },
    [getValues, setValue]
  );

  const columnDefs = useCallback(
    (enableCheckbox: boolean) => {
      return [
        {
          field: "name",
          headerName: t("inventory.attribute.attributeName"),
          checkboxSelection: enableCheckbox,
          headerCheckboxSelection: enableCheckbox,
        },
        {
          field: "acronym",
          headerName: t("inventory.attribute.acronym"),
        },
        {
          field: "data_type",
          headerName: t("inventory.attribute.attributeType"),
          cellRenderer: (params: any) => {
            if (params.data.type === "number") return "ตัวเลข";
            else return "ตัวอักษร";
          },
        },
        {
          field: "description",
          headerName: t("inventory.attribute.description"),
          width: 400,
          autoHeight: true,
        },
      ];
    },
    [t]
  );

  return (
    <>
      <Box>
        {!disabled && (
          <Grid container alignItems={"center"}>
            <CustomizedButton
              testId="item-open-modal-add-attribute-btn"
              title={t("button.add")}
              variant="outlined"
              onClick={async () => {
                setOpenModal(true);
              }}
              sx={{ my: 2 }}
              disabled={!isInventoryPage}
            />
          </Grid>
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell align="center" key={index} width={header.width}>
                    <Box minWidth={header.width}>
                      <Typography
                        textAlign="center"
                        fontSize={14}
                        fontWeight={700}
                      >
                        {header.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((row, index: number) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <Typography fontSize={14}>{row.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={14}>{row.acronym}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`attribute_list[${index}].value`}
                      control={control}
                      render={({ ...field }) => (
                        <CustomizedTextField
                          {...field}
                          testId={`item-attribute-value-text-field-${index}`}
                          onChange={(e) => {
                            setValue(
                              `attribute_list[${index}].value`,
                              e.target.value
                            );
                          }}
                          value={getValues(`attribute_list[${index}].value`)}
                          error={
                            errors &&
                            errors?.attribute_list &&
                            errors?.attribute_list?.[index]?.value
                          }
                          type={
                            `${row}.data_type` === "number" ? "number" : "text"
                          }
                          disabled={!isInventoryPage || disabled}
                        />
                      )}
                    />
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                        onClick={() => {
                          handleRemoveAttribute(index);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ModalUI
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={t("inventory.items.attribute")}
        fullWidth
        maxWidth="md"
      >
        <AgGrid
          ref={attributeListGridRef}
          columnDefs={columnDefs(true)}
          height={450}
          disabledSidebar
          rowData={data?.attributes ?? []}
          rowSelection="multiple"
          rowMultiSelectWithClick={true}
        />
        <CustomizedButton
          testId="item-add-attribute-btn"
          title="เพิ่ม"
          variant="contained"
          onClick={() => handleSelectAttribute()}
          sx={{ mt: 2 }}
        />
      </ModalUI>
    </>
  );
};

export default SpecificInfo;
