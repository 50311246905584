import {
  FormControlLabel,
  Switch,
  TextFieldProps,
  SwitchProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { SxProps, styled } from "@mui/material/styles";

type Props = {
  name: string;
  label?: TextFieldProps["label"];
  control: Control<any>;
  disabled?: boolean;
  onChange?: (e: any, field?: any) => void;
  sx?: SxProps;
  checked? : boolean;
};

const CustomizedSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 19,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#fff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ControlledSwitch = ({
  name,
  control,
  label,
  disabled,
  onChange,
  sx,
}: Props) => {
  return (
    <Controller
      key={`${name}-switch`}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <CustomizedSwitch
              {...field}
              sx={sx}
              onChange={
                onChange ? (e: any) => onChange(e, field) : field.onChange
              }
              checked={Boolean(field.value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};

export default ControlledSwitch;
