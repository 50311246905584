import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsAdjustmentSchema: IGoodsAdjustment = {
  unique_id: "",
  source_warehouse_unique_id: "",
  main_status: "",
  created_date: dayjs().toDate(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  flag_status: [],
};

const goodsAdjustmentTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .notOneOf([0], "กรุณาระบุจำนวนที่จะปรับปรุง")
    .required("กรุณาระบุจำนวนที่จะปรับปรุง"),
});

const goodsAdjustmentSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .notOneOf([0], "กรุณาระบุจำนวนที่จะปรับปรุง")
          .required("กรุณาระบุจำนวนที่จะปรับปรุง")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsAdjustmentTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsAdjustmentValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().typeError("กรุณาระบุ").required("กรุณาระบุ"),
  source_warehouse_unique_id: Yup.string().required("กรุณาระบุคลัง"),
  trace_entry_list: Yup.array()
    .of(goodsAdjustmentSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
