import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useParams } from "react-router";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsAdjustmentHeader from "../../../components/Form/Inventory/GoodsAdjustment/Header";
import GoodsAdjustmentDetail from "../../../components/Form/Inventory/GoodsAdjustment/Detail";
import GoodsAdjustmentItemList from "../../../components/Table/Inventory/GoodsAdjustment/GoodsAdjustmentItemList";
import {
  goodsAdjustmentSchema,
  goodsAdjustmentValidation,
} from "../../../components/Form/Inventory/GoodsAdjustment/schema";
import {
  GoodsAdjustCreateInput,
  GoodsAdjustUpdateInput,
  GoodsAdjustmentQuery,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  WarehousesQuery,
  useGoodsAdjustmentCreateMutation,
  useGoodsAdjustmentQuery,
  useGoodsAdjustmentUpdateMutation,
  useInventoryDocumentNextStatusMutation,
  useItemSkuQtysQuery,
  useWarehousesQuery,
  useItemSkuDetailsQuery,
  ItemSkuDetailsQuery,
} from "../../../generated/inventory";
import { IGoodsAdjustment } from "../../../types/Inventory/goodsAdjustment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  goodsAdjustmentCreatePayloadFormatter,
  goodsAdjustmentQueryFormatter,
  goodsAdjustmentUpdatePayloadFormatter,
} from "../../../utils/Formatter/GoodsAdjustment";
import { useInventoryError } from "../../../hooks/Inventory/use-inventory-error";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  errorMessageFormatter,
  sortDataByUniqueIdAndName,
} from "../../../utils/Formatter/Global";
import GoodsAdjustmentMobile from "./Mobile";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { IWarehouse } from "../../../types/Setting/inventory";

const GoodsAdjustmentContainer = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();

  const [barcodeList, setBarcodeList] = useState<any[]>([]);

  const [createWithSendApprove, setCreateWithSendApprove] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  const [initialTraceItems, setInitialTraceItems] = useState<string[]>([]);
  const [existingInitialTraceItems, setExistingInitialTraceItems] = useState<
    string[]
  >([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_adjustment.index"),
      to: "/inventory/goods_adjustment",
    },
    {
      name: Boolean(id)
        ? `${id}`
        : `${t("button.create")}${t("inventory.goods_adjustment.index")}`,
    },
  ];

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: goodsAdjustmentSchema,
    resolver: yupResolver(goodsAdjustmentValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const sortedWarehouses = sortDataByUniqueIdAndName(
    warehouseData?.warehouses || []
  ) as IWarehouse[];

  const { data, isLoading, refetch } =
    useGoodsAdjustmentQuery<GoodsAdjustmentQuery>(
      graphQLClientWithHeaderItem,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: fetchItemSkuDetails } =
    useItemSkuDetailsQuery<ItemSkuDetailsQuery>(
      graphQLClientWithHeaderItem,
      {
        findManyInput: {
          sku_name: {
            in: data?.goodsAdjust?.trace_entry_list?.map(
              (trace: any) => trace?.item_unique_id
            ),
          },
        },
      },
      {
        enabled: false,
      }
    );

  useEffect(() => {
    if (data) {
      const traceItems =
        data?.goodsAdjust?.trace_entry_list?.map(
          (trace: any) => trace.item_unique_id
        ) ?? [];
      setInitialTraceItems(traceItems);
      const getItemSkuDetails = async () => {
        const { data: result } = await fetchItemSkuDetails();
        const existingSkuNames =
          result?.itemSkuDetails?.map((sku: any) => sku.sku_name) ?? [];
        setExistingInitialTraceItems(existingSkuNames);
      };
      getItemSkuDetails();
    }
  }, [data, fetchItemSkuDetails]);

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            in: barcodeList,
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (id) {
      const allSkuQty = async () => {
        const { data } = await refetch();
        if (data) {
          const { goodsAdjust } = data;
          const traceEntryList = goodsAdjust?.trace_entry_list;
          const allBarcode =
            traceEntryList?.map((trace) => trace?.barcode) || [];
          setBarcodeList(allBarcode);

          const { data: allSkuQtys } = await refetchSkuQty();

          const goodsAdjustmentType = goodsAdjust as IGoodsAdjustment;
          const formatGoodsIssue = goodsAdjustmentQueryFormatter(
            goodsAdjustmentType,
            allSkuQtys
          );
          reset(formatGoodsIssue);
        }
      };
      allSkuQty();
    }
  }, [data, id, refetch, refetchSkuQty, reset]);

  const { isLoading: isCreating, mutateAsync: create } =
    useGoodsAdjustmentCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: async ({ goodsAdjustCreate }) => {
        if (goodsAdjustCreate) {
          if (createWithSendApprove) {
            await updateStatus({
              documentInput: {
                reference_document_type: InventoryDocumentType.GoodsAdjust,
                unique_id: goodsAdjustCreate.unique_id,
              },
            });
            enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
              variant: "success",
            });
            setCreateWithSendApprove(false);
          } else {
            enqueueSnackbar("สร้างใบปรับปรุงสำเร็จ", {
              variant: "success",
            });
          }
        }
        navigate(`/inventory/goods_adjustment/${goodsAdjustCreate?.unique_id}`);
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบปรับปรุงไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: update } =
    useGoodsAdjustmentUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        refetch();
        enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        refetch();
      },
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.goodsAdjust?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.goodsAdjust?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onGoodsAdjustmentCreate = async (
    data: IGoodsAdjustment,
    status: string
  ) => {
    const payload = goodsAdjustmentCreatePayloadFormatter(
      data
    ) as GoodsAdjustCreateInput;
    await create({
      createInput: { ...payload, sub_status: status },
    });
  };

  const onGoodsAdjustmentUpdate = async (data: IGoodsAdjustment) => {
    if (data) {
      if (data.main_status === "draft") {
        const traces = data.trace_entry_list.map(
          (trace) => trace.item_unique_id
        );
        const matchingIntialTraces = traces.filter((trace) =>
          initialTraceItems.includes(trace)
        );
        const tracesToBeDeleted = matchingIntialTraces.filter(
          (trace) => !existingInitialTraceItems.includes(trace)
        );
        if (tracesToBeDeleted.length > 0) {
          enqueueSnackbar(
            "เอกสารมีสินค้าที่ถูกลบออกจากระบบแล้ว\nกรุณาลบสินค้า",
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
          return;
        }
      }
      const payload = goodsAdjustmentUpdatePayloadFormatter(
        data,
        false
      ) as GoodsAdjustUpdateInput;
      setSnackbarMessage("บันทึกร่าง");
      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onGoodsAdjustmentSendApprove = async (data: IGoodsAdjustment) => {
    if (data) {
      if (!id) {
        setCreateWithSendApprove(true);
        await onGoodsAdjustmentCreate(data, "wait_approve");
      } else {
        setCreateWithSendApprove(false);
        const payload = goodsAdjustmentUpdatePayloadFormatter(
          data,
          false
        ) as GoodsAdjustUpdateInput;
        setSnackbarMessage("ส่งอนุมัติ");
        await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, sub_status: "wait_approve" },
        });
        await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsAdjust,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onGoodsAdjustmentNotApprove = async (data: IGoodsAdjustment) => {
    if (data) {
      const payload = goodsAdjustmentUpdatePayloadFormatter(
        data,
        true
      ) as GoodsAdjustUpdateInput;
      setSnackbarMessage("ไม่อนุมัติ");
      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onGoodsAdjustmentApprove = async (data: IGoodsAdjustment) => {
    if (data) {
      const payload = goodsAdjustmentUpdatePayloadFormatter(
        data,
        false
      ) as GoodsAdjustUpdateInput;

      setSnackbarMessage("อนุมัติ");
      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: { ...payload, sub_status: "finished" },
      });
      await updateStatus({
        documentInput: {
          reference_document_type: InventoryDocumentType.GoodsAdjust,
          unique_id: data.unique_id,
        },
      });
    }
  };

  const renderButton = () => {
    switch (data?.goodsAdjust?.aggrid_status) {
      case "draft":
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.save_draft")}
                  variant="outlined"
                  onClick={handleSubmit(onGoodsAdjustmentUpdate)}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.send")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsAdjustmentSendApprove)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.save_draft")}
                variant="outlined"
                onClick={handleSubmit(onGoodsAdjustmentUpdate)}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                onClick={handleSubmit(onGoodsAdjustmentSendApprove)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }

      case "wait_approve":
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.not_approve")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onGoodsAdjustmentNotApprove)}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.approve")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsAdjustmentApprove)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onGoodsAdjustmentNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onGoodsAdjustmentApprove)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }

      case "finished":
      case "not_approved":
      case "cancelled":
        return null;
      default:
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isCreating}
                  onClick={handleSubmit((data) =>
                    onGoodsAdjustmentCreate(data, "draft")
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.send")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsAdjustmentSendApprove)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating}
                onClick={handleSubmit((data) =>
                  onGoodsAdjustmentCreate(data, "draft")
                )}
              />
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                onClick={handleSubmit(onGoodsAdjustmentSendApprove)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }
    }
  };

  useInventoryError(errors);

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isMobile ? (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsAdjustmentMobile
        control={control}
        disabled={disabled}
        errors={errors}
        getValues={getValues}
        refetch={refetch}
        setValue={setValue}
        data={data}
        reset={reset}
        allWarehouses={sortedWarehouses}
        renderButton={renderButton}
      />
    </form>
  ) : (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsAdjustmentHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsAdjust?.aggrid_status}
        refetch={refetch}
      />
      <GoodsAdjustmentDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsAdjust?.aggrid_status}
        allWarehouses={sortedWarehouses}
        reset={reset}
      />
      <CustomizedBox margin="2rem 0 0 0">
        <GoodsAdjustmentItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          initialTraceItems={initialTraceItems}
          existingInitialTraceItems={existingInitialTraceItems}
        />
      </CustomizedBox>
      {!["finished", "not_approved", "cancelled"].includes(
        data?.goodsAdjust?.aggrid_status || ""
      ) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default GoodsAdjustmentContainer;
