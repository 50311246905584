import { useRef, useState } from "react";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import {
  AttributeType,
  AttributesQuery,
  useAttributeDeleteMutation,
} from "../../../../generated/inventory";
import { IAttribute } from "../../../../types/Inventory/item";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useModal } from "../../../../hooks/use-modal";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import AgGrid from "../../../UI/AgGrid";
import Confirmation from "../../../UI/Confirmation";
import { useTranslation } from "react-i18next";
import AttributeModal from "../../../Form/Attribute/AttributeModal";
import { errorMessageFormatter } from "../../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";

interface Props {
  data?: AttributesQuery;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AttributesQuery, unknown>>;
}

const AttributeTable = ({ data, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef<IAttribute>(null);
  const [currentId, setCurrentId] = useState<number>();
  const [currentName, setCurrentName] = useState<string>();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { mutate } = useAttributeDeleteMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess() {
        enqueueSnackbar(`ลบ${t("setting.inventory.attribute")}สำเร็จ`, {
          variant: "success",
        });
        refetch();
      },
      onError(error) {
        const errorMessage = errorMessageFormatter(error);
        enqueueSnackbar(
          errorMessage || `ลบ${t("setting.inventory.attribute")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const { modal, openModalHandler, closeModalHandler } = useModal();
  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() => {
    mutate({
      uniqueInput: {
        name: currentName!,
      },
    });
  });

  const onEditHandler = (id?: number, name?: string) => {
    setCurrentId(id);
    setCurrentName(name);
    openModalHandler();
  };

  const onDeleteHandler = (id?: number, name?: string) => {
    setCurrentId(id);
    setCurrentName(name);
    openConfirmationHandler();
  };

  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: t("setting.inventory.attribute"),
      filter: "agTextColumnFilter",
    },
    {
      field: "acronym",
      headerName: "ตัวย่อ",
      filter: "agTextColumnFilter",
    },
    {
      field: "data_type",
      headerName: "ชนิดของข้อมูล",
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === AttributeType.Number) {
            return "ตัวเลข";
          } else {
            return "ตัวอักษร";
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === AttributeType.Number) {
          return "ตัวเลข";
        } else {
          return "ตัวอักษร";
        }
      },
    },
    {
      field: "description",
      headerName: "คำอธิบาย",
      filter: "agTextColumnFilter",
    },
    {
      field: "",
      filter: false,
      sortable: false,
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      cellRenderer: (params: ICellRendererParams<IAttribute>) => {
        if (params.data) {
          return (
            <Box>
              <IconButton
                color="primary"
                onClick={() =>
                  onEditHandler(params.data?.id, params.data?.name)
                }
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() =>
                  onDeleteHandler(params.data?.id, params.data?.name)
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <>
      <AgGrid
        columnDefs={columnDefs}
        ref={gridRef}
        rowData={data?.attributes || []}
        height={650}
        disabledSidebar
      />
      <AttributeModal
        open={modal}
        closeModalHandler={closeModalHandler}
        currentId={currentId}
        refetch={refetch}
      />
      <Confirmation
        title={`ยันยืนการลบ${t("setting.inventory.attribute")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default AttributeTable;
