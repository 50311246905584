import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GET_ALL_BIN_LOCATION,
  GET_ALL_WAREHOUSES,
  GET_GROUP_LEVEL_ONE,
  GET_GROUP_LEVEL_THREE,
  GET_GROUP_LEVEL_TWO,
} from "../../../../services/AgGrid/InventoryAgGrid";
import {
  ItemGroupLevel1,
  ItemGroupLevel2,
  ItemGroupLevel3,
  Warehouse,
  WarehouseBinLocation,
} from "../../../../generated/inventory";
import AgGrid from "../../../UI/AgGrid";
import { GET_STOCK_BY_LOT_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";
import { formatNumber } from "../../../../utils/dataTransformer";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Formatter/Date";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const StockByLotReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("inventory.img_url"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams<any, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] ?? "",
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
        } else
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sku_name",
      headerName: t("inventory.items.sku"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_group_level_1",
      headerName: t("inventory.items.item_group") + " 1",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel1s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_ONE);
          const itemGroupLevel1sName = itemGroupLevel1s.map(
            (level1: ItemGroupLevel1) => level1.name
          );
          params.success(itemGroupLevel1sName);
        },
      },
    },
    {
      field: "item_group_level_2",
      headerName: t("inventory.items.item_group") + " 2",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel2s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_TWO);
          const itemGroupLevel2sName = itemGroupLevel2s.map(
            (level2: ItemGroupLevel2) => level2.name
          );
          params.success(itemGroupLevel2sName);
        },
      },
    },
    {
      field: "item_group_level_3",
      headerName: t("inventory.items.item_group") + " 3",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel3s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_THREE);
          const itemGroupLevel3sName = itemGroupLevel3s.map(
            (level3: ItemGroupLevel3) => level3.name
          );
          params.success(itemGroupLevel3sName);
        },
      },
    },
    {
      field: "warehouse_unique_id",
      headerName: t("inventory.warehouseId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesUniqueId = warehouses.map(
            (warehouse: Warehouse) => warehouse.unique_id
          );
          params.success(warehousesUniqueId);
        },
      },
    },
    {
      field: "warehouse_name",
      headerName: t("inventory.warehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
    },
    {
      field: "bin_location_id",
      headerName: t("inventory.itemStockByLot.binLocationId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsId = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.id
          );
          params.success(binLocationsId);
        },
      },
    },
    {
      field: "bin_location_name",
      headerName: t("inventory.itemStockByLot.binLocation"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsName = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.bin_name
          );
          params.success(binLocationsName);
        },
      },
    },
    {
      field: "serial_no",
      headerName: "Serial No.",
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "lot",
      headerName: "Lot",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "purchase_ordered_qty",
      headerName: t("inventory.quantities.purchase_ordered_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "sale_committed_qty",
      headerName: t("inventory.quantities.sale_committed_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "price_per_unit",
      headerName: "ราคาต่อหน่วย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "total_amount",
      headerName: "มูลค่ารวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "item_status",
      headerName: "สถานะสินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { lot, item_status } = filterModel;

      const formatFilter = {
        ...filterModel,
        item_status: item_status && {
          ...item_status,
          values: item_status.values.map((v: string) => parseInt(v)),
        },
        lot: dateFilterModel(lot),
      };

      try {
        const { StockByLotReportViews } = await graphQLClient.request(
          GET_STOCK_BY_LOT_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: StockByLotReportViews.data as any[],
          rowCount: StockByLotReportViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = (params: any) => {
    const instaceItemStatus = params.api.getFilterInstance("item_status");
    instaceItemStatus?.setModel({ values: ["1"] });

    params.api.onFilterChanged();
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default StockByLotReportTable;
