import { Grid, Typography, List, ListItem } from "@mui/material";
import { useCallback, useEffect, useState, Fragment } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  useItemCanDisableQuery,
  ItemCanDisableQuery,
} from "../../../../generated/inventory";
import { Box } from "@mui/system";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedRadioGroup from "../../../Custom/CustomizedRadioGroup";
import CustomizedTextField from "../../../Custom/CustomizedTextField";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IDefaultForm, IRadio } from "../../../../types/global";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import ModalUI from "../../..//UI/ModalUI";
import { IBundleDetail } from "../../../../types/Inventory/item";
import { useSnackbar } from "notistack";
import { ItemType } from "../../../../generated/inventory";
import { GraphQLClient } from "graphql-request";

type ExtendedProps = IDefaultForm & {
  isInventoryPage: boolean;
};

const ActiveStatus = ({
  control,
  disabled,
  setValue,
  getValues,
  errors,
  isInventoryPage,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentStockStatus, setCurrentStockStatus] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [showActiveDocuments, setShowActiveDocuments] =
    useState<boolean>(false);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: itemCanDisable } = useItemCanDisableQuery<ItemCanDisableQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        unique_id: pathname.split("/")[3],
      },
    }
  );

  const radioLists: IRadio[] = [
    { value: 1, label: t("status.active"), testId: "item-active-radio" },
    { value: 0, label: t("status.in_active"), testId: "item-inactive-radio" },
  ];

  const itemType = useWatch({ control, name: "type" });
  const bundleItemList = useWatch({ control, name: "bundle_item_detail_list" });

  const checkIfHaveCurrentStock = useCallback(async () => {
    // const filterItemCurrentStock = {
    //   item_document_id: {
    //     filterType: "text",
    //     type: "equals",
    //     filter: id ?? getValues("document_id"),
    //   },
    // };
    // const { results } = await InventoryService.getAllICS({
    //   startRow: 0,
    //   endRow: 9999999,
    //   filterModel: filterItemCurrentStock,
    // });
    // if (
    //   results.some(
    //     (result: IItem) =>
    //       result.current_quantity && result.current_quantity > 0
    //   )
    // )
    //   return setCurrentStockStatus(true);
    // else return
    setCurrentStockStatus(false);
  }, []);

  const handleChangeActiveStatus = (value: number) => {
    const variationList = getValues("variation_list") || [];
    const updatedVariationList = (items: any) => {
      items.forEach((item: any) => {
        if (item.value) {
          updatedVariationList(item.value);
        } else {
          item.is_active = false;
        }
      });
    };
    updatedVariationList(variationList);
    setValue("variation_list", variationList);
    setValue("is_active", 0);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(handleChangeActiveStatus);

  useEffect(() => {
    checkIfHaveCurrentStock();
  }, [checkIfHaveCurrentStock]);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mt={2} mb={1}>
            <Typography fontWeight="bold">{t("inventory.status")}</Typography>
            <CustomizedTooltip
              title="ไม่สามารถปิดการใช้งานได้ เนื่องจากมีสินค้าคงคลังคงเหลือ จะปิดการใช้งานได้ต่อเมื่อ สินค้าคงคลังเท่ากับศูนย์"
              // nomaxwidth
              placement="right"
            >
              <ErrorOutlineIcon sx={{ fontSize: "14px", ml: 1 }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_active"
            control={control}
            render={({ field }) => (
              <CustomizedRadioGroup
                radioList={radioLists}
                disabled={!isInventoryPage || currentStockStatus || disabled}
                {...field}
                onChange={(e) => {
                  if (pathname.split("/")[3] !== "add" && field.value) {
                    if (!itemCanDisable?.itemCanDisable?.can_disable) {
                      setShowActiveDocuments(true);
                      return;
                    }
                  }
                  if (itemType === ItemType.Variant && field.value)
                    openConfirmationHandler();
                  else if (itemType === ItemType.Variant && !field.value) {
                    const variationList = getValues("variation_list") || [];
                    const updatedVariationList = (items: any) => {
                      items.forEach((item: any) => {
                        if (item.value) {
                          updatedVariationList(item.value);
                        } else {
                          item.is_active = true;
                        }
                      });
                    };
                    updatedVariationList(variationList);
                    setValue("variation_list", variationList);
                    setValue("is_active", 1);
                  } else if (itemType === ItemType.Bundle) {
                    if (
                      bundleItemList.some(
                        (list: IBundleDetail) => !list.reference_item?.is_active
                      ) &&
                      !field.value
                    )
                      enqueueSnackbar(
                        t("inventory.sentence.bundle_item_disabled"),
                        {
                          variant: "error",
                        }
                      );
                    else field.onChange(e.target.value);
                  } else field.onChange(e.target.value);
                }}
                row
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="remark_status"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={"หมายเหตุสถานะ"}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
        <Confirmation
          open={confirmation}
          title={t("inventory.variant.sentence.diabled_item")}
          message={
            t("inventory.variant.sentence.diabled_item_detail_1") +
            " " +
            getValues("name") +
            " " +
            t("inventory.variant.sentence.diabled_item_detail_2")
          }
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </Grid>
      <ModalUI
        open={showActiveDocuments}
        handleClose={() => setShowActiveDocuments(false)}
        title={"ไม่สามารถปิดใช้งานได้ เนื่องจากสินค้าถูกใช้งานในเอกสาร"}
      >
        <Fragment>
          <Typography variant="h6">เอกสารที่ใช้งานสินค้านี้อยู่คือ:</Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {itemCanDisable?.itemCanDisable?.active_document_list?.map(
              (doc) => (
                <ListItem
                  key={doc}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                    }}
                  >
                    {doc}
                  </Typography>
                </ListItem>
              )
            )}
          </List>
        </Fragment>
      </ModalUI>
    </Fragment>
  );
};

export default ActiveStatus;
