import { Box, Typography } from "@mui/material";

const EmptyBarChart = () => {
  return (
    <Box
      display={"flex"}
      height={25}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"#F2F2F2"}
      mt={2}
    >
      <Typography color={"#737373"} fontSize={10}>
        ไม่มีข้อมูล
      </Typography>
    </Box>
  );
};

export default EmptyBarChart;
