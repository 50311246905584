import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
    align: "right",
  },
  {
    thaiLabel: "จำนวนรับคืนแล้ว",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "จำนวนรอรับคืน",
    width: 100,
    align: "right",
  },

  {
    thaiLabel: "จำนวนที่จะรับคืน",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  // {
  //   thaiLabel: "Barcode/SN",
  //   width: 150,
  // },
];

const defaultScanHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
    align: "right",
  },
  {
    thaiLabel: "จำนวนรับคืนแล้ว",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "จำนวนรอรับคืน",
    width: 100,
    align: "right",
  },

  {
    thaiLabel: "จำนวนที่จะรับคืน",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่",
    width: 200,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
];

export const useGoodsReturnItemList = (step: number, disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!disabled) {
      if (step === 1) {
        setHeaders(defaultHeaders);
      } else {
        setHeaders([
          ...defaultScanHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (step === 1) {
        setHeaders(defaultHeaders);
      } else {
        setHeaders(defaultScanHeaders);
      }
    }
  }, [disabled, step]);

  return headers;
};
