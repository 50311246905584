import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { NumericFormat, OnValueChange } from "react-number-format";
// import CustomizedTextField from "../Custom/CustomizedTextField";
import { TextField, TextFieldProps } from "@mui/material";
import { StandardLonghandProperties } from "../../../node_modules/csstype/index";

type ExtendedNumberTextFieldProps = {
  disabled?: TextFieldProps["disabled"];
  control: Control<any>;
  label?: TextFieldProps["label"];
  name: string;
  error?: TextFieldProps["error"];
  sx?: TextFieldProps["sx"];
  helperText?: TextFieldProps["helperText"];
  onChange?: OnValueChange;
  onKeyDown?: TextFieldProps["onKeyDown"];
  required?: TextFieldProps["required"];
  textAlign?: StandardLonghandProperties["textAlign"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  pricePerUnit?: number;
  endAdorment?: JSX.Element;
  helperTextAlign?: StandardLonghandProperties["textAlign"];
  fullWidth?: boolean;
  decimalScale?: number;
  inputFontSize?: number;
  helperTextFontSize?: number;
  allowNegative?: boolean;
};

const ControlledNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  helperText,
  pricePerUnit,
  onChange,
  onKeyDown,
  textAlign,
  InputLabelProps,
  helperTextAlign,
  fullWidth,
  endAdorment,
  decimalScale,
  required,
  inputFontSize,
  helperTextFontSize,
  allowNegative,
}: ExtendedNumberTextFieldProps) => {
  const [warn, setWarn] = useState(false);
  const [showDecimal, setShowDecimal] = useState(true);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          fullWidth={fullWidth}
          size="small"
          label={label}
          value={field.value}
          allowNegative={allowNegative}
          thousandSeparator=","
          decimalScale={
            decimalScale ? decimalScale : decimalScale === 0 ? 0 : 2
          }
          onValueChange={(v, s) => {
            field.onChange(v.floatValue || "");
            if (onChange) {
              onChange(v, s);
            }
            if (pricePerUnit) {
              const value = v.floatValue || 0;
              if (pricePerUnit > value) {
                showWarning();
              } else hideWarning();
            }
          }}
          required={required}
          disabled={disabled}
          customInput={TextField}
          helperText={helperText}
          error={error || warn}
          inputProps={{
            style: { textAlign: textAlign ? textAlign : "right" },
          }}
          InputProps={{
            style: { fontSize: inputFontSize || 16 },
            endAdornment: endAdorment, // Add your adornment here
          }}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={{
            style: {
              fontSize: helperTextFontSize ? helperTextFontSize : 12,
              textAlign: helperTextAlign ? "right" : "left",
            },
          }}
          onFocus={(e) => {
            setShowDecimal(false);
            setTimeout(() => {
              e.target.select();
            }, 1);
          }}
          onBlur={() => {
            setShowDecimal(true);
          }}
          placeholder={
            decimalScale ? "0.00" : decimalScale === 0 ? "0" : "0.00"
          }
          onKeyDown={onKeyDown}
          sx={sx}
          fixedDecimalScale={showDecimal}
        />
      )}
    />
  );
};

export default ControlledNumberTextField;
