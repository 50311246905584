import React from 'react'
import CustomizedMenuOptions from '../../Custom/CustomizedMenuOptions';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../contexts/auth-context';
import { GraphQLClient } from 'graphql-request';
import { createGraphQLClientWithMiddleware } from '../../../services/graphqlClient';
import { useUserQuery } from '../../../generated/company-user';
import { useUserInformationQuery } from '../../../generated/user-infomation';
import { IMenuOption } from '../../../types/global';
import { SettingsOutlined } from '@mui/icons-material';
import useConfigurableAgGridEditButtonOptions, { onConfigurableAgGridOptionSelect } from './useConfigurableAgGridEditButtonOptions';
import { ConfigurableAgGridReact } from './ConfigurableAgGrid';

type Props = {
    tableRef: React.MutableRefObject<ConfigurableAgGridReact<any> | undefined | null>
}

const ConfigurableAgGridEditButton = ({
    tableRef
}: Props) => {
    const { t } = useTranslation();
    const options = useConfigurableAgGridEditButtonOptions()
    return (
        <CustomizedMenuOptions
            size="medium"
            label={t("button.option")}
            isIcon
            options={options}
            onSelect={(e) => {
                const value = e.target as HTMLElement;
                onConfigurableAgGridOptionSelect(value.innerText, tableRef)
            }}
        // disabled={!disabled}
        />
    )
}

export default ConfigurableAgGridEditButton