import { Box, Grid } from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
};

const Stock = ({
  control,
  errors,
  disabled,
  isInventoryPage,
}: ExtendedProps) => {
  return (
    <Box sx={{ overflow: "visible" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {/* <Controller
            control={control}
            name={"minimum_stock"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-minumim-stock-text-field"
                type="number"
                label="Minimum Stock"
                onChange={(e) => field.onChange(parseInt(e.target.value))}
                error={Boolean(errors.minimum_stock)}
                helperText={errors.minimum_stock?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          /> */}
          <ControlledNumberTextField
            fullWidth
            control={control}
            name={"minimum_stock"}
            label="Minimum Stock"
            error={Boolean(errors.minimum_stock)}
            helperText={errors.minimum_stock?.message}
            disabled={!isInventoryPage || disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {/* <Controller
            control={control}
            name={"maximum_stock"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-maximum-stock-text-field"
                type="number"
                label="Maximum Stock"
                onChange={(e) => field.onChange(parseInt(e.target.value))}
                error={Boolean(errors.maximum_stock)}
                helperText={errors.maximum_stock?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          /> */}
          <ControlledNumberTextField
            fullWidth
            control={control}
            name={"maximum_stock"}
            label="Maximum Stock"
            error={Boolean(errors.maximum_stock)}
            helperText={errors.maximum_stock?.message}
            disabled={!isInventoryPage || disabled}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"reorder_point"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-reorder-point-text-field"
                type="number"
                label="Reorder Point"
                onChange={(e) => field.onChange(parseInt(e.target.value))}
                error={Boolean(errors.reorder_point)}
                helperText={errors.reorder_point?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Stock;
