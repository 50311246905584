import React, { ReactNode } from "react";
import ModalUI from "./ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import { Box, DialogContentText } from "@mui/material";

interface Props {
  open: boolean;
  title: string;
  message?: ReactNode | string;
  maxWidth?: any;
  handleClose: () => void;
  action: () => void;
}

const Confirmation = ({
  open,
  title,
  message,
  handleClose,
  action,
  maxWidth,
}: Props) => {
  return (
    <ModalUI
      title={title}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth || "xs"}
      fullWidth
      action={
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={handleClose}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={action}
          />
        </Box>
      }
    >
      {message && (
        <DialogContentText
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          {message}
        </DialogContentText>
      )}
    </ModalUI>
  );
};

export default Confirmation;
