import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useWatch } from "react-hook-form";
// import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
// import CheckedBomModal from "../../Table/CheckedBomModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import EditIcon from "@mui/icons-material/Edit";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
// import ModalUI from "../../UI/ModalUI";
// import BomDetailForm from "../Manufacture/Bom/Detail";
// import BomTableForm from "../Manufacture/Bom/Table";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   bomInitialState,
//   bomValidation,
// } from "../../../features/Manufacture/Bom/bom-initial";
// import { unixToDate } from "../../../utils/date-converter";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { getBomById } from "../../../features/Manufacture/Bom/bom-actions";
// import { useSnackbar } from "notistack";
// import { bomActions } from "../../../features/Manufacture/Bom/bom-slice";
import { IDefaultForm } from "../../../../types/global";
import { EntityTypeEnum } from "../../../../generated/creatable";
import CheckedBomModal from "../../../Table/CheckedBomModal";
import { useModal } from "../../../../hooks/use-modal";
import { useEffect, useState } from "react";
import { formatterBom } from "../../../../utils/Formatter/Bom";
import { IBom } from "../../../../types/Manufacture/bom";
import CheckedRoutingModal from "../../../Table/CheckedRoutingModal";
import { IRouting } from "../../../../types/Manufacture/routing";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
};

const Manufacture = ({
  control,
  errors,
  setValue,
  disabled,
  isInventoryPage,
}: ExtendedProps) => {
  // const StyledLink = styled(Link)(({ theme }) => ({
  //   color: theme.palette.primary.main,
  //   textDecoration: "none",
  //   cursor: "pointer",
  // }));
  const { t } = useTranslation();
  const [bomIds, setBomIds] = useState<string[]>([]);
  const [bomIdsSnapshot, setBomIdsSnapshot] = useState<string[]>([]);
  const [routingIds, setRoutingIds] = useState<string[]>([]);
  const [routingIdsSnapshot, setRoutingIdsSnapshot] = useState<string[]>([]);

  const watchUniqueId = useWatch({ control, name: "unique_id" });
  const watchBomDetail = useWatch({ control, name: "bom_detail" });
  const watchRoutingDetail = useWatch({ control, name: "routing_detail" });

  const {
    modal: showBomTable,
    openModalHandler: openBomTable,
    closeModalHandler: closeBomTable,
  } = useModal();

  const {
    modal: showRoutingTable,
    openModalHandler: openRoutingTable,
    closeModalHandler: closeRoutingTable,
  } = useModal();

  const finishBomSelectHandler = async (data: IBom) => {
    if (data) {
      const { id } = data;
      setValue("bom_id", id);

      const bom_detail = await formatterBom(data);

      setValue("bom_detail", bom_detail);
    }
    closeBomTable();
  };

  const finishRoutingSelectHandler = async (data: IRouting) => {
    if (data) {
      const { id } = data;
      setValue("routing_id", id);

      setValue("routing_detail", data);
    }
    closeRoutingTable();
  };

  useEffect(() => {
    if (watchBomDetail) {
      setBomIds([watchBomDetail.name]);
      setBomIdsSnapshot([watchBomDetail.name]);
    }
  }, [setBomIds, setBomIdsSnapshot, watchBomDetail]);

  useEffect(() => {
    if (watchRoutingDetail) {
      setRoutingIds([watchRoutingDetail.name]);
      setRoutingIdsSnapshot([watchRoutingDetail.name]);
    }
  }, [watchRoutingDetail]);

  const openBomTab = () => {
    window.open("/manufacture/bom?filter=" + watchUniqueId, "_blank");
  };

  const openRoutingTab = () => {
    window.open("/manufacture/routing?filter=" + watchUniqueId, "_blank");
  };

  return (
    <Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="manufacturing_type"
            dataName="manufacturing_type"
            documentType={EntityTypeEnum.Item}
            defaultOptions={[]}
            error={Boolean(errors.manufacturing_type)}
            helperText={errors.manufacturing_type?.message}
            setValue={setValue}
            disabled={!isInventoryPage || disabled}
          />
          {/* <CustomizedTextField
            type="number"
            testId="item-menu-minimun-qty-text-field"
            label={t("inventory.items.manufactureType")}
            error={Boolean(errors.manufacture_type)}
            helperText={errors.manufacture_type?.message}
            disabled={true}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          {/* <Controller
            control={control}
            name={"manufacture_minimum_qty"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                type="number"
                onChange={(e) => field.onChange(parseInt(e.target.value))}
                testId="item-menu-minimun-qty-text-field"
                label={t("inventory.items.manufactureMinimum")}
                disabled={!isInventoryPage || disabled}
              />
            )}
          /> */}
          <ControlledNumberTextField
            fullWidth
            control={control}
            name={"manufacture_minimum_qty"}
            label={t("inventory.items.manufactureMinimum")}
            error={Boolean(errors.manufacture_minimum_qty)}
            helperText={errors.manufacture_minimum_qty?.message}
            disabled={!isInventoryPage || disabled}
          />
        </Grid>
      </Grid>
      <Box my={2} ml={1} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography fontWeight="bold">
          {t("inventory.items.formula")}
        </Typography>
        {isInventoryPage && !disabled && (
          <CustomizedTooltip title="เลือกสูตรผลิต">
            <IconButton onClick={openBomTable} color="primary">
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name={"bom_detail.name"}
            label={t("inventory.items.formula")}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isInventoryPage && (
                    <IconButton
                      onClick={openBomTab}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <LaunchOutlinedIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box my={2} ml={1} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography fontWeight="bold">
          {t("inventory.items.routing")}
        </Typography>
        {isInventoryPage && !disabled && (
          <CustomizedTooltip title="เลือกขั้นตอนงานผลิต">
            <IconButton onClick={openRoutingTable} color="primary">
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name={"routing_detail.name"}
            label={t("inventory.items.routing")}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isInventoryPage && (
                    <IconButton
                      onClick={openRoutingTab}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <LaunchOutlinedIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* <Controller
            control={control}
            error={errors}
            name={"main_bom.name"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.items.formula")}
                disabled
              />
            )}
          /> */}
        </Grid>
      </Grid>
      <CheckedBomModal
        showSelectBom={showBomTable}
        closeBomTable={closeBomTable}
        finishBomSelect={finishBomSelectHandler}
        watchItemId={watchUniqueId}
        bomIds={bomIds}
        setBomIds={setBomIds}
        bomIdsSnapshot={bomIdsSnapshot}
        setBomIdsSnapshot={setBomIdsSnapshot}
      />
      <CheckedRoutingModal
        showSelect={showRoutingTable}
        closeTable={closeRoutingTable}
        finishSelect={finishRoutingSelectHandler}
        watchItemId={watchUniqueId}
        ids={routingIds}
        setIds={setRoutingIds}
        idsSnapshot={routingIdsSnapshot}
        setIdsSnapshot={setRoutingIdsSnapshot}
      />
      {/*  <ModalUI
        open={showBomForm}
        navigateTo={`/manufacture/bom/${watchBomId}`}
        handleClose={closeBomForm}
        fullWidth
        title={watchBomName ?? ""}
        maxWidth="lg"
      >
        <BomDetailForm
          control={bomControl}
          errors={bomErrors}
          reset={bomReset}
          disabled={true}
          minimumStockQty={watchMinimunStockQty}
          isItemMasterPage
        />
        <Divider />
        <BomTableForm control={bomControl} errors={bomErrors} disabled={true} />
      </ModalUI> */}
      {/* <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.accounting")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"manufacture_account"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.items.manufactureAccount")}
                disabled={true}
              />
            )}
          />
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default Manufacture;
