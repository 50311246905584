import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IDefaultForm, ISelectOption } from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import { Controller, UseFieldArrayAppend, useWatch } from "react-hook-form";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import { useCallback, useEffect, useState } from "react";
import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import {
  InventoryDocumentType,
  InventoryUniqueIdQuery,
  ModelType,
  SortOrder,
  useInventoryUniqueIdQuery,
  useItemSkuDetailQuery,
} from "../../../../generated/inventory";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import {
  PurchaseDocumentType,
  PurchaseItemListQuery,
  PurchaseOrdersUniqueIdQuery,
  usePurchaseItemListQuery,
  usePurchaseOrdersUniqueIdQuery,
} from "../../../../generated/purchase";
import { itemListToTraceEntryListFormatter } from "../../../../utils/Formatter/Global";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { EntityTypeEnum } from "../../../../generated/creatable";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { GraphQLClient } from "graphql-request";
import {
  ManufactureOrderQuery,
  ManufactureOrdersUniqueIdQuery,
  useManufactureOrderQuery,
  useManufactureOrdersUniqueIdQuery,
} from "../../../../generated/manufacture";
import { manufactureOrderToTraceEntryFormatter } from "../../../../utils/Formatter/ManufactureOrder";
import { IManufactureOrder } from "../../../../types/Manufacture/order";
import { IItem } from "../../../../types/Inventory/item";
import { IWarehouse } from "../../../../types/Setting/inventory";

const typeOptions: ISelectOption[] = [
  {
    label: "ซื้อ",
    value: "purchase_order",
  },
  {
    label: "ผลิต",
    value: "manufacture_order",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

const StyledLink = styled(Link)<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: 8,
}));

type Props = IDefaultForm & {
  status?: string | null;
  step: number;
  allWarehouses?: IWarehouse[];
  append: UseFieldArrayAppend<IGoodsReceive, "trace_entry_list">;
};

const GoodsReceiveDetail = ({
  control,
  errors,
  disabled,
  status,
  setValue,
  getValues,
  append,
  reset,
  allWarehouses,
  step,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState<string>("");
  const [currentReference, setCurrentReference] = useState<string>("");
  const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
    ISelectOption[]
  >([]);

  const [itemSkuName, setItemSkuName] = useState<string>("");

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchReferenceUniqueId = useWatch({
    control,
    name: "reference_unique_id",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { refetch } = useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
    graphQLClientWithHeaderItem,
    {
      modelType: ModelType.GoodsReceive,
    },
    {
      enabled: false,
    }
  );

  const {
    data: purchaseOrdersUniqueId,
    isFetching,
    refetch: refetchPurchaseOrdersUniqueId,
  } = usePurchaseOrdersUniqueIdQuery<PurchaseOrdersUniqueIdQuery>(
    graphQLClientWithHeaderPurchase,
    {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          aggrid_status: {
            values: ["approved", "partially_imported", "fully_imported"],
            filterType: "set",
          },
          item_list: {
            filterType: "objectArray",
            type: "some",
            filter: {
              item_type: {
                filterType: "text",
                type: "notEqual",
                filter: "service",
              },
            },
          },
        },
        sortModel: [
          {
            sort: SortOrder.Desc,
            colId: "created_date",
          },
        ],
      },
    },
    {
      enabled: false,
    }
  );

  const { refetch: purchaseOrderItemListRefetch } =
    usePurchaseItemListQuery<PurchaseItemListQuery>(
      graphQLClientWithHeaderPurchase,
      {
        whereInput: {
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          reference_unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const {
    data: manufactureOrdersUniqueId,
    isFetching: isManufactureFetching,
    refetch: refetchManufactureOrdersUniqueId,
  } = useManufactureOrdersUniqueIdQuery<ManufactureOrdersUniqueIdQuery>(
    graphQLClientWithHeaderManufacture,
    {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          aggrid_status: {
            values: ["pending_manu", "in_progress", "finished"],
            filterType: "set",
          },
        },
        sortModel: [
          {
            sort: SortOrder.Desc,
            colId: "created_date",
          },
        ],
      },
    },
    {
      enabled: watchType === "manufacture_order",
    }
  );

  useEffect(() => {
    if (watchType) {
      if (watchType === "purchase_order") {
        refetchPurchaseOrdersUniqueId();
      } else {
        refetchManufactureOrdersUniqueId();
      }
    }
  }, [
    refetchManufactureOrdersUniqueId,
    refetchPurchaseOrdersUniqueId,
    watchType,
  ]);

  const { refetch: manufactureOrderRefetch } =
    useManufactureOrderQuery<ManufactureOrderQuery>(
      graphQLClientWithHeaderManufacture,
      {
        uniqueInput: {
          unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchItemSku } = useItemSkuDetailQuery(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: { sku_name: itemSkuName },
    },
    {
      enabled: Boolean(itemSkuName),
    }
  );

  const resetTraceEntry = () => {
    if (reset) {
      reset((prev: IGoodsReceive) => ({
        ...prev,
        trace_entry_list: [],
      }));
    }
  };

  const onTypeChangeHandler = () => {
    setValue("type", currentType);
    setValue("reference_unique_id", "");
    resetTraceEntry();
  };

  useEffect(() => {
    if (watchType) {
      setCurrentType(watchType);
    }
  }, [watchType]);

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onTypeChangeHandler);

  const onReferenceChangeHandler = async () => {
    resetTraceEntry();
    if (currentReference) {
      await setValue("reference_unique_id", currentReference);

      if (currentType === "purchase_order") {
        const { data } = await purchaseOrderItemListRefetch();
        const item_list = data?.purchaseItems;
        if (item_list && item_list.length > 0) {
          const filteredItemList = item_list.filter(
            (item) => item?.item_type !== "service"
          );

          const formatPurchaseOrderItemList = itemListToTraceEntryListFormatter(
            filteredItemList as IPurchaseItemList[],
            InventoryDocumentType.GoodsReceive,
            EntityTypeEnum.PurchaseOrder
          );
          append(formatPurchaseOrderItemList);
        }
      } else if (currentType === "manufacture_order") {
        const { data } = await manufactureOrderRefetch();
        if (data?.manufactureOrder) {
          setItemSkuName(data.manufactureOrder.item_unique_id);
          setTimeout(async () => {
            const { data: itemSkuData } = await refetchItemSku();
            const formatManufactureOrderItemList =
              manufactureOrderToTraceEntryFormatter(
                data.manufactureOrder as IManufactureOrder,
                itemSkuData?.itemSkuDetail?.item_sku?.item as IItem,
                InventoryDocumentType.GoodsReceive
              );
            append(formatManufactureOrderItemList);
          });
        }
      }
    }
  };

  const {
    confirmation: referenceConfirmation,
    openConfirmationHandler: openReferenceConfirmationHandler,
    closeConfirmationHandler: closeReferenceConfirmationHandler,
    submitConfirmationHandler: submitReferenceConfirmationHandler,
  } = useConfirmation(onReferenceChangeHandler);

  const warehouseOptions: ISelectOption[] =
    allWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  useEffect(() => {
    if (watchType === "purchase_order") {
      setReferenceUniqueIdOptions(
        purchaseOrdersUniqueId?.purchaseOrdersFindManyAggrid.data?.map(
          (purchaseOrder) => ({
            id: purchaseOrder?.unique_id || "",
            label: purchaseOrder?.unique_id || "",
            value: purchaseOrder?.unique_id || "",
          })
        ) || []
      );
    } else if (watchType === "manufacture_order") {
      setReferenceUniqueIdOptions(
        manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data?.map(
          (manufactureOrder) => ({
            id: manufactureOrder?.unique_id || "",
            label: manufactureOrder?.unique_id || "",
            value: manufactureOrder?.unique_id || "",
          })
        ) || []
      );
    } else {
      setReferenceUniqueIdOptions([]);
    }
  }, [
    watchType,
    purchaseOrdersUniqueId?.purchaseOrdersFindManyAggrid.data,
    manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data,
  ]);

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetch();
    if (data) {
      setValue("unique_id", data.utilGetUniqueId);
    }
  }, [refetch, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const handleClickOpenRef = () => {
    if (watchType === "manufacture_order")
      return window.open(
        `/manufacture/order/` + watchReferenceUniqueId,
        "_blank"
      );
    else if (watchType === "purchase_order")
      return window.open(`/purchase/order/` + watchReferenceUniqueId, "_blank");
  };

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Typography fontWeight="bold">{t("sentence.detail")}</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ControlledTextField
              control={control}
              name="unique_id"
              error={Boolean(errors && errors.unique_id)}
              helperText={errors && errors.unique_id?.message}
              label={t("sentence.unique_id")}
              disabled={disabled || status === "draft"}
              onChange={(e, field) => {
                const trimmedValue = e.target.value
                  .replaceAll(" ", "")
                  .replaceAll(/\u00a0/g, "");
                field.onChange(trimmedValue);
              }}
              required
            />
            {!id && (
              <CustomizedTooltip
                title="เรียกเลขที่เอกสารใหม่"
                enterNextDelay={200}
              >
                <IconButton onClick={generateUniqueId} color="primary">
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                label={t("sentence.type")}
                error={Boolean(errors && errors.type)}
                helperText={errors && errors.type?.message}
                disabled={disabled || step === 2}
                options={typeOptions}
                onChange={(e: any) => {
                  setCurrentType(e.target.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openConfirmationHandler();
                  } else {
                    field.onChange(e);
                  }
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="reference_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={isFetching || isManufactureFetching}
                label={t("sentence.reference_unique_id")}
                error={Boolean(errors && errors.reference_unique_id)}
                helperText={errors && errors.reference_unique_id?.message}
                options={referenceUniqueIdOptions}
                onChange={async (_, newValue) => {
                  setCurrentReference(newValue.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openReferenceConfirmationHandler();
                  } else {
                    await field.onChange(newValue.value);

                    if (currentType === "purchase_order") {
                      const { data } = await purchaseOrderItemListRefetch();
                      const item_list = data?.purchaseItems;
                      if (item_list && item_list.length > 0) {
                        const filteredItemList = item_list.filter(
                          (item) => item?.item_type !== "service"
                        );

                        const formatPurchaseOrderItemList =
                          itemListToTraceEntryListFormatter(
                            filteredItemList as IPurchaseItemList[],
                            InventoryDocumentType.GoodsReceive,
                            EntityTypeEnum.PurchaseOrder
                          );
                        append(formatPurchaseOrderItemList);
                      }
                    } else if (currentType === "manufacture_order") {
                      const { data } = await manufactureOrderRefetch();
                      if (data?.manufactureOrder) {
                        setItemSkuName(data.manufactureOrder.item_unique_id);
                        setTimeout(async () => {
                          const { data: itemSkuData } = await refetchItemSku();
                          const formatManufactureOrderItemList =
                            manufactureOrderToTraceEntryFormatter(
                              data.manufactureOrder as IManufactureOrder,
                              itemSkuData?.itemSkuDetail?.item_sku
                                ?.item as IItem,
                              InventoryDocumentType.GoodsReceive
                            );
                          append(formatManufactureOrderItemList);
                        });
                      }
                    }
                  }
                }}
                disabled={
                  disabled || step === 2 || !watchType || watchType === "other"
                }
                sx={{
                  input: {
                    visibility:
                      watchType !== "other" && (disabled || step === 2)
                        ? "hidden"
                        : "visible",
                  },
                }}
                InputProps={{
                  startAdornment:
                    watchType !== "other" && (disabled || step === 2) ? (
                      <InputAdornment position="start">
                        <StyledLink onClick={handleClickOpenRef}>
                          {watchReferenceUniqueId}
                        </StyledLink>
                      </InputAdornment>
                    ) : null,
                }}
                required={watchType && watchType !== "other"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            label={t("inventory.warehouse")}
            control={control}
            name="source_warehouse_unique_id"
            options={warehouseOptions}
            error={Boolean(errors && errors.source_warehouse_unique_id)}
            helperText={errors && errors.source_warehouse_unique_id?.message}
            disabled={disabled || step === 2}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            error={Boolean(errors && errors.remark)}
            helperText={errors && errors.remark?.message}
            label={t("sentence.remark")}
            disabled={status === "finished" || status === "cancelled"}
          />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนประเภท"
        message="หากเปลี่ยนประเภทแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนเอกสารอ้างอิง"
        message="หากเปลี่ยนเอกสารอ้างอิงแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={referenceConfirmation}
        handleClose={closeReferenceConfirmationHandler}
        action={submitReferenceConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default GoodsReceiveDetail;
