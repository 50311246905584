import { Box, Skeleton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
// import LaunchIcon from "@mui/icons-material/Launch";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useNavigate } from "react-router-dom";

type Props = {
  name: string;
  qty: number;
  isFetching: boolean;
  status: string;
};

const ItemQtyCard = ({ name, qty, isFetching, status }: Props) => {
  const navigate = useNavigate();
  return (
    <CustomizedBox
      height={"100%"}
      border={`1px solid #E2E2E2`}
      margin={"0.5rem 0"}
      padding={"1rem"}
      boxShadow={"unset"}
    >
      {/* <Link to={url} style={{ textDecoration: "none" }}> */}
      <Box
        display={"flex"}
        gap={0.5}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/inventory/report/stock", {
            state: [status],
          });
        }}
      >
        <Typography color={"secondary.main"} fontWeight={600}>
          {name}
        </Typography>
        {/* <LaunchIcon sx={{ fontSize: 16 }} color="secondary" /> */}
      </Box>
      {/* </Link> */}

      <Box
        pt={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <ShoppingCartOutlinedIcon sx={{ fontSize: 24, color: "#BDBDBD" }} />
        <Typography variant="h5" color={"secondary"}>
          {isFetching ? <Skeleton /> : qty.toLocaleString()}
        </Typography>
      </Box>
      <Typography align="right" fontSize={12} color={"#737373"}>
        รายการ
      </Typography>
    </CustomizedBox>
  );
};

export default ItemQtyCard;
