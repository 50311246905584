import {
  IDateFilterParams,
  ProcessCellForExportParams,
} from "ag-grid-community";
import { formatDateExport } from "./Date";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const dateFilterParams: IDateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    // Handle cases where the date value is undefined or null to prevent 'substring' errors
    if (!cellValue) {
      return -1;
    }
    const dateWithoutTime = cellValue.split(",")[0];
    var dateParts = dateWithoutTime.split("/");
    var year = Number(dateParts[2].substring(0, 4));
    var month = Number(dateParts[1]) - 1;
    var day = Number(dateParts[0]);
    var cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const dateFilterModel = (filterObj: any) => {
  if (!filterObj) {
    return undefined;
  }

  if (filterObj.type === "equals" || filterObj.dateFrom === filterObj.dateTo) {
    const nextDay = dayjs.utc(filterObj.dateFrom).endOf("day");

    return {
      filterType: "date",
      type: "inRange",
      dateFrom: filterObj.dateFrom
        ? dayjs.utc(filterObj.dateFrom).toISOString()
        : undefined,
      dateTo: nextDay ? nextDay.toISOString() : undefined,
    };
  }

  return {
    filterType: "date",
    type: filterObj.type,
    dateFrom: filterObj.dateFrom
      ? dayjs.utc(filterObj.dateFrom).toISOString()
      : undefined,
    dateTo: filterObj.dateTo
      ? dayjs.utc(filterObj.dateTo).endOf("day").toISOString()
      : undefined,
  };
};
export const exportCSVParams = (
  fileName: string,
  startDate?: Date | null,
  endDate?: Date | null
) => {
  let formatFileName;
  if (startDate && endDate) {
    formatFileName = `${fileName}_${formatDateExport(
      startDate
    )}_${formatDateExport(endDate)}`;
  } else {
    formatFileName = fileName;
  }
  return {
    fileName: formatFileName,
    allColumns: true,
    processCellCallback: (params: ProcessCellForExportParams) => {
      if (params.column.getColId() === "created_date")
        return params.value.split(",")[0];
      else if (params.column.getColId() === "group")
        return params.value.map((tag: any) => tag.name).join(",");
      else if (params.column.getColId() === "wo_group")
        return params.value.join(",");
      return params.value;
    },
  };
};
