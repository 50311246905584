import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useContactError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const {
        branch_id,
      } = errors;
      if (branch_id && branch_id?.message?.includes('ไม่สามารถใช้รหัสสาขา')) {
        enqueueSnackbar(branch_id.message, {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);
  return;
};
