import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Typography } from "@mui/material";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  control: IDefaultForm<IGoodsAdjustment>["control"];
  nestedIndex: number;
}

const TotalStockQuantityCell = ({ control, nestedIndex }: Props) => {
  const watchSerialList =
    useWatch({
      control,
      name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
    }) || [];

  const totalStockQuantity = watchSerialList.reduce((prev, curr) => {
    if (curr.stock_qty) {
      return prev + curr.stock_qty;
    } else {
      return prev + 0;
    }
  }, 0);

  return (
    <Typography fontSize={14}>{formatNumber(totalStockQuantity)}</Typography>
  );
};

export default TotalStockQuantityCell;
