import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import { formatNumber } from "../../../../utils/dataTransformer";

import { IDefaultForm } from "../../../../types/global";

type Props = {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
};

const ManuItemListTable = ({ control, errors, disabled }: Props) => {
  const [headers, setHeaders] = useState<
    { label: string; width?: number; align?: TableCellProps["align"] }[]
  >([]);

  const item_unique_id = useWatch({ control, name: "item_unique_id" });
  const item_name = useWatch({ control, name: "item_name" });
  const item_description = useWatch({ control, name: "item_description" });
  const qty = useWatch({ control, name: "actual_production_qty" }) || "0";
  const receive_qty = useWatch({ control, name: "goods_receive_qty" }) || "0";
  const uom = useWatch({ control, name: "uom" });

  useEffect(() => {
    setHeaders([
      { label: "รายการ", width: 40 },
      { label: "ชื่อสินค้า", width: 250 },
      { label: "จำนวนสั่งผลิตจริง", width: 150, align: "right" },
      { label: "จำนวนนำเข้า", width: 150, align: "right" },
      { label: "หน่วย", width: 150 },
    ]);
  }, [disabled]);

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  width={header.width}
                >
                  <Box
                    display="flex"
                    gap={1}
                    justifyContent={!header.align ? "center" : header.align}
                  >
                    <Typography
                      textAlign={!header.align ? "center" : header.align}
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <Typography fontSize={14}>{1}</Typography>
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "stretch",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "stretch",
                      flex: 1,
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      mt={1}
                      color="primary"
                      onClick={() =>
                        window.open(
                          `/inventory/items/${item_unique_id}?tab=item&subtab=general`,
                          "_blank"
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      {item_name}
                    </Typography>

                    <Typography
                      variant="overline"
                      sx={{
                        my: 0,
                        ml: !disabled ? 2 : 0,
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {item_unique_id}
                    </Typography>
                    <Typography
                      variant="body2"
                      align="left"
                      gutterBottom
                      sx={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {item_description}
                    </Typography>
                  </Box>
                  {/* <CustomizedTooltip
                    title={
                      <ItemQty
                        control={control}
                        index={index}
                        documentType="manufacture_order"
                        isSufficient={isSufficient}
                        setIsSufficient={setIsSufficient}
                      />
                    }
                  >
                    <IconButton
                      size="small"
                      sx={{ color: "#8FCBB1", fontSize: "13px" }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </CustomizedTooltip> */}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize={14}>
                  {formatNumber(parseFloat(qty))}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize={14}>
                  {formatNumber(parseFloat(receive_qty))}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={14}>{uom}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManuItemListTable;
