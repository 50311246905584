import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

import {
  SalesActivityLogCreateInput,
  SalesActivityLogCreateMutation,
  SalesActivityLogDocumentType,
  SalesActivityLogFindManyByReferenceIdQuery,
  useSalesActivityLogCreateMutation,
  useSalesActivityLogFindManyByReferenceIdQuery,
} from "../generated/sales";

import {
  PurchaseActivityLogCreateInput,
  PurchaseActivityLogCreateMutation,
  PurchaseActivityLogDocumentType,
  PurchaseActivityLogFindManyByReferenceIdQuery,
  usePurchaseActivityLogCreateMutation,
  usePurchaseActivityLogFindManyByReferenceIdQuery,
} from "../generated/purchase";
import {
  ManufactureActivityLogCreateInput,
  ManufactureActivityLogCreateMutation,
  ManufactureActivityLogDocumentType,
  ManufactureActivityLogFindManyByReferenceIdQuery,
  useManufactureActivityLogCreateMutation,
  useManufactureActivityLogFindManyByReferenceIdQuery,
} from "../generated/manufacture";

export type ServiceType = "sales" | "purchase" | "manufacture";

export type DocumentTypeMap = {
  sales: SalesActivityLogDocumentType;
  purchase: PurchaseActivityLogDocumentType;
  manufacture: ManufactureActivityLogDocumentType;
};

const queryMap: Record<ServiceType, any> = {
  sales:
    useSalesActivityLogFindManyByReferenceIdQuery<SalesActivityLogFindManyByReferenceIdQuery>,
  purchase:
    usePurchaseActivityLogFindManyByReferenceIdQuery<PurchaseActivityLogFindManyByReferenceIdQuery>,
  manufacture:
    useManufactureActivityLogFindManyByReferenceIdQuery<ManufactureActivityLogFindManyByReferenceIdQuery>,
};

interface Props<T extends ServiceType> {
  service: T;
  reference_id: number;
  document_type: string;
}

export const useActivityLogFindManyByReferenceIdQuery = <
  T extends ServiceType
>({
  service,
  reference_id,
  document_type,
}: Props<T>) => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware(service);
  const query = queryMap[service];

  if (!query) {
    throw new Error(`Invalid service type: ${service}`);
  }
  return query(
    graphQLClient,
    {
      findManyInput: {
        reference_id,
        document_type: document_type as DocumentTypeMap[T],
      },
    },
    {
      enabled: false,
    }
  );
};

export const useActivityLogCreate = () => {
  const graphqlClientSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");
  const { mutateAsync: salesLogCreate } =
    useSalesActivityLogCreateMutation<SalesActivityLogCreateMutation>(
      graphqlClientSales
    );

  const salesActivityLogCreate = async (input: SalesActivityLogCreateInput) => {
    await salesLogCreate({
      createInput: {
        ...input,
        document_type: input.document_type as any,
      },
    });
  };

  const graphqlClientPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");
  const { mutateAsync: purchaseLogCreate } =
    usePurchaseActivityLogCreateMutation<PurchaseActivityLogCreateMutation>(
      graphqlClientPurchase
    );

  const purchaseActivityLogCreate = async (
    input: PurchaseActivityLogCreateInput
  ) => {
    await purchaseLogCreate({
      createInput: {
        ...input,
        document_type: input.document_type as any,
      },
    });
  };

  const graphqlClientManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");
  const { mutateAsync: manufactureLogCreate } =
    useManufactureActivityLogCreateMutation<ManufactureActivityLogCreateMutation>(
      graphqlClientManufacture
    );

  const manufactureActivityLogCreate = async (
    input: ManufactureActivityLogCreateInput
  ) => {
    await manufactureLogCreate({
      createInput: {
        ...input,
        document_type: input.document_type as any,
      },
    });
  };

  return {
    salesActivityLogCreate,
    purchaseActivityLogCreate,
    manufactureActivityLogCreate,
  };
};
