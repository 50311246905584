import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import PDFCompanyInfo from "../../Pdf/PDFCompanyInfo";
import PDFFooter from "../../Pdf/PDFFooter";
import PDFLeftInfo from "../../Pdf/PDFLeftInfo";
import PDFHighlight from "../../Pdf/PDFHighlight";
import PDFRightInfo from "../../Pdf/PDFRightInfo";
import PDFSigner from "../../Pdf/PDFSigner";
import { useSearchParams } from "react-router-dom";
import {
  salesOrderPdfTypeEngToEng,
  salesOrderPdfTypeEngToThai,
} from "../../../../utils/Formatter/Global";

interface Props {
  children?: ReactNode;
  documentName: string;
  data: any;
  documentType?: string;
  noCompany?: boolean;
  page?: number;
  allPage?: number;
  footer?: boolean;
  leftHeader?: string[];
  leftInfo?: string[];
  highlightHeader?: string[];
  highlightInfo?: string[];
  rightHeader?: string[];
  rightInfo?: string[];
  bgColor?: string;
  value: any;
}

const SOPdfLayout = ({
  children,
  documentName,
  data,
  documentType,
  noCompany,
  footer,
  page,
  allPage,
  leftHeader = [],
  leftInfo = [],
  highlightHeader = [],
  highlightInfo = [],
  rightHeader = [],
  rightInfo = [],
  bgColor,
  value,
}: Props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  return (
    <Box>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Grid container spacing={1.5}>
            <Grid xs={7}>
              <Box mx={2} mt={1}>
                <PDFCompanyInfo noCompany={noCompany} value={value} />
                <Divider sx={{ mt: 0.5 }} />
                <PDFLeftInfo leftHeader={leftHeader} leftInfo={leftInfo} />
              </Box>
            </Grid>
            <Grid xs={5} height="fit-content">
              <Box mt={1} pr={1}>
                <Typography
                  fontSize={28}
                  fontWeight={600}
                  align="right"
                  color="#186740"
                  lineHeight={1}
                  sx={{ minWidth: "max-content" }}
                >
                  {type
                    ? salesOrderPdfTypeEngToThai[type]
                    : documentName.split("/")[0]}
                </Typography>
                <Typography fontSize={11} align="right" color="#186740">
                  {type
                    ? salesOrderPdfTypeEngToEng[type]
                    : documentName.split("/")[1]}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    fontSize={11}
                    fontWeight={600}
                    align="right"
                    color="#186740"
                    marginRight={1}
                  >
                    เลขที่เอกสาร
                  </Typography>
                  <Typography fontSize={11} align="right" color="#186740">
                    {type
                      ? data?.tax_invoice_number || "-"
                      : data?.unique_id || "-"}
                  </Typography>
                </Box>
                <Box my={0.5}>
                  <PDFHighlight
                    highlightHeader={highlightHeader}
                    highlightInfo={highlightInfo}
                    bgColor={bgColor}
                  />
                  <PDFRightInfo
                    rightHeader={rightHeader}
                    rightInfo={rightInfo}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mt={1}>{children}</Box>
          {footer ? (
            <Box>
              <PDFFooter
                data={data}
                documentType={documentType}
                bgColor={bgColor}
                textColor="#333333"
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
        {footer ? (
          <Box
            sx={{
              mt: "auto",
              mb: 2,
            }}
          >
            <PDFSigner documentType={documentType} />
          </Box>
        ) : (
          ""
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: 28,
            right: 28,
          }}
        >
          <Typography fontSize={11} align="right" color="#333333">
            หน้า {footer && page === 1 ? "1" : `${page}/${allPage}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SOPdfLayout;
