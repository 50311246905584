import { GraphQLClient } from "graphql-request";
import { useCompanyQuery, CompanyQuery } from "../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { renderAddressString } from "../pages/Sales/Quotation/PDF";
import { remarkFormatter } from "../utils/Formatter/PurchaseOrder";

export const usePDFMultiplePage = (
  data: any,
  rowWidth: number,
  maxHeightWithoutFooter: number, //Footer is summary + signer
  maxHeightWithFooter: number,
  leftHeaderMinHeight: number,
  rightHeaderMinHeight: number,
  isInternational?: boolean
) => {
  const baseHeaderHeight = Math.max(leftHeaderMinHeight, rightHeaderMinHeight);
  const companyAddressMaxWidth = 342;
  const addressMaxWidth = 289;

  let paginatedItems: any[][] = [];
  let currentItems: any[] = [];
  let currentTableHeight = 0;

  let itemList = data?.item_list;

  const tenantId = sessionStorage.getItem("tenant-id");

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data: company } = useCompanyQuery<CompanyQuery>(graphQLClient, {
    uniqueInput: {
      unique_id: tenantId,
    },
  });

  const defaultAddress = company?.company?.address_list?.find(
    (address: any) => address.is_default
  );

  //Calculate header height
  const calculateLeftHeaderHeight = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }

    context.font = "11px";

    let numberOfLines = 2;

    const companyAddress = isInternational
      ? `88 Moo 7 Kanchanaphisek-Bang Pa-In Road Lam Pho Subdistrict Bang Bua Thong District Nonthaburi 11110 Thailand`
      : `${defaultAddress?.address} ${defaultAddress?.sub_district} ${defaultAddress?.district} ${defaultAddress?.province} ${defaultAddress?.postal_code}`;

    const customerAddress =
      renderAddressString(data?.customer_contact?.billing_address || {}) || "-";

    let companyAddressWidth = context.measureText(companyAddress).width;
    if (companyAddressWidth > companyAddressMaxWidth) {
      const lines = Math.ceil(companyAddressWidth / addressMaxWidth);
      numberOfLines += lines - 1;
    }

    let customerAddressWidth = context.measureText(customerAddress).width;
    if (customerAddressWidth > addressMaxWidth) {
      const lines = Math.ceil(customerAddressWidth / addressMaxWidth);
      numberOfLines += lines - 1;
    }

    return (
      leftHeaderMinHeight + (numberOfLines - 2) * (!isInternational ? 24 : 20)
    );
  };

  const calculateRightHeaderHeight = () => {
    const referenceIds = data?.reference_unique_id_list;

    let additionalLines = 0;

    if (referenceIds && referenceIds.length > 0) {
      const refLines = Math.ceil(referenceIds.length / 2);
      additionalLines = refLines - 1;
    }

    if (data?.sales_contact_list) {
      //not working yet
      let sellers = data?.sales_contact_list
        .map(
          (contact: any) =>
            `${contact.user_unique_id} - ${contact.first_name} ${contact.last_name}`
        )
        .join(", ");

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (!context) {
        throw new Error("Cannot get 2D context");
      }

      context.font = "11px";

      let width = context.measureText(sellers).width;

      if (data?.sales_contact_list && data?.sales_contact_list.length === 1) {
        if (Math.ceil(width / 180.344) > 1) {
          if (Math.ceil(width / 180.344) > 2) {
            additionalLines += 2;
          } else {
            additionalLines += 1;
          }
        }
      } else if (
        data?.sales_contact_list &&
        data?.sales_contact_list.length > 1
      ) {
        additionalLines += Math.ceil(width / 180.344);
      }
    }

    let remarkAdditionalLines = 0;
    if (isInternational) {
      const remarks = remarkFormatter(data?.remark || "");
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) {
        throw new Error("Cannot get 2D context");
      }
      const letterSpacing = 0.15;
      context.font = "400 11px 'Kanit'";

      const levelOfUrgency = `Level of Urgency: ${remarks[0]}`;
      const shippingMethod = `Shipping Method: ${remarks[1]}`;
      const procurementObjective = `Procurement Objective: ${remarks[2]}`;
      const paymentTerm = `Payment Term: ${remarks[3]}`;
      const dateOfStorageRequest = `Date of Store Request: ${remarks[4]}`;
      const dateOfContactingSupplier = `Date of Contacting Supplier: ${remarks[5]}`;
      const platformName = `Platform Name: ${remarks[6]}`;

      const remarkArray = [
        levelOfUrgency,
        shippingMethod,
        procurementObjective,
        paymentTerm,
        dateOfStorageRequest,
        dateOfContactingSupplier,
        platformName,
      ];

      const remarkWidth: any = [];

      remarkArray.forEach((remark) => {
        remarkWidth.push(context.measureText(remark).width);
      });

      remarkWidth.forEach((remark: number, index: number) => {
        remarkWidth[index] += (remarkArray[index].length - 1) * letterSpacing;
      });

      remarkWidth.forEach((remark: number) => {
        if (remark > 295) {
          remarkAdditionalLines += Math.ceil(remark / 295) - 1;
        }
      });
    }

    return (
      rightHeaderMinHeight +
      additionalLines * 24 +
      (isInternational ? remarkAdditionalLines * 18 : 0)
    );
  };

  const leftHeaderHeight = calculateLeftHeaderHeight();
  const rightHeaderHeight = calculateRightHeaderHeight();
  const additionalHeaderHeight =
    Math.max(leftHeaderHeight, rightHeaderHeight) - baseHeaderHeight;

  maxHeightWithFooter -= additionalHeaderHeight;
  maxHeightWithoutFooter -= additionalHeaderHeight;

  //Calculate row height by item name and description
  itemList?.forEach((item: any) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "11px";

    let numberOfLines = 1;
    let rowHeight = 0;
    const {
      item_id: item_unique_id,
      item_name: itemName,
      item_sku_desc: itemDescription,
    } = item;

    const nameText = item_unique_id + " - " + itemName;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;

    // Handle multiline description
    const descriptionLines = itemDescription ? itemDescription.split("\n") : [];

    descriptionLines.forEach((line: string) => {
      const lineWidth = context.measureText(line).width;
      const linesRequired = Math.ceil(lineWidth / rowWidth);
      numberOfLines += linesRequired > 1 ? linesRequired : 1;
    });

    if (numberOfLines > 2) {
      rowHeight = 50 + (numberOfLines - 2) * 16.5;
    } else {
      rowHeight = 50;
    }

    if (currentTableHeight + rowHeight > maxHeightWithoutFooter) {
      paginatedItems.push(currentItems);
      currentItems = [];
      currentTableHeight = rowHeight;
      currentItems.push(item);
    } else {
      currentTableHeight += rowHeight;
      currentItems.push(item);
    }
  });

  paginatedItems.push(currentItems);
  if (currentTableHeight > maxHeightWithFooter) {
    paginatedItems.push([]);
  }

  let startNumbers: number[] = [];
  let currentNumber = 0;

  paginatedItems.forEach((pageItems, index) => {
    if (index === 0) {
      startNumbers.push(1);
      currentNumber = pageItems.length;
    } else {
      startNumbers.push(currentNumber + 1);
      currentNumber += pageItems.length;
    }
  });

  return {
    paginatedItems,
    startNumbers, //start number of each page
  };
};
