import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

type ExtendedProps = TooltipProps & {
  isNoMaxWidth?: boolean;
  isCalendar?: boolean;
  isDisabled?: boolean;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: number;
};

const CustomizedTooltipComponent = ({ className, ...props }: ExtendedProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
);

export const CustomizedTooltip = styled(CustomizedTooltipComponent)(
  ({
    isCalendar,
    isDisabled,
    isNoMaxWidth,
    padding,
    backgroundColor,
    borderRadius,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor ? backgroundColor : "#ECF6FD",
      color: isDisabled ? "#666666" : isCalendar ? "#2167D3" : "#333333",
      boxShadow: "0px 2px 10px #E5E5E5",
      fontSize: 14,
      fontWeight: isCalendar ? 500 : 400,
      maxWidth: isNoMaxWidth ? "none" : 500,
      border: isCalendar ? "1px solid #2167D3" : "none",
      borderRadius: borderRadius ? borderRadius : 16,
      padding: padding ? padding : undefined,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor ? backgroundColor : "#ECF6FD",
      "&::before": {
        border: isCalendar ? "1px solid #2167D3" : "none",
        boxSizing: "border-box",
      },
    },
  })
);
