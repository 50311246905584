import GoodsReceiveHeader from "../../../components/Form/Inventory/GoodsReceive/Header";
import GoodsReceiveDetail from "../../../components/Form/Inventory/GoodsReceive/Detail";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { GoodsReceiveQuery } from "../../../generated/inventory";
import { IDefaultForm } from "../../../types/global";
import { UseFieldArrayAppend } from "react-hook-form";
import { IGoodsReceive } from "../../../types/Inventory/goodsReceive";
import { IWarehouse } from "../../../types/Setting/inventory";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsReceiveQuery, unknown>>;
  data?: GoodsReceiveQuery;
  allWarehouses?: IWarehouse[];
  step: number;
  append: UseFieldArrayAppend<IGoodsReceive, "trace_entry_list">;
};

const DocumentInfoTab = ({
  control,
  getValues,
  setValue,
  disabled,
  refetch,
  reset,
  step,
  errors,
  data,
  allWarehouses,
  append,
}: Props) => {
  return (
    <>
      <GoodsReceiveHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        refetch={refetch}
      />
      <GoodsReceiveDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        reset={reset}
        allWarehouses={allWarehouses}
        step={step}
        append={append}
      />
    </>
  );
};

export default DocumentInfoTab;
