import React from 'react'
import { useStateContext } from '../../../contexts/auth-context';
import { GraphQLClient } from 'graphql-request';
import { createGraphQLClientWithMiddleware } from '../../../services/graphqlClient';
import { useUserQuery } from '../../../generated/company-user';
import { useUserInformationQuery } from '../../../generated/user-infomation';
import { IMenuOption } from '../../../types/global';
import { SettingsOutlined } from '@mui/icons-material';
import { ConfigurableAgGridReact } from './ConfigurableAgGrid';

type Props = {}

const useConfigurableAgGridEditButtonOptions = () => {
  const {
      state: { authUser },
  } = useStateContext();
  const graphQLClientWithHeaderCompany: GraphQLClient =
      createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderSetting: GraphQLClient =
      createGraphQLClientWithMiddleware("general");
  const { data, isSuccess, isLoading } = useUserQuery(
      graphQLClientWithHeaderCompany,
      {
          uniqueInput: { unique_id: authUser?.unique_id },
      },
      { enabled: !!authUser?.unique_id }
  );

  const { data: userInfoData, isSuccess: isSuccessUserInfo } =
      useUserInformationQuery(
          graphQLClientWithHeaderSetting,
          {
              uniqueInput: { user_unique_id: authUser?.unique_id },
          },
          { enabled: !!authUser?.unique_id }
      );
  const isAdmin = !!userInfoData?.userinformation?.role_list?.find((role) => ['เจ้าของกิจการ', 'แอดมิน'].includes(role.name))
  let options:IMenuOption[] = []
  if (isAdmin) {
      options = [
          {
              icon: <SettingsOutlined />,
              value: "จัดการรูปแบบตาราง (ทุกผู้ใช้งาน)",
          },
          {
              icon: <SettingsOutlined />,
              value: "จัดการรูปแบบตาราง",
          },
      ]
  } else {
      options = [
          {
              icon: <SettingsOutlined />,
              value: "จัดการรูปแบบตาราง",
          },
      ]
  }
  return options
}
    
const onConfigurableAgGridOptionSelect = (option: string, tableRef: React.MutableRefObject<ConfigurableAgGridReact<any> | null | undefined>) => {
  switch (option) {
    case `จัดการรูปแบบตาราง (ทุกผู้ใช้งาน)`:
        tableRef.current?.setMode('everyone')
        break;
    case `จัดการรูปแบบตาราง`:
        tableRef.current?.setMode('onlyme')
        break;
    default:
}
}

export default useConfigurableAgGridEditButtonOptions
export {
  onConfigurableAgGridOptionSelect
}