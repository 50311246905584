import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../generated/inventory";
import { ManufactureOrderQuery } from "../../generated/manufacture";
import { IManufactureOrder } from "../../types/Manufacture/order";
import { ICreatedBy } from "../../types/global";
import { IItem, IUom } from "../../types/Inventory/item";
import { v4 as uuidv4 } from "uuid";
import { ITraceEntry } from "../../types/Inventory";
import { UseFormSetValue } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ITEM_STOCK_UOMS } from "../../services/AgGrid/InventoryAgGrid";
import { GraphQLClient } from "graphql-request";

export const formatQueryManufactureOrder = (
  data: ManufactureOrderQuery["manufactureOrder"]
): IManufactureOrder => {
  const new_work_order_list = data?.work_order_list?.map((work_order) => ({
    ...work_order,
    duration: parseFloat(work_order?.duration || 0),
    work_order_id: work_order?.id,
    id: undefined,
  }));

  const new_ingredient_list = data?.ingredient_list?.map((ingredient) => ({
    ...ingredient,
    qty: parseFloat(ingredient?.qty || 0),
    cost_per_unit: parseFloat(ingredient?.cost_per_unit || 0),
  }));

  const new_waste_production_list = data?.waste_production_list?.map(
    (waste) => ({
      ...waste,
      qty: parseFloat(waste?.qty || 0),
    })
  );

  return {
    ...data,
    production_qty: parseFloat(data?.production_qty || 0),
    work_order_list: new_work_order_list,
    ingredient_list: new_ingredient_list,
    waste_production_list: new_waste_production_list,
  } as IManufactureOrder;
};

export const formatterManufactureOrder = ({
  id,
  created_by,
  created_date,
  main_status,
  bom_id,
  bom_name,
  bom_detail,
  ingredient_list,
  work_order_list,
  waste_production_list,
  tag_list,
  routing_id,
  routing_detail,
  ...otherData
}: IManufactureOrder) => {
  const new_ingredient_list = ingredient_list?.map((ingredient) => ({
    cuid: ingredient?.cuid || undefined,
    item_unique_id: ingredient?.item_unique_id,
    item_name: ingredient?.item_name,
    item_description: ingredient?.item_description,
    item_tracability: ingredient?.item_tracability,
    item_img_url: ingredient?.item_img_url,
    item_barcode: ingredient?.item_barcode,
    qty: ingredient?.qty,
    cost_per_unit: ingredient?.cost_per_unit,
    good_issue_qty: ingredient?.good_issue_qty,
    uom_unique_id: ingredient?.uom_unique_id,
    uom: ingredient?.uom,
    bom_name: ingredient?.bom_name,
  }));

  const new_waste_production_list = waste_production_list?.map(
    (production_list) => ({
      cuid: production_list?.cuid || undefined,
      item_unique_id: production_list.item_unique_id,
      item_name: production_list.item_name,
      item_description: production_list.item_description,
      qty: production_list.qty,
      uom_unique_id: production_list.uom_unique_id,
      uom: production_list.uom,
      remark: production_list.remark,
    })
  );

  const new_tag_list = tag_list?.map((tag) => tag.name) || undefined;

  const new_work_order_list = work_order_list?.map(
    (
      { created_by, actual_duration, duration, cost_price, ...workOrder },
      index
    ) => ({
      ...workOrder,
      indexing: workOrder.indexing || index,
      id: (workOrder as any)?.work_order_id,
      duration:
        duration !== null && (duration as any) !== "" ? duration : undefined,
      cost_price:
        cost_price !== null && (cost_price as any) !== ""
          ? cost_price
          : undefined,
      work_order_id: undefined,
    })
  );

  return {
    ...otherData,
    bom_id: bom_id || bom_detail?.id || null,
    bom_name: bom_name ?? null,
    ingredient_list: new_ingredient_list,
    tag_list: new_tag_list,
    work_order_list: new_work_order_list,
    waste_production_list: new_waste_production_list,
    routing_id: !routing_id ? undefined : routing_id,
    routing_detail: routing_detail ? routing_detail : undefined,
  };
};

export const createInventoryDocumentFromManufactureOrder = async (
  data: IManufactureOrder,
  currentUser: ICreatedBy,
  item?: IItem,
  type?: string
) => {
  const { unique_id, goods_receive_qty, ingredient_list, production_qty } =
    data;

  let formatUom: IUom = {
    unique_id: item?.stock_uom?.unique_id || "",
    name: item?.stock_uom?.name || "",
  };

  const formatTraceEntryGR = [
    {
      item_unique_id: item?.unique_id,
      item_name: item?.name,
      item_img_url: item?.img_url,
      tracability: item?.tracability,
      type: InventoryDocumentType.GoodsReceive,
      unique_id: uuidv4(),
      document_item_qty: parseInt((production_qty || 0).toString()),
      posted_qty: parseInt(goods_receive_qty.toString() ?? "0"),
      qty: 0,
      uom: formatUom,
      serial_list: item?.tracability === Tracability.Serial ? [] : undefined,
      status:
        item?.tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: item?.barcode || undefined,
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const ingredient_item_unique_id_list = ingredient_list?.map(
    (itemInt) => itemInt?.item_unique_id
  );

  const { itemStockUoms } = await graphQLClientWithHeaderItem.request(
    ITEM_STOCK_UOMS,
    {
      itemUniqueIdList: ingredient_item_unique_id_list,
    }
  );

  const formatTraceEntryGI = ingredient_list?.map((itemInt) => {
    const foundItemWithStockUom = itemStockUoms.find(
      (stockUom: any) => stockUom.unique_id === itemInt?.item_unique_id
    );
    return {
      item_unique_id: itemInt?.item_unique_id,
      item_name: itemInt?.item_name,
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsIssue,
      unique_id: uuidv4(),
      document_item_qty: parseInt(itemInt.qty || "0"),
      posted_qty: parseInt(itemInt?.good_issue_qty?.toString() ?? "0"),
      qty: 0,
      uom: foundItemWithStockUom
        ? {
            unique_id: foundItemWithStockUom?.stock_uom?.unique_id || "",
            name: foundItemWithStockUom?.stock_uom?.name || "",
          }
        : {
            unique_id: itemInt?.uom_unique_id || "",
            name: itemInt?.uom || "",
          },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    };
  });

  return {
    type: "manufacture_order",
    reference_unique_id: unique_id,
    trace_entry_list: type === "gr" ? formatTraceEntryGR : formatTraceEntryGI,
    created_by: currentUser,
  };
};

export const manufactureOrderToTraceEntryFormatter = (
  data: IManufactureOrder,
  item?: IItem,
  type?: InventoryDocumentType
) => {
  const { goods_receive_qty, ingredient_list, production_qty } = data;

  let formatUom: IUom = {
    unique_id: item?.stock_uom?.unique_id || "",
    name: item?.stock_uom?.name || "",
  };

  const formatTraceEntryGR: ITraceEntry[] = [
    {
      item_unique_id: item?.unique_id || "",
      item_name: item?.name || "",
      item_img_url: item?.img_url,
      tracability: item?.tracability,
      type: InventoryDocumentType.GoodsReceive,
      unique_id: uuidv4(),
      document_item_qty: parseInt((production_qty || 0).toString()),
      posted_qty: parseInt(goods_receive_qty.toString()) ?? 0,
      qty: 0,
      uom: formatUom,
      serial_list: item?.tracability === Tracability.Serial ? [] : undefined,
      status:
        item?.tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: item?.barcode || undefined,
    },
  ];

  const formatTraceEntryGI: ITraceEntry[] =
    ingredient_list?.map((itemInt) => ({
      item_unique_id: itemInt?.item_unique_id || "",
      item_name: itemInt?.item_name || "",
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsIssue,
      unique_id: uuidv4(),
      document_item_qty: parseInt(itemInt.qty || "0"),
      posted_qty: itemInt?.good_issue_qty
        ? parseInt(itemInt?.good_issue_qty.toString())
        : 0,
      qty: 0,
      uom: {
        unique_id: itemInt?.uom_unique_id || "",
        name: itemInt?.uom || "",
      },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    })) || [];

  const formatTraceEntryRE: ITraceEntry[] =
    ingredient_list?.map((itemInt) => ({
      item_unique_id: itemInt?.item_unique_id || "",
      item_name: itemInt?.item_name || "",
      item_img_url: itemInt?.item_img_url,
      tracability: itemInt?.item_tracability,
      type: InventoryDocumentType.GoodsReturn,
      unique_id: uuidv4(),
      document_item_qty: parseInt(itemInt.qty || "0"),
      posted_qty: itemInt?.good_return_qty
        ? parseInt(itemInt?.good_return_qty.toString())
        : 0,
      qty: 0,
      uom: {
        unique_id: itemInt?.uom_unique_id || "",
        name: itemInt?.uom || "",
      },
      serial_list:
        itemInt?.item_tracability === Tracability.Serial ? [] : undefined,
      status:
        itemInt?.item_tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: itemInt?.item_barcode || undefined,
      // scanned_by: currentUser,
    })) || [];

  if (type === InventoryDocumentType.GoodsReceive) {
    return formatTraceEntryGR;
  } else if (type === InventoryDocumentType.GoodsIssue) {
    return formatTraceEntryGI;
  } else {
    return formatTraceEntryRE;
  }
};

export const copyManufactureOrderformatter = (data: IManufactureOrder) => {
  const {
    id,
    unique_id,
    main_status,
    sub_status,
    flag_status,
    aggrid_status,
    created_by,
    created_date,
    issue_date,
    actual_production_qty,
    production_date,
    production_completion_date,
    goods_receive_qty,
    work_order_list,
    ingredient_list,
    delivery_date,
    ...otherData
  } = data;

  const new_ingredient_list = ingredient_list?.map(
    ({ good_issue_qty, cuid, ...otherData }) => ({
      ...otherData,
      cuid: uuidv4(),
      good_issue_qty: 0,
    })
  );

  const new_work_order_list = work_order_list?.map(
    ({
      id,
      main_status,
      started_date,
      finished_date,
      updated_status_detail,
      ...otherData
    }) => ({
      ...otherData,
      updated_status_detail: [],
    })
  );

  return {
    ...otherData,
    copied_id: id,
    copied_unique_id: unique_id,
    goods_receive_qty: 0,
    actual_production_qty: 0,
    ingredient_list: new_ingredient_list,
    work_order_list: new_work_order_list,
  };
};

export const addUsersToRelatedEmployee = (
  watchCreatedBy: ICreatedBy,
  users: ICreatedBy[],
  relatedUsers: ICreatedBy[],
  setValue: UseFormSetValue<any>
) => {
  const mergedData = Array.from(
    new Set([...users, ...relatedUsers].map((user) => user.user_unique_id))
  )
    .map((user_unique_id) => {
      const formatDataUser = users.find(
        (user) => user.user_unique_id === user_unique_id
      );
      const relatedUsersUser = relatedUsers.find(
        (user: any) => user.user_unique_id === user_unique_id
      );

      return {
        user_unique_id,
        email: formatDataUser?.email || relatedUsersUser?.email,
        first_name: formatDataUser?.first_name || relatedUsersUser?.first_name,
        last_name: formatDataUser?.last_name || relatedUsersUser?.last_name,
        img_url: formatDataUser?.img_url || relatedUsersUser?.img_url,
      };
    })
    .filter((user) => user.user_unique_id !== watchCreatedBy?.user_unique_id);

  setValue("related_user_list", mergedData);
};
