import {
  Box,
  Divider,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../../../utils/dataTransformer";

type Props = {
  totalSales?: number | null;
  totalPurchase?: number | null;
  totalProfit?: number | null;
  isLoading?: boolean;
  isShowSales: boolean | null;
  isShowPurchase: boolean | null;
  isShowProfit: boolean | null;
};

const TotalSalesPurchaseAmount = ({
  totalSales,
  totalPurchase,
  totalProfit,
  isLoading,
  isShowSales,
  isShowPurchase,
  isShowProfit,
}: Props) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box
      display={"flex"}
      flexDirection={isMobile ? "row" : "column"}
      gap={1}
      width={isMobile ? "100%" : 180}
      justifyContent={isMobile ? "center" : "unset"}
      textAlign={"right"}
    >
      {isShowSales && (
        <>
          {isMobile && (
            <Divider orientation="vertical" variant="fullWidth" flexItem />
          )}
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <Box
                sx={{
                  width: isMobile ? 12 : 14,
                  height: isMobile ? 12 : 14,
                  borderRadius: 100,
                  backgroundColor: "#84B6F7",
                }}
              />
              <Typography color={"#59A1FF"} fontSize={isMobile ? 14 : 16}>
                {t("dashboard.total_sales")}
              </Typography>
            </Box>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              sx={{ fontSize: isMobile ? 14 : undefined, fontWeight: 600 }}
            >
              {isLoading ? (
                <Skeleton animation={"wave"} />
              ) : totalSales ? (
                formatNumber(totalSales)
              ) : (
                "-"
              )}
            </Typography>
          </Box>
        </>
      )}
      {isShowPurchase && (
        <>
          {isMobile && (
            <Divider orientation="vertical" variant="fullWidth" flexItem />
          )}
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <Box
                sx={{
                  width: isMobile ? 12 : 14,
                  height: isMobile ? 12 : 14,
                  borderRadius: 100,
                  backgroundColor: "#FF9090",
                }}
              />
              <Typography color={"#FF9090"} fontSize={isMobile ? 14 : 16}>
                {t("dashboard.total_purchase")}
              </Typography>
            </Box>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              sx={{ fontSize: isMobile ? 14 : undefined, fontWeight: 600 }}
            >
              {isLoading ? (
                <Skeleton animation={"wave"} />
              ) : totalPurchase ? (
                formatNumber(totalPurchase)
              ) : (
                "-"
              )}
            </Typography>
          </Box>
        </>
      )}
      {isShowProfit && (
        <>
          {isMobile && (
            <Divider orientation="vertical" variant="fullWidth" flexItem />
          )}
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <Box
                sx={{
                  width: isMobile ? 12 : 14,
                  height: isMobile ? 12 : 14,
                  borderRadius: 100,
                  backgroundColor: "#A488E7",
                }}
              />
              <Typography color={"#A488E7"} fontSize={isMobile ? 14 : 16}>
                {t("dashboard.total_profit")}
              </Typography>
            </Box>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              sx={{ fontSize: isMobile ? 14 : undefined, fontWeight: 600 }}
            >
              {isLoading ? (
                <Skeleton animation={"wave"} />
              ) : totalProfit ? (
                formatNumber(totalProfit)
              ) : (
                "-"
              )}
            </Typography>
          </Box>
        </>
      )}
      {isMobile && (
        <Divider orientation="vertical" variant="fullWidth" flexItem />
      )}
    </Box>
  );
};

export default TotalSalesPurchaseAmount;
