import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { IUserArray } from "../../../types/Auth/user";
import { ICreatedBy, IDefaultForm } from "../../../types/global";
import CheckedUserModal from "../../Table/CheckedUserModal";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RelatedEmployeeIcon from "./RelatedEmployeeIcon";
import { useTranslation } from "react-i18next";

type FieldArrayType = {
  sales_contact_list: IUserArray;
};

const SalesEmployeeListForm = ({
  control,
  setValue,
  disabled,
}: IDefaultForm) => {
  const [showSelectEmployees, setShowSelectEmployees] =
    useState<boolean>(false);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState<string[]>([]);
  const { t } = useTranslation();
  const { fields, remove } = useFieldArray<
    FieldArrayType,
    "sales_contact_list",
    "id"
  >({
    control,
    name: "sales_contact_list",
  });

  const removeEmployee = (index: number) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderAvatars = () => {
    return fields.map((item, index) => {
      return (
        <RelatedEmployeeIcon
          isMain={false}
          key={item.id}
          first_name={item.first_name}
          last_name={item.last_name}
          index={index}
          img_url={item.img_url ? item?.img_url[0] : ""}
          remove={removeEmployee}
          disabled={disabled}
        />
      );
    });
  };

  const finishEmployeesSelect = (data: any[]) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.unique_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.unique_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.unique_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.unique_id) - employeeIds.indexOf(b.unique_id)
      );
    });

    const formatRelatedUserList: ICreatedBy[] = filteredEmployees.map(
      (employee) => ({
        user_unique_id: employee.unique_id,
        email: employee.email,
        first_name: employee.first_name,
        last_name: employee.last_name,
        img_url: employee.img_url,
      })
    );

    setValue("sales_contact_list", formatRelatedUserList);
    setShowSelectEmployees(false);
  };

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        {renderAvatars()}
        {!disabled && (
          <Grid item>
            <CustomizedTooltip title="เพิ่มผู้รับผิดชอบ" enterNextDelay={200}>
              <IconButton onClick={openEmployeeTable} color="primary">
                <ControlPointOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Grid>
        )}
      </Grid>
      <CheckedUserModal
        title={t("sales.sales_contact_list")}
        showSelectUser={showSelectEmployees}
        closeUserTable={closeEmployeeTable}
        finishUsersSelect={finishEmployeesSelect}
        userIds={employeeIds}
        setUserIds={setEmployeeIds}
        userIdsSnapshot={employeeIdsSnapshot}
        setUserIdsSnapshot={setEmployeeIdsSnapshot}
        rowSelection="multiple"
      />
    </>
  );
};

export default SalesEmployeeListForm;
