import {
  Grid,
  Link,
  IconButton,
  InputAdornment,
  LinkProps,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDefaultForm } from "../../../types/global";
import ControlledTextField from "../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import styled from "@emotion/styled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import CheckedContactModal from "../../Table/CheckedContactModal";
import BillingAddressForm from "./BillingAddressForm";
import DeliveryAddressForm from "./DeliveryAddressForm";
import { useContactDescription } from "../../../hooks/use-contact-description";
import ModalUI from "../../UI/ModalUI";
import ContactContainer from "../../../pages/Contact/Container";
import SalesInfo from "../Sales/SalesInfo";
import { EntityTypeEnum } from "../../../generated/creatable";
interface ThemeComponentProps extends LinkProps {
  color?: "primary" | "secondary";
}

type Props = IDefaultForm & {
  documentType: EntityTypeEnum;
};

const StyledLink = styled(Link)<ThemeComponentProps>(({ theme }) => ({
  // color: theme.pallete.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const CustomerInfoForm = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
  reset,
  documentType,
}: Props) => {
  const { t } = useTranslation();

  const {
    contact,
    contactUniqueId,
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openBillingAddress,
    billingAddressSnapshot,
    setBillingAddressSnapshot,
    openDeliveryAddress,
    deliveryAddressSnapshot,
    setDeliveryAddressSnapshot,
    allAddressList,
    openContactTableHandler,
    openBillingAddressHandler,
    closeBillingAddressHandler,
    openDeliveryAddressHandler,
    closeDeliveryAddressHandler,
    finishContactsSelect,
    openContactDetail,
    openContactDetailHandler,
    closeContactDetailHandler,
  } = useContactDescription(control, reset, "sales", documentType);

  const renderAddressString = (values: string) => {
    const addressValues = getValues(`customer_contact[${values}]`);
    if (addressValues) {
      const {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      const orderedValues = {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            const newValue = orderedValues[key as keyof typeof orderedValues];
            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "";
  };

  const renderAddressContact = () => {
    const addressValues = getValues("customer_contact.delivery_address");

    if (addressValues) {
      const { address_contact_name, address_contact_phone } = addressValues;

      if (address_contact_name && address_contact_phone) {
        const result = `${address_contact_name}, ${address_contact_phone}`;
        return result;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={6} sm={4} md={2} pl={1}>
          <Typography fontWeight="bold">
            {t("sales.customer_description")}
          </Typography>
        </Grid>
        {!disabled && (
          <Grid item xs={2}>
            <Box>
              <CustomizedTooltip title={t("sentence.choose_customer")}>
                <IconButton onClick={openContactTableHandler} color="primary">
                  <EditOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.unique_id_name"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.unique_id_name
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.unique_id_name?.message
            }
            label="รหัสลูกค้า - ชื่อลูกค้า"
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openContactDetailHandler}>
                    {contact?.unique_id_name}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: !disabled && contactUniqueId && (
                <InputAdornment position="end">
                  <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                    <IconButton
                      onClick={openContactDetailHandler}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <LaunchOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.identity_no"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.identity_no
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.identity_no?.message
            }
            label="เลขประจำตัวผู้เสียภาษี"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.branch"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.branch
            )}
            helperText={
              errors.customer_contact && errors.customer_contact.branch?.message
            }
            label="ประเภทสำนักงาน"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.phone"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.phone
            )}
            helperText={
              errors.customer_contact && errors.customer_contact.phone?.message
            }
            label="เบอร์โทรศัพท์"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.email"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.email
            )}
            helperText={
              errors.customer_contact && errors.customer_contact.email?.message
            }
            label={t("user.account.email")}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.fax"
            error={Boolean(
              errors.customer_contact && errors.customer_contact.fax
            )}
            helperText={
              errors.customer_contact && errors.customer_contact.fax?.message
            }
            label="โทรสาร"
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {!Boolean(documentType === EntityTypeEnum.DeliveryOrder) && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={4} md={2} pl={1}>
              <Typography fontWeight="bold">
                {t("global.billing_address")}
              </Typography>
            </Grid>
            {!disabled && (
              <Grid item xs={2}>
                {contactUniqueId ? (
                  <CustomizedTooltip title="แก้ไขที่อยู่จดทะเบียน">
                    <IconButton
                      onClick={openBillingAddressHandler}
                      color="primary"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                ) : null}
              </Grid>
            )}
          </Grid>
          <Typography variant="body1" sx={{ my: 2 }}>
            {renderAddressString("billing_address")}
          </Typography>
        </>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={4} md={2} pl={1}>
          <Typography fontWeight="bold">
            {t("sentence.deliver_location")}
          </Typography>
        </Grid>
        {!disabled && (
          <Grid item xs={2}>
            {contactUniqueId ? (
              <CustomizedTooltip title="แก้ไขที่อยู่จัดส่ง">
                <IconButton
                  onClick={openDeliveryAddressHandler}
                  color="primary"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            ) : null}
          </Grid>
        )}
      </Grid>
      <Typography variant="body1">{renderAddressContact()}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {renderAddressString("delivery_address")}
      </Typography>
      <SalesInfo
        control={control}
        errors={errors}
        disabled={disabled}
        getValues={getValues}
        setValue={setValue}
        documentType={documentType}
      />
      <CheckedContactModal
        showSelectContact={openContactTable}
        closeContactTable={closeContactTableHandler}
        contactIds={contactIds}
        setContactIds={setContactIds}
        contactIdsSnapshot={contactIdsSnapshot}
        setContactIdsSnapshot={setContactIdsSnapshot}
        finishContactsSelect={finishContactsSelect}
        type="customer"
      />
      <BillingAddressForm
        control={control}
        errors={errors}
        open={openBillingAddress}
        handleClose={closeBillingAddressHandler}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        billingAddressSnapshot={billingAddressSnapshot}
        setBillingAddressSnapshot={setBillingAddressSnapshot}
      />
      <DeliveryAddressForm
        control={control}
        errors={errors}
        open={openDeliveryAddress}
        handleClose={closeDeliveryAddressHandler}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        deliveryAddressSnapshot={deliveryAddressSnapshot}
        setDeliveryAddressSnapshot={setDeliveryAddressSnapshot}
        allAddressList={allAddressList}
      />
      <ModalUI
        open={openContactDetail}
        handleClose={closeContactDetailHandler}
        maxWidth="lg"
      >
        <ContactContainer
          isContactPage={false}
          contactUniqueId={contactUniqueId}
        />
      </ModalUI>
    </CustomizedBox>
  );
};

export default CustomerInfoForm;
