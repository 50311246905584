import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm, ITab } from "../../../types/global";
import { GoodsTransferQuery } from "../../../generated/inventory";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DocumentInfoTab from "./DocumentInfoTab";
import BarcodeTab from "./BarcodeTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IGoodsTransfer } from "../../../types/Inventory/goodsTransfer";
import { IWarehouse } from "../../../types/Setting/inventory";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsTransferQuery, unknown>>;
  data?: GoodsTransferQuery;
  allWarehouses?: IWarehouse[];
  renderButton: () => JSX.Element | null;
  fields: FieldArrayWithId<IGoodsTransfer, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsTransfer, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
};

const GoodsTransferMobile = ({
  control,
  getValues,
  setValue,
  disabled,
  refetch,
  reset,
  errors,
  data,
  allWarehouses,
  fields,
  append,
  remove,
  update,
  renderButton,
}: Props) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs: ITab[] = [
    {
      label: "ข้อมูลโอนย้าย",
      path: `${pathname}`,
    },
    {
      label: "สแกน Barcode",
      path: `${pathname}?tab=barcode`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "barcode":
        setCurrentTab(pathname + "?tab=barcode");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "barcode":
        return (
          <BarcodeTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            renderButton={renderButton}
            fields={fields}
            append={append}
            remove={remove}
            update={update}
            data={data}
            allWarehouses={allWarehouses}
          />
        );
      default:
        return (
          <DocumentInfoTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            allWarehouses={allWarehouses}
            refetch={refetch}
            data={data}
          />
        );
    }
  };

  return (
    <form>
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </form>
  );
};

export default GoodsTransferMobile;
