import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TopItemChart from "./TopItemChart";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  ManufactureOrderByStatusQuery,
  MostManufactureItemByStatusQuery,
  useManufactureOrderByStatusQuery,
  useMostManufactureItemByStatusQuery,
  useWorkOrderByStatusQuery,
  WorkOrderByStatusQuery,
} from "../../../../../generated/manufacture";
import { IDashboardInput } from "../../../../../types/Dashboard";
import { useEffect } from "react";
import ManufactureOrderChart from "./ManufactureOrderChart";
import WorkOrderChart from "./WorkOrderChart";

type Props = {
  input: IDashboardInput;
};

const ManufactureDashboard = ({ input }: Props) => {
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("manufacture");

  const {
    data: pendingManu,
    refetch: refetchPendingManu,
    isFetching: isFetchingPendingManu,
  } = useMostManufactureItemByStatusQuery<MostManufactureItemByStatusQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
        status: "pending_manu",
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  const {
    data: inProgress,
    refetch: refetchInProgress,
    isFetching: isFetchingInProgress,
  } = useMostManufactureItemByStatusQuery<MostManufactureItemByStatusQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
        status: "in_progress",
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  const {
    data: manufactureOrder,
    refetch: refetchManufactureOrder,
    isFetching: isFetchingManufactureOrder,
  } = useManufactureOrderByStatusQuery<ManufactureOrderByStatusQuery>(
    graphQLClient,
    {
      manufactureDateInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  const {
    data: workOrder,
    refetch: refetchWorkOrder,
    isFetching: isFetchingWorkOrder,
  } = useWorkOrderByStatusQuery<WorkOrderByStatusQuery>(
    graphQLClient,
    {
      manufactureDateInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    refetchPendingManu();
    refetchInProgress();
    refetchManufactureOrder();
    refetchWorkOrder();
  }, [
    refetchPendingManu,
    refetchInProgress,
    refetchManufactureOrder,
    refetchWorkOrder,
  ]);

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TopItemChart
          name={t("dashboard.pending_manu_item")}
          data={pendingManu?.MostManufactureItemByStatus}
          isFetching={isFetchingPendingManu}
          url="/manufacture/order?filter=pending_manu"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TopItemChart
          name={t("dashboard.in_progress_item")}
          data={inProgress?.MostManufactureItemByStatus}
          isFetching={isFetchingInProgress}
          url="/manufacture/order?filter=in_progress"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <ManufactureOrderChart
          data={manufactureOrder?.ManufactureOrderByStatus}
          isFetching={isFetchingManufactureOrder}
        />
        <WorkOrderChart
          data={workOrder?.WorkOrderByStatus}
          isFetching={isFetchingWorkOrder}
        />
      </Grid>
    </Grid>
  );
};

export default ManufactureDashboard;
