import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import ModalUI from "../ModalUI";
import ButtonLayout from "../ButtonLayout";
import CustomizedButton from "../../Custom/CustomizedButton";

import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { Alert } from "@mui/material";

interface TaxInvoiceModalProps {
  modal: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  submitHandler: (data: any) => Promise<void>;
  isUpdating?: boolean;
}

const POTaxInvoiceModal = ({
  modal,
  handleClose,
  submitHandler,
  isUpdating,
}: TaxInvoiceModalProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      tax_invoice_no: "",
      tax_invoice_date: dayjs(),
    },
    resolver: yupResolver(
      Yup.object().shape({
        tax_invoice_date: Yup.date()
          .required("กรุณาระบุวันที่ใบกำกับภาษี")
          .typeError("กรุณาระบุวันที่ใบกำกับภาษี"),
        tax_invoice_no: Yup.string().required("กรุณาระบุเลขที่ใบกำกับภาษี"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  return (
    <ModalUI
      title="บันทึกใบกำกับภาษี"
      open={modal}
      handleClose={handleClose}
      action={
        <ButtonLayout>
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={handleClose}
            disabled={isUpdating}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={handleSubmit(submitHandler)}
            disabled={isUpdating}
          />
        </ButtonLayout>
      }
      maxWidth="sm"
    >
      <ControlledDatePicker
        control={control}
        name="tax_invoice_date"
        label={t("sales.tax_invoice_date")}
        error={errors?.tax_invoice_date}
        disabled={isUpdating}
        helperTextPosition
        required
      />
      <ControlledTextField
        control={control}
        name="tax_invoice_no"
        label={t("sales.tax_invoice_number")}
        error={Boolean(errors?.tax_invoice_no)}
        helperText={errors?.tax_invoice_no?.message?.toString()}
        disabled={isUpdating}
        sx={{ marginTop: 2 }}
        required
      />
      <Alert
        severity="info"
        sx={{
          color: "#333333",
          bgcolor: "#ECF6FD",
          marginTop: 2,
          height: 1,
          padding: "0px 16px",
        }}
      >
        หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
      </Alert>
    </ModalUI>
  );
};

export default POTaxInvoiceModal;
