import { Box, Typography } from "@mui/material";

interface Props {
  leftHeader: string[];
  leftInfo: string[];
  isInternational?: boolean;
}

const PDFLeftInfo = ({ leftHeader, leftInfo, isInternational }: Props) => {
  if (!isInternational) {
    return (
      <>
        <Box paddingTop={1}>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.4,
              }}
            >
              {leftHeader[0]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[0]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.4,
              }}
            >
              {leftHeader[1]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[1]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.4,
              }}
            >
              {leftHeader[2]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[2]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.6,
              }}
            >
              {leftHeader[3]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 0.6,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[3]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.3,
              }}
            >
              {leftHeader[4]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 0.6,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[4]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.4,
              }}
            >
              {leftHeader[5]}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[5]}
            </Typography>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <Box paddingTop={1}>
        {leftHeader.map((item, index) => (
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "20px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.4,
              }}
            >
              {item}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "20px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[index]}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }
};

export default PDFLeftInfo;
