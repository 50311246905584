import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { IDefaultForm } from "../../../../types/global";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState } from "react";
import CheckedUserModal from "../../../Table/CheckedUserModal";
import { IUser } from "../../../../types/Auth/user";
import {
  IPurchaseRequest,
  IRequestor,
} from "../../../../types/Purchase/purchaseRequest";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import { departmentType, positionType } from "../../../../utils/userInfo";
import { EntityTypeEnum } from "../../../../generated/creatable";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../../../contexts/auth-context";

const RequestorForm = ({
  control,
  errors,
  disabled,
  reset,
  setValue,
}: IDefaultForm) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [userModel, setUserModal] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);
  const {
    state: { authUser },
  } = useStateContext();

  const openUserTableHandler = () => {
    setUserModal(true);
  };

  const closeUserTableHandler = () => {
    setUserModal(false);
  };

  const finishUserSelect = (data: IUser) => {
    const { first_name, last_name, department, position } = data;

    const requestor_contact: IRequestor = {
      name: `${first_name} ${last_name}`,
      department,
      position,
    };
    if (reset) {
      reset((prev: IPurchaseRequest) => ({
        ...prev,
        requestor_contact,
      }));
    }
    closeUserTableHandler();
  };

  useEffect(() => {
    if (!id) {
      if (authUser && authUser.unique_id) {
        setUserIds([authUser?.unique_id]);
        setUserIdsSnapshot([authUser?.unique_id]);
      }
    }
  }, [authUser, id]);

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={6} sm={4} md={2}>
          <Typography ml={1} fontWeight="bold">
            {t("purchase.request.requestor_description")}
          </Typography>
        </Grid>
        {!disabled && (
          <Grid item xs={2}>
            <Box>
              <CustomizedTooltip title={t("sentence.choose_requestor")}>
                <IconButton onClick={openUserTableHandler} color="primary">
                  <EditOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="requestor_contact.name"
            label={t("purchase.request.requestor_name")}
            error={Boolean(
              errors.requestor_contact && errors.requestor_contact.name
            )}
            helperText={
              errors.requestor_contact &&
              errors.requestor_contact.name &&
              errors.requestor_contact.name.message
            }
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("user.account.department")}
            control={control}
            name="requestor_contact.department"
            documentType={EntityTypeEnum.Employee}
            defaultOptions={departmentType}
            error={Boolean(
              errors.requestor_contact && errors.requestor_contact.department
            )}
            helperText={
              errors.requestor_contact &&
              errors.requestor_contact.department &&
              errors.requestor_contact.department.message
            }
            setValue={setValue}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("user.account.position")}
            control={control}
            name="requestor_contact.position"
            documentType={EntityTypeEnum.Employee}
            defaultOptions={positionType}
            error={Boolean(
              errors.requestor_contact && errors.requestor_contact.position
            )}
            helperText={
              errors.requestor_contact &&
              errors.requestor_contact.position &&
              errors.requestor_contact.position.message
            }
            setValue={setValue}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <CheckedUserModal
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        showSelectUser={userModel}
        closeUserTable={closeUserTableHandler}
        finishUsersSelect={finishUserSelect}
        rowSelection="single"
      />
    </CustomizedBox>
  );
};

export default RequestorForm;
