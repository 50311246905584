import { Grid } from "@mui/material";
import InventoryChart from "./InventoryChart";
import { useTranslation } from "react-i18next";
import {
  InventoryByStatusQuery,
  ItemQtyByStatusQuery,
  useInventoryByStatusQuery,
  useItemQtyByStatusQuery,
} from "../../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { useEffect } from "react";
import { IDashboardInput } from "../../../../../types/Dashboard";
import ItemQtyCard from "./ItemQtyCard";

type Props = {
  input: IDashboardInput;
};

const InventoryDashboard = ({ input }: Props) => {
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("item");

  const {
    data: goodsReceive,
    isFetching: isFetchingReceive,
    refetch: refetchReceive,
  } = useInventoryByStatusQuery<InventoryByStatusQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        document_type: "goodsReceive",
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  const {
    data: goodsIssue,
    isFetching: isFetchingIssue,
    refetch: refetchIssue,
  } = useInventoryByStatusQuery<InventoryByStatusQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        document_type: "goodsIssue",
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  const {
    data: itemQty,
    isFetching: isFetchingItemQty,
    refetch: refetchItemQty,
  } = useItemQtyByStatusQuery<ItemQtyByStatusQuery>(
    graphQLClient,
    {},
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    refetchReceive();
    refetchIssue();
    refetchItemQty();
  }, [refetchIssue, refetchItemQty, refetchReceive]);

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <InventoryChart
          name={t("inventory.goods_receive.index")}
          data={goodsReceive?.InventoryByStatus}
          isFetching={isFetchingReceive}
          url="/inventory/goods_receive"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <InventoryChart
          name={t("inventory.goods_issue.index")}
          data={goodsIssue?.InventoryByStatus}
          isFetching={isFetchingIssue}
          url="/inventory/goods_issue"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <ItemQtyCard
          name="สินค้าที่ต้องสั่งซื้อ"
          qty={itemQty?.ItemQtyByStatus?.reorder || 0}
          isFetching={isFetchingItemQty}
          status="ต้องสั่งซื้อ"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <ItemQtyCard
          name="สินค้าเกินสต๊อก"
          qty={itemQty?.ItemQtyByStatus?.overstock || 0}
          isFetching={isFetchingItemQty}
          status="เกินสต๊อก"
        />
      </Grid>
    </Grid>
  );
};

export default InventoryDashboard;
