import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  PurchaseEntityType,
  usePurchaseTagsFindByEntityQuery,
} from "../generated/purchase";
import {
  SalesEntityType,
  useSalesTagsFindByEntityQuery,
} from "../generated/sales";
import {
  CreatablesQuery,
  EntityTypeEnum,
  useCreatablesQuery,
} from "../generated/creatable";

export const useTagListTable = (
  type: string,
  documentType: PurchaseEntityType | SalesEntityType
) => {
  const [tags, setTags] = useState<any[]>([]);

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(type);

  const { data: purchaseTags } = usePurchaseTagsFindByEntityQuery(
    graphQLClient,
    {
      entityName: documentType as PurchaseEntityType,
    },
    {
      enabled: Object.values(PurchaseEntityType).includes(
        documentType as PurchaseEntityType
      ),
    }
  );

  const { data: salesTags } = useSalesTagsFindByEntityQuery(
    graphQLClient,
    {
      entityName: documentType as SalesEntityType,
    },
    {
      enabled: Object.values(SalesEntityType).includes(
        documentType as SalesEntityType
      ),
    }
  );

  useEffect(() => {
    if (purchaseTags) {
      setTags(purchaseTags?.purchaseTagsFindByEntity || []);
    }
    if (salesTags) {
      setTags(salesTags?.salesTagsFindByEntity || []);
    }
  }, [purchaseTags, salesTags]);

  return { tags };
};

export const useSalesInfo = (name: string, documentType: EntityTypeEnum) => {
  const [salesInfo, setSalesInfo] = useState<any[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isSuccess } = useCreatablesQuery<CreatablesQuery>(
    graphQLClient,
    {
      filterInput: {
        usage_field_name: name,
        usage_field_type: documentType,
      },
    }
  );
  useEffect(() => {
    if (isSuccess) {
      setSalesInfo(data?.creatables.map((options) => options.name) || []);
    }
  }, [data?.creatables, isSuccess]);

  return { salesInfo };
};
