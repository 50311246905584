import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import PDFHighlight from "../../PDFHighlight";
import PDFLeftInfo from "../../PDFLeftInfo";
import PDFRightInfo from "../../PDFRightInfo";
import PDFFooter from "../../PDFFooter";
import PDFCompanyInfo from "../../PDFCompanyInfo";
import PDFSigner from "../../PDFSigner";

interface Props {
  children?: ReactNode;
  documentName: string;
  data: any;
  documentType?: string;
  noCompany?: boolean;
  page?: number;
  allPage?: number;
  footer?: boolean;
  leftHeader?: string[];
  leftInfo?: string[];
  highlightHeader?: string[];
  highlightInfo?: string[];
  rightHeader?: string[];
  rightInfo?: string[];
  bgColor?: string;
  isInternational?: boolean;
}

const POPdfLayout = ({
  children,
  documentName,
  data,
  documentType,
  noCompany,
  footer,
  page,
  allPage,
  leftHeader = [],
  leftInfo = [],
  highlightHeader = [],
  highlightInfo = [],
  rightHeader = [],
  rightInfo = [],
  isInternational,
}: Props) => {
  return (
    <Box
      sx={{
        width: "210mm",
        height: "297mm",
        border: "1px solid #eee",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "28px",
        mb: 5,
      }}
    >
      <Box>
        <Grid container spacing={1.5}>
          <Grid xs={7}>
            <Box mx={2} mt={1}>
              <PDFCompanyInfo
                noCompany={noCompany}
                isInternational={isInternational}
              />
              <Divider sx={{ mt: 0.5 }} />
              <PDFLeftInfo
                leftHeader={leftHeader}
                leftInfo={leftInfo}
                isInternational={isInternational}
              />
            </Box>
          </Grid>
          <Grid xs={5} height="fit-content">
            <Box mt={1} mr={1}>
              <Typography
                fontSize={28}
                fontWeight={600}
                align="right"
                color="#A01313"
                lineHeight={1}
              >
                {isInternational
                  ? documentName.split("/")[1]
                  : documentName.split("/")[0]}
              </Typography>
              {!isInternational && (
                <Typography fontSize={11} align="right" color="#A01313">
                  {documentName.split("/")[1]}
                </Typography>
              )}
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography
                  fontSize={11}
                  fontWeight={600}
                  align="right"
                  color="#A01313"
                  marginRight={1}
                >
                  {isInternational ? "Document No." : "เลขที่เอกสาร"}
                </Typography>
                <Typography fontSize={11} align="right" color="#A01313">
                  {data?.unique_id || "-"}
                </Typography>
              </Box>
            </Box>
            <Box my={0.5}>
              <PDFHighlight
                highlightHeader={highlightHeader}
                highlightInfo={highlightInfo}
                isPurchase
              />
              <PDFRightInfo
                rightHeader={rightHeader}
                rightInfo={rightInfo}
                isInternational={isInternational}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}>{children}</Box>
        {footer && !isInternational && (
          <Box
            sx={{
              mt: "auto",
            }}
          >
            <PDFFooter
              data={data}
              documentType={documentType}
              isPurchase
              isInternational={isInternational}
            />
          </Box>
        )}
      </Box>
      {footer ? (
        <Box
          sx={{
            mt: "auto",
            mb: 2,
          }}
        >
          {isInternational && (
            <Box sx={{ mb: 0.5 }}>
              <PDFFooter
                data={data}
                documentType={documentType}
                isPurchase
                isInternational={isInternational}
              />
            </Box>
          )}
          <PDFSigner
            documentType={documentType}
            isInternational={isInternational}
          />
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: 28,
          right: 28,
        }}
      >
        <Typography fontSize={11} align="right" color="#333333">
          {!isInternational ? "หน้า" : "page"}{" "}
          {footer && page === 1 ? "1" : `${page}/${allPage}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default POPdfLayout;
