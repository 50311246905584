import { Dispatch, RefObject, SetStateAction } from "react";
import { ItemSkuQtysQuery } from "../../../../generated/inventory";
import AgGrid from "../../../UI/AgGrid";
import {
  ColDef,
  FilterChangedEvent,
  GridReadyEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { ISkuQty } from "../../../../types/Inventory/item";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  gridRef: RefObject<AgGridReact<ISkuQty>>;
  data?: ItemSkuQtysQuery;
  setRowCount: Dispatch<SetStateAction<number>>;
}

const CurrentStockBySNTable = ({ gridRef, data, setRowCount }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "item_level_1_name",
      headerName: t("inventory.items.item_group") + " " + 1,
      filter: "agSetColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        if (
          params.data.item_sku_detail &&
          params.data.item_sku_detail.item_sku &&
          params.data.item_sku_detail.item_sku.item &&
          params.data.item_sku_detail.item_sku.item.item_level_1 &&
          params.data.item_sku_detail.item_sku.item.item_level_1.name
        ) {
          return params.data.item_sku_detail.item_sku.item.item_level_1.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "sku_name",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        if (
          params.data.item_sku_detail &&
          params.data.item_sku_detail.item_sku &&
          params.data.item_sku_detail.item_sku.item &&
          params.data.item_sku_detail.item_sku.item.name
        ) {
          return params.data.item_sku_detail.item_sku.item.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "desc",
      headerName: t("inventory.items.desc"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        if (
          params.data.item_sku_detail &&
          params.data.item_sku_detail.item_sku &&
          params.data.item_sku_detail.item_sku.item &&
          params.data.item_sku_detail.item_sku.item.desc
        ) {
          return params.data.item_sku_detail.item_sku.item.desc;
        } else {
          return "";
        }
      },
    },
    {
      field: "stock_qty",
      headerName: t("inventory.itemCurrentStock.currentQuantity"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellClass: "ag-end-cell",
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      filter: "agTextColumnFilter",
    },
    {
      field: "warehouse_name",
      headerName: t("inventory.warehouse"),
      filter: "agSetColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        if (
          params.data.warehouse_level_3.sub_level_2 &&
          params.data.warehouse_level_3.sub_level_2.sub_level_1 &&
          params.data.warehouse_level_3.sub_level_2.sub_level_1.warehouse &&
          params.data.warehouse_level_3.sub_level_2.sub_level_1.warehouse.name
        ) {
          return params.data.warehouse_level_3.sub_level_2.sub_level_1.warehouse
            .name;
        } else {
          return "";
        }
      },
    },
    {
      field: "bin_name",
      headerName: t("inventory.location"),
      filter: "agSetColumnFilter",
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    const currentRowCount = params.api.getDisplayedRowCount();
    setRowCount(currentRowCount);
  };

  const onFilterChanged = (params: FilterChangedEvent) => {
    const currentRowCount = params.api.getDisplayedRowCount();
    setRowCount(currentRowCount);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={data?.itemSkuQtys || []}
      onGridReady={onGridReady}
      onFilterChanged={onFilterChanged}
    />
  );
};

export default CurrentStockBySNTable;
