import { Box } from "@mui/material";

const EmptyDonutChart = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        mt: 2,
      }}
    >
      <img
        src="/static/emptyDonutChart.svg"
        alt="empty-box"
        style={{
          height: 160,
          width: 160,
        }}
      />
    </Box>
  );
};

export default EmptyDonutChart;
