import { Box, Typography } from "@mui/material";

interface Props {
  isInternational?: boolean;
  rightHeader: string[];
  rightInfo: string[];
}

const PDFRightInfo = ({ rightHeader, rightInfo, isInternational }: Props) => {
  if (!isInternational) {
    return (
      <Box px={1}>
        {rightHeader.map((name, index) => (
          <Box sx={{ display: "flex", gap: 2 }} key={index}>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 0.55,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 11,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {rightInfo[index]}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          px: "8px",
          py: "4px",
          backgroundColor: "#F9F9F9",
          mt: 0.7,
          borderRadius: 1,
        }}
      >
        {rightHeader.map((item, index) => (
          <>
            <Box key={index}>
              <Typography
                sx={{
                  fontSize: 11,
                  lineHeight: "18px",
                  letterSpacing: "0.15px",
                  fontWeight: 400,
                  color: "#333333",
                  wordBreak: "break-word",
                  minHeight: "147px",
                  whiteSpace: "pre-line",
                }}
              >
                {item}
              </Typography>
            </Box>
          </>
        ))}
      </Box>
    );
  }
};

export default PDFRightInfo;
