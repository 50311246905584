import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { IApproval } from "../../../types/global";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ApprovalTemplate from "./ApprovalTemplateNew";
import ControlledSwitch from "../../Controller/ContolledSwitch";

interface ApprovalListProps {
    documents: { name: string; document_type: string }[];
}

const ApprovalList = ({ documents }: ApprovalListProps) => {
    const { t } = useTranslation();
    const { control, setValue, getValues } = useFormContext<IApproval>();

    const watchToggle = useWatch({
        control,
        name: "approval_templates", 
    });

    const handleToggle = (index: number) => {
        const currentState = getValues(`approval_templates.${index}.is_have_approval`);
        const newState = !currentState;
    
        setValue(`approval_templates.${index}.is_have_approval`, newState);
    
        if (!newState) {
            const currentTemplates = getValues("approval_templates");
            const updatedTemplates = currentTemplates.map((template: any, templateIndex: number) =>
                templateIndex === index
                    ? {
                          ...template,
                          is_have_approval: false,
                          approver_list: [],
                      }
                    : template
            );
            setValue("approval_templates", updatedTemplates);
        }
    
        const newStates = getValues("approval_templates").map(
            (template: any) => template.is_have_approval
        );
        const hasActiveApproval = newStates.some((state: boolean) => state);
        setValue("is_have_approval", hasActiveApproval);
    };

    return (
        <>
            {documents.map((doc, index) => {
                const isActive = watchToggle?.[index]?.is_have_approval || false;
                return (
                    <CustomizedBox key={doc.document_type}>
                        <Typography fontWeight={"bold"} mb={2}>
                            {doc.name}
                        </Typography>
                        <ControlledSwitch
                            control={control}
                            name={`approval_templates.${index}.is_have_approval`}
                            label={`ตั้งค่า${t("setting.approval.index")}`}
                            checked={isActive}
                            onChange={() => handleToggle(index)}
                            sx={{ ml: 1, mr: 1 }}
                        />
                        {isActive && <ApprovalTemplate index={index} />}
                    </CustomizedBox>
                );
            })}
        </>
    );
};

export default ApprovalList;

