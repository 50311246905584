import { useCallback, useEffect, useState } from "react";
import { InventoryDocumentType } from "../generated/inventory";
import { SalesDocumentType } from "../generated/sales";
import { PurchaseDocumentType } from "../generated/purchase";
import { DeliveryDocumentType } from "../generated/logistic";
import { ManufactureEntityType } from "../generated/manufacture";

export const useActiveStep = (
  aggridStatus?: string | null,
  mainStatus?: string | null,
  document?: string,
  stepNo?: number,
  approval?: boolean
) => {
  const [activeStep, setActiveStep] = useState<number>(-1);
  const [percentage, setPercentage] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const goodReceiveActiveStep = useCallback(() => {
    switch (mainStatus) {
      case "":
      case undefined:
      case "draft":
        switch (stepNo) {
          case 1:
            setActiveStep(0);
            setPercentage(100 / 3);
            break;
          case 2:
            setActiveStep(1);
            setPercentage(2 * (100 / 3));
            break;
          default:
            setActiveStep(-1);
            break;
        }
        break;
      case "finished":
        setActiveStep(2);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
        break;
    }
  }, [mainStatus, stepNo]);

  const goodIssueAndGoodReturnActiveStep = useCallback(() => {
    switch (mainStatus) {
      case "":
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 2);
        break;
      case "finished":
        setActiveStep(1);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const goodTransferActiveStep = useCallback(() => {
    switch (mainStatus) {
      case "":
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 3);
        break;
      case "wait_transfer":
        setActiveStep(1);
        setPercentage(2 * (100 / 3));
        break;
      case "finished":
        setActiveStep(2);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const goodAdjustActiveStep = useCallback(() => {
    switch (mainStatus) {
      case "":
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 3);
        break;
      case "wait_approve":
        setActiveStep(1);
        setPercentage(2 * (100 / 3));
        break;
      case "finished":
        setActiveStep(2);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const quotationActiveStep = useCallback(() => {
    if (approval) {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 5);
          break;
        case "wait_approve":
          setActiveStep(1);
          setPercentage(2 * (100 / 5));
          break;
        case "wait_accept":
          setActiveStep(2);
          setPercentage(3 * (100 / 5));
          break;
        case "accepted":
          setActiveStep(3);
          setPercentage(4 * (100 / 5));
          break;
        case "finished":
          setActiveStep(4);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    } else {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 4);
          break;
        case "wait_accept":
          setActiveStep(1);
          setPercentage(3 * (100 / 4));
          break;
        case "accepted":
          setActiveStep(2);
          setPercentage(4 * (100 / 4));
          break;
        case "finished":
          setActiveStep(3);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    }
  }, [mainStatus, approval]);


  const salesOrderActiveStep = useCallback(() => {
    if (approval) {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 4);
          break;
        case "wait_approve":
          setActiveStep(1);
          setPercentage(2 * (100 / 4));
          break;
        case "approved":
          setActiveStep(2);
          setPercentage(3 * (100 / 4));
          break;
        case "finished":
          setActiveStep(3);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    } else {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 3);
          break;
        case "approved":
          setActiveStep(1);
          setPercentage(2 * (100 / 3));
          break;
        case "finished":
          setActiveStep(2);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    }
  }, [mainStatus, approval]);

  const salesReturnActiveStep = useCallback(() => {
    switch (mainStatus) {
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 4);
        break;
      case "wait_approve":
        setActiveStep(1);
        setPercentage(2 * (100 / 4));
        break;
      case "approved":
        setActiveStep(2);
        setPercentage(3 * (100 / 4));
        break;
      case "finished":
        setActiveStep(3);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const purchaseRequestActiveStep = useCallback(() => {
    switch (mainStatus) {
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 5);
        break;
      case "wait_approve":
        setActiveStep(1);
        setPercentage(2 * (100 / 5));
        break;
      case "approved":
        setActiveStep(2);
        setPercentage(3 * (100 / 5));
        break;
      case "partially_ordered":
        setActiveStep(3);
        setPercentage(4 * (100 / 5));
        break;
      case "fully_ordered":
        setActiveStep(4);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const purchaseOrderActiveStep = useCallback(() => {
    if (approval) {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 5);
          break;
        case "wait_approve":
          setActiveStep(1);
          setPercentage(2 * (100 / 5));
          break;
        case "approved":
          setActiveStep(2);
          setPercentage(3 * (100 / 5));
          break;
        case "partially_imported":
          setActiveStep(3);
          setPercentage(4 * (100 / 5));
          break;
        case "fully_imported":
          setActiveStep(4);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    } else {
      switch (mainStatus) {
        case undefined:
        case "draft":
          setActiveStep(0);
          setPercentage(100 / 4);
          break;
        case "approved":
          setActiveStep(1);
          setPercentage(3 * (100 / 4));
          break;
        case "partially_imported":
          setActiveStep(2);
          setPercentage(4 * (100 / 4));
          break;
        case "fully_imported":
          setActiveStep(3);
          setPercentage(100);
          break;
        default:
          setActiveStep(-1);
      }
    }
  }, [mainStatus, approval]);

  const purchaseReturnActiveStep = useCallback(() => {
    switch (mainStatus) {
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 4);
        break;
      case "wait_approve":
        setActiveStep(1);
        setPercentage(2 * (100 / 4));
        break;
      case "approved":
        setActiveStep(2);
        setPercentage(3 * (100 / 4));
        break;
      case "finished":
        setActiveStep(3);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const manufactureOrderActiveStep = useCallback(() => {
    switch (mainStatus) {
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 4);
        break;
      case "pending_manu":
        setActiveStep(1);
        setPercentage(2 * (100 / 4));
        break;
      case "in_progress":
        setActiveStep(2);
        setPercentage(3 * (100 / 4));
        break;
      case "finished":
        setActiveStep(3);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  const deliveryOrderActiveStep = useCallback(() => {
    switch (mainStatus) {
      case undefined:
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 3);
        break;
      case "not_completed":
      case "wait_deliver":
        setActiveStep(1);
        setPercentage(2 * (100 / 3));
        break;
      case "completed":
        setActiveStep(2);
        setPercentage(100);
        break;
      default:
        setActiveStep(-1);
    }
  }, [mainStatus]);

  useEffect(() => {
    if (document) {
      if (document === InventoryDocumentType.GoodsReceive) {
        goodReceiveActiveStep();
      } else if (document === InventoryDocumentType.GoodsReturn) {
        goodIssueAndGoodReturnActiveStep();
      } else if (document === InventoryDocumentType.GoodsIssue) {
        goodIssueAndGoodReturnActiveStep();
      } else if (document === InventoryDocumentType.GoodsAdjust) {
        goodAdjustActiveStep();
      } else if (document === InventoryDocumentType.GoodsTransfer) {
        goodTransferActiveStep();
      } else if (document === SalesDocumentType.Quotation) {
        quotationActiveStep();
      } else if (document === SalesDocumentType.SalesOrder) {
        salesOrderActiveStep();
      } else if (document === SalesDocumentType.SalesReturn) {
        salesReturnActiveStep();
      } else if (document === PurchaseDocumentType.PurchaseRequest) {
        purchaseRequestActiveStep();
      } else if (document === PurchaseDocumentType.PurchaseOrder) {
        purchaseOrderActiveStep();
      } else if (document === PurchaseDocumentType.PurchaseReturn) {
        purchaseReturnActiveStep();
      } else if (document === ManufactureEntityType.ManufactureOrder) {
        manufactureOrderActiveStep();
      } else if (document === DeliveryDocumentType.DeliveryOrder) {
        deliveryOrderActiveStep();
      }
    }
  }, [
    document,
    goodAdjustActiveStep,
    goodIssueAndGoodReturnActiveStep,
    goodReceiveActiveStep,
    goodTransferActiveStep,
    mainStatus,
    salesOrderActiveStep,
    quotationActiveStep,
    salesReturnActiveStep,
    purchaseOrderActiveStep,
    purchaseRequestActiveStep,
    purchaseReturnActiveStep,
    manufactureOrderActiveStep,
    deliveryOrderActiveStep,
  ]);

  useEffect(() => {
    if (aggridStatus) {
      if (aggridStatus === "not_completed") {
        setError("not_completed");
      }
      if (aggridStatus !== mainStatus) {
        if (aggridStatus === "cancelled") {
          setError("cancelled");
        } else if (aggridStatus === "not_approved") {
          setError("not_approved");
        }
      }
    }
  }, [aggridStatus, mainStatus]);

  return { activeStep, percentage, error };
};
