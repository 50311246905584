import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  InventoryUniqueIdQuery,
  // Item,
  ItemCreateWithUomMutation,
  ItemDeleteMutation,
  // ItemSkuQtysQuery,
  ItemType,
  Tracability,
  ItemUpdateWithSkuMutation,
  UomsQuery,
  useInventoryUniqueIdQuery,
  useItemCreateWithUomMutation,
  useItemDeleteMutation,
  useItemSkuDetailQuery,
  useItemUpdateWithSkuMutation,
  useUomsQuery,
  useItemSkuQtyBySkuViewQuery,
  ItemSkuQtyBySkuViewQuery,
} from "../../../generated/inventory";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { IItem, IUom } from "../../../types/Inventory/item";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formatItem,
  formattedItemPayload,
} from "../../../utils/Formatter/Item";
import { useConfirmation } from "../../../hooks/use-confrimation";

import BundleTab from "./BundleTab";
import DetailTab from "./DetailTab";
import VariationTab from "./VariationTab";
import ItemCurrentStockTab from "./ItemCurrentStockTab";
import Confirmation from "../../../components/UI/Confirmation";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import {
  itemSchema,
  itemValidate,
} from "../../../components/Form/Inventory/Item/schema";

import TransactionTab from "./TransactionTab";
import TotalBox from "../../../components/UI/TotalBox";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import ItemHeader from "../../../components/Form/Inventory/Item/Header";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedRadioGroup from "../../../components/Custom/CustomizedRadioGroup";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import ScanNavigate from "../../../components/UI/ScanNavigate";
import { useStateContext } from "../../../contexts/auth-context";

const ItemsContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname, state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isEditVariant, setIsEditVariant] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [allUoms, setAllUoms] = useState<IUom[]>([]);
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [hasBarcodeError, setHasBarcodeError] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(
    `${pathname}?tab=item&subtab=general`
  );
  // const [tableType, setTableType] = useState<string>("stock_qty");
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);
  const prevIsStock = useRef<boolean | null>(null);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IItem>({
    defaultValues: { ...itemSchema },
    resolver: yupResolver(itemValidate),
    mode: "onChange",
  });

  const {
    state: { authUser },
  } = useStateContext();

  const { control: controlFilter, handleSubmit: handleSubmitFilter } = useForm<{
    value: string;
  }>({
    defaultValues: { value: "all" },
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const {
    data: uomData,
    isSuccess: uomIsSuccess,
    isLoading: isLoadingUom,
  } = useUomsQuery<UomsQuery>(graphQLClientWithHeaderItem);

  useEffect(() => {
    if (uomIsSuccess) {
      const { uoms } = uomData;
      const uomsType = uoms as IUom[];
      setAllUoms(uomsType);
    }
  }, [uomData, uomIsSuccess]);

  const { data, isSuccess, isLoading } = useItemSkuDetailQuery(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        sku_name: id || "",
      },
    },
    { enabled: !!id }
  );

  const { data: itemSkuQty, isLoading: isItemSkuQtyLoading } =
    useItemSkuQtyBySkuViewQuery<ItemSkuQtyBySkuViewQuery>(
      graphQLClientWithHeaderItem,
      {
        skuName: id || "",
      },
      {
        enabled: !!id,
      }
    );

  const mapTypeToModelType = () => {
    const itemType = getValues("type");
    if (itemType) {
      if (itemType === "service") return itemType;
      else return `item_${itemType}`;
    } else if (state) {
      if (state.type === "service") return state.type;
      else return `item_${state.type}`;
    } else return "";
  };

  const { refetch: refetchUniqueId } =
    useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
      graphQLClientWithHeaderItem,
      {
        modelType: mapTypeToModelType(),
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.utilGetUniqueId ?? "");
    const currentBarcode = getValues("barcode");
    const type = getValues("type");
    if (type === "normal") {
      if (!currentBarcode || currentBarcode.trim().length === 0) {
        setValue("barcode", data?.utilGetUniqueId ?? "");
      }
    }
  }, [refetchUniqueId, setValue, getValues]);

  const itemType = useWatch({ control, name: "type" });

  const getItem = useCallback(() => {
    if (isSuccess) {
      const { itemSkuDetail } = data;
      const itemType = itemSkuDetail?.item_sku?.item as IItem;
      const newFormatItem = formatItem(itemType);
      if (newFormatItem.type === ItemType.Bundle) {
        const bundle_list = newFormatItem.bundle_item_detail_list.map(
          (list) => list.item_sku_name
        );
        setItemIds(bundle_list);
        setItemIdsSnapshot(bundle_list);
      }
      reset(newFormatItem);
      setIsEditVariant(true);
      setDisabled(true);
    }
  }, [data, isSuccess, reset]);

  const getDefaultPackageAttribute = useCallback(() => {
    if (uomIsSuccess) {
      if (!id) {
        const { uoms } = uomData;
        const uomsType = uoms as IUom[];
        const findCM =
          uomsType.find((uom) => uom.name === "เซนติเมตร")?.unique_id ?? "";
        const findKG =
          uomsType.find((uom) => uom.name === "กิโลกรัม")?.unique_id ?? "";

        const package_attribute = {
          ...itemSchema.package_attribute,
          weight_uom_unique_id: findKG,
          height_uom_unique_id: findCM,
          length_uom_unique_id: findCM,
          width_uom_unique_id: findCM,
        } as IItem["package_attribute"];
        setValue("package_attribute", package_attribute);
      }
    }
  }, [id, setValue, uomData, uomIsSuccess]);

  useEffect(() => {
    if (id) {
      getItem();
    } else {
      if (state) {
        const newSchema = {
          ...itemSchema,
          ...state,
          type: state.type ?? "normal",
          tracability:
            state.type === ItemType.Normal
              ? Tracability.Normal
              : Tracability.Serial,
          stock_uom_unique_id: state.type === ItemType.Service ? "01" : "",
        } as IItem;
        reset(newSchema);
        getDefaultPackageAttribute();
        generateUniqueId();
      }
    }
  }, [id, reset, state, getItem, generateUniqueId, getDefaultPackageAttribute]);

  useEffect(() => {
    if (state && !id) {
      if (allUoms.length > 0) {
        if (state.type === ItemType.Normal) {
          const foundUom =
            allUoms.find((uom) => uom.name === "ชิ้น")?.unique_id ?? "";
          setValue("stock_uom_unique_id", foundUom);
        } else if (state.type === ItemType.Service) {
          const foundUom =
            allUoms.find((uom) => uom.name === "ครั้ง")?.unique_id ?? "";
          setValue("stock_uom_unique_id", foundUom);
        }
      }
    }
  }, [id, allUoms, setValue, state]);

  const isStock = useWatch({ control, name: "is_stockable" });

  const defaultTabs: ITab[] = useMemo(
    () => [
      {
        label: t("inventory.items.itemInfomation"),
        path: `${pathname}?tab=item&subtab=general`,
      },
      {
        label: t("inventory.items.variation"),
        path: `${pathname}?tab=variation`,
      },
      {
        label: t("inventory.items.item_bundle"),
        path: `${pathname}?tab=bundle`,
      },
      {
        label: t("inventory.items.onHandItemWarehouseTab"),
        path: `${pathname}?tab=currentstock`,
        disabled: !Boolean(id) || !disabled,
      },
      {
        label: t("inventory.items.transaction"),
        path: `${pathname}?tab=transaction`,
        disabled: !Boolean(id) || !disabled,
      },
    ],
    [t, pathname, id, disabled]
  );

  useEffect(() => {
    if (itemType)
      if (["service"].includes(itemType)) {
        setTabs([defaultTabs[0]]);
      } else if (["normal"].includes(itemType)) {
        if (isStock) setTabs([defaultTabs[0], defaultTabs[3], defaultTabs[4]]);
        else setTabs([defaultTabs[0]]);
      } else if (["variant"].includes(itemType)) {
        if (isStock)
          setTabs([
            defaultTabs[0],
            defaultTabs[1],
            defaultTabs[3],
            defaultTabs[4],
          ]);
        else setTabs([defaultTabs[0], defaultTabs[1]]);
      } else if (["bundle"].includes(itemType)) {
        if (isStock)
          setTabs([
            defaultTabs[0],
            defaultTabs[2],
            defaultTabs[3],
            defaultTabs[4],
          ]);
        else setTabs([defaultTabs[0], defaultTabs[2]]);
      } else {
        setTabs(defaultTabs);
      }
  }, [defaultTabs, isStock, itemType, setSearchParams]);

  useEffect(() => {
    if (prevIsStock.current && prevIsStock.current !== isStock) {
      setSearchParams({ subtab: "general" });
    }
    prevIsStock.current = isStock;
  }, [isStock, setSearchParams]);

  useEffect(() => {
    switch (tab) {
      case "item":
        setCurrentTab(pathname + `?tab=item&subtab=general`);
        break;
      default:
        setCurrentTab(pathname + `?tab=${tab}`);
        break;
    }
  }, [pathname, tab]);

  const renderTab = () => {
    switch (tab) {
      case "currentstock": {
        if (!isStock)
          return (
            <DetailTab
              control={control}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              reset={reset}
              setValue={setValue}
              disabled={disabled}
              allUoms={allUoms}
              getID={generateUniqueId}
              hasBarcodeError={hasBarcodeError}
              isInventoryPage
            />
          );
        return <ItemCurrentStockTab />;
      }
      case "transaction":
        return <TransactionTab />;
      case "bundle":
        return (
          <BundleTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            disabled={disabled}
            itemIds={itemIds}
            setItemIds={setItemIds}
            itemIdsSnapshot={itemIdsSnapshot}
            setItemIdsSnapshot={setItemIdsSnapshot}
          />
        );
      case "variation":
        return (
          <VariationTab
            control={control}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            errors={errors}
            handleSubmit={handleSubmit}
            isEditVariant={isEditVariant}
            setIsEditVariant={setIsEditVariant}
            disabled={disabled}
          />
        );
      default:
        return (
          <DetailTab
            control={control}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            reset={reset}
            setValue={setValue}
            disabled={disabled}
            allUoms={allUoms}
            getID={generateUniqueId}
            hasBarcodeError={hasBarcodeError}
            isInventoryPage
          />
        );
    }
  };

  const { mutate: createItem } = useItemCreateWithUomMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: (data: ItemCreateWithUomMutation) => {
        enqueueSnackbar("สร้างสินค้าสำเร็จ", {
          variant: "success",
        });
        navigate(
          "/inventory/items/" +
            data.itemCreateWithUOM?.sku_list?.[0]?.sku_name +
            "?tab=item&subtab=general"
        );
        setDisabled(true);
      },
      onError: (error) => {
        if (
          error.message?.includes(
            "Unique constraint failed on the fields: (`barcode`)"
          )
        ) {
          setHasBarcodeError(true);
        }
        if (errorMessageFormatter(error, "item")) {
          return enqueueSnackbar(errorMessageFormatter(error, "item"), {
            variant: "error",
          });
        } else
          enqueueSnackbar("สร้างสินค้าไม่สำเร็จ", {
            variant: "error",
          });
      },
    }
  );

  const { mutate: updateItem } = useItemUpdateWithSkuMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: (data: ItemUpdateWithSkuMutation) => {
        enqueueSnackbar("แก้ไขสินค้าสำเร็จ", {
          variant: "success",
        });
        setDisabled(true);
      },
      onError: (error) => {
        if (
          error.message?.includes(
            "Unique constraint failed on the fields: (`barcode`)"
          )
        ) {
          setHasBarcodeError(true);
          enqueueSnackbar("ไม่สามารถใช้ Barcode ซ้ำกับสินค้าอื่นได้", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขสินค้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    }
  );

  const { mutate: deleteItem } = useItemDeleteMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: (data: ItemDeleteMutation) => {
        const { itemDelete } = data;
        if (
          itemDelete &&
          itemDelete?.active_document_list &&
          itemDelete?.active_document_list?.length > 0
        ) {
          enqueueSnackbar("ไม่สามารถลบได้\nเนื่องจากสินค้านี้ถูกใช้งานอยู่", {
            variant: "error",
            style: { whiteSpace: "pre-line" },
          });
          return;
        } else if (itemDelete && !itemDelete?.is_deletable) {
          enqueueSnackbar("ลบสินค้าไม่สำเร็จ", {
            variant: "error",
          });
          return;
        }
        navigate("/inventory/items");
        enqueueSnackbar("ลบสินค้าสำเร็จ", {
          variant: "success",
        });
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("ลบสินค้าไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const onSubmit = (data: IItem) => {
    if (id) {
      submitEditHandler(data);
    } else if (data.type === ItemType.Service) {
      submitCreateHandler(data);
    } else {
      openCreateConfirmation();
    }
  };

  const submitCreateHandler = async (submitData: IItem) => {
    let data = submitData;
    if (!submitData) {
      data = getValues() as IItem;
    }
    const formattedFormData = await formattedItemPayload(data, allUoms);
    setHasBarcodeError(false);
    createItem({
      createInput: {
        ...formattedFormData,
        created_by: {
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        },
      },
    });
  };

  const submitEditHandler = async (data: IItem) => {
    const formattedFormData = await formattedItemPayload(data, allUoms, true);
    const { unique_id, ...otherData } = formattedFormData;
    setHasBarcodeError(false);
    updateItem({
      uniqueInput: { unique_id },
      updateInput: otherData,
    });
  };

  const submitDeleteHandler = () => {
    const unique_id = getValues("unique_id");
    deleteItem({ uniqueInput: { unique_id } });
  };

  useEffect(() => {
    if (errors) {
      if (errors.unique_id) {
        enqueueSnackbar("กรุณาระบุรหัสสินค้า", {
          variant: "error",
        });
      }
      if (errors.barcode || errors.variation_list) {
        enqueueSnackbar("กรุณาระบุ Barcode", {
          variant: "error",
        });
      }
      if (errors.name) {
        enqueueSnackbar("กรุณาระบุชื่อสินค้า", {
          variant: "error",
        });
      }
      if (errors.option_1) {
        if (errors.option_1.key)
          enqueueSnackbar("กรุณาระบุชื่อตัวเลือกที่ 1", {
            variant: "error",
          });
        if (errors.option_1.list)
          enqueueSnackbar("กรุณาระบุตัวเลือกอย่างน้อย 1 ตัวเลือก", {
            variant: "error",
          });
      }
      if (errors.item_level_1_unique_id) {
        enqueueSnackbar("กรุณาเลือกหมวดหมู่สินค้า", {
          variant: "error",
        });
      }
      if (errors.stock_uom_unique_id) {
        enqueueSnackbar("กรุณาเลือกหน่วยมาตรฐาน", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  const {
    confirmation: confirmationDelete,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(submitDeleteHandler);

  const {
    confirmation: confirmationCreate,
    openConfirmationHandler: openCreateConfirmation,
    closeConfirmationHandler: closeCreateConfirmation,
    submitConfirmationHandler: submitCreateConfirmation,
  } = useConfirmation(submitCreateHandler);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: Boolean(id)
        ? data?.itemSkuDetail?.item_sku?.item?.unique_id ??
          t("inventory.items.add")
        : t("inventory.items.add"),
    },
  ];

  if (id && isLoading && isLoadingUom && isItemSkuQtyLoading) return <></>;

  // const onClickTotalBox = (type: string): void => {
  //   setTableType(type);
  // };

  // const mapQtyAndStockQty = (
  //   itemSku: ItemSkuQtysQuery["itemSkuQtys"],
  //   bundleList: Item["bundle_item_detail_list"]
  // ): {
  //   warehouse_unique_id: string;
  //   name: string;
  //   stock_qty: number;
  // }[] => {
  //   const result: {
  //     warehouse_unique_id: string;
  //     name: string;
  //     stock_qty: number;
  //   }[] = [];

  //   bundleList?.forEach((item) => {
  //     const matchingItems = itemSku?.filter(
  //       (list) => list?.item_unique_id === item?.reference_item_unique_id
  //     );

  //     if (matchingItems) {
  //       const itemQtys = matchingItems.map((item) => item?.stock_qty ?? 0);
  //       if (itemQtys.length > 0) {
  //         const minItemQty = Math.min(...itemQtys);
  //         const bundleQty = item?.qty ?? 0;
  //         const bundleItemQty = minItemQty / bundleQty;

  //         const warehouseIds = Array.from(
  //           new Set(
  //             matchingItems.map(
  //               (item) =>
  //                 item?.warehouse_level_3?.sub_level_2?.sub_level_1
  //                   ?.warehouse_unique_id
  //             )
  //           )
  //         ).join(",");

  //         const warehouseNames = Array.from(
  //           new Set(
  //             matchingItems.map(
  //               (item) =>
  //                 item?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse
  //                   ?.name
  //             )
  //           )
  //         ).join(",");

  //         result.push({
  //           warehouse_unique_id: warehouseIds,
  //           name: warehouseNames,
  //           stock_qty: Math.floor(bundleItemQty),
  //         });
  //       }
  //     }
  //   });

  //   return result;
  // };

  // const sumValues = (type: string) => {
  //   const itemSkuQtys = data?.itemSkuDetail?.item_sku_qty as any;
  //   const sumQtys = itemSkuQtys?.reduce(
  //     (prev: number, curr: any) => prev + curr[`${type}`],
  //     0
  //   );
  //   const bundleList = getValues(
  //     "bundle_item_detail_list"
  //   ) as Item["bundle_item_detail_list"];
  //   if (itemType === ItemType.Bundle) {
  //     const res = mapQtyAndStockQty(itemSkuQtys, bundleList);
  //     const sortMinimum = res?.sort((a, b) => a.stock_qty - b.stock_qty);
  //     return sortMinimum?.[0]?.stock_qty ?? 0;
  //   }
  //   if (!sumQtys) return "-";
  //   else return sumQtys.toLocaleString() ?? "";
  // };

  // const CardList: ICardList[] = [
  //   {
  //     title: t("inventory.quantities.available_qty"),
  //     field: "available_qty",
  //     borderColor: "rgba(189, 189, 189, 1)",
  //   },
  //   {
  //     title: t("inventory.quantities.stock_qty"),
  //     field: "stock_qty",
  //   },
  //   {
  //     title: t("inventory.quantities.purchase_ordered_qty"),
  //     field: "purchase_ordered_qty",
  //   },
  //   {
  //     title: t("inventory.quantities.sale_committed_qty"),
  //     field: "sale_committed_qty",
  //   },
  //   {
  //     title: t("inventory.quantities.manufacture_committed_qty"),
  //     field: "manufacture_committed_qty",
  //   },
  //   {
  //     title: t("inventory.quantities.manufacture_ordered_qty"),
  //     field: "manufacture_ordered_qty",
  //   },
  // ];

  // const renderCard = (list: ICardList[]) =>
  //   list.map(
  //     (menu: ICardList) =>
  //       (itemType !== ItemType.Bundle ||
  //         (itemType === ItemType.Bundle &&
  //           !["manufacture_committed_qty", "manufacture_ordered_qty"].includes(
  //             menu.field
  //           ))) && (
  //         <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
  //           <TotalBox
  //             title={menu.title}
  //             value={sumValues(menu.field)}
  //             unit={getValues("stock_uom")?.name ?? ""}
  //             borderColor={
  //               menu.field === "available_qty"
  //                 ? menu.borderColor
  //                 : tableType === menu.field
  //                 ? undefined
  //                 : "#BF8CDE"
  //             }
  //             onClick={
  //               tab === "currentstock" && menu.field !== "available_qty"
  //                 ? () => onClickTotalBox(menu.field)
  //                 : undefined
  //             }
  //           />
  //         </Grid>
  //       )
  //   );

  const filterTypeList = [
    {
      label: t("inventory.items.filter.all"),
      value: "all",
    },
    {
      label: t("inventory.items.filter.seperate"),
      value: "seperate",
    },
  ];

  const onSubmitFilter = (data: { value: string }) => {
    setFilterModal(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1650,
        }}
      >
        <Box width={"100%"}>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
        {id && <ScanNavigate pathname="inventory/items" />}
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      <ItemHeader
        control={control}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        disabled={disabled}
        setDisabled={setDisabled}
        openDeleteConfirmation={openDeleteConfirmation}
        item={data?.itemSkuDetail?.item_sku?.item}
        setFilterModal={setFilterModal}
      />
      {/* {id && itemType !== "service" && (
        <Grid container spacing={1}>
          {renderCard(CardList)}
        </Grid>
      )} */}
      <Grid container spacing={1.5}>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <TotalBox
            title={"จำนวนคงเหลือสุทธิ"}
            unit={getValues("stock_uom.name")}
            value={
              itemSkuQty?.ItemSkuQtyBySkuView?.available_qty?.toLocaleString() ||
              "0"
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <TotalBox
            title={"จำนวนคงคลัง"}
            unit={getValues("stock_uom.name")}
            value={
              itemSkuQty?.ItemSkuQtyBySkuView?.stock_qty?.toLocaleString() ||
              "0"
            }
          />
        </Grid>
      </Grid>
      <form>
        {renderTab()}
        {!["currentstock", "transaction", null].includes(tab) && !disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                onClick={() => {
                  setDisabled(true);
                  reset();
                }}
              />
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              />
            </Stack>
          </BottomNavbar>
        )}
      </form>
      <Confirmation
        open={confirmationDelete}
        title={t("inventory.sentence.delete_item") + " " + getValues("name")}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
      />
      <Confirmation
        open={confirmationCreate}
        title={t("inventory.sentence.create_item")}
        message={t("inventory.sentence.create_item_detail")}
        handleClose={closeCreateConfirmation}
        action={submitCreateConfirmation}
      />
      <ModalUI
        open={filterModal}
        handleClose={() => setFilterModal(false)}
        title={t("inventory.items.filter.index")}
        width={420}
        action={
          <CustomizedButton
            variant="contained"
            title={t("inventory.items.filter.button")}
            onClick={handleSubmitFilter(onSubmitFilter)}
          />
        }
      >
        <Controller
          name="value"
          control={controlFilter}
          render={({ field }) => (
            <Grid item xs={12}>
              <CustomizedRadioGroup radioList={filterTypeList} {...field} row />
            </Grid>
          )}
        />
      </ModalUI>
    </React.Fragment>
  );
};

export default ItemsContainer;
