import { InputAdornment, Typography } from "@mui/material";
import { IDefaultForm, IOptionsCreatable } from "../../../../types/global";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";
import { formatNumber } from "../../../../utils/dataTransformer";
import { EntityTypeEnum } from "../../../../generated/creatable";
import { IDeliveryOrderItemList } from "../../../../types/Logistic/deliveryOrder";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  setValue: IDefaultForm["setValue"];
  index: number;
  disabled: IDefaultForm["disabled"];
  documentType: EntityTypeEnum;
  field: IDeliveryOrderItemList;
}

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { value: "ยังไม่ระบุ", label: "ยังไม่ระบุ" },
  { value: "ไม่มี", label: "ไม่มี" },
  { value: "0.75", label: "0.75 %" },
  { value: "1", label: "1 %" },
  { value: "1.5", label: "1.5 %" },
  { value: "2", label: "2 %" },
  { value: "3", label: "3 %" },
  { value: "5", label: "5 %" },
  { value: "10", label: "10 %" },
  { value: "15", label: "15 %" },
];

const WithholdingTaxCell = ({
  control,
  index,
  errors,
  setValue,
  disabled,
  documentType,
  field,
}: Props) => {
  const additional_discount = useWatch({
    control,
    name: `additional_discount`,
  });

  const additional_discount_type = useWatch({
    control,
    name: "additional_discount_type",
  });

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const actualAdditionalDiscount =
    additional_discount_type === AdditionalDiscountType.Percent
      ? pre_vat_amount * (additional_discount / 100)
      : additional_discount;

  const vat_type = useWatch({
    control,
    name: `item_list[${index}].vat_percentage`,
  });

  const item_pre_vat_amount = useWatch({
    control,
    name: `item_list[${index}].pre_vat_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `item_list[${index}].withholding_tax_type`,
  });

  const withholding_tax_value = useWatch({
    control,
    name: `item_list[${index}].withholding_tax_value`,
  });

  const item_additional_discount =
    actualAdditionalDiscount * (item_pre_vat_amount / pre_vat_amount) || 0;

  const price_vat_type = useWatch({
    control,
    name: "price_vat_type",
  });

  useEffect(() => {
    if (price_vat_type === PriceVatType.ExcludedVat) {
      setValue(
        `item_list[${index}].withholding_tax_value`,
        ((item_pre_vat_amount - item_additional_discount) *
          parseFloat(
            withholding_tax_type === "ยังไม่ระบุ" ||
              withholding_tax_type === "ไม่มี" ||
              withholding_tax_type === "" ||
              withholding_tax_type === undefined
              ? 0
              : withholding_tax_type
          )) /
          100
      );
    } else {
      if (vat_type === "7") {
        setValue(
          `item_list[${index}].withholding_tax_value`,
          (((item_pre_vat_amount - item_additional_discount) / 1.07) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      } else {
        setValue(
          `item_list[${index}].withholding_tax_value`,
          ((item_pre_vat_amount - item_additional_discount) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      }
    }
  }, [
    index,
    item_additional_discount,
    item_pre_vat_amount,
    price_vat_type,
    setValue,
    vat_type,
    withholding_tax_type,
  ]);

  return (
    <>
      {disabled ? (
        <>
          <Typography fontSize={14}>{field.withholding_tax_type} %</Typography>
          <Typography variant="caption">
            {formatNumber(field.withholding_tax_value)} บาท
          </Typography>
        </>
      ) : (
        <ControlledServerSideCreatable
          sx={{ mt: 3 }}
          control={control}
          name={`item_list[${index}].withholding_tax_type`}
          dataName="withholding_tax_type"
          documentType={documentType}
          defaultOptions={withholdingTaxTypeOption}
          error={Boolean(
            errors?.item_list &&
              errors?.item_list[index] &&
              errors?.item_list[index]?.withholding_tax_type
          )}
          helperText={formatNumber(withholding_tax_value) + " บาท"}
          helperTextAlign="right"
          alignInput="right"
          setValue={setValue}
          disabled={documentType === EntityTypeEnum.SalesReturn}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      )}
    </>
  );
};

export default WithholdingTaxCell;
