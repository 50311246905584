import { useEffect, useMemo, useState } from "react";
import { IDefaultForm, IMenuOption } from "../../types/global";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { ISalesItemList } from "../../types/Sales";

export const usePurchaseOrderCreate = (
  status?: string | null,
  control?: IDefaultForm["control"]
) => {
  const itemList: ISalesItemList[] = useWatch({
    control,
    name: "item_list",
  });

  const currency = useWatch({
    control,
    name: "currency",
  });

  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: `ใบ${t("inventory.goods_receive.index")}`,
        disabled:
          itemList.filter((item) => item.item_type !== "service").length === 0,
      },
      {
        value: t("purchase.return.index"),
        disabled: currency === "THB" ? false : true,
      },
    ],
    [currency, itemList, t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "approved":
      case "partially_imported":
      case "fully_imported":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
