import {
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  //   Badge,
  //   LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../../hooks/use-navbar";
import DashboardNavbar from "../Dashboard/DashboardNavbar";
import CustomizedButton from "../../Custom/CustomizedButton";
import UserBadge from "../UserBadge";
import { useStateContext } from "../../../contexts/auth-context";
import CompanyBadge from "../CompanyBadge";
import { useCookies } from "react-cookie";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ExportBadge from "../ExportBadge";
import { useSessionStorage } from "../../../hooks/use-session-storage";
import { createApolloClient } from "../../../services/apollo";
import { ApolloProvider } from "@apollo/client";

const TopNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navbar = useNavbar();
  const { pathname } = useLocation();
  const [tenantId] = useSessionStorage("tenant-id");

  const apolloClient = createApolloClient(tenantId as string | undefined);

  const [, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
    "selected_tenant",
  ]);

  const {
    state: { authUser },
    dispatch,
  } = useStateContext();

  const logoutHandler = () => {
    removeCookie("access_token", {
      path: "/",
    });
    removeCookie("refresh_token", {
      path: "/",
    });
    setCookie("logged_in", "false", {
      path: "/",
    });
    setCookie("selected_tenant", "false", {
      path: "/",
    });
    sessionStorage.removeItem("tenant-id");
    sessionStorage.removeItem("company-info");

    dispatch({
      type: "SET_USER",
      payload: null,
    });
    navigate("/login", { replace: true });
  };

  const splitPath = pathname.split("/");
  const previousPath = splitPath.splice(0, splitPath.length - 1).join("/");

  return (
    <>
      <DashboardNavbar
        open={!isSmallScreen && navbar?.sidebar.isSidebarOpen}
        theme={theme}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            backgroundColor: "#F9F9F9",
            backgroundImage: isSmallScreen
              ? "linear-gradient(to right, #2786ED , #226EE1, #43A7EF)"
              : undefined,
          }}
        >
          {!navbar?.sidebar.isSidebarOpen && (
            <IconButton
              onClick={() => {
                navbar?.sidebar.setIsSidebarOpen(true);
              }}
            >
              <MenuIcon
                fontSize="small"
                sx={{ color: isSmallScreen ? "#FFFFFF" : "inherit" }}
              />
            </IconButton>
          )}
          {!isSmallScreen ? (
            <>
              <Box sx={{ ml: navbar?.sidebar.isSidebarOpen ? 0 : 4 }}>
                <CustomizedButton
                  variant="outlined"
                  title={t("button.back")}
                  onClick={() => navigate(previousPath)}
                  startIcon={<ArrowBackIcon fontSize="small" />}
                />
                {/* {isSmallScreen && (
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "4px",
                }}
              >
                <IconButton onClick={() => navigate(previousPath)}>
                  <ArrowBackIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontSize: "0.75rem",
                    }}
                  />
                </IconButton>
              </Box>
            )} */}
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              {authUser && (
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  alignItems="center"
                >
                  <CustomizedTooltip title="คู่มือการใช้งาน">
                    <IconButton
                      onClick={() =>
                        window.open(
                          "https://doc.clickup.com/3654689/p/h/3fh11-53362/db286604b2ae948/3fh11-53362",
                          "_blank"
                        )
                      }
                    >
                      <MenuBookOutlinedIcon color="primary" />
                    </IconButton>
                  </CustomizedTooltip>
                  <CompanyBadge />
                  <ApolloProvider client={apolloClient}>
                    <ExportBadge />
                  </ApolloProvider>
                  <UserBadge {...authUser} onLogout={logoutHandler} />
                </Stack>
              )}
            </>
          ) : (
            <>
              <Box
                sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
              >
                <CompanyBadge />
              </Box>
              <Box sx={{ justifySelf: "flex-end" }}>
                <UserBadge {...authUser} onLogout={logoutHandler} />
              </Box>
            </>
          )}
        </Toolbar>
      </DashboardNavbar>
    </>
  );
};

export default TopNavbar;
