import { Dispatch, SetStateAction } from "react";
import GoodsReceiveItemList from "../../../components/Table/Inventory/GoodsReceive/GoodsReceiveItemList";
import { GoodsReceiveQuery } from "../../../generated/inventory";
import { IDefaultForm } from "../../../types/global";
import Confirmation from "../../../components/UI/Confirmation";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IGoodsReceive } from "../../../types/Inventory/goodsReceive";
import { IWarehouse } from "../../../types/Setting/inventory";

type Props = IDefaultForm & {
  data?: GoodsReceiveQuery;
  allWarehouses?: IWarehouse[];
  step: number;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  submitConfirmationHandler: () => void;
  renderButton: () => JSX.Element | null;
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};

const BarcodeTab = ({
  control,
  getValues,
  setValue,
  disabled,
  errors,
  data,
  step,
  allWarehouses,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  confirmation,
  closeConfirmationHandler,
  submitConfirmationHandler,
  renderButton,
  fields,
  remove,
  update,
  replace,
}: Props) => {
  return (
    <>
      <GoodsReceiveItemList
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        allWarehouses={allWarehouses}
        step={step}
        goodReceiveData={data?.goodsReceive}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        fields={fields}
        remove={remove}
        update={update}
        replace={replace}
      />
      {renderButton && renderButton()}
      <Confirmation
        title="ยืนยันหากต้องการนำเข้าสินค้า"
        message="ขณะนี้มีสินค้า “รอสแกน” อยู่หากนำเข้าแล้วจะไม่สามารถแก้ไขเอกสารนี้ได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default BarcodeTab;
