import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import { AdditionalDiscountType } from "../../../generated/purchase";
import { bahttext } from "bahttext";
import { ISalesItemList } from "../../../types/Sales";
import { PriceVatType } from "../../../generated/sales";
import { useSearchParams } from "react-router-dom";

const CustomizedSummary = styled(Box)({
  display: "grid",
  justifyContent: "flex-end",
  textAlign: "right",
  gridGap: "10px",
  gap: "10px",
  paddingRight: "30px",
  paddingTop: "4px",
  paddingBottom: "4px",
  gridTemplateColumns:
    "minmax(min-content,max-content) minmax(6rem,max-content) 24px",
});

interface Props {
  data: any;
  documentType?: string;
  isPurchase?: boolean;
  bgColor?: string;
  textColor?: string;
  isInternational?: boolean;
}

const PDFFooter = ({
  data,
  documentType,
  isPurchase,
  bgColor,
  textColor,
  isInternational,
}: Props) => {
  const sxColor = isPurchase ? "#333333" : textColor ? textColor : "#2167D3";
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const netAmount =
    roundingNumber(data.vat_exempted_amount) +
    roundingNumber(data.vat_0_amount) +
    roundingNumber(data.vat_7_amount) +
    roundingNumber(data.vat_amount);

  const totalAmount =
    netAmount +
    data.shipping_cost -
    roundingNumber(data.withholding_tax_amount);

  return (
    <>
      <Grid container sx={{ breakInside: "avoid" }}>
        {documentType !== "delivery_order" && (
          <Grid item xs={12}>
            <CustomizedSummary
              bgcolor={isPurchase ? "#FCE8E8" : bgColor ? bgColor : "#ECF6FD"}
              borderRadius="4px"
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {!isInternational ? "มูลค่ารวมก่อนภาษี" : "Sub Total Amount"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {formatNumber(
                  data.item_list.reduce(
                    (acc: number, item: ISalesItemList) =>
                      acc + item.pre_discount_amount,
                    0
                  ) || data.pre_vat_amount
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {!isInternational ? "บาท" : data.currency}
              </Typography>
            </CustomizedSummary>
          </Grid>
        )}
        {!isInternational && (
          <Grid item xs={6}>
            <Box sx={{ display: "block", gap: 0.75, mt: 1.7 }}>
              <Typography sx={{ fontSize: 11, fontWeight: 600 }}>
                หมายเหตุ
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  mr: 2,
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {data.remark}
              </Typography>
            </Box>
          </Grid>
        )}
        {!["purchase_request", "delivery_order"].includes(
          documentType || "-"
        ) && (
          <Grid item xs={!isInternational ? 6 : 12}>
            <Box mt={1}>
              {data.additional_discount > 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {!isInternational
                      ? "ส่วนลดก่อนภาษี"
                      : "Additional Discount"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {formatNumber(
                      roundingNumber(
                        data.item_list?.reduce(
                          (acc: number, item: ISalesItemList) => {
                            let additional_discount = data.additional_discount;
                            if (
                              data.additional_discount_type ===
                              AdditionalDiscountType.Baht
                            ) {
                              additional_discount =
                                data.additional_discount || 0;
                            } else {
                              additional_discount =
                                data.pre_vat_amount *
                                ((data.additional_discount || 0) / 100);
                            }

                            if (
                              data.price_vat_type === PriceVatType.IncludedVat
                            ) {
                              if (item.vat_percentage === "7") {
                                acc +=
                                  additional_discount *
                                  ((item.pre_vat_amount / data.pre_vat_amount ||
                                    0) /
                                    1.07);
                              } else
                                acc +=
                                  additional_discount *
                                  (item.pre_vat_amount / data.pre_vat_amount);
                            } else
                              acc +=
                                additional_discount *
                                (item.pre_vat_amount / data.pre_vat_amount);
                            return acc;
                          },
                          0
                        )
                      ) || 0
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {!isInternational ? "บาท" : data.currency}
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
              {data.additional_discount !== 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {!isInternational ? "มูลค่าหลังหักส่วนลด" : "Total Amount"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {formatNumber(data.sub_total)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                    }}
                  >
                    {!isInternational ? "บาท" : data.currency}
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
            </Box>
            {(!isInternational || data.vat_amount > 0) && (
              <Box>
                <CustomizedSummary
                  bgcolor={(type && bgColor) || undefined}
                  borderRadius="4px"
                >
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: type ? 600 : undefined,
                    }}
                  >
                    {!isInternational ? "ภาษีมูลค่าเพิ่มรวม" : "VAT Amount"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: type ? 600 : undefined,
                    }}
                  >
                    {formatNumber(data.vat_amount)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: type ? 600 : undefined,
                    }}
                  >
                    {!isInternational ? "บาท" : data.currency}
                  </Typography>
                </CustomizedSummary>
              </Box>
            )}
            {!isInternational ? (
              <CustomizedSummary
                bgcolor={
                  (!type &&
                    (isPurchase ? "#FCE8E8" : bgColor ? bgColor : "#ECF6FD")) ||
                  undefined
                }
                borderRadius="4px"
              >
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                    fontWeight: !type ? 600 : undefined,
                  }}
                >
                  มูลค่ารวมสุทธิ
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                    fontWeight: !type ? 600 : undefined,
                  }}
                >
                  {formatNumber(netAmount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                    fontWeight: !type ? 600 : undefined,
                  }}
                >
                  {!isInternational ? "บาท" : data.currency}
                </Typography>
              </CustomizedSummary>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CustomizedSummary
                  bgcolor={
                    (!type &&
                      (isPurchase
                        ? "#FCE8E8"
                        : bgColor
                        ? bgColor
                        : "#ECF6FD")) ||
                    undefined
                  }
                  borderRadius="4px"
                  width={"50%"}
                >
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: !type ? 600 : undefined,
                    }}
                  >
                    {!isInternational ? "มูลค่ารวมสุทธิ" : "Net Total Amount"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: !type ? 600 : undefined,
                    }}
                  >
                    {formatNumber(netAmount)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: sxColor,
                      fontWeight: !type ? 600 : undefined,
                    }}
                  >
                    {!isInternational ? "บาท" : data.currency}
                  </Typography>
                </CustomizedSummary>
              </Box>
            )}
            {data.withholding_tax_amount > 0 && (
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {!isInternational ? "ภาษีหัก ณ ที่จ่าย" : "WHT Amount"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {formatNumber(data.withholding_tax_amount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {!isInternational ? "บาท" : data.currency}
                </Typography>
              </CustomizedSummary>
            )}
            {data.shipping_cost > 0 && (
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {!isInternational ? "ค่าส่ง" : "Shipping Cost"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {formatNumber(data.shipping_cost)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                  }}
                >
                  {!isInternational ? "บาท" : data.currency}
                </Typography>
              </CustomizedSummary>
            )}
            <CustomizedSummary>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {!isInternational ? "จำนวนเงินรวมทั้งสิ้น" : "Amount Due"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {formatNumber(totalAmount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: sxColor,
                  fontWeight: 600,
                }}
              >
                {!isInternational ? "บาท" : data.currency}
              </Typography>
            </CustomizedSummary>
            {!isInternational && (
              <Box mr="30px">
                <Typography
                  sx={{
                    fontSize: 11,
                    color: sxColor,
                    textAlign: "right",
                  }}
                >
                  ({bahttext(roundingNumber(totalAmount))})
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PDFFooter;
