import { useRef, useState } from "react";
import { IUom } from "../../../../types/Inventory/item";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import AgGrid from "../../../UI/AgGrid";
import {
  UomsQuery,
  useUomDeleteMutation,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { Box, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "../../../../hooks/use-modal";
import UomModal from "../../../Form/Uom/UomModal";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import { useSnackbar } from "notistack";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { errorMessageFormatter } from "../../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";

interface Props {
  data?: UomsQuery;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UomsQuery, unknown>>;
}

const UomTable = ({ data, refetch }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef<IUom>(null);
  const [currentId, setCurrentId] = useState<number>();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { mutate } = useUomDeleteMutation<Error>(graphQLClientWithHeaderItem, {
    onSuccess() {
      enqueueSnackbar("ลบหน่วยสำเร็จ", {
        variant: "success",
      });
      refetch();
    },
    onError(error) {
      const errorMessage = errorMessageFormatter(error);
      enqueueSnackbar(errorMessage || "ลบหน่วยไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const { modal, openModalHandler, closeModalHandler } = useModal();
  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() =>
    mutate({
      uniqueInput: {
        id: currentId,
      },
    })
  );

  const onEditHandler = (id?: number) => {
    setCurrentId(id);
    openModalHandler();
  };

  const onDeleteHandler = (id?: number) => {
    setCurrentId(id);
    openConfirmationHandler();
  };

  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "ชื่อ",
      filter: "agTextColumnFilter",
    },
    {
      field: "",
      filter: false,
      sortable: false,
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      cellRenderer: (params: ICellRendererParams<IUom>) => {
        if (
          params.data &&
          params.data.unique_id !== "ชิ้น" &&
          params.data.unique_id !== "ครั้ง"
        ) {
          return (
            <Box>
              <IconButton
                onClick={() => onEditHandler(params.data?.id)}
                color="primary"
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => onDeleteHandler(params.data?.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <>
      <AgGrid
        columnDefs={columnDefs}
        ref={gridRef}
        rowData={data?.uoms || []}
        height={650}
        disabledSidebar
      />
      <UomModal
        open={modal}
        closeModalHandler={closeModalHandler}
        currentId={currentId}
        refetch={refetch}
      />
      <Confirmation
        title="ยันยืนการลบหน่วย"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default UomTable;
