import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { IAvatar, ICreatedBy } from "../../../../types/global";
import { IPurchaseRequest } from "../../../../types/Purchase/purchaseRequest";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { formatDate } from "../../../../utils/Formatter/Date";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../../utils/dataTransformer";

export const usePurchaseRequestColumnDefs = (
  t: TFunction,
  isReport: boolean
) => {
  const [columnDef, setColumnDef] = useState<ColDef<IPurchaseRequest>[]>([]);

  useEffect(() => {
    if (!isReport) {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "due_date",
          headerName: t("date.purchase_due_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          sortable: false,
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IPurchaseRequest, string>
          ) => <CustomizedStatus status={params.value} />,
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
          flex: 1,
          filterParams: {
            values: [
              "draft",
              "wait_approve",
              "not_approved",
              "approved",
              "partially_ordered",
              "fully_ordered",
              "cancelled",
            ],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "requestor_contact",
          headerName: t("purchase.request.requestor_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams<IPurchaseRequest>) =>
            params.value.name,
        },
        {
          field: "created_by",
          headerName: t("sales.created_by"),
          filter: "agTextColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IPurchaseRequest, IAvatar>
          ) => <CustomizedAvatar avatars={[params.value]} />,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "related_user_list",
          headerName: t("sales.employee_list"),
          filter: "agTextColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IPurchaseRequest, IAvatar[]>
          ) => {
            if (params.value && params.value.length > 0) {
              return <CustomizedAvatar avatars={params.value} />;
            }
          },
          minWidth: 200,
          flex: 1,
        },
      ]);
    } else {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.issue_date),
        },
        {
          field: "due_date",
          headerName: t("date.purchase_due_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.due_date),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          sortable: true,
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IPurchaseRequest, string>
          ) => <CustomizedStatus status={params.value} />,
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
          flex: 1,
          filterParams: {
            values: [
              "ร่าง",
              "รออนุมัติ",
              "ไม่อนุมัติ",
              "อนุมัติแล้ว",
              "สั่งซื้อแล้วบางส่วน",
              "สั่งซื้อแล้ว",
              "ยกเลิก",
            ],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "project_unique_id",
          headerName: t("sales.project_unique_id"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.project_unique_id || "-",
        },
        {
          field: "project_name",
          headerName: t("sales.project_name"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.project_name || "-",
        },
        {
          field: "item_unique_id",
          headerName: t("reports.item_document_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_name",
          headerName: t("reports.item_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty",
          headerName: t("reports.qty"),
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "uom",
          headerName: t("reports.uom"),
          filter: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "qty_ordered",
          headerName: t("reports.ordered_qty"),
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_ordered),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "qty_pending",
          headerName: t("reports.pending_qty"),
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.qty_pending),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "item_description",
          headerName: t("reports.item_description"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "remark",
          headerName: t("reports.remark"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "destination_warehouse",
          headerName: t("reports.destination_warehouse"),
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "requestor_name",
          headerName: t("reports.requestor_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "requestor_department",
          headerName: t("reports.requestor_department"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "requestor_position",
          headerName: t("reports.requestor_position"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "reference_unique_id_list",
          headerName: t("reports.reference_document_id"),
          hide: true,
          filter: "agSetColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.reference_unique_id_list &&
            params.data.reference_unique_id_list.length > 0
              ? params.data.reference_unique_id_list
                  .map((reference_unique_id: string) => reference_unique_id)
                  .join(", ")
              : "-",
        },
        {
          field: "external_reference_id",
          headerName: t("reports.external_ref_id"),
          hide: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "item_remark",
          headerName: t("reports.item_remark"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
        },
        {
          field: "created_date",
          headerName: t("reports.created_date"),
          filter: "agDateColumnFilter",
          hide: true,
          minWidth: 200,
          flex: 1,
          filterParams: dateFilterParams,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.created_date),
        },
        {
          field: "updated_date",
          headerName: t("reports.updated_date"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          filterParams: dateFilterParams,
          valueGetter: (params: ValueGetterParams) =>
            formatDate(params.data.updated_date),
        },
        {
          field: "created_by",
          headerName: t("reports.created_by"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.created_by.first_name +
            " " +
            params.data.created_by.last_name,
        },
        {
          field: "related_user_list",
          headerName: t("reports.employee_list"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            params.data.related_user_list &&
            params.data.related_user_list.length > 0
              ? params.data.related_user_list
                  .map(
                    (user: ICreatedBy) => user.first_name + " " + user.last_name
                  )
                  .join(", ")
              : "-",
        },
      ]);
    }
  }, [isReport, t]);
  return columnDef;
};
