import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PurchaseApproval from "./PurchaseNew";
import SalesApproval from "./SalesNew";

const ApprovalSetting = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.approval.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("setting.approval.sales"),
      path: `${pathname}?tab=sales`,
    },
    {
      label: t("setting.approval.purchase"),
      path: `${pathname}?tab=purchase`,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tab || "sales");

  useEffect(() => {
    if (!tab) {
      setSearchParams({ tab: "sales" });
    }
    setCurrentTab(tab || "sales");
  }, [tab, setSearchParams]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box my={2}>
      <CustomizedTab tabs={tabs} currentTab={`${pathname}?tab=${currentTab}`} divider />
        {tab === "purchase" && <PurchaseApproval activeTab={tab}/>}
        {tab === "sales" && <SalesApproval activeTab={tab}/>}
      </Box>
      </>
  );
};

export default ApprovalSetting;