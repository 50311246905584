import {
  Box,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  ICreatedBy,
  IDefaultForm,
  ISelectOption,
} from "../../../../types/global";
import EmployeeListForm from "../../EmployeeList";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { Theme } from "@mui/system";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { usePurchaseOrderCreate } from "../../../../hooks/Purchase/use-purchase-order-create";
import { usePurchaseOrderOption } from "../../../../hooks/Purchase/use-purchase-order-option";
import { WarehouseQuery } from "../../../../generated/inventory";
import {
  ActivityType,
  Exact,
  ModelType,
  PurchaseActivityLogDocumentType,
  PurchaseApprovalTemplatesQuery,
  PurchaseDocumentType,
  PurchaseOrderQuery,
  PurchaseOrderUpdateInput,
  PurchaseOrderUpdateMutation,
  PurchaseOrderWhereUniqueInput,
  PurchaseUniqueIdQuery,
  PurchaseWorkflowProgressQuery,
  Status,
  Tracability,
  usePurchaseApprovalTemplatesQuery,
  usePurchaseDocumentCancelMutation,
  usePurchaseOrderUpdateMutation,
  usePurchaseUniqueIdQuery,
  usePurchaseWorkflowProgressQuery,
} from "../../../../generated/purchase";
import { useSnackbar } from "notistack";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateAsyncFunction,
} from "@tanstack/react-query";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import {
  copyPurchaseOrderFormatter,
  createGoodsReceiveFromOrder,
  createPurchaseReturnFromOrder,
  purchaseOrderUpdatePayloadFormatter,
} from "../../../../utils/Formatter/PurchaseOrder";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { useStateContext } from "../../../../contexts/auth-context";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";
import { ActivityLog } from "../../../UI/SlideInPanel/ActivityLog";
import { useActivityLogCreate } from "../../../../hooks/use-global-activity-log";
import { useModal } from "../../../../hooks/use-modal";
import AutoGRModal from "../../../UI/Modal/AutoGRModal";
import POTaxInvoiceModal from "../../../UI/Modal/POTaxInvoiceModal";
import ApprovalListForm from "../../ApprovalList";
import showApprovalList from "../../ApprovalList/ApproverList";

type Props = IDefaultForm & {
  allWarehouses?: WarehouseQuery["warehouse"][];
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<PurchaseOrderQuery, unknown>>;
  update: UseMutateAsyncFunction<
    PurchaseOrderUpdateMutation,
    Error,
    Exact<{
      uniqueInput: PurchaseOrderWhereUniqueInput;
      updateInput: PurchaseOrderUpdateInput;
    }>,
    unknown
  >;
};

const PurchaseOrderHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  allWarehouses,
  editClickHandler,
  refetch,
  update,
  handleSubmit,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState<boolean>(false);
  const [expectDateIsOpen, setExpectDateIsOpen] = useState<boolean>(false);
  const [paymentDateIsOpen, setPaymentDateIsOpen] = useState<boolean>(false);

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { refetch: getUniqueId } =
    usePurchaseUniqueIdQuery<PurchaseUniqueIdQuery>(
      graphQLClientWithHeaderPurchase,
      {
        modelType: ModelType.PurchaseOrder,
      },
      {
        enabled: false,
        onSuccess: ({ utilGetUniqueId }) => {
          setValue("unique_id", utilGetUniqueId);
        },
      }
    );

  const { purchaseActivityLogCreate } = useActivityLogCreate();

  const { mutateAsync: updatePO } = usePurchaseOrderUpdateMutation<Error>(
    graphQLClientWithHeaderPurchase
  );

  const { mutateAsync: cancel } = usePurchaseDocumentCancelMutation<Error>(
    graphQLClientWithHeaderPurchase,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const tax_invoice_no = useWatch({
    control,
    name: "tax_invoice_no",
  });

  const tax_invoice_date = useWatch({
    control,
    name: "tax_invoice_date",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_unique_id_list = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const item_list = useWatch({
    control,
    name: "item_list",
  });

  const currentTenantId = sessionStorage.getItem("tenant-id");
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

  const printOptions = useMemo(() => {
    const options = [
      {
        value: `พิมพ์${t("purchase.order.index")}`,
        disabled: !id,
      },
    ];
    if (
      (ENVIRONMENT === "development" && currentTenantId === "2eF5dAF6D") ||
      currentTenantId === "R51WD3UC"
    ) {
      options.push({
        value: `${t("purchase.print.foreign_documents")}`,
        disabled: !id,
      });
    }
    return options;
  }, [ENVIRONMENT, currentTenantId, id, t]);

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const { data: approvalTemplatesData } = usePurchaseApprovalTemplatesQuery<PurchaseApprovalTemplatesQuery>(
    graphQLClientWithHeaderPurchase, {}
  );

  const { data: workflowProgressData, refetch: refetchWorkflowProgress } = usePurchaseWorkflowProgressQuery<PurchaseWorkflowProgressQuery>(
    graphQLClientWithHeaderPurchase,
    {
      input: {
        reference_document_type: PurchaseDocumentType.PurchaseOrder,
        reference_unique_id: getValues("unique_id")
      }
    },
    {
      enabled: !!getValues("unique_id"),
    }
  )

  useEffect(() => {
    if (status === "approved" || status === "ejected") {
      refetchWorkflowProgress();
    }
  }, [status, refetchWorkflowProgress]);

  useEffect(() => {
  }, [approvalTemplatesData]);
  const isApprovalEnabled = approvalTemplatesData?.purchaseApprovalTemplates && approvalTemplatesData.purchaseApprovalTemplates.length > 0
    ? approvalTemplatesData.purchaseApprovalTemplates.some(
      (template) =>
        template?.is_have_approval && template.document_type === "purchase_order"
    )
    : false;
    
    const showApprovalLists = showApprovalList({
      workflowProgressData,
      isApprovalEnabled,
      documentType: "purchase",
    });

  const steps = isApprovalEnabled
    ? [
      {
        label: t("status.draft"),
        value: "draft",
      },
      {
        label: t("status.wait_approve"),
        value: "wait_approve",
      },
      {
        label: t("status.approved"),
        value: "approved",
      },
      {
        label: t("status.partially_imported"),
        value: "partially_imported",
      },
      {
        label: t("status.fully_imported"),
        value: "fully_imported",
      },
    ]
    : [
      {
        label: t("status.draft"),
        value: "draft",
      },
      {
        label: t("status.approved"),
        value: "approved",
      },
      {
        label: t("status.partially_imported"),
        value: "partially_imported",
      },
      {
        label: t("status.fully_imported"),
        value: "fully_imported",
      },
    ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(
    status,
    watchMainStatus,
    PurchaseDocumentType.PurchaseOrder,
    undefined,
    isApprovalEnabled
  );

  const selectModifyOptions = usePurchaseOrderOption(status);
  const { selectCreateOptions, disabledCreateOptions } = usePurchaseOrderCreate(
    status,
    control
  );
  const taxInvoiceModal = useConfirmation();

  const warehouseOptions: ISelectOption[] =
    allWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const cancelDocumentHandler = async () => {
    try {
      await cancel({
        documentInput: {
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          unique_id: id ? id : "",
        },
      });
      try {
        await purchaseActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
          reference_id: getValues("id"),
          activity_detail: {
            curr_status: Status.Cancelled,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) { }
  };

  const copyDocumentHandler = () => {
    const purchaseOrder = getValues();
    navigate("/purchase/order/add", {
      state: copyPurchaseOrderFormatter(purchaseOrder),
    });
  };

  const saveInvoiceHandler = (data: IPurchaseOrder) => {
    const payload = purchaseOrderUpdatePayloadFormatter(
      data,
      data?.sub_status || ""
    ) as PurchaseOrderUpdateInput;
    // setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
  };

  const taxInvoiceSubmitHandler = async (data: any) => {
    try {
      if (data) {
        const { purchaseOrderUpdate } = await updatePO({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: data,
        });

        await purchaseActivityLogCreate({
          activity_type: ActivityType.Edit,
          document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
          reference_id: purchaseOrderUpdate.id || 0,
          activity_detail: {},
        });

        taxInvoiceModal.closeConfirmationHandler();

        refetch();

        enqueueSnackbar("บันทึกใบกำกับภาษีสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกใบกำกับภาษีไม่สำเร็จ", { variant: "error" });
    }
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: saveInvoiceModal,
    openConfirmationHandler: openSaveInvoiceModal,
    closeConfirmationHandler: closeSaveInvoiceModal,
    submitConfirmationHandler: submitSaveInvoiceModal,
  } = useConfirmation(
    handleSubmit ? handleSubmit(saveInvoiceHandler) : undefined
  );

  const autoGRModal = useModal();

  return (
    <Fragment>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("purchase.order.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <CustomizedMenuOptions
                sx={{ minWidth: "120px" }}
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === "พิมพ์ใบสั่งซื้อ") {
                    navigate(
                      `/purchase/order/${encodeURIComponent(id ?? "")}/pdf`
                    );
                  } else if (value.innerText === "พิมพ์เอกสารต่างประเทศ") {
                    navigate(
                      `/purchase/order/${encodeURIComponent(
                        id ?? ""
                      )}/pdf-international`
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item>
              <CustomizedMenuOptions
                sx={{ minWidth: "120px" }}
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions.filter((option) =>
                  tax_invoice_no ? option.value !== "บันทึกใบกำกับภาษี" : true
                )}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    case "บันทึกใบบันทึกซื้อ":
                      openSaveInvoiceModal();
                      break;
                    case "บันทึกใบกำกับภาษี":
                      taxInvoiceModal.openConfirmationHandler();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item>
              <CustomizedMenuOptions
                sx={{ minWidth: "120px" }}
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={async (e) => {
                  const purchaseOrder = getValues();
                  const currentUser: ICreatedBy = {
                    user_unique_id: authUser?.unique_id || "",
                    email: authUser?.email || "",
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name,
                    img_url: authUser?.img_url,
                  };
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "ใบนำเข้า":
                      navigate("/inventory/goods_receive/add", {
                        state: await createGoodsReceiveFromOrder(
                          purchaseOrder,
                          currentUser
                        ),
                      });
                      break;
                    case "ใบส่งคืน":
                      navigate("/purchase/return/add", {
                        state: createPurchaseReturnFromOrder(purchaseOrder),
                      });
                      break;
                    default:
                      break;
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            <Grid item>
              <CustomizedButton
                sx={{ minWidth: "120px" }}
                variant="contained"
                size="medium"
                title={t("inventory.goods_receive.index")}
                onClick={autoGRModal.openModalHandler}
                disabled={
                  !disabled ||
                  item_list.every(
                    (item: IPurchaseItemList) =>
                      item.tracability === Tracability.Serial
                  ) ||
                  item_list
                    .filter(
                      (item: IPurchaseItemList) =>
                        item.item_type === "normal" &&
                        item.tracability === Tracability.Normal
                    )
                    .every(
                      (item: IPurchaseItemList) =>
                        item.qty <= (item.qty_received || 0)
                    ) ||
                  !["approved", "partially_imported"].includes(status)
                }
              />
            </Grid>
            {id && (
              <Grid item>
                <CustomizedTooltip
                  title="ดูความเคลื่อนไหว"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                    color="primary"
                  >
                    <RestoreOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <Typography fontWeight="bold">
                {t("purchase.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={8} md={8} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={!!id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography fontWeight="bold">
                {t("purchase.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} md={8} lg={6}>
              {reference_unique_id_list && reference_unique_id_list.length > 0
                ? reference_unique_id_list.map(
                  (reference_unique_id: string) => (
                    <CustomizedChips
                      key={reference_unique_id}
                      onClick={() =>
                        window.open(
                          `/purchase/request/${encodeURIComponent(
                            reference_unique_id
                          )}`,
                          "_blank"
                        )
                      }
                      sx={{ mr: 0.5 }}
                      value={reference_unique_id}
                      onDelete={
                        disabled
                          ? undefined
                          : () => {
                            const itemList = getValues("item_list");
                            const filteredItem = itemList.filter(
                              (item: IPurchaseItemList) =>
                                item.pr_reference_unique_id !==
                                reference_unique_id
                            );
                            const filteredRef =
                              reference_unique_id_list.filter(
                                (current_reference_unique_id: string) =>
                                  current_reference_unique_id !==
                                  reference_unique_id
                              );
                            setValue(
                              "reference_unique_id_list",
                              filteredRef
                            );
                            setValue("item_list", filteredItem);
                          }
                      }
                      color="primary"
                    />
                  )
                )
                : "-"}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={3}>
              <Typography fontWeight="bold">
                {t("purchase.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} md={8} lg={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={3}>
              <Typography fontWeight="bold">
                {t("inventory.goods_transfer.destination_warehouse")}
              </Typography>
            </Grid>
            <Grid item sm={11} md={8} lg={6}>
              <ControlledSelect
                name="destination_warehouse_unique_id"
                control={control}
                error={Boolean(errors?.destination_warehouse_unique_id)}
                helperText={
                  errors?.destination_warehouse_unique_id &&
                  errors?.destination_warehouse_unique_id.message
                }
                options={warehouseOptions}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            {tax_invoice_no && (
              <>
                <Grid item xs={12} sm={3}>
                  <Typography fontWeight="bold">
                    {t("sales.tax_invoice_number")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  {tax_invoice_no}
                </Grid>
              </>
            )}
            {showApprovalLists && (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography fontWeight="bold">
                    {t("purchase.approver_list")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
                  <ApprovalListForm
                    control={control}
                    documentType="purchase_order"
                    approvalTemplates={approvalTemplatesData?.purchaseApprovalTemplates || []}
                    workflowProgress={workflowProgressData?.purchaseWorkflowProgressByDocument || []}
                    data={getValues("flag_status")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Typography fontWeight="bold">
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item md={9}>
              <EmployeeListForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                error={errors.due_date}
                open={dueDateIsOpen}
                onClose={() => setDueDateIsOpen(false)}
                onOpen={() => setDueDateIsOpen(true)}
                label={t("date.purchase_due_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="expect_date"
                control={control}
                error={errors.expect_date}
                open={expectDateIsOpen}
                onClose={() => setExpectDateIsOpen(false)}
                onOpen={() => setExpectDateIsOpen(true)}
                label={t("date.expect_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="payment_due_date"
                control={control}
                error={errors.payment_due_date}
                open={paymentDateIsOpen}
                onClose={() => setPaymentDateIsOpen(false)}
                onOpen={() => setPaymentDateIsOpen(true)}
                label={t("date.payment_due_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                type="number"
                label={t("purchase.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
              />
            </Grid>
            {tax_invoice_date && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledDatePicker
                  name="tax_invoice_date"
                  control={control}
                  open={expectDateIsOpen}
                  onClose={() => setExpectDateIsOpen(false)}
                  onOpen={() => setExpectDateIsOpen(true)}
                  label={t("sales.tax_invoice_date")}
                  disabled={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ModalUI
        title="บันทึกใบบันทึกซื้อ"
        open={saveInvoiceModal}
        handleClose={closeSaveInvoiceModal}
        maxWidth="xs"
        fullWidth
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closeSaveInvoiceModal}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={submitSaveInvoiceModal}
            />
          </Box>
        }
      >
        <DialogContentText sx={{ mb: 2 }}>
          หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
        </DialogContentText>
        <ControlledTextField
          label={t("sales.external_reference_id")}
          control={control}
          name="external_reference_id_confirmation"
        />
      </ModalUI>
      <ActivityLog
        service="purchase"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        documentId={getValues("id")}
        documentType="purchase_order"
      />
      <AutoGRModal
        modal={autoGRModal.modal}
        closeModalHandler={autoGRModal.closeModalHandler}
        poData={getValues()}
        refetch={refetch}
      />
      <POTaxInvoiceModal
        modal={taxInvoiceModal.confirmation}
        handleOpen={taxInvoiceModal.openConfirmationHandler}
        handleClose={taxInvoiceModal.closeConfirmationHandler}
        submitHandler={taxInvoiceSubmitHandler}
      />
    </Fragment>
  );
};

export default PurchaseOrderHeader;
