import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { useWarehousesQuery, WarehousesQuery } from "../../generated/inventory";
import { ISelectOption } from "../../types/global";

export const useWarehouseOptions = (currentWarehouse: string) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const warehouseOptions: ISelectOption[] =
    warehouseData?.warehouses?.map((warehouse) => ({
      id: warehouse?.unique_id || "",
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  const renderWarehouseValue = (value?: string) => {
    const findOption = warehouseData?.warehouses?.find(
      (warehouse) => warehouse?.unique_id === value
    );
    if (value && findOption) return findOption?.name;
  };

  const foundWarehouse = warehouseData?.warehouses?.find(
    (warehouse) => warehouse?.unique_id === currentWarehouse
  );

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.sub_level_2_list?.forEach((level2) => {
      level2?.sub_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = [
    ...allBinLocation.map((bin) => ({
      id: bin.id,
      label: bin.bin_name,
      value: bin.id,
    })),
  ];

  const renderBinlocationValue = (value?: number) => {
    const findOption = binLocationOptions.find(
      (bin_location) => bin_location?.value === value
    );
    if (value && findOption) return findOption.label;
  };

  return {
    warehouseOptions,
    renderWarehouseValue,
    isWarehouseLoading,
    binLocationOptions,
    renderBinlocationValue,
  };
};
