type Props = {
    workflowProgressData: any;
    isApprovalEnabled: boolean;
    documentType: string;
};

const showApprovalList = ({
    workflowProgressData,
    isApprovalEnabled,
    documentType,
}: Props): boolean => {
    const latestProgress =
        workflowProgressData?.[`${documentType}WorkflowProgressByDocument`]?.[0];

    if (
        isApprovalEnabled &&
        (!latestProgress ||
            (latestProgress.status_name === "draft" || latestProgress.status_name === "wait_approve"))
    ) {
        return true;
    }

    if (
        isApprovalEnabled &&
        workflowProgressData?.[`${documentType}WorkflowProgressByDocument`]?.some(
            (progress: any) => progress?.approved_action === "approved"
        )
    ) {
        return true;
    }

    if (
        !isApprovalEnabled &&
        workflowProgressData?.[`${documentType}WorkflowProgressByDocument`]?.some(
            (progress: any) => progress?.approved_action === "approved"
        )
    ) {
        return true;
    }

    return false;
};

export default showApprovalList;
