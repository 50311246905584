import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm } from "../../../types/global";
import { GoodsReceiveQuery } from "../../../generated/inventory";
import { UseFieldArrayAppend } from "react-hook-form";
import { IGoodsReturn } from "../../../types/Inventory/goodsReturn";
import GoodsReturnHeader from "../../../components/Form/Inventory/GoodsReturn/Header";
import GoodsReturnDetail from "../../../components/Form/Inventory/GoodsReturn/Detail";
import { IWarehouse } from "../../../types/Setting/inventory";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsReceiveQuery, unknown>>;
  data?: GoodsReceiveQuery;
  allWarehouses?: IWarehouse[];
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  step: number;
};

const DocumentInfoTab = ({
  control,
  append,
  disabled,
  errors,
  getValues,
  refetch,
  setValue,
  data,
  allWarehouses,
  reset,
  step,
}: Props) => {
  return (
    <>
      <GoodsReturnHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        refetch={refetch}
      />
      <GoodsReturnDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        allWarehouses={allWarehouses}
        append={append}
        reset={reset}
        step={step}
      />
    </>
  );
};

export default DocumentInfoTab;
