import React, { forwardRef, useImperativeHandle, useState } from 'react'
import ButtonLayout from '../ButtonLayout'
import CustomizedButton from '../../Custom/CustomizedButton'
import { useTranslation } from 'react-i18next'
import { Alert, Grid } from '@mui/material'
import { ColumnApi, GridApi } from 'ag-grid-community'

type Props = {
  api: GridApi,
  columnApi: ColumnApi,
}

const CustomViewMessageStatusPanel = forwardRef(({
  ...rest
}: Props, ref) => {
  const { t } = useTranslation();
  const [editMode, seteditMode] = useState<'everyone' | 'onlyme'>()
  const visible = editMode === 'everyone'
  useImperativeHandle(ref, () => {
    return {
      setMode: (mode: 'everyone' | 'onlyme') => {
        seteditMode(mode)
      }
    };
  })
  if (!visible) {
    return null
  }
  return (
    <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
      <Alert severity='info' style={{ backgroundColor: "#e5f6fd", padding: "0px 16px", margin: "12px" }}>การเปลี่ยนแปลงจะมีผลต่อทุกผู้ใช้งานในระบบ</Alert>
    </div>
  )
})

export default CustomViewMessageStatusPanel