import { DateRange, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { IDashboardInput } from "../../../../types/Dashboard";
import { Dispatch, SetStateAction } from "react";
import { Dayjs } from "dayjs";
import Calendar from "@mui/icons-material/Event";

type Props = {
  input: IDashboardInput;
  setInput: Dispatch<SetStateAction<IDashboardInput>>;
};

export default function SingleInputDateRangePicker({ input, setInput }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        format={"DD/MM/YYYY"}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            size: "small",
            InputProps: {
              endAdornment: (
                <Calendar
                  sx={{
                    color: "#737373",
                  }}
                />
              ),
            },
          },
        }}
        value={[input.started_date, input.ended_date]}
        onChange={(v: DateRange<Dayjs>) => {
          const [started_date, ended_date] = v;
          const formatStartDate = started_date?.startOf("day") || null;
          const formatEndDate = ended_date?.endOf("day") || null;
          setInput({
            started_date: formatStartDate,
            ended_date: formatEndDate,
          });
        }}
      />
    </LocalizationProvider>
  );
}
