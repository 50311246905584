import { Box, Typography, Divider } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useSearchParams } from "react-router-dom";

interface Props {
  documentType?: string;
  isInternational?: boolean;
}

const PDFSigner = ({ documentType, isInternational }: Props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        flexWrap: "nowrap",
        width: "197.3mm",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          mt: 1.5,
        }}
      >
        <QRCodeSVG value={window.location.href} size={60} />
        <Typography sx={{ fontSize: 11, fontWeight: 600, mt: 1 }}>
          {!isInternational ? "สแกนดูเอกสาร" : "Scan Document"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          mt: 5,
        }}
      >
        <Box>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 170,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 40,
              }}
            />
          </Box>
        </Box>
        <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
          {!type &&
            (documentType === "sales_order"
              ? "ผู้สั่งขาย / Seller"
              : documentType === "purchase_order"
              ? !isInternational
                ? "ผู้สั่งซื้อ / Buyer"
                : "Buyer"
              : documentType === "quotation"
              ? "ผู้เสนอราคา / Quoted by"
              : documentType === "delivery_order"
              ? "ผู้รับสินค้า / Received by"
              : "")}
          {type && "ผู้ออกเอกสาร / Issued by"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          mt: 5,
        }}
      >
        <Box>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 170,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 40,
              }}
            />
          </Box>
        </Box>
        <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
          {!type &&
            (documentType === "sales_order" || documentType === "quotation"
              ? "ผู้ตรวจสอบ (ผู้ขาย) / Authorized by (Seller)"
              : documentType === "purchase_order"
              ? !isInternational
                ? "ผู้ตรวจสอบ (ผู้ซื้อ) / Authorized by (Buyer)"
                : "Approver"
              : documentType === "delivery_order"
              ? "ผู้ส่งสินค้า / Sent by"
              : "")}
          {type && "ผู้รับเอกสาร / Received by"}
        </Typography>
      </Box>
      {!type && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            mt: 5,
          }}
        >
          <Box>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 170,
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                mt: "4px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 30,
                }}
              />
              <Typography fontSize={14}>/</Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 30,
                }}
              />
              <Typography fontSize={14}>/</Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 40,
                }}
              />
            </Box>
          </Box>

          <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
            {documentType === "sales_order" || documentType === "quotation"
              ? "ลูกค้า / Customer"
              : documentType === "purchase_order"
              ? !isInternational
                ? "ผู้ขาย / Vendor"
                : "Vendor"
              : documentType === "delivery_order"
              ? "ผู้อนุมัติ / Approved by"
              : ""}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PDFSigner;
