import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  AnyAggridInput: any;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type ActivityLogCopyObject = {
  __typename?: 'ActivityLogCopyObject';
  id?: Maybe<Scalars['Int']>;
  unique_id?: Maybe<Scalars['String']>;
};

export type ActivityLogCopyObjectInput = {
  id?: InputMaybe<Scalars['Int']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type ActivityLogDetailObject = {
  __typename?: 'ActivityLogDetailObject';
  added_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  attachment_list?: Maybe<Array<Scalars['String']>>;
  copied_from?: Maybe<ActivityLogCopyObject>;
  copied_to?: Maybe<ActivityLogCopyObject>;
  curr_status?: Maybe<Status>;
  deleted_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  message?: Maybe<Array<Scalars['String']>>;
  prev_status?: Maybe<Status>;
  updated_fields?: Maybe<Scalars['JSON']>;
};

export type ActivityLogDetailObjectInput = {
  added_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  attachment_list?: InputMaybe<Array<Scalars['String']>>;
  copied_from?: InputMaybe<ActivityLogCopyObjectInput>;
  copied_to?: InputMaybe<ActivityLogCopyObjectInput>;
  curr_status?: InputMaybe<Status>;
  deleted_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  message?: InputMaybe<Array<Scalars['String']>>;
  prev_status?: InputMaybe<Status>;
  secondary_operation?: InputMaybe<ActivityType>;
  updated_fields?: InputMaybe<Scalars['JSON']>;
};

export type ActivityLogRelatedEmployeeObject = {
  __typename?: 'ActivityLogRelatedEmployeeObject';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type ActivityLogRelatedEmployeeObjectInput = {
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type ActivityLogUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum ActivityType {
  Active = 'active',
  AddRelatedEmployee = 'add_related_employee',
  Approve = 'approve',
  Cancel = 'cancel',
  ChangePassword = 'change_password',
  Comment = 'comment',
  Copy = 'copy',
  Create = 'create',
  CreateImporter = 'create_importer',
  Delete = 'delete',
  DeleteRelatedEmployee = 'delete_related_employee',
  Document = 'document',
  Edit = 'edit',
  EditImporter = 'edit_importer',
  EditPermission = 'edit_permission',
  FactChange = 'fact_change',
  Image = 'image',
  InActive = 'in_active',
  MainBom = 'main_bom',
  MainRouting = 'main_routing',
  StatusChange = 'status_change'
}

export enum AggridArrayFilterType {
  Array = 'array'
}

export type AggridBooleanFilterModelInput = {
  filter?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AggridBooleanFilterModelType>;
};

export enum AggridBooleanFilterModelType {
  Equals = 'equals',
  NotEqual = 'notEqual'
}

export type AggridDateFilterModelInput = {
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridDateFilterType>;
  type?: InputMaybe<AggridDateFilterModelType>;
};

export enum AggridDateFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridDateFilterType {
  Date = 'date'
}

export type AggridFloatFilterModelInput = {
  condition1?: InputMaybe<AggridFloatFilterModelInputCondition>;
  condition2?: InputMaybe<AggridFloatFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Float']>;
  filterTo?: InputMaybe<Scalars['Float']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridFloatFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Float']>;
  filterTo?: InputMaybe<Scalars['Float']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridGenericArrayFilterModelInput = {
  filterType?: InputMaybe<AggridArrayFilterType>;
  type?: InputMaybe<AggridGenericArrayFilterModelType>;
  values?: InputMaybe<Scalars['Any']>;
};

export enum AggridGenericArrayFilterModelType {
  Equals = 'equals',
  Has = 'has',
  HasEvery = 'hasEvery',
  HasSome = 'hasSome',
  IsEmpty = 'isEmpty'
}

export enum AggridISimpleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export type AggridIntFilterModelInput = {
  condition1?: InputMaybe<AggridIntFilterModelInputCondition>;
  condition2?: InputMaybe<AggridIntFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Int']>;
  filterTo?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridIntFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Int']>;
  filterTo?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export enum AggridNumberFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridNumberFilterType {
  Number = 'number'
}

export enum AggridObjectArrayFilterModelType {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export enum AggridObjectArrayFilterType {
  ObjectArray = 'objectArray'
}

export enum AggridOperatorFilter {
  And = 'AND',
  Or = 'OR'
}

export type AggridSetSingleFilterModelInput = {
  condition1?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  condition2?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  filterType?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<AggridOperatorFilter>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
};

export type AggridSetSingleFilterModelInputCondition = {
  filterType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AggridSetSingleFilterModelType>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
};

export enum AggridSetSingleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual'
}

export type AggridSortModelItem = {
  colId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortOrder>;
};

export type AggridSortModelItemInput = {
  colId: Scalars['String'];
  sort: SortOrder;
};

export type AggridStringFilterModelInput = {
  condition1?: InputMaybe<AggridStringFilterModelInputCondition>;
  condition2?: InputMaybe<AggridStringFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export type AggridStringFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['String']>;
  filterTo?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export enum AggridStringFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export enum AggridStringModeOption {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type AggridTemplateInput = {
  endRow?: InputMaybe<Scalars['Int']>;
  filterModel?: InputMaybe<Scalars['Any']>;
  sortModel?: InputMaybe<Array<AggridSortModelItemInput>>;
  startRow?: InputMaybe<Scalars['Int']>;
};

export enum AggridTextFilterType {
  Text = 'text'
}

export enum ApprovalAction {
  Approved = 'approved',
  Ejected = 'ejected'
}

export type ApprovalDetail = {
  __typename?: 'ApprovalDetail';
  approval_template: ApprovalTemplate;
  approval_template_id: Scalars['Int'];
  approved_step_number: Scalars['Int'];
  id: Scalars['Int'];
  reviewer_list?: Maybe<Array<ReviewerObject>>;
  reviewer_unique_id_list?: Maybe<Array<Scalars['String']>>;
};

export type ApprovalDetailCreateOrUpdateInput = {
  approved_step_number?: InputMaybe<Scalars['Int']>;
  reviewer_list?: InputMaybe<Array<ReviewerCreateObject>>;
  reviewer_unique_id_list?: InputMaybe<Array<Scalars['String']>>;
};

export type ApprovalTemplate = {
  __typename?: 'ApprovalTemplate';
  approval_detail_list?: Maybe<Array<ApprovalDetail>>;
  id: Scalars['Int'];
  required_approval_number: Scalars['Int'];
  workflow_template_list?: Maybe<Array<ManufactureWorkflowTemplate>>;
};

export type ApproveInput = {
  approved_action?: InputMaybe<ApprovalAction>;
  document: DocumentInput;
  reviewer_unique_id: Scalars['String'];
};

export type AttachmentInputObject = {
  attachment_name?: InputMaybe<Scalars['String']>;
  uploaded_by?: InputMaybe<UploaderInputObject>;
  uploaded_date?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type AttachmentObject = {
  __typename?: 'AttachmentObject';
  attachment_name?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<UploaderObject>;
  uploaded_date?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type Bom = {
  __typename?: 'Bom';
  attachment_list?: Maybe<Array<Maybe<AttachmentObject>>>;
  created_by?: Maybe<UserObject>;
  created_date?: Maybe<Scalars['DateTime']>;
  creator_unique_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  ingredient_list?: Maybe<Array<Maybe<BomIngredient>>>;
  internal_remark?: Maybe<Scalars['String']>;
  is_active: Scalars['Int'];
  is_main_bom: Scalars['Int'];
  item_name: Scalars['String'];
  item_unique_id: Scalars['String'];
  mfg_qty?: Maybe<Scalars['Decimal']>;
  mfg_type?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start_date?: Maybe<Scalars['DateTime']>;
  uom?: Maybe<Scalars['String']>;
  uom_unique_id?: Maybe<Scalars['String']>;
};

export type BomAggridOutput = {
  __typename?: 'BomAggridOutput';
  count: Scalars['Int'];
  data: Array<Bom>;
};

export type BomCreateInput = {
  attachment_list?: InputMaybe<Array<AttachmentInputObject>>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  ingredient_list?: InputMaybe<Array<BomIngredientCreateByBomInput>>;
  internal_remark?: InputMaybe<Scalars['String']>;
  is_active: Scalars['Int'];
  is_main_bom: Scalars['Int'];
  item_name: Scalars['String'];
  item_unique_id: Scalars['String'];
  mfg_qty?: InputMaybe<Scalars['Decimal']>;
  mfg_type?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  start_date?: InputMaybe<Scalars['DateTime']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomIngredient = {
  __typename?: 'BomIngredient';
  bom?: Maybe<Bom>;
  bom_name?: Maybe<Scalars['String']>;
  cost_per_unit?: Maybe<Scalars['Decimal']>;
  cuid: Scalars['String'];
  item_barcode?: Maybe<Scalars['String']>;
  item_description?: Maybe<Scalars['String']>;
  item_img_url?: Maybe<Array<Scalars['String']>>;
  item_name?: Maybe<Scalars['String']>;
  item_tracability?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  manufacture_order?: Maybe<Array<Maybe<ManufactureOrder>>>;
  qty?: Maybe<Scalars['Decimal']>;
  uom?: Maybe<Scalars['String']>;
  uom_unique_id?: Maybe<Scalars['String']>;
};

export type BomIngredientCreateByBomInput = {
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomIngredientCreateInput = {
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomIngredientUpdateByBomInput = {
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  cuid?: InputMaybe<Scalars['String']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomIngredientUpdateInput = {
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BomUpdateInput = {
  attachment_list?: InputMaybe<Array<AttachmentInputObject>>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  ingredient_list?: InputMaybe<Array<BomIngredientUpdateByBomInput>>;
  internal_remark?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Int']>;
  is_main_bom?: InputMaybe<Scalars['Int']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  mfg_qty?: InputMaybe<Scalars['Decimal']>;
  mfg_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type BomUpdateManyFromItemInput = {
  item_unique_id: Scalars['String'];
  main_bom_id: Scalars['String'];
};

export type BomValidateDetail = {
  __typename?: 'BomValidateDetail';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Int']>;
  is_main_bom?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type BomWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<BomWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BomWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BomWhereInput>>>;
  id?: InputMaybe<Scalars['Int']>;
  item_unique_id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type BooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type BooleanNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  has?: InputMaybe<Scalars['Boolean']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Company = {
  __typename?: 'Company';
  address_list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  company_permission?: Maybe<CompanyPermission>;
  contact_channel_list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  expired_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  identity_no?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  is_active: Scalars['Int'];
  is_registered_vat?: Maybe<Scalars['Boolean']>;
  last_active_date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  owner_email: Scalars['String'];
  register_date?: Maybe<Scalars['DateTime']>;
  resubscription_date?: Maybe<Scalars['DateTime']>;
  status_remark?: Maybe<Scalars['String']>;
  subscription_level_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
  user_email_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  vat_registration_date?: Maybe<Scalars['DateTime']>;
};

export type CompanyPermission = {
  __typename?: 'CompanyPermission';
  company?: Maybe<Company>;
  company_id: Scalars['String'];
  id: Scalars['Int'];
};

export type CreatorObject = {
  __typename?: 'CreatorObject';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  last_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DocumentCancelOutput = {
  __typename?: 'DocumentCancelOutput';
  flag_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  reference_document_type: ManufactureEntityType;
  unique_id: Scalars['String'];
};

export type DocumentInput = {
  reference_document_type: ManufactureEntityType;
  unique_id: Scalars['String'];
};

export type DocumentSetStatusInput = {
  flag_status?: InputMaybe<Array<InputMaybe<ManufactureFlagStatus>>>;
  reference_document_type: ManufactureEntityType;
  reference_unique_id: Scalars['String'];
  sub_status?: InputMaybe<Scalars['String']>;
};

export type DocumentSetStatusOutput = {
  __typename?: 'DocumentSetStatusOutput';
  unique_id?: Maybe<Scalars['String']>;
};

export type DocumentSetStepInput = {
  reference_document_type: ManufactureEntityType;
  step: Scalars['Int'];
  unique_id: Scalars['String'];
};

export type DocumentStepOutput = {
  __typename?: 'DocumentStepOutput';
  approved_step_number?: Maybe<Scalars['Int']>;
  reference_document_type: ManufactureEntityType;
  status_name: Scalars['String'];
  step_number: Scalars['Int'];
  unique_id: Scalars['String'];
};

export type ExportGenerateInput = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  export_type: ManufacExportType;
  params?: InputMaybe<Scalars['JSON']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type FloatNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  has?: InputMaybe<Scalars['Float']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type ItemValidateInManufactureOutput = {
  __typename?: 'ItemValidateInManufactureOutput';
  active_document_list?: Maybe<Array<Scalars['String']>>;
  can_disable?: Maybe<Scalars['Boolean']>;
};

export enum ManufacExportType {
  ManufacBomReport = 'manufac_bom_report',
  ManufacIngredientReport = 'manufac_ingredient_report',
  ManufacOrderReport = 'manufac_order_report',
  ManufacProcessReport = 'manufac_process_report'
}

export type ManufactureActivityLog = {
  __typename?: 'ManufactureActivityLog';
  activity_detail?: Maybe<ActivityLogDetailObject>;
  activity_type?: Maybe<ActivityType>;
  created_by?: Maybe<CreatorObject>;
  created_date?: Maybe<Scalars['DateTime']>;
  document_type?: Maybe<ManufactureActivityLogDocumentType>;
  id: Scalars['Int'];
  reference_id?: Maybe<Scalars['Int']>;
};

export type ManufactureActivityLogByRefereceIdInput = {
  document_type: ManufactureActivityLogDocumentType;
  reference_id: Scalars['Int'];
};

export type ManufactureActivityLogCreateInput = {
  activity_detail: ActivityLogDetailObjectInput;
  activity_type: ActivityType;
  document_type: ManufactureActivityLogDocumentType;
  reference_id: Scalars['Int'];
};

export enum ManufactureActivityLogDocumentType {
  Bom = 'bom',
  ManufactureOrder = 'manufacture_order',
  Routing = 'routing'
}

export type ManufactureActivityLogFindManyAggrid = {
  __typename?: 'ManufactureActivityLogFindManyAggrid';
  count: Scalars['Int'];
  data: Array<ManufactureActivityLog>;
};

export type ManufactureApprovalTemplateCreateInput = {
  approval_detail_list?: InputMaybe<Array<ApprovalDetailCreateOrUpdateInput>>;
};

export type ManufactureApprovalTemplateUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ManufactureApprovalTemplateUpdateInput = {
  approval_detail_list?: InputMaybe<Array<ApprovalDetailCreateOrUpdateInput>>;
  required_approval_number?: InputMaybe<Scalars['Int']>;
};

export type ManufactureBomExportView = {
  __typename?: 'ManufactureBomExportView';
  cost_per_unit?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  internal_remark?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Int']>;
  is_main?: Maybe<Scalars['Int']>;
  item_id?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  mfg_qty?: Maybe<Scalars['Float']>;
  mfg_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['DateTime']>;
  uom_item?: Maybe<Scalars['String']>;
  uom_product?: Maybe<Scalars['String']>;
};

export type ManufactureBomExportViewsAggridOutput = {
  __typename?: 'ManufactureBomExportViewsAggridOutput';
  count?: Maybe<Scalars['Int']>;
  results: Array<Maybe<ManufactureBomExportView>>;
};

export type ManufactureDateInput = {
  ended_date: Scalars['DateTime'];
  started_date: Scalars['DateTime'];
};

export enum ManufactureFlagStatus {
  Cancelled = 'cancelled',
  Late = 'late',
  NotApproved = 'not_approved'
}

export type ManufactureIngredientExportView = {
  __typename?: 'ManufactureIngredientExportView';
  actual_production_qty?: Maybe<Scalars['Float']>;
  aggrid_status?: Maybe<Scalars['String']>;
  attachment_urls?: Maybe<Scalars['String']>;
  bom_cost?: Maybe<Scalars['Float']>;
  bom_name?: Maybe<Scalars['String']>;
  cost_per_unit?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['DateTime']>;
  customer_contact_unique_id?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  delivery_date?: Maybe<Scalars['DateTime']>;
  external_reference_id?: Maybe<Scalars['String']>;
  good_issue_qty?: Maybe<Scalars['Float']>;
  goods_receive_qty?: Maybe<Scalars['Float']>;
  group?: Maybe<Scalars['String']>;
  ingredient_order_id?: Maybe<Scalars['Int']>;
  issue_date?: Maybe<Scalars['DateTime']>;
  item_description?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_remark?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  mfg_type?: Maybe<Scalars['String']>;
  mo_item_description?: Maybe<Scalars['String']>;
  mo_item_name?: Maybe<Scalars['String']>;
  mo_item_unique_id?: Maybe<Scalars['String']>;
  mo_uom?: Maybe<Scalars['String']>;
  moi_uom?: Maybe<Scalars['String']>;
  other_manufacture_cost?: Maybe<Scalars['Float']>;
  production_completion_date?: Maybe<Scalars['DateTime']>;
  production_date?: Maybe<Scalars['DateTime']>;
  production_qty?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Int']>;
  reference_unique_ids?: Maybe<Scalars['String']>;
  related_users?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  routing_name?: Maybe<Scalars['String']>;
  total_cost?: Maybe<Scalars['Float']>;
  unique_id?: Maybe<Scalars['String']>;
  work_order_cost?: Maybe<Scalars['Float']>;
};

export type ManufactureIngredientExportViewsAggridOutput = {
  __typename?: 'ManufactureIngredientExportViewsAggridOutput';
  count?: Maybe<Scalars['Int']>;
  results: Array<Maybe<ManufactureIngredientExportView>>;
};

export type ManufactureOrder = {
  __typename?: 'ManufactureOrder';
  actual_production_qty?: Maybe<Scalars['Decimal']>;
  aggrid_status?: Maybe<Scalars['String']>;
  attachment_list?: Maybe<Array<Maybe<AttachmentObject>>>;
  bom_cost?: Maybe<Scalars['Float']>;
  bom_id?: Maybe<Scalars['Int']>;
  bom_name?: Maybe<Scalars['String']>;
  created_by?: Maybe<UserObject>;
  created_date?: Maybe<Scalars['DateTime']>;
  customer_contact_unique_id: Scalars['String'];
  customer_name: Scalars['String'];
  delivery_date?: Maybe<Scalars['DateTime']>;
  external_reference_id?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  goods_receive_qty?: Maybe<Scalars['Decimal']>;
  id: Scalars['Int'];
  ingredient_list?: Maybe<Array<Maybe<ManufactureOrderIngredient>>>;
  issue_date?: Maybe<Scalars['DateTime']>;
  item_description?: Maybe<Scalars['String']>;
  item_name: Scalars['String'];
  item_remark?: Maybe<Scalars['String']>;
  item_unique_id: Scalars['String'];
  main_status?: Maybe<Scalars['String']>;
  other_manufacture_cost?: Maybe<Scalars['Float']>;
  production_completion_date?: Maybe<Scalars['DateTime']>;
  production_date?: Maybe<Scalars['DateTime']>;
  production_qty?: Maybe<Scalars['Decimal']>;
  project?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  reference_item_uuid?: Maybe<Scalars['String']>;
  reference_unique_id_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  related_user_list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  remark?: Maybe<Scalars['String']>;
  routing_detail?: Maybe<Scalars['JSON']>;
  routing_id?: Maybe<Scalars['Int']>;
  sub_status?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<ManufactureTag>>>;
  template_remark_id?: Maybe<Scalars['String']>;
  total_cost?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
  uom?: Maybe<Scalars['String']>;
  uom_unique_id?: Maybe<Scalars['String']>;
  waste_production_list?: Maybe<Array<Maybe<WasteProduction>>>;
  work_order_cost?: Maybe<Scalars['Float']>;
  work_order_list?: Maybe<Array<Maybe<WorkOrder>>>;
};

export type ManufactureOrderAggridOutput = {
  __typename?: 'ManufactureOrderAggridOutput';
  count: Scalars['Int'];
  data: Array<ManufactureOrder>;
};

export type ManufactureOrderByStatus = {
  __typename?: 'ManufactureOrderByStatus';
  cancelled: StatusData;
  draft: StatusData;
  finished: StatusData;
  in_progress: StatusData;
  pending_manu: StatusData;
  total_count: Scalars['Int'];
};

export type ManufactureOrderCreateInput = {
  actual_production_qty?: InputMaybe<Scalars['Decimal']>;
  aggrid_status?: InputMaybe<Scalars['String']>;
  attachment_list?: InputMaybe<Array<AttachmentInputObject>>;
  bom_cost?: InputMaybe<Scalars['Float']>;
  bom_id?: InputMaybe<Scalars['Int']>;
  bom_name?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  customer_contact_unique_id: Scalars['String'];
  customer_name: Scalars['String'];
  delivery_date?: InputMaybe<Scalars['DateTime']>;
  external_reference_id?: InputMaybe<Scalars['String']>;
  flag_status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  goods_receive_qty?: InputMaybe<Scalars['Decimal']>;
  ingredient_list?: InputMaybe<Array<ManufactureOrderIngredientCreateByMoInput>>;
  issue_date?: InputMaybe<Scalars['DateTime']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_name: Scalars['String'];
  item_remark?: InputMaybe<Scalars['String']>;
  item_unique_id: Scalars['String'];
  other_manufacture_cost?: InputMaybe<Scalars['Float']>;
  production_completion_date?: InputMaybe<Scalars['DateTime']>;
  production_date?: InputMaybe<Scalars['DateTime']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  project?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  reference_item_uuid?: InputMaybe<Scalars['String']>;
  reference_unique_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  related_user_list?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  remark?: InputMaybe<Scalars['String']>;
  routing_detail?: InputMaybe<Scalars['JSON']>;
  routing_id?: InputMaybe<Scalars['Int']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  template_remark_id?: InputMaybe<Scalars['String']>;
  total_cost?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  unique_id: Scalars['String'];
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
  waste_production_list?: InputMaybe<Array<WasteProductionCreateByManufactureOrderInput>>;
  work_order_cost?: InputMaybe<Scalars['Float']>;
  work_order_list?: InputMaybe<Array<WorkOrderCreateByManufactureOrderInput>>;
};

export type ManufactureOrderExportView = {
  __typename?: 'ManufactureOrderExportView';
  actual_production_qty?: Maybe<Scalars['Float']>;
  aggrid_status?: Maybe<Scalars['String']>;
  attachment_urls?: Maybe<Scalars['String']>;
  bom_cost?: Maybe<Scalars['Float']>;
  bom_name?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['DateTime']>;
  customer_contact_unique_id?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  delivery_date?: Maybe<Scalars['DateTime']>;
  external_reference_id?: Maybe<Scalars['String']>;
  goods_receive_qty?: Maybe<Scalars['Float']>;
  internal_remark?: Maybe<Scalars['String']>;
  issue_date?: Maybe<Scalars['DateTime']>;
  item_description?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_remark?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  mt_name?: Maybe<Scalars['String']>;
  other_manufacture_cost?: Maybe<Scalars['Float']>;
  prod_type?: Maybe<Scalars['String']>;
  production_completion_date?: Maybe<Scalars['DateTime']>;
  production_date?: Maybe<Scalars['DateTime']>;
  production_qty?: Maybe<Scalars['Float']>;
  reference_unique_ids?: Maybe<Scalars['String']>;
  related_users?: Maybe<Scalars['String']>;
  routing_name?: Maybe<Scalars['String']>;
  total_cost?: Maybe<Scalars['Float']>;
  unique_id?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  work_order_cost?: Maybe<Scalars['Float']>;
};

export type ManufactureOrderExportViewsAggridOutput = {
  __typename?: 'ManufactureOrderExportViewsAggridOutput';
  count?: Maybe<Scalars['Int']>;
  results: Array<Maybe<ManufactureOrderExportView>>;
};

export type ManufactureOrderIngredient = {
  __typename?: 'ManufactureOrderIngredient';
  bom?: Maybe<Bom>;
  bom_name?: Maybe<Scalars['String']>;
  cost_per_unit?: Maybe<Scalars['Decimal']>;
  cuid: Scalars['String'];
  good_issue_qty?: Maybe<Scalars['Decimal']>;
  good_return_qty?: Maybe<Scalars['Decimal']>;
  item_barcode?: Maybe<Scalars['String']>;
  item_description?: Maybe<Scalars['String']>;
  item_img_url?: Maybe<Array<Scalars['String']>>;
  item_name?: Maybe<Scalars['String']>;
  item_tracability?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  manufacture_order: ManufactureOrder;
  manufacture_order_unique_id: Scalars['String'];
  qty?: Maybe<Scalars['Decimal']>;
  uom?: Maybe<Scalars['String']>;
  uom_unique_id?: Maybe<Scalars['String']>;
};

export type ManufactureOrderIngredientCreateByMoInput = {
  bom_name?: InputMaybe<Scalars['String']>;
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  good_issue_qty?: InputMaybe<Scalars['Decimal']>;
  good_return_qty?: InputMaybe<Scalars['Decimal']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type ManufactureOrderIngredientUpdateByMoInput = {
  bom_name?: InputMaybe<Scalars['String']>;
  cost_per_unit?: InputMaybe<Scalars['Decimal']>;
  cuid?: InputMaybe<Scalars['String']>;
  good_issue_qty?: InputMaybe<Scalars['Decimal']>;
  good_return_qty?: InputMaybe<Scalars['Decimal']>;
  item_barcode?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_img_url?: InputMaybe<Array<Scalars['String']>>;
  item_name?: InputMaybe<Scalars['String']>;
  item_tracability?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type ManufactureOrderUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type ManufactureOrderUpdateInput = {
  actual_production_qty?: InputMaybe<Scalars['Decimal']>;
  aggrid_status?: InputMaybe<Scalars['String']>;
  attachment_list?: InputMaybe<Array<AttachmentInputObject>>;
  bom_cost?: InputMaybe<Scalars['Float']>;
  bom_id?: InputMaybe<Scalars['Int']>;
  bom_name?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  customer_contact_unique_id?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  delivery_date?: InputMaybe<Scalars['DateTime']>;
  external_reference_id?: InputMaybe<Scalars['String']>;
  flag_status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  goods_receive_qty?: InputMaybe<Scalars['Decimal']>;
  ingredient_list?: InputMaybe<Array<ManufactureOrderIngredientUpdateByMoInput>>;
  issue_date?: InputMaybe<Scalars['DateTime']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_remark?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  other_manufacture_cost?: InputMaybe<Scalars['Float']>;
  production_completion_date?: InputMaybe<Scalars['DateTime']>;
  production_date?: InputMaybe<Scalars['DateTime']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  project?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  reference_item_uuid?: InputMaybe<Scalars['String']>;
  reference_unique_id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  related_user_list?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  remark?: InputMaybe<Scalars['String']>;
  routing_detail?: InputMaybe<Scalars['JSON']>;
  routing_id?: InputMaybe<Scalars['Int']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  template_remark_id?: InputMaybe<Scalars['String']>;
  total_cost?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
  waste_production_list?: InputMaybe<Array<WasteProductionUpdateByManufactureOrderInput>>;
  work_order_cost?: InputMaybe<Scalars['Float']>;
  work_order_list?: InputMaybe<Array<WorkOrderUpdateByManufactureOrderInput>>;
};

export type ManufactureProcessExportView = {
  __typename?: 'ManufactureProcessExportView';
  actual_production_qty?: Maybe<Scalars['Float']>;
  attachment_urls?: Maybe<Scalars['String']>;
  bom_cost?: Maybe<Scalars['Float']>;
  bom_name?: Maybe<Scalars['String']>;
  cost_price?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['DateTime']>;
  customer_contact_unique_id?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  delivery_date?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  external_reference_id?: Maybe<Scalars['String']>;
  factory?: Maybe<Scalars['String']>;
  finished_date?: Maybe<Scalars['DateTime']>;
  goods_receive_qty?: Maybe<Scalars['Float']>;
  goods_remark?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  issue_date?: Maybe<Scalars['DateTime']>;
  item_description?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_remark?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  mfg_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  other_manufacture_cost?: Maybe<Scalars['Float']>;
  production_center?: Maybe<Scalars['String']>;
  production_completion_date?: Maybe<Scalars['DateTime']>;
  production_date?: Maybe<Scalars['DateTime']>;
  production_qty?: Maybe<Scalars['Float']>;
  reference_unique_ids?: Maybe<Scalars['String']>;
  related_users?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  responsible_users?: Maybe<Scalars['String']>;
  routing_name?: Maybe<Scalars['String']>;
  started_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  step_status?: Maybe<Scalars['String']>;
  total_cost?: Maybe<Scalars['Float']>;
  unique_id?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  work_order_cost?: Maybe<Scalars['Float']>;
  work_order_id?: Maybe<Scalars['Int']>;
};

export type ManufactureProcessExportViewsAggridOutput = {
  __typename?: 'ManufactureProcessExportViewsAggridOutput';
  count?: Maybe<Scalars['Int']>;
  results: Array<Maybe<ManufactureProcessExportView>>;
};

export type ManufactureTag = {
  __typename?: 'ManufactureTag';
  entity: ManufactureEntityType;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ManufactureTagAggridOutput = {
  __typename?: 'ManufactureTagAggridOutput';
  count: Scalars['Int'];
  data: Array<ManufactureTag>;
};

export type ManufactureTagCreateinput = {
  entity: ManufactureEntityType;
  name: Scalars['String'];
};

export type ManufactureTagNameEntityCompoundUniqueinput = {
  entity: ManufactureEntityType;
  name: Scalars['String'];
};

export type ManufactureTagUpdateinput = {
  entity?: InputMaybe<ManufactureEntityType>;
  name?: InputMaybe<Scalars['String']>;
};

export type ManufactureTagWhereUniqueinput = {
  id: Scalars['Int'];
  name_entity?: InputMaybe<ManufactureTagNameEntityCompoundUniqueinput>;
};

export type ManufactureWorkflowProgress = {
  __typename?: 'ManufactureWorkflowProgress';
  approved_action?: Maybe<ApprovalAction>;
  approved_step_number: Scalars['Int'];
  id: Scalars['Int'];
  manufacture_order?: Maybe<ManufactureOrder>;
  manufacture_order_unique_id?: Maybe<Scalars['String']>;
  need_approve: Scalars['Boolean'];
  reference_document_type?: Maybe<ManufactureEntityType>;
  reference_unique_id: Scalars['String'];
  reviewer_unique_id?: Maybe<Scalars['String']>;
  status_name: Scalars['String'];
  unique_id: Scalars['String'];
  work_order?: Maybe<WorkOrder>;
  work_order_id?: Maybe<Scalars['Int']>;
  workflow?: Maybe<ManufactureWorkflowTemplate>;
  workflow_step_number: Scalars['Int'];
  workflow_template_id?: Maybe<Scalars['String']>;
};

export type ManufactureWorkflowProgressFindManyInput = {
  cursor?: InputMaybe<ManufactureWorkflowProgressWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManufactureWorkflowProgressWhereInput>;
};

export type ManufactureWorkflowProgressWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<ManufactureWorkflowProgressWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ManufactureWorkflowProgressWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ManufactureWorkflowProgressWhereInput>>>;
  approved_step_number?: InputMaybe<IntFilter>;
  need_approve?: InputMaybe<BooleanFilter>;
  reference_document_type?: InputMaybe<ManufactureEntityType>;
  reference_unique_id?: InputMaybe<StringFilter>;
  status_name?: InputMaybe<StringFilter>;
  unique_id?: InputMaybe<StringFilter>;
  workflow_step_number?: InputMaybe<IntFilter>;
};

export type ManufactureWorkflowProgressWhereUniqueInput = {
  unique_id?: InputMaybe<StringFilter>;
  workflow_document_step?: InputMaybe<WorkflowDocumentStepInput>;
};

export type ManufactureWorkflowTemplate = {
  __typename?: 'ManufactureWorkflowTemplate';
  approval_template?: Maybe<ApprovalTemplate>;
  approval_template_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  need_approve: Scalars['Boolean'];
  reference_document_type: ManufactureEntityType;
  status_name: Scalars['String'];
  step_number: Scalars['Int'];
  unique_id: Scalars['String'];
  workflow_progress_list?: Maybe<Array<Maybe<ManufactureWorkflowProgress>>>;
};

export type ManufactureWorkflowTemplateCreateInput = {
  approval_template_id?: InputMaybe<Scalars['Int']>;
  need_approve?: InputMaybe<Scalars['Boolean']>;
  reference_document_type: ManufactureEntityType;
  status_name: Scalars['String'];
  step_number: Scalars['Int'];
  unique_id: Scalars['String'];
};

export type ManufactureWorkflowTemplateFindManyInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ManufactureWorkflowTemplateObjWhereUniqueInput = {
  reference_document_type_step_number?: InputMaybe<ManufactureWorkflowTemplateStepNumberReferenceDocumentTypeInput>;
};

export type ManufactureWorkflowTemplateStepNumberReferenceDocumentTypeInput = {
  reference_document_type: ManufactureEntityType;
  step_number: Scalars['Int'];
};

export type ManufactureWorkflowTemplateUpdateInput = {
  approval_template_id?: InputMaybe<Scalars['Int']>;
  need_approve?: InputMaybe<Scalars['Boolean']>;
  reference_document_type?: InputMaybe<ManufactureEntityType>;
  status_name?: InputMaybe<Scalars['String']>;
  step_number?: InputMaybe<Scalars['Int']>;
};

export type ManufactureWorkflowTemplateWhereUniqueInput = {
  reference_document_type: ManufactureEntityType;
  step_number: Scalars['Int'];
};

export enum ModelType {
  ManufactureOrder = 'manufacture_order'
}

export type MostManufactureItemByStatus = {
  __typename?: 'MostManufactureItemByStatus';
  item_name?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Float']>;
};

export type MostManufactureItemByStatusInput = {
  ended_date: Scalars['DateTime'];
  started_date: Scalars['DateTime'];
  status: Scalars['String'];
  top: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ManufactureActivityLogCreate?: Maybe<ManufactureActivityLog>;
  bomCreate?: Maybe<Bom>;
  bomDelete?: Maybe<Bom>;
  bomUpdate?: Maybe<Bom>;
  bomUpdateManyFromItem?: Maybe<Bom>;
  manufactureApprovalTemplateCreate?: Maybe<ApprovalTemplate>;
  manufactureApprovalTemplateDelete?: Maybe<ApprovalTemplate>;
  manufactureApprovalTemplateUpdate?: Maybe<ApprovalTemplate>;
  manufactureDocumentApprove?: Maybe<ManufactureWorkflowProgress>;
  manufactureDocumentCancel: DocumentCancelOutput;
  manufactureDocumentNextStatus?: Maybe<ManufactureWorkflowProgress>;
  manufactureDocumentSetStatus?: Maybe<DocumentSetStatusOutput>;
  manufactureDocumentSetStep?: Maybe<DocumentStepOutput>;
  manufactureOrderCreate?: Maybe<ManufactureOrder>;
  manufactureOrderDelete?: Maybe<ManufactureOrder>;
  manufactureOrderUpdate?: Maybe<ManufactureOrder>;
  manufactureTagCreate?: Maybe<ManufactureTag>;
  manufactureTagDelete?: Maybe<ManufactureTag>;
  manufactureTagDeleteVerify?: Maybe<Array<Scalars['String']>>;
  manufactureTagUpdate?: Maybe<ManufactureTag>;
  manufactureWorkflowTemplateCreate?: Maybe<ManufactureWorkflowTemplate>;
  manufactureWorkflowTemplateUpdate?: Maybe<ManufactureWorkflowTemplate>;
  routingCreate?: Maybe<Routing>;
  routingDelete?: Maybe<Routing>;
  routingUpdate?: Maybe<Routing>;
  routingUpdateManyFromItem?: Maybe<Routing>;
  workOrderCreate?: Maybe<WorkOrder>;
  workOrderDelete?: Maybe<WorkOrder>;
  workOrderUpdate?: Maybe<WorkOrder>;
};


export type MutationManufactureActivityLogCreateArgs = {
  createInput: ManufactureActivityLogCreateInput;
};


export type MutationBomCreateArgs = {
  createInput: BomCreateInput;
};


export type MutationBomDeleteArgs = {
  uniqueInput: BomUniqueInput;
};


export type MutationBomUpdateArgs = {
  uniqueInput: BomUniqueInput;
  updateinput: BomUpdateInput;
};


export type MutationBomUpdateManyFromItemArgs = {
  updateInput: BomUpdateManyFromItemInput;
};


export type MutationManufactureApprovalTemplateCreateArgs = {
  createInput: ManufactureApprovalTemplateCreateInput;
};


export type MutationManufactureApprovalTemplateDeleteArgs = {
  uniqueInput: ManufactureApprovalTemplateUniqueInput;
};


export type MutationManufactureApprovalTemplateUpdateArgs = {
  uniqueInput: ManufactureApprovalTemplateUniqueInput;
  updateInput: ManufactureApprovalTemplateUpdateInput;
};


export type MutationManufactureDocumentApproveArgs = {
  approveInput?: InputMaybe<ApproveInput>;
};


export type MutationManufactureDocumentCancelArgs = {
  documentInput: DocumentInput;
};


export type MutationManufactureDocumentNextStatusArgs = {
  documentInput: DocumentInput;
};


export type MutationManufactureDocumentSetStatusArgs = {
  statusInput: DocumentSetStatusInput;
};


export type MutationManufactureDocumentSetStepArgs = {
  documentSetStepInput: DocumentSetStepInput;
};


export type MutationManufactureOrderCreateArgs = {
  createInput: ManufactureOrderCreateInput;
};


export type MutationManufactureOrderDeleteArgs = {
  uniqueInput: ManufactureOrderUniqueInput;
};


export type MutationManufactureOrderUpdateArgs = {
  uniqueInput: ManufactureOrderUniqueInput;
  updateInput: ManufactureOrderUpdateInput;
};


export type MutationManufactureTagCreateArgs = {
  createInput?: InputMaybe<ManufactureTagCreateinput>;
};


export type MutationManufactureTagDeleteArgs = {
  uniqueInput?: InputMaybe<ManufactureTagWhereUniqueinput>;
};


export type MutationManufactureTagDeleteVerifyArgs = {
  uniqueInput?: InputMaybe<ManufactureTagWhereUniqueinput>;
};


export type MutationManufactureTagUpdateArgs = {
  uniqueInput?: InputMaybe<ManufactureTagWhereUniqueinput>;
  updateInput?: InputMaybe<ManufactureTagUpdateinput>;
};


export type MutationManufactureWorkflowTemplateCreateArgs = {
  createInput: ManufactureWorkflowTemplateCreateInput;
};


export type MutationManufactureWorkflowTemplateUpdateArgs = {
  uniqueInput: ManufactureWorkflowTemplateWhereUniqueInput;
  updateInput: ManufactureWorkflowTemplateUpdateInput;
};


export type MutationRoutingCreateArgs = {
  createInput: RoutingCreateInput;
};


export type MutationRoutingDeleteArgs = {
  uniqueInput: RoutingUniqueInput;
};


export type MutationRoutingUpdateArgs = {
  uniqueInput: RoutingUniqueInput;
  updateInput: RoutingUpdateInput;
};


export type MutationRoutingUpdateManyFromItemArgs = {
  updateInput: RoutingUpdateManyFromItemInput;
};


export type MutationWorkOrderCreateArgs = {
  createInput: WorkOrderCreateInput;
};


export type MutationWorkOrderDeleteArgs = {
  uniqueInput: WorkOrderUniqueInput;
};


export type MutationWorkOrderUpdateArgs = {
  uniqueInput: WorkOrderUniqueInput;
  updateInput: WorkOrderUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type NestedBooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBooleanFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  GenerateExport: Scalars['String'];
  ManufactureActivityLogFindManyAggrid: ManufactureActivityLogFindManyAggrid;
  ManufactureActivityLogFindManyByReferenceId: Array<ManufactureActivityLog>;
  ManufactureActivityLogFindUnique?: Maybe<ManufactureActivityLog>;
  ManufactureBomExportViewsAggrid: ManufactureBomExportViewsAggridOutput;
  ManufactureIngredientExportViewsAggrid: ManufactureIngredientExportViewsAggridOutput;
  ManufactureOrderByStatus?: Maybe<ManufactureOrderByStatus>;
  ManufactureOrderExportViewsAggrid: ManufactureOrderExportViewsAggridOutput;
  ManufactureProcessExportViewsAggrid: ManufactureProcessExportViewsAggridOutput;
  MostManufactureItemByStatus?: Maybe<Array<MostManufactureItemByStatus>>;
  WorkOrderByStatus?: Maybe<WorkOrderByStatus>;
  bom?: Maybe<Bom>;
  bomExistingNames?: Maybe<Array<Scalars['String']>>;
  boms?: Maybe<Array<Maybe<Bom>>>;
  bomsFindManyAggrid: BomAggridOutput;
  manufactureApprovalTemplate?: Maybe<ApprovalTemplate>;
  manufactureApprovalTemplates?: Maybe<Array<Maybe<ApprovalTemplate>>>;
  manufactureOrder?: Maybe<ManufactureOrder>;
  manufactureOrders?: Maybe<Array<Maybe<ManufactureOrder>>>;
  manufactureOrdersFindManyAggrid: ManufactureOrderAggridOutput;
  manufactureTagsFindByEntity: Array<ManufactureTag>;
  manufactureTagsFindManyAggrid?: Maybe<ManufactureTagAggridOutput>;
  manufactureWorkflowProgressByDocument?: Maybe<Array<Maybe<ManufactureWorkflowProgress>>>;
  manufactureWorkflowTemplate?: Maybe<ManufactureWorkflowTemplate>;
  manufactureWorkflowTemplates?: Maybe<Array<Maybe<ManufactureWorkflowTemplate>>>;
  routing?: Maybe<Routing>;
  routings?: Maybe<Array<Maybe<Routing>>>;
  routingsFindManyAggrid: RoutingAggridOutput;
  utilGetUniqueId: Scalars['String'];
  utilUniqueIdIsExist: Scalars['Boolean'];
  validateAccesssTokenWithHeader: User;
  validateExistingBomsAndRouting?: Maybe<ValidateExistingBomsAndRoutingOutput>;
  validateItemDisable?: Maybe<ItemValidateInManufactureOutput>;
  wasteProduction?: Maybe<WasteProduction>;
  wasteProductions?: Maybe<Array<Maybe<WasteProduction>>>;
  workOrder?: Maybe<WorkOrder>;
  workOrders?: Maybe<Array<Maybe<WorkOrder>>>;
  workOrdersFindManyAggrid: WorkOrderAggridOutput;
};


export type QueryGenerateExportArgs = {
  input?: InputMaybe<ExportGenerateInput>;
};


export type QueryManufactureActivityLogFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryManufactureActivityLogFindManyByReferenceIdArgs = {
  findManyInput: ManufactureActivityLogByRefereceIdInput;
};


export type QueryManufactureActivityLogFindUniqueArgs = {
  uniqueInput?: InputMaybe<ActivityLogUniqueInput>;
};


export type QueryManufactureBomExportViewsAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
};


export type QueryManufactureIngredientExportViewsAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
};


export type QueryManufactureOrderByStatusArgs = {
  manufactureDateInput: ManufactureDateInput;
};


export type QueryManufactureOrderExportViewsAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
};


export type QueryManufactureProcessExportViewsAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
};


export type QueryMostManufactureItemByStatusArgs = {
  input: MostManufactureItemByStatusInput;
};


export type QueryWorkOrderByStatusArgs = {
  manufactureDateInput: ManufactureDateInput;
};


export type QueryBomArgs = {
  uniqueInput: BomUniqueInput;
};


export type QueryBomExistingNamesArgs = {
  bomNameList?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBomsFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryManufactureApprovalTemplateArgs = {
  uniqueInput: ManufactureApprovalTemplateUniqueInput;
};


export type QueryManufactureOrderArgs = {
  uniqueInput: ManufactureOrderUniqueInput;
};


export type QueryManufactureOrdersFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryManufactureTagsFindByEntityArgs = {
  entityName?: InputMaybe<ManufactureEntityType>;
};


export type QueryManufactureTagsFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryManufactureWorkflowProgressByDocumentArgs = {
  referenceDocumentInput: ReferenceDocumentInput;
};


export type QueryManufactureWorkflowTemplateArgs = {
  uniqueInput: ManufactureWorkflowTemplateObjWhereUniqueInput;
};


export type QueryManufactureWorkflowTemplatesArgs = {
  findManyInput?: InputMaybe<ManufactureWorkflowTemplateFindManyInput>;
};


export type QueryRoutingArgs = {
  uniqueInput: RoutingUniqueInput;
};


export type QueryRoutingsFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};


export type QueryUtilGetUniqueIdArgs = {
  modelType: ModelType;
};


export type QueryUtilUniqueIdIsExistArgs = {
  modelType: ModelType;
  uniqueId: Scalars['String'];
};


export type QueryValidateExistingBomsAndRoutingArgs = {
  bomIdList: Array<Scalars['Int']>;
  routingIdList: Array<Scalars['Int']>;
};


export type QueryValidateItemDisableArgs = {
  isForDisable?: InputMaybe<Scalars['Boolean']>;
  skuList: Array<Scalars['String']>;
};


export type QueryWasteProductionArgs = {
  uniqueInput: WasteProductionUniqueInput;
};


export type QueryWorkOrderArgs = {
  uniqueInput: WorkOrderUniqueInput;
};


export type QueryWorkOrdersFindManyAggridArgs = {
  aggridInput?: InputMaybe<AggridTemplateInput>;
};

export type ReferenceDocumentInput = {
  reference_document_type: ManufactureEntityType;
  reference_unique_id: Scalars['String'];
};

export type ReviewerCreateObject = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  img_url?: InputMaybe<Array<Scalars['String']>>;
  last_name?: InputMaybe<Scalars['String']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type ReviewerObject = {
  __typename?: 'ReviewerObject';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  last_name?: Maybe<Scalars['String']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type Routing = {
  __typename?: 'Routing';
  attachment_list?: Maybe<Array<Maybe<AttachmentObject>>>;
  created_by?: Maybe<UserObject>;
  created_date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  internal_remark?: Maybe<Scalars['String']>;
  is_active: Scalars['Int'];
  is_main_routing: Scalars['Boolean'];
  item_name: Scalars['String'];
  item_unique_id: Scalars['String'];
  mfg_type?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start_date?: Maybe<Scalars['DateTime']>;
  stock_uom?: Maybe<Scalars['String']>;
  work_order_id_list?: Maybe<Array<Scalars['Int']>>;
  work_order_list?: Maybe<Array<Scalars['JSON']>>;
};

export type RoutingAggridOutput = {
  __typename?: 'RoutingAggridOutput';
  count: Scalars['Int'];
  data: Array<Routing>;
};

export type RoutingCreateInput = {
  attachment_list?: InputMaybe<Array<InputMaybe<AttachmentInputObject>>>;
  created_by?: InputMaybe<UserInputObject>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  internal_remark?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Int']>;
  is_main_routing: Scalars['Boolean'];
  item_name: Scalars['String'];
  item_unique_id: Scalars['String'];
  mfg_type?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  start_date?: InputMaybe<Scalars['DateTime']>;
  stock_uom?: InputMaybe<Scalars['String']>;
  work_order_id_list?: InputMaybe<Array<Scalars['Int']>>;
  work_order_list?: InputMaybe<Array<Scalars['JSON']>>;
};

export type RoutingUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RoutingUpdateInput = {
  attachment_list?: InputMaybe<Array<InputMaybe<AttachmentInputObject>>>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  internal_remark?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Int']>;
  is_main_routing?: InputMaybe<Scalars['Boolean']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  mfg_type?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  stock_uom?: InputMaybe<Scalars['String']>;
  work_order_id_list?: InputMaybe<Array<Scalars['Int']>>;
  work_order_list?: InputMaybe<Array<Scalars['JSON']>>;
};

export type RoutingUpdateManyFromItemInput = {
  item_unique_id: Scalars['String'];
  main_routing_id: Scalars['String'];
};

export type RoutingValidateDetail = {
  __typename?: 'RoutingValidateDetail';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Int']>;
  is_main_routing?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Accepted = 'accepted',
  Active = 'active',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Delivering = 'delivering',
  Draft = 'draft',
  Finished = 'finished',
  FullyImported = 'fully_imported',
  FullyOrdered = 'fully_ordered',
  FullyPaid = 'fully_paid',
  InProgress = 'in_progress',
  Inactive = 'inactive',
  InsufficientIngredient = 'insufficient_ingredient',
  IsMainBom = 'is_main_bom',
  Manufacturing = 'manufacturing',
  NotApproved = 'not_approved',
  NotCompleted = 'not_completed',
  PartiallyImported = 'partially_imported',
  PartiallyOrdered = 'partially_ordered',
  PartiallyPaid = 'partially_paid',
  PendingManu = 'pending_manu',
  Ready = 'ready',
  WaitAccept = 'wait_accept',
  WaitApprove = 'wait_approve',
  WaitDeliver = 'wait_deliver',
  WaitPayment = 'wait_payment',
  WaitPreviousJobs = 'wait_previous_jobs',
  WaitTransfer = 'wait_transfer'
}

export type StatusData = {
  __typename?: 'StatusData';
  amount?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<StringQueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export enum StringQueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type UploaderInputObject = {
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type UploaderObject = {
  __typename?: 'UploaderObject';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['JSON']>;
  company_list?: Maybe<Array<Maybe<Company>>>;
  company_unique_id_list?: Maybe<Array<Scalars['String']>>;
  created_date: Scalars['DateTime'];
  creator_unique_id?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  img_url?: Maybe<Array<Scalars['String']>>;
  inactive_remarks?: Maybe<Scalars['String']>;
  is_active: Scalars['Int'];
  last_login_date?: Maybe<Scalars['DateTime']>;
  last_login_tenant?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['DateTime']>;
  last_updator_unique_id?: Maybe<Scalars['String']>;
  national_id?: Maybe<Scalars['String']>;
  nick_name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  title_name?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type UserInputObject = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  img_url?: InputMaybe<Array<Scalars['String']>>;
  last_name?: InputMaybe<Scalars['String']>;
  user_unique_id?: InputMaybe<Scalars['String']>;
};

export type UserObject = {
  __typename?: 'UserObject';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Array<Scalars['String']>>;
  last_name?: Maybe<Scalars['String']>;
  user_unique_id?: Maybe<Scalars['String']>;
};

export type ValidateExistingBomsAndRoutingOutput = {
  __typename?: 'ValidateExistingBomsAndRoutingOutput';
  existing_bom_detail_list?: Maybe<Array<BomValidateDetail>>;
  existing_bom_id_list?: Maybe<Array<Scalars['Int']>>;
  existing_routing_detail_list?: Maybe<Array<RoutingValidateDetail>>;
  existing_routing_id_list?: Maybe<Array<Scalars['Int']>>;
};

export type WasteProduction = {
  __typename?: 'WasteProduction';
  cuid: Scalars['String'];
  item_description?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_unique_id?: Maybe<Scalars['String']>;
  manufacture_order?: Maybe<ManufactureOrder>;
  manufacture_order_unique_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Decimal']>;
  reference_unique_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  uom_unique_id?: Maybe<Scalars['String']>;
};

export type WasteProductionCreateByManufactureOrderInput = {
  item_description?: InputMaybe<Scalars['String']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type WasteProductionUniqueInput = {
  cuid: Scalars['String'];
};

export type WasteProductionUpdateByManufactureOrderInput = {
  cuid?: InputMaybe<Scalars['String']>;
  item_description?: InputMaybe<Scalars['String']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_unique_id?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  uom?: InputMaybe<Scalars['String']>;
  uom_unique_id?: InputMaybe<Scalars['String']>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  actual_duration?: Maybe<Scalars['Decimal']>;
  aggrid_status?: Maybe<Scalars['String']>;
  cost_price?: Maybe<Scalars['Float']>;
  created_by?: Maybe<UserObject>;
  duration?: Maybe<Scalars['Decimal']>;
  duration_unit?: Maybe<Scalars['String']>;
  finished_date?: Maybe<Scalars['DateTime']>;
  flag_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
  indexing?: Maybe<Scalars['Int']>;
  main_status?: Maybe<Scalars['String']>;
  manufacture_order?: Maybe<ManufactureOrder>;
  manufacture_order_unique_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  plant?: Maybe<Scalars['String']>;
  production_center?: Maybe<Scalars['String']>;
  production_qty?: Maybe<Scalars['Decimal']>;
  reference_unique_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  responsible_user_list?: Maybe<Array<Scalars['JSON']>>;
  start_date_time?: Maybe<Scalars['DateTime']>;
  started_date?: Maybe<Scalars['DateTime']>;
  sub_status?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<ManufactureTag>>>;
  updated_by?: Maybe<Scalars['JSON']>;
  updated_date?: Maybe<Scalars['DateTime']>;
  updated_status_detail?: Maybe<Array<Scalars['JSON']>>;
};

export type WorkOrderAggridOutput = {
  __typename?: 'WorkOrderAggridOutput';
  count: Scalars['Int'];
  data: Array<WorkOrder>;
};

export type WorkOrderByStatus = {
  __typename?: 'WorkOrderByStatus';
  cancelled?: Maybe<Scalars['Int']>;
  draft?: Maybe<Scalars['Int']>;
  finished?: Maybe<Scalars['Int']>;
  in_progress?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type WorkOrderCreateByManufactureOrderInput = {
  actual_duration?: InputMaybe<Scalars['Decimal']>;
  aggrid_status?: InputMaybe<Scalars['String']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Decimal']>;
  duration_unit?: InputMaybe<Scalars['String']>;
  finished_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<Array<Scalars['String']>>;
  indexing?: InputMaybe<Scalars['Int']>;
  main_status?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  plant?: InputMaybe<Scalars['String']>;
  production_center?: InputMaybe<Scalars['String']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<Array<Scalars['JSON']>>;
  start_date_time?: InputMaybe<Scalars['DateTime']>;
  started_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  updated_status_detail?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WorkOrderCreateInput = {
  actual_duration?: InputMaybe<Scalars['Decimal']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Decimal']>;
  duration_unit?: InputMaybe<Scalars['String']>;
  finished_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  indexing?: InputMaybe<Scalars['Int']>;
  main_status: Scalars['String'];
  manufacture_order_unique_id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  plant?: InputMaybe<Scalars['String']>;
  production_center?: InputMaybe<Scalars['String']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<Array<Scalars['JSON']>>;
  start_date_time?: InputMaybe<Scalars['DateTime']>;
  started_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  updated_status_detail?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WorkOrderObject = {
  __typename?: 'WorkOrderObject';
  duration: Scalars['Decimal'];
  duration_unit?: Maybe<Scalars['String']>;
  factory: Scalars['String'];
  production_center: Scalars['String'];
  work_order_name: Scalars['String'];
  work_order_remark?: Maybe<Scalars['String']>;
};

export type WorkOrderObjectInput = {
  duration: Scalars['Decimal'];
  duration_unit?: InputMaybe<Scalars['String']>;
  factory: Scalars['String'];
  production_center: Scalars['String'];
  work_order_name: Scalars['String'];
  work_order_remark?: InputMaybe<Scalars['String']>;
};

export type WorkOrderUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type WorkOrderUpdateByManufactureOrderInput = {
  actual_duration?: InputMaybe<Scalars['Decimal']>;
  aggrid_status?: InputMaybe<Scalars['String']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Decimal']>;
  duration_unit?: InputMaybe<Scalars['String']>;
  finished_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  indexing?: InputMaybe<Scalars['Int']>;
  main_status?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  plant?: InputMaybe<Scalars['String']>;
  production_center?: InputMaybe<Scalars['String']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<Array<Scalars['JSON']>>;
  start_date_time?: InputMaybe<Scalars['DateTime']>;
  started_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  updated_status_detail?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WorkOrderUpdateInput = {
  actual_duration?: InputMaybe<Scalars['Decimal']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Decimal']>;
  duration_unit?: InputMaybe<Scalars['String']>;
  finished_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  indexing?: InputMaybe<Scalars['Int']>;
  main_status?: InputMaybe<Scalars['String']>;
  manufacture_order_unique_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  plant?: InputMaybe<Scalars['String']>;
  production_center?: InputMaybe<Scalars['String']>;
  production_qty?: InputMaybe<Scalars['Decimal']>;
  reference_unique_id?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<Array<Scalars['JSON']>>;
  start_date_time?: InputMaybe<Scalars['DateTime']>;
  started_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  updated_status_detail?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WorkflowDocumentStepInput = {
  reference_document_type: ManufactureEntityType;
  reference_unique_id: Scalars['String'];
  workflow_step_number: Scalars['Int'];
};

export enum ManufactureEntityType {
  ManufactureOrder = 'manufacture_order',
  WorkOrder = 'work_order'
}

export type GenerateExportQueryVariables = Exact<{
  input?: InputMaybe<ExportGenerateInput>;
}>;


export type GenerateExportQuery = { __typename?: 'Query', GenerateExport: string };

export type ManufactureBomExportViewsAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
}>;


export type ManufactureBomExportViewsAggridQuery = { __typename?: 'Query', ManufactureBomExportViewsAggrid: { __typename?: 'ManufactureBomExportViewsAggridOutput', count?: number | null, results: Array<{ __typename?: 'ManufactureBomExportView', name?: string | null, mfg_type?: string | null, is_active?: number | null, created_date?: any | null, start_date?: any | null, end_date?: any | null, product_id?: string | null, product_name?: string | null, rank?: number | null, item_id?: string | null, item_name?: string | null, qty?: number | null, uom_item?: string | null, cost_per_unit?: number | null, mfg_qty?: number | null, uom_product?: string | null, internal_remark?: string | null, description?: string | null, is_main?: number | null, created_by?: string | null } | null> } };

export type ManufactureIngredientExportViewsAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
}>;


export type ManufactureIngredientExportViewsAggridQuery = { __typename?: 'Query', ManufactureIngredientExportViewsAggrid: { __typename?: 'ManufactureIngredientExportViewsAggridOutput', count?: number | null, results: Array<{ __typename?: 'ManufactureIngredientExportView', unique_id?: string | null, issue_date?: any | null, aggrid_status?: string | null, reference_unique_ids?: string | null, external_reference_id?: string | null, production_date?: any | null, production_completion_date?: any | null, delivery_date?: any | null, customer_contact_unique_id?: string | null, customer_name?: string | null, mo_item_unique_id?: string | null, mo_item_name?: string | null, production_qty?: number | null, actual_production_qty?: number | null, goods_receive_qty?: number | null, mo_uom?: string | null, mo_item_description?: string | null, remark?: string | null, bom_name?: string | null, routing_name?: string | null, mfg_type?: string | null, group?: string | null, other_manufacture_cost?: number | null, work_order_cost?: number | null, bom_cost?: number | null, total_cost?: number | null, item_remark?: string | null, created_date?: any | null, created_by?: string | null, related_users?: string | null, attachment_urls?: string | null, rank?: number | null, item_unique_id?: string | null, item_name?: string | null, item_description?: string | null, qty?: number | null, cost_per_unit?: number | null, good_issue_qty?: number | null, moi_uom?: string | null } | null> } };

export type ManufactureOrderExportViewsAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
}>;


export type ManufactureOrderExportViewsAggridQuery = { __typename?: 'Query', ManufactureOrderExportViewsAggrid: { __typename?: 'ManufactureOrderExportViewsAggridOutput', count?: number | null, results: Array<{ __typename?: 'ManufactureOrderExportView', unique_id?: string | null, issue_date?: any | null, aggrid_status?: string | null, reference_unique_ids?: string | null, external_reference_id?: string | null, production_date?: any | null, production_completion_date?: any | null, delivery_date?: any | null, customer_contact_unique_id?: string | null, customer_name?: string | null, item_unique_id?: string | null, item_name?: string | null, production_qty?: number | null, actual_production_qty?: number | null, goods_receive_qty?: number | null, uom?: string | null, item_description?: string | null, item_remark?: string | null, prod_type?: string | null, bom_name?: string | null, mt_name?: string | null, routing_name?: string | null, other_manufacture_cost?: number | null, work_order_cost?: number | null, bom_cost?: number | null, total_cost?: number | null, internal_remark?: string | null, created_date?: any | null, created_by?: string | null, related_users?: string | null, attachment_urls?: string | null } | null> } };

export type ManufactureProcessExportViewsAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']>;
}>;


export type ManufactureProcessExportViewsAggridQuery = { __typename?: 'Query', ManufactureProcessExportViewsAggrid: { __typename?: 'ManufactureProcessExportViewsAggridOutput', count?: number | null, results: Array<{ __typename?: 'ManufactureProcessExportView', unique_id?: string | null, issue_date?: any | null, status?: string | null, reference_unique_ids?: string | null, external_reference_id?: string | null, production_date?: any | null, production_completion_date?: any | null, delivery_date?: any | null, customer_contact_unique_id?: string | null, customer_name?: string | null, item_unique_id?: string | null, item_name?: string | null, production_qty?: number | null, actual_production_qty?: number | null, goods_receive_qty?: number | null, uom?: string | null, item_description?: string | null, item_remark?: string | null, bom_name?: string | null, routing_name?: string | null, mfg_type?: string | null, group?: string | null, other_manufacture_cost?: number | null, work_order_cost?: number | null, bom_cost?: number | null, total_cost?: number | null, goods_remark?: string | null, created_date?: any | null, created_by?: string | null, related_users?: string | null, attachment_urls?: string | null, order?: number | null, step_status?: string | null, responsible_users?: string | null, name?: string | null, factory?: string | null, production_center?: string | null, duration?: number | null, duration_unit?: string | null, cost_price?: number | null, started_date?: any | null, finished_date?: any | null, remark?: string | null } | null> } };


export const GenerateExportDocument = `
    query GenerateExport($input: ExportGenerateInput) {
  GenerateExport(input: $input)
}
    `;
export const useGenerateExportQuery = <
      TData = GenerateExportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GenerateExportQueryVariables,
      options?: UseQueryOptions<GenerateExportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GenerateExportQuery, TError, TData>(
      variables === undefined ? ['GenerateExport'] : ['GenerateExport', variables],
      fetcher<GenerateExportQuery, GenerateExportQueryVariables>(client, GenerateExportDocument, variables, headers),
      options
    );
export const ManufactureBomExportViewsAggridDocument = `
    query ManufactureBomExportViewsAggrid($aggridInput: AnyAggridInput) {
  ManufactureBomExportViewsAggrid(aggridInput: $aggridInput) {
    count
    results {
      name
      mfg_type
      is_active
      created_date
      start_date
      end_date
      product_id
      product_name
      rank
      item_id
      item_name
      qty
      uom_item
      cost_per_unit
      mfg_qty
      uom_product
      internal_remark
      description
      is_main
      created_by
    }
  }
}
    `;
export const useManufactureBomExportViewsAggridQuery = <
      TData = ManufactureBomExportViewsAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ManufactureBomExportViewsAggridQueryVariables,
      options?: UseQueryOptions<ManufactureBomExportViewsAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ManufactureBomExportViewsAggridQuery, TError, TData>(
      variables === undefined ? ['ManufactureBomExportViewsAggrid'] : ['ManufactureBomExportViewsAggrid', variables],
      fetcher<ManufactureBomExportViewsAggridQuery, ManufactureBomExportViewsAggridQueryVariables>(client, ManufactureBomExportViewsAggridDocument, variables, headers),
      options
    );
export const ManufactureIngredientExportViewsAggridDocument = `
    query ManufactureIngredientExportViewsAggrid($aggridInput: AnyAggridInput) {
  ManufactureIngredientExportViewsAggrid(aggridInput: $aggridInput) {
    count
    results {
      unique_id
      issue_date
      aggrid_status
      reference_unique_ids
      external_reference_id
      production_date
      production_completion_date
      delivery_date
      customer_contact_unique_id
      customer_name
      mo_item_unique_id
      mo_item_name
      production_qty
      actual_production_qty
      goods_receive_qty
      mo_uom
      mo_item_description
      remark
      bom_name
      routing_name
      mfg_type
      group
      other_manufacture_cost
      work_order_cost
      bom_cost
      total_cost
      item_remark
      created_date
      created_by
      related_users
      attachment_urls
      rank
      item_unique_id
      item_name
      item_description
      qty
      cost_per_unit
      good_issue_qty
      moi_uom
    }
  }
}
    `;
export const useManufactureIngredientExportViewsAggridQuery = <
      TData = ManufactureIngredientExportViewsAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ManufactureIngredientExportViewsAggridQueryVariables,
      options?: UseQueryOptions<ManufactureIngredientExportViewsAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ManufactureIngredientExportViewsAggridQuery, TError, TData>(
      variables === undefined ? ['ManufactureIngredientExportViewsAggrid'] : ['ManufactureIngredientExportViewsAggrid', variables],
      fetcher<ManufactureIngredientExportViewsAggridQuery, ManufactureIngredientExportViewsAggridQueryVariables>(client, ManufactureIngredientExportViewsAggridDocument, variables, headers),
      options
    );
export const ManufactureOrderExportViewsAggridDocument = `
    query ManufactureOrderExportViewsAggrid($aggridInput: AnyAggridInput) {
  ManufactureOrderExportViewsAggrid(aggridInput: $aggridInput) {
    count
    results {
      unique_id
      issue_date
      aggrid_status
      reference_unique_ids
      external_reference_id
      production_date
      production_completion_date
      delivery_date
      customer_contact_unique_id
      customer_name
      item_unique_id
      item_name
      production_qty
      actual_production_qty
      goods_receive_qty
      uom
      item_description
      item_remark
      prod_type
      bom_name
      mt_name
      routing_name
      other_manufacture_cost
      work_order_cost
      bom_cost
      total_cost
      internal_remark
      created_date
      created_by
      related_users
      attachment_urls
    }
  }
}
    `;
export const useManufactureOrderExportViewsAggridQuery = <
      TData = ManufactureOrderExportViewsAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ManufactureOrderExportViewsAggridQueryVariables,
      options?: UseQueryOptions<ManufactureOrderExportViewsAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ManufactureOrderExportViewsAggridQuery, TError, TData>(
      variables === undefined ? ['ManufactureOrderExportViewsAggrid'] : ['ManufactureOrderExportViewsAggrid', variables],
      fetcher<ManufactureOrderExportViewsAggridQuery, ManufactureOrderExportViewsAggridQueryVariables>(client, ManufactureOrderExportViewsAggridDocument, variables, headers),
      options
    );
export const ManufactureProcessExportViewsAggridDocument = `
    query ManufactureProcessExportViewsAggrid($aggridInput: AnyAggridInput) {
  ManufactureProcessExportViewsAggrid(aggridInput: $aggridInput) {
    count
    results {
      unique_id
      issue_date
      status
      reference_unique_ids
      external_reference_id
      production_date
      production_completion_date
      delivery_date
      customer_contact_unique_id
      customer_name
      item_unique_id
      item_name
      production_qty
      actual_production_qty
      goods_receive_qty
      uom
      item_description
      item_remark
      bom_name
      routing_name
      mfg_type
      group
      other_manufacture_cost
      work_order_cost
      bom_cost
      total_cost
      goods_remark
      created_date
      created_by
      related_users
      attachment_urls
      order
      step_status
      responsible_users
      name
      factory
      production_center
      duration
      duration_unit
      cost_price
      started_date
      finished_date
      remark
    }
  }
}
    `;
export const useManufactureProcessExportViewsAggridQuery = <
      TData = ManufactureProcessExportViewsAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ManufactureProcessExportViewsAggridQueryVariables,
      options?: UseQueryOptions<ManufactureProcessExportViewsAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ManufactureProcessExportViewsAggridQuery, TError, TData>(
      variables === undefined ? ['ManufactureProcessExportViewsAggrid'] : ['ManufactureProcessExportViewsAggrid', variables],
      fetcher<ManufactureProcessExportViewsAggridQuery, ManufactureProcessExportViewsAggridQueryVariables>(client, ManufactureProcessExportViewsAggridDocument, variables, headers),
      options
    );