import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  IBreadcrumbsAndMenu,
  ITab,
  IMenuOption,
  IExporter,
} from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DeliveryOrderTable from "../../../components/Table/Logistic/DeliveryOrder";
import { GENERATE_EXPORT } from "../../../services/AgGrid/LogisticAgGrid";
import LogisticExporterModal from "../../../components/UI/LogisticExporterModal";
import { useModal } from "../../../hooks/use-modal";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

const DeliveryOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const options: IMenuOption[] = [
    {
      value: "นำออก",
    },
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/sales",
    },
    {
      name: t("logistic.delivery_order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_deliver"),
      path: `${pathname}?filter=wait_deliver`,
    },
    {
      label: t("status.completed"),
      path: `${pathname}?filter=completed`,
    },
    {
      label: t("status.not_completed"),
      path: `${pathname}?filter=not_completed`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const exportHandler = async (data: IExporter) => {
    const { start_date, end_date } = data;
    const input = {
      start_date: start_date ?? undefined,
      end_date: end_date ?? undefined,
    };

    try {
      const { DeliveryOrderGenerateExport } = await graphqlClient.request(
        GENERATE_EXPORT,
        { input }
      );
      window.open(DeliveryOrderGenerateExport, "_blank");
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
    }
  };

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("logistic.delivery_order.index")}
        </Typography>
        <Box>
          <CustomizedButton
            title={`${t("button.create")}${t("logistic.delivery_order.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          <CustomizedMenuOptions
            isIcon
            label="delivery-order-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "นำออก":
                  openModalHandler();
                  break;
                default:
              }
            }}
          />
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <DeliveryOrderTable isFilter={isFilter} />
      <LogisticExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default DeliveryOrder;
