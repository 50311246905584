import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { IGoodsTransfer } from "../../../../types/Inventory/goodsTransfer";
import { IDefaultForm } from "../../../../types/global";
import { useFieldArray } from "react-hook-form";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { InventoryDocumentStatus } from "../../../../generated/inventory";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { formatNumber } from "../../../../utils/dataTransformer";

type Props = {
  control: IDefaultForm<IGoodsTransfer>["control"];
  nestedIndex: number;
  status?: string | null;
};

const SerialListScan = ({ control, nestedIndex, status }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
  });

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: InventoryDocumentStatus.IsActive,
      destination_bin_location: undefined,
      destination_bin_location_id: undefined,
      destination_scanned_by: undefined,
      destination_scanned_date: undefined,
    });
  };

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.posted_date)}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">{formatNumber(serial.qty)}</TableCell>
          <TableCell align="center">{serial.uom?.name}</TableCell>
          <TableCell align="center">{serial.serial_no}</TableCell>
          <TableCell align="center">
            {serial.source_bin_location?.bin_name}
          </TableCell>
          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="center">
            {serial.destination_bin_location?.bin_name}
          </TableCell>
          <TableCell align="center">
            <CustomizedStatus status={serial.status} />
          </TableCell>
          {status !== "finished" && status !== "cancelled" && (
            <TableCell align="center">
              {serial.status === InventoryDocumentStatus.IsScan && (
                <CustomizedTooltip title="สแกนใหม่">
                  <IconButton
                    color="primary"
                    onClick={() => rescanBarcodeHandler(index)}
                  >
                    <ReplayOutlinedIcon fontSize="small" />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </TableCell>
          )}
          <TableCell align="center">
            {serial.destination_scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id:
                        serial.destination_scanned_by.user_unique_id || "",
                      first_name:
                        serial.destination_scanned_by.first_name || "",
                      last_name: serial.destination_scanned_by.last_name || "",
                      img_url: serial.destination_scanned_by.img_url
                        ? serial.destination_scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.destination_scanned_date)}
          </TableCell>
          <TableCell>{serial.barcode}</TableCell>
          <TableCell>{formatDate(serial.lot_date)}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default SerialListScan;
