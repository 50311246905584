import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import TraceEntryReportTable from "../../../components/Table/Inventory/Reports/TraceEntryReportTable";
import { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GENERATE_REPORT,
  GET_EXPORTED_REPORT,
} from "../../../services/AgGrid/InventoryReportAgGrid";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateAgGrid } from "../../../utils/Formatter/Date";

const TraceEntryReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.traceEntry.index"),
    },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const onBtnExport = async () => {
    try {
      setIsLoading(true);
      const filterModel = gridRef?.current?.api.getFilterModel() as any;
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { created_date, posted_date, lot_date, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        lot_date: dateFilterModel(lot_date),
      };

      // const { GenerateReportNoQueue } = await graphQLClient.request(
      //   GET_EXPORTED_REPORT,
      //   {
      //     reportType: "trace_entry",
      //     aggridInput: {
      //       startRow: 0,
      //       endRow: Math.pow(10, 5),
      //       filterModel: formatFilter,
      //       sortModel,
      //     },
      //   }
      // );
      // window.open(GenerateReportNoQueue, "_blank");
      // enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
      //   variant: "success",
      // });

      await graphQLClient.request(GENERATE_REPORT, {
        priority: 1,
        reportType: "trace_entry",
        params: {
          aggridInput: {
            startRow: 0,
            endRow: Math.pow(10, 5),
            filterModel: formatFilter,
            sortModel,
          },
        },
      });

      enqueueSnackbar("คำขอสำเร็จ ระบบกำลังดำเนินการ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
      // enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
      //   variant: "error",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      let startDate = getValues("dateFrom");
      let endDate = getValues("dateTo");

      const created_date = params.api.getFilterInstance("created_date");
      const instaceItemStatus = params.api.getFilterInstance("item_status");
      instaceItemStatus?.setModel({ values: ["1"] });
      created_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = () => {
    reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("inventory.traceEntry.index")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading || !showTable}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
      />
      {showTable && (
        <TraceEntryReportTable
          gridRef={gridRef}
          onFilterChanged={onFilterChanged}
        />
      )}
    </>
  );
};

export default TraceEntryReport;
