import Chart from "react-google-charts";
import { customChartTooltip } from "../../../../../utils/chartUtils";
import {
  TotalSalesPurchaseByYearQuery,
  YearRange,
} from "../../../../../generated/dashboard";
import { formatNumber } from "../../../../../utils/dataTransformer";
import { Box, Theme, useMediaQuery } from "@mui/material";

type Props = {
  data: TotalSalesPurchaseByYearQuery["TotalSalesPurchaseByYear"];
  selectedYear: YearRange;
  isShowSales: boolean | null;
  isShowPurchase: boolean | null;
};

const monthNames = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

const fullMonthNames = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const TotalSalesPurchaseChart = ({
  data,
  selectedYear,
  isShowSales,
  isShowPurchase,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const months = data?.months || [];

  const formatMonths = [
    [
      "Month",
      ...(isShowSales ? [{ label: "Sales", type: "number" }] : []),
      ...(isShowSales
        ? [{ type: "string", role: "tooltip", p: { html: true } }]
        : []), // Tooltip for Sales
      ...(isShowPurchase ? [{ label: "Purchase", type: "number" }] : []),
      ...(isShowPurchase
        ? [{ type: "string", role: "tooltip", p: { html: true } }]
        : []), // Tooltip for Purchase
    ],
    ...months.map((month, index) => {
      if (isShowSales && !isShowPurchase) {
        return [
          monthNames[index],
          month?.total_sales,
          customChartTooltip(
            "ยอดขาย",
            "#84B6F7",
            fullMonthNames[index],
            selectedYear === YearRange.ThisYear
              ? new Date().getFullYear()
              : new Date().getFullYear() - 1,
            month?.total_sales,
            null,
            null
          ),
        ];
      }

      if (isShowPurchase && !isShowSales) {
        return [
          monthNames[index],
          month?.total_purchase,
          customChartTooltip(
            "ยอดซื้อ",
            "#FFABAB",
            fullMonthNames[index] || `เดือน ${index + 1}`,
            selectedYear === YearRange.ThisYear
              ? new Date().getFullYear()
              : new Date().getFullYear() - 1,
            month?.total_purchase,
            null,
            null
          ),
        ];
      }

      return [
        monthNames[index],
        month?.total_sales,
        customChartTooltip(
          "ยอดขาย",
          "#84B6F7",
          fullMonthNames[index],
          selectedYear === YearRange.ThisYear
            ? new Date().getFullYear()
            : new Date().getFullYear() - 1,
          month?.total_sales,
          month?.yoy_percentage
            ? month.yoy_percentage >= 0
              ? `+${formatNumber(month.yoy_percentage)}`
              : `${formatNumber(month.yoy_percentage)}`
            : null,
          month?.yoy_percentage
            ? month.yoy_percentage >= 0
              ? "#22915A"
              : "#E41B1B"
            : null
        ),
        month?.total_purchase,
        customChartTooltip(
          "ยอดซื้อ",
          "#FFABAB",
          fullMonthNames[index] || `เดือน ${index + 1}`,
          selectedYear === YearRange.ThisYear
            ? new Date().getFullYear()
            : new Date().getFullYear() - 1,
          month?.total_purchase,
          month?.yoy_percentage
            ? month.yoy_percentage >= 0
              ? `+${formatNumber(month.yoy_percentage)}`
              : `${formatNumber(month.yoy_percentage)}`
            : null,
          month?.yoy_percentage
            ? month.yoy_percentage >= 0
              ? "#22915A"
              : "#E41B1B"
            : null
        ),
      ];
    }),
  ];

  const formatColor = () => {
    if (isShowSales && !isShowPurchase) {
      return ["#84B6F7"];
    }
    if (isShowPurchase && !isShowSales) {
      return ["#FFABAB"];
    }
    return ["#84B6F7", "#FFABAB"];
  };

  const maxValue = Math.max(
    ...months.map((item) =>
      Math.max(item?.total_sales || 0, item?.total_purchase || 0)
    )
  );

  // const minValue = Math.min(
  //   ...months.map((item) =>
  //     Math.min(item?.total_sales || 0, item?.total_purchase || 0)
  //   )
  // );

  const options = {
    legend: "none",
    chartArea: {
      width: "100%",
      left: 50,
      top: 16,
      bottom: 32,
    },
    hAxis: {
      textStyle: {
        fontName: "Kanit",
        fontSize: 14,
        color: "#333333",
      },
    },
    vAxis: {
      format: "short",
      minorGridlines: { color: "transparent" },
      textStyle: {
        fontName: "Kanit",
        fontSize: 14,
        color: "#333333",
      },
      maxValue: maxValue > 5000 ? undefined : 5000,
    },
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: "Kanit",
        fontSize: 14,
        color: "#737373",
      },
    },
    bar: {
      groupWidth: "40%",
    },
    colors: formatColor(),
  };

  if (isMobile) {
    return (
      <Box width={"100%"} sx={{ overflowX: "scroll", overflowY: "hidden" }}>
        <Chart
          chartType="ColumnChart"
          width={750}
          height={300}
          data={formatMonths}
          options={options}
          loader={<Box height={300} />}
        />
      </Box>
    );
  }

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height={300}
      data={formatMonths}
      options={options}
      loader={<Box height={300} />}
    />
  );
};

export default TotalSalesPurchaseChart;
