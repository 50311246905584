import { WarehousesQuery } from "../../generated/inventory";
import { IPurchaseItemList } from "../../types/Purchase";
import { ISalesItemList } from "../../types/Sales";
import { statusValueFormatter } from "./Global";

export const salesExportFormatter = (
  payload: any[] | undefined,
  documentType: string
) => {
  let formattedPayload: any[] = [];

  if (payload) {
    payload.forEach((document) => {
      const {
        price_vat_type,
        additional_discount,
        additional_discount_type,
        pre_vat_amount,
      } = document;

      const actualAdditionalDiscount =
        additional_discount_type === "percent"
          ? pre_vat_amount * (additional_discount / 100)
          : additional_discount;

      const sum_pre_vat_amount_exclude_discount = document.item_list.reduce(
        (prev: any, item: ISalesItemList) => {
          const pre_vat_amount_exclude_discount = prev + item.pre_vat_amount;
          return pre_vat_amount_exclude_discount;
        },
        0
      );

      document.item_list.forEach((item: ISalesItemList) => {
        const pre_vat_amount_exclude_discount = item.pre_vat_amount;

        // const item_discount =
        //   item.discount_type === "baht"
        //     ? item.discount
        //     : (item.price_per_unit * item.discount) / 100;

        const item_discount_amount =
          item.discount_type === "baht"
            ? item.qty * item.discount
            : item.qty * ((item.price_per_unit * item.discount) / 100);

        const item_additional_discount =
          actualAdditionalDiscount *
          (pre_vat_amount_exclude_discount /
            sum_pre_vat_amount_exclude_discount);

        const pre_vat_amount_include_discount =
          item.pre_vat_amount - item_additional_discount;

        const item_vat_exempted_amount =
          item.vat_percentage === "ไม่มี" ? pre_vat_amount_include_discount : 0;

        const item_vat_0_amount =
          item.vat_percentage === "0" ? pre_vat_amount_include_discount : 0;

        const item_vat_7_amount =
          item.vat_percentage === "7" && price_vat_type === "included_vat"
            ? pre_vat_amount_include_discount / 1.07
            : item.vat_percentage === "7" && price_vat_type === "excluded_vat"
            ? pre_vat_amount_include_discount
            : 0;

        const item_vat_amount = item_vat_7_amount * 0.07;

        const item_withholding_tax_type = item.withholding_tax_type;
        const item_withholding_tax = item.withholding_tax_value;

        const item_net_amount =
          item_vat_exempted_amount +
          item_vat_0_amount +
          item_vat_7_amount +
          item_vat_amount;

        const item_total_amount = item_net_amount - item.withholding_tax_value;

        const project_unique_id =
          document.project && document.project.length > 0
            ? document.project.map((proj: any) => proj.unique_id).join(", ")
            : "-";
        const project_name =
          document.project && document.project.length > 0
            ? document.project.map((proj: any) => proj.name).join(", ")
            : "-";

        let formatDocument;
        if (documentType === "quotation") {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            customer_unique_id: document.customer_contact_unique_id,
            customer_name: document.customer_contact.name,
            price_vat_type: document.price_vat_type,
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            qty: item.qty,
            uom: item.uom,
            price_per_unit: item.price_per_unit,
            vat_percentage: item.vat_percentage,
            pre_vat_amount_exclude_discount,
            item_discount: item_discount_amount,
            item_additional_discount,
            pre_vat_amount_include_discount,
            item_vat_exempted_amount,
            item_vat_0_amount,
            item_vat_7_amount,
            item_vat_amount,
            item_net_amount,
            item_withholding_tax_type,
            item_withholding_tax,
            item_total_amount,
            project_unique_id,
            project_name,
            sales_channel: document.sales_channel,
            account_id: document.account_id,
            tag_list: document.tag_list,
            shipping_cost: document.shipping_cost,
            total_amount: document.total_amount,
            additional_discount: actualAdditionalDiscount,
            credit_day: document.credit_day,
            remark: document.remark,
            stock_qty: item.stock_qty,
            is_late: document.flag_status?.includes("late")
              ? "เกินเวลาแล้ว"
              : "ยังไม่เกินเวลา",
            external_reference_id: document.external_reference_id,
            accepted_date: document.accepted_date,
            accepted_remark: document.accepted_remark,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            sales_contact_list: document.sales_contact_list,
            email: document.customer_contact.email,
            phone: document.customer_contact.phone,
            fax: document.customer_contact.fax,
            billing_address: document.customer_contact.billing_address?.address,
            billing_sub_district:
              document.customer_contact.billing_address?.sub_district,
            billing_district:
              document.customer_contact.billing_address?.district,
            billing_province:
              document.customer_contact.billing_address?.province,
            billing_postal_code:
              document.customer_contact.billing_address?.postal_code,
            billing_country: document.customer_contact.billing_address?.country,
            is_same_as_default_address:
              document.customer_contact.delivery_address
                ?.is_same_as_default_address,
            delivery_address_contact_name:
              document.customer_contact.delivery_address?.address_contact_name,
            delivery_address_contact_phone:
              document.customer_contact.delivery_address?.address_contact_phone,
            delivery_address:
              document.customer_contact.delivery_address?.address,
            delivery_sub_district:
              document.customer_contact.delivery_address?.sub_district,
            delivery_district:
              document.customer_contact.delivery_address?.district,
            delivery_province:
              document.customer_contact.delivery_address?.province,
            delivery_postal_code:
              document.customer_contact.delivery_address?.postal_code,
            delivery_country:
              document.customer_contact.delivery_address?.country,
          };
        } else if (documentType === "sales_order") {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            delivery_date: document.delivery_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            customer_unique_id: document.customer_contact_unique_id,
            customer_name: document.customer_contact.name,
            price_vat_type: document.price_vat_type,
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            qty: item.qty,
            uom: item.uom,
            price_per_unit: item.price_per_unit,
            vat_percentage: item.vat_percentage,
            pre_vat_amount_exclude_discount,
            item_discount: item_discount_amount,
            item_additional_discount,
            pre_vat_amount_include_discount,
            item_vat_exempted_amount,
            item_vat_0_amount,
            item_vat_7_amount,
            item_vat_amount,
            item_net_amount,
            item_withholding_tax_type,
            item_withholding_tax,
            item_total_amount,
            project_unique_id,
            project_name,
            payment_type: document.payment_type,
            sales_channel: document.sales_channel,
            account_id: document.account_id,
            tag_list: document.tag_list,
            shipping_cost: document.shipping_cost,
            total_amount: document.total_amount,
            additional_discount: actualAdditionalDiscount,
            credit_day: document.credit_day,
            remark: document.remark,
            stock_qty: item.stock_qty,
            is_late: document.flag_status?.includes("late")
              ? "เกินเวลาแล้ว"
              : "ยังไม่เกินเวลา",
            external_reference_id: document.external_reference_id,
            accepted_date: document.accepted_date,
            accepted_remark: document.accepted_remark,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            reference_unique_id_list: document.reference_unique_id_list,
            sales_contact_list: document.sales_contact_list,
            email: document.customer_contact.email,
            phone: document.customer_contact.phone,
            fax: document.customer_contact.fax,
            billing_address: document.customer_contact.billing_address?.address,
            billing_sub_district:
              document.customer_contact.billing_address?.sub_district,
            billing_district:
              document.customer_contact.billing_address?.district,
            billing_province:
              document.customer_contact.billing_address?.province,
            billing_postal_code:
              document.customer_contact.billing_address?.postal_code,
            billing_country: document.customer_contact.billing_address?.country,
            is_same_as_default_address:
              document.customer_contact.delivery_address
                ?.is_same_as_default_address,
            delivery_address_contact_name:
              document.customer_contact.delivery_address?.address_contact_name,
            delivery_address_contact_phone:
              document.customer_contact.delivery_address?.address_contact_phone,
            delivery_address:
              document.customer_contact.delivery_address?.address,
            delivery_sub_district:
              document.customer_contact.delivery_address?.sub_district,
            delivery_district:
              document.customer_contact.delivery_address?.district,
            delivery_province:
              document.customer_contact.delivery_address?.province,
            delivery_postal_code:
              document.customer_contact.delivery_address?.postal_code,
            delivery_country:
              document.customer_contact.delivery_address?.country,
            tax_invoice_date: document.tax_invoice_date,
            tax_invoice_number: document.tax_invoice_number,
          };
        } else {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            delivery_date: document.delivery_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            customer_unique_id: document.customer_contact_unique_id,
            customer_name: document.customer_contact.name,
            price_vat_type: document.price_vat_type,
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            so_qty: item.so_qty,
            qty: item.qty,
            uom: item.uom,
            price_per_unit: item.price_per_unit,
            vat_percentage: item.vat_percentage,
            pre_vat_amount_exclude_discount,
            item_discount: item_discount_amount,
            item_additional_discount,
            pre_vat_amount_include_discount,
            item_vat_exempted_amount,
            item_vat_0_amount,
            item_vat_7_amount,
            item_vat_amount,
            item_net_amount,
            item_withholding_tax_type,
            item_withholding_tax,
            item_total_amount,
            project_unique_id,
            project_name,
            sales_channel: document.sales_channel,
            account_id: document.account_id,
            tag_list: document.tag_list,
            shipping_cost: document.shipping_cost,
            total_amount: document.total_amount,
            additional_discount: actualAdditionalDiscount,
            credit_day: document.credit_day,
            remark: document.remark,
            stock_qty: item.stock_qty,
            external_reference_id: document.external_reference_id,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            reference_unique_id_list: document.reference_unique_id_list,
            sales_contact_list: document.sales_contact_list,
            email: document.customer_contact.email,
            phone: document.customer_contact.phone,
            fax: document.customer_contact.fax,
            billing_address: document.customer_contact.billing_address?.address,
            billing_sub_district:
              document.customer_contact.billing_address?.sub_district,
            billing_district:
              document.customer_contact.billing_address?.district,
            billing_province:
              document.customer_contact.billing_address?.province,
            billing_postal_code:
              document.customer_contact.billing_address?.postal_code,
            billing_country: document.customer_contact.billing_address?.country,
            is_same_as_default_address:
              document.customer_contact.delivery_address
                ?.is_same_as_default_address,
            delivery_address_contact_name:
              document.customer_contact.delivery_address?.address_contact_name,
            delivery_address_contact_phone:
              document.customer_contact.delivery_address?.address_contact_phone,
            delivery_address:
              document.customer_contact.delivery_address?.address,
            delivery_sub_district:
              document.customer_contact.delivery_address?.sub_district,
            delivery_district:
              document.customer_contact.delivery_address?.district,
            delivery_province:
              document.customer_contact.delivery_address?.province,
            delivery_postal_code:
              document.customer_contact.delivery_address?.postal_code,
            delivery_country:
              document.customer_contact.delivery_address?.country,
            is_effect_stock: document.is_effect_stock,
            reason_to_return: document.reason_to_return,
          };
        }
        formattedPayload.push(formatDocument);
      });
    });
  }
  return formattedPayload;
};

export const purchaseExportFormatter = (
  payload: any[] | undefined | null,
  documentType: string,
  warehouses: WarehousesQuery["warehouses"]
) => {
  let formattedPayload: any[] = [];

  if (payload) {
    payload.forEach((document) => {
      const {
        price_vat_type,
        additional_discount,
        additional_discount_type,
        pre_vat_amount,
      } = document;

      const actualAdditionalDiscount =
        additional_discount_type === "percent"
          ? pre_vat_amount * (additional_discount / 100)
          : additional_discount;
      const sum_pre_vat_amount_exclude_discount = document.item_list.reduce(
        (prev: any, item: IPurchaseItemList) => {
          const pre_vat_amount_exclude_discount = prev + item.pre_vat_amount;
          return pre_vat_amount_exclude_discount;
        },
        0
      );

      document.item_list.forEach((item: IPurchaseItemList) => {
        const pre_vat_amount_exclude_discount = item.pre_vat_amount;

        // const item_discount =
        //   item.discount_type === "baht"
        //     ? item.discount
        //     : (item.price_per_unit * item.discount) / 100;

        const item_discount_amount =
          item.discount_type === "baht"
            ? item.qty * item.discount
            : item.qty * ((item.price_per_unit * item.discount) / 100);

        const item_additional_discount =
          actualAdditionalDiscount *
          (pre_vat_amount_exclude_discount /
            sum_pre_vat_amount_exclude_discount);

        const pre_vat_amount_include_discount =
          item.pre_vat_amount - item_additional_discount;

        const item_vat_exempted_amount =
          item.vat_percentage === "ไม่มี" ? pre_vat_amount_include_discount : 0;

        const item_vat_0_amount =
          item.vat_percentage === "0" ? pre_vat_amount_include_discount : 0;

        const item_vat_7_amount =
          item.vat_percentage === "7" && price_vat_type === "included_vat"
            ? pre_vat_amount_include_discount / 1.07
            : item.vat_percentage === "7" && price_vat_type === "excluded_vat"
            ? pre_vat_amount_include_discount
            : 0;

        const item_vat_amount = item_vat_7_amount * 0.07;

        const item_withholding_tax_type = item.withholding_tax_type;
        const item_withholding_tax = item.withholding_tax_value;

        const item_net_amount =
          item_vat_exempted_amount +
          item_vat_0_amount +
          item_vat_7_amount +
          item_vat_amount;

        const item_total_amount = item_net_amount - item.withholding_tax_value;

        const project_unique_id =
          document.project && document.project.length > 0
            ? document.project.map((proj: any) => proj.unique_id).join(", ")
            : "-";
        const project_name =
          document.project && document.project.length > 0
            ? document.project.map((proj: any) => proj.name).join(", ")
            : "-";

        let formatDocument;
        if (documentType === "purchase_request") {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            qty: item.qty,
            uom: item.uom,
            qty_ordered: item.qty_ordered,
            qty_pending: item.qty - (item.qty_ordered || 0),
            project_unique_id,
            project_name,
            remark: document.remark,
            external_reference_id: document.external_reference_id,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            destination_warehouse: warehouses?.find(
              (warehouse) =>
                warehouse.unique_id === document.destination_warehouse_unique_id
            )?.name,
            requestor_name: document.requestor_contact.name,
            requestor_department: document.requestor_contact.department,
            requestor_position: document.requestor_contact.position,
          };
        } else if (documentType === "purchase_order") {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            expect_date: document.expect_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            vendor_unique_id: document.vendor_contact_unique_id,
            vendor_name: document.vendor_contact.name,
            price_vat_type: document.price_vat_type,
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            qty: item.qty,
            qty_received: item.qty_received,
            uom: item.uom,
            price_per_unit: item.price_per_unit,
            vat_percentage: item.vat_percentage,
            pr_reference_unique_id: item.pr_reference_unique_id,
            pre_vat_amount_exclude_discount,
            item_discount: item_discount_amount,
            item_additional_discount,
            pre_vat_amount_include_discount,
            item_vat_exempted_amount,
            item_vat_0_amount,
            item_vat_7_amount,
            item_vat_amount,
            item_net_amount,
            item_withholding_tax_type,
            item_withholding_tax,
            item_total_amount,
            project_unique_id,
            project_name,
            tag_list: document.tag_list,
            shipping_cost: document.shipping_cost,
            total_amount: document.total_amount,
            additional_discount: actualAdditionalDiscount,
            credit_day: document.credit_day,
            remark: document.remark,
            stock_qty: item.stock_qty,
            external_reference_id: document.external_reference_id,
            accepted_date: document.accepted_date,
            accepted_remark: document.accepted_remark,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            reference_unique_id_list: document.reference_unique_id_list,
            email: document.vendor_contact.email,
            phone: document.vendor_contact.phone,
            fax: document.vendor_contact.fax,
            billing_address: document.vendor_contact.billing_address?.address,
            billing_sub_district:
              document.vendor_contact.billing_address?.sub_district,
            billing_district: document.vendor_contact.billing_address?.district,
            billing_province: document.vendor_contact.billing_address?.province,
            billing_postal_code:
              document.vendor_contact.billing_address?.postal_code,
            billing_country: document.vendor_contact.billing_address?.country,
            is_same_as_default_address:
              document.vendor_contact.delivery_address
                ?.is_same_as_default_address,
            delivery_address_contact_name:
              document.vendor_contact.delivery_address?.address_contact_name,
            delivery_address_contact_phone:
              document.vendor_contact.delivery_address?.address_contact_phone,
            delivery_address: document.vendor_contact.delivery_address?.address,
            delivery_sub_district:
              document.vendor_contact.delivery_address?.sub_district,
            delivery_district:
              document.vendor_contact.delivery_address?.district,
            delivery_province:
              document.vendor_contact.delivery_address?.province,
            delivery_postal_code:
              document.vendor_contact.delivery_address?.postal_code,
            delivery_country: document.vendor_contact.delivery_address?.country,
            destination_warehouse: warehouses?.find(
              (warehouse) =>
                warehouse.unique_id === document.destination_warehouse_unique_id
            )?.name,
          };
        } else {
          formatDocument = {
            unique_id: document.unique_id,
            issue_date: document.issue_date,
            due_date: document.due_date,
            delivery_date: document.delivery_date,
            aggrid_status: statusValueFormatter(document.aggrid_status),
            vendor_unique_id: document.vendor_contact_unique_id,
            vendor_name: document.vendor_contact.name,
            price_vat_type: document.price_vat_type,
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            qty: item.qty,
            po_qty: item.po_qty,
            uom: item.uom,
            price_per_unit: item.price_per_unit,
            vat_percentage: item.vat_percentage,
            pre_vat_amount_exclude_discount,
            item_discount: item_discount_amount,
            item_additional_discount,
            pre_vat_amount_include_discount,
            item_vat_exempted_amount,
            item_vat_0_amount,
            item_vat_7_amount,
            item_vat_amount,
            item_net_amount,
            item_withholding_tax_type,
            item_withholding_tax,
            item_total_amount,
            project_unique_id,
            project_name,
            tag_list: document.tag_list,
            shipping_cost: document.shipping_cost,
            total_amount: document.total_amount,
            additional_discount: actualAdditionalDiscount,
            credit_day: document.credit_day,
            remark: document.remark,
            stock_qty: item.stock_qty,
            external_reference_id: document.external_reference_id,
            item_description: item.item_sku_desc,
            item_remark: item.remark,
            created_date: document.created_date,
            updated_date: document.updated_date,
            created_by: document.created_by,
            related_user_list: document.related_user_list,
            reference_unique_id_list: document.reference_unique_id_list,
            email: document.vendor_contact.email,
            phone: document.vendor_contact.phone,
            fax: document.vendor_contact.fax,
            billing_address: document.vendor_contact.billing_address?.address,
            billing_sub_district:
              document.vendor_contact.billing_address?.sub_district,
            billing_district: document.vendor_contact.billing_address?.district,
            billing_province: document.vendor_contact.billing_address?.province,
            billing_postal_code:
              document.vendor_contact.billing_address?.postal_code,
            billing_country: document.vendor_contact.billing_address?.country,
            is_same_as_default_address:
              document.vendor_contact.delivery_address
                ?.is_same_as_default_address,
            delivery_address_contact_name:
              document.vendor_contact.delivery_address?.address_contact_name,
            delivery_address_contact_phone:
              document.vendor_contact.delivery_address?.address_contact_phone,
            delivery_address: document.vendor_contact.delivery_address?.address,
            delivery_sub_district:
              document.vendor_contact.delivery_address?.sub_district,
            delivery_district:
              document.vendor_contact.delivery_address?.district,
            delivery_province:
              document.vendor_contact.delivery_address?.province,
            delivery_postal_code:
              document.vendor_contact.delivery_address?.postal_code,
            delivery_country: document.vendor_contact.delivery_address?.country,
            reason_to_return: document.reason_to_return,
          };
        }
        formattedPayload.push(formatDocument);
      });
    });
  }

  return formattedPayload;
};
