import { useState, forwardRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// import ControlledDatePicker from "../Custom/ControlledDatePicker";
// import CustomizedMenuOption from "../Custom/CustomizedMenuOption";
// import ContactFormGeneral from "./general";
// import ModalUI from "../../UI/ModalUI";
// import RightDrawer from "../../UI/RightDrawer";
// import ContactService from "../../../services/Contact";
// import ActivityLogsService from "../../../services/ActivityLogs";
// import { createActivityLogDeletePayload } from "../../../utils/activityLogsPayloadFormatter";
// import { useAuth } from "../../../hooks/use-auth";
// import { userHavePermission } from "../../../utils/userInfo";
import CustomizedMenuOptions from "../../Custom/CustomizedMenuOptions";
import ModalUI from "../../UI/ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import {
  ContactDeleteMutation,
  useContactDeleteMutation,
} from "../../../generated/contact";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

const ContactHeader = forwardRef<HTMLInputElement, any>(
  (
    { disabled, setDisabled, control, errors, disableModes, isContactPage },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    // const { pathname } = useLocation();
    // const { user } = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    // const allEditPermission = userHavePermission(
    //   user,
    //   "contact",
    //   "all",
    //   "edit"
    // );
    // const allDeletePermission = userHavePermission(
    //   user,
    //   "contact",
    //   "all",
    //   "delete"
    // );
    // const vendorEditPermission = userHavePermission(
    //   user,
    //   "contact",
    //   "vendor",
    //   "edit"
    // );
    // const vendorDeletePermission = userHavePermission(
    //   user,
    //   "contact",
    //   "vendor",
    //   "delete"
    // );
    // const customerEditPermission = userHavePermission(
    //   user,
    //   "contact",
    //   "customer",
    //   "edit"
    // );
    // const customerDeletePermission = userHavePermission(
    //   user,
    //   "contact",
    //   "customer",
    //   "delete"
    // );

    const selectModifyOptions = [
      {
        value: "แก้ไข",
        // disabled:
        //   (!allEditPermission &&
        //     !vendorEditPermission &&
        //     !customerEditPermission) ||
        //   (!watchIsVendor && vendorEditPermission && !allEditPermission) ||
        //   (!watchIsCustomer && customerEditPermission && !allEditPermission),
      },
      {
        value: "ลบ",
        // disabled:
        //   (!allDeletePermission &&
        //     !vendorDeletePermission &&
        //     !customerDeletePermission) ||
        //   (!watchIsVendor && vendorDeletePermission && !allDeletePermission) ||
        //   (!watchIsCustomer &&
        //     customerDeletePermission &&
        //     !allDeletePermission),
      },
    ];

    const cancelDeleteHandler = () => {
      setDisabled(false);
      setDeleteModalIsOpen(false);
    };

    const graphQLClientWithHeaderContact: GraphQLClient =
      createGraphQLClientWithMiddleware("contact");

    const { mutate: deleteContact } = useContactDeleteMutation<Error>(
      graphQLClientWithHeaderContact,
      {
        onSuccess: (data: ContactDeleteMutation) => {
          navigate("/contact/all", { replace: true });
          enqueueSnackbar("ลบผู้ติดต่อสำเร็จ", {
            variant: "success",
          });
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar("เกิดความผิดพลาดในการลบข้อมูลผู้ติดต่อ", {
            variant: "error",
          });
        },
      }
    );

    const deleteContactHandler = async () => {
      deleteContact({ uniqueInput: { unique_id: id } });
      // try {
      // const logPayload = createActivityLogDeletePayload(
      //   { creator_document_id: user.document_id, document_id: id },
      //   "contact"
      // );
      // await ActivityLogsService.createActivityLogs({
      //   createActivityLogInput: logPayload,
      // });
      // } catch (err) {
      //   console.log(err);
      // }
      setDeleteModalIsOpen(false);
    };

    const renderDatePicker = (
      <ControlledDatePicker
        name="created_date"
        control={control}
        // error={errors.created_date?.error}
        open={datePickerIsOpen}
        onClose={() => setDatePickerIsOpen(false)}
        onOpen={() => setDatePickerIsOpen(true)}
        label={t("date.created_date")}
        disabled={disabled || id}
      />
    );

    return (
      <Box sx={{ width: "100%" }}>
        {isContactPage && (
          <Grid container alignItems={"flex-end"} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
              <Typography variant="h5">
                {id ? id || "แก้ไขผู้ติดต่อ" : "เพิ่มผู้ติดต่อ"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                  {renderDatePicker}
                </Grid>
                {isContactPage && (
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                    <CustomizedMenuOptions
                      size="medium"
                      fullWidth
                      label={"ตัวเลือก"}
                      options={selectModifyOptions}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        switch (value.innerText) {
                          case "แก้ไข":
                            setDisabled(false);
                            break;
                          case "ลบ":
                            setDeleteModalIsOpen(true);
                            break;

                          default:
                            break;
                        }
                      }}
                      disabled={!id}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isContactPage && (
          <Box sx={{ pr: "2rem" }}>
            <Grid container alignItems={"flex-end"} justifyContent={"flex-end"}>
              <Grid item xs={5} sm={5} md={3}>
                {renderDatePicker}
              </Grid>
            </Grid>
          </Box>
        )}
        <ModalUI
          open={deleteModalIsOpen}
          handleClose={cancelDeleteHandler}
          fullWidth
          maxWidth="sm"
          title="ยืนยันการลบข้อมูลผู้ติดต่อ"
          action={
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ mr: 1 }}>
                <CustomizedButton
                  title={"ยกเลิก"}
                  variant="outlined"
                  type="reset"
                  size="medium"
                  onClick={cancelDeleteHandler}
                />
              </Box>
              <CustomizedButton
                title={"ยืนยัน"}
                variant="contained"
                size="medium"
                onClick={deleteContactHandler}
              />
            </Box>
          }
        />
      </Box>
    );
  }
);

export default ContactHeader;
