import Chart from "react-google-charts";
import { customChartTooltip } from "../../../../../utils/chartUtils";
import {
  TotalSalesPurchaseByYearQuery,
  YearRange,
} from "../../../../../generated/dashboard";
import { formatNumber } from "../../../../../utils/dataTransformer";
import { Box, Theme, useMediaQuery } from "@mui/material";

type Props = {
  data: TotalSalesPurchaseByYearQuery["TotalSalesPurchaseByYear"];
  selectedYear: YearRange;
};

const monthNames = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

const fullMonthNames = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

// const data = [
//   ["Month", "Profit", { type: "string", role: "tooltip", p: { html: true } }],
//   [
//     "ม.ค.",
//     20000,
//     customChartTooltip("กำไร", "#A488E7", "มกราคม", 2024, 20000, "+15.00"),
//   ],
//   [
//     "ก.พ.",
//     17000,
//     customChartTooltip("กำไร", "#A488E7", "กุมภาพันธ์", 2024, 17000, "+10.00"),
//   ],
//   [
//     "มี.ค.",
//     30000,
//     customChartTooltip("กำไร", "#A488E7", "มีนาคม", 2024, 30000, "+20.00"),
//   ],
//   [
//     "เม.ย.",
//     20000,
//     customChartTooltip("กำไร", "#A488E7", "เมษายน", 2024, 20000, "+5.00"),
//   ],
//   [
//     "พ.ค.",
//     19000,
//     customChartTooltip("กำไร", "#A488E7", "พฤษภาคม", 2024, 19000, "+8.00"),
//   ],
//   [
//     "มิ.ย.",
//     15000,
//     customChartTooltip(
//       "กำไร",
//       "#A488E7",
//       "มิถุนายน",
//       2024,
//       15000,
//       "-3.00",
//       "#E41B1B"
//     ),
//   ],
//   [
//     "ก.ค.",
//     20000,
//     customChartTooltip("กำไร", "#A488E7", "กรกฎาคม", 2024, 20000, "+7.00"),
//   ],
//   [
//     "ส.ค.",
//     20000,
//     customChartTooltip("กำไร", "#A488E7", "สิงหาคม", 2024, 20000, "+6.00"),
//   ],
//   [
//     "ก.ย.",
//     17000,
//     customChartTooltip(
//       "กำไร",
//       "#A488E7",
//       "กันยายน",
//       2024,
//       17000,
//       "-1.00",
//       "#E41B1B"
//     ),
//   ],
//   [
//     "ต.ค.",
//     45000,
//     customChartTooltip("กำไร", "#A488E7", "ตุลาคม", 2024, 45000, "+25.00"),
//   ],
//   [
//     "พ.ย.",
//     13000,
//     customChartTooltip(
//       "กำไร",
//       "#A488E7",
//       "พฤศจิกายน",
//       2024,
//       13000,
//       "-5.00",
//       "#E41B1B"
//     ),
//   ],
//   [
//     "ธ.ค.",
//     -10000,
//     customChartTooltip(
//       "กำไร",
//       "#A488E7",
//       "ธันวาคม",
//       2024,
//       -10000,
//       "-15.00",
//       "#E41B1B"
//     ),
//   ],
// ];

const ProfitChart = ({ data, selectedYear }: Props) => {
  const months = data?.months || [];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const maxValue = Math.max(
    ...months.map((item) => Math.max(item?.total_profit || 0))
  );

  // const minValue = Math.min(
  //   ...months.map((item) => Math.min(item?.total_profit || 0))
  // );

  const formatMonths = [
    ["Month", "Profit", { type: "string", role: "tooltip", p: { html: true } }],
    ...months.map((month, index) => [
      monthNames[index],
      month?.total_profit,
      customChartTooltip(
        "กำไร",
        "#A488E7",
        fullMonthNames[index],
        selectedYear === YearRange.ThisYear
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1,
        month?.total_profit,
        month?.yoy_percentage
          ? month.yoy_percentage >= 0
            ? `+${formatNumber(month.yoy_percentage)}`
            : `${formatNumber(month.yoy_percentage)}`
          : null,
        month?.yoy_percentage
          ? month.yoy_percentage >= 0
            ? "#22915A"
            : "#E41B1B"
          : null
      ),
    ]),
  ];
  const options = {
    legend: "none",
    pointSize: 10,
    chartArea: {
      width: "100%", // Keep full width for the chart area
      left: 50, // Add space for Y-axis labels
      top: 16,
      bottom: 32,
    },
    hAxis: {
      textStyle: {
        fontName: "Kanit", // Replace with your desired font family
        fontSize: 14, // Change font size
        color: "#333333", // Change font color
      },
    },
    vAxis: {
      format: "short",
      minorGridlines: { color: "transparent" },
      textStyle: {
        fontName: "Kanit", // Replace with your desired font family
        fontSize: 14, // Change font size
        color: "#333333", // Change font color
      },
      maxValue: maxValue > 5000 ? undefined : 5000,
    },
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: "Kanit",
        fontSize: 14,
        color: "#737373",
      },
    },
    colors: ["#A488E7"],
  };

  if (isMobile) {
    return (
      <Box width={"100%"} sx={{ overflowX: "scroll", overflowY: "hidden" }}>
        <Chart
          chartType="LineChart"
          width={750}
          height={300}
          data={formatMonths}
          options={options}
          loader={<Box height={300} />}
        />
      </Box>
    );
  }

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height={300}
      data={formatMonths}
      options={options}
      loader={<Box height={300} />}
    />
  );
};

export default ProfitChart;
