import {
  Box,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import TotalSalesPurchaseChart from "./TotalSalesPurchaseChart";
import TotalSalesPurchaseAmount from "./TotalSalesPurchaseAmount";
import ProfitChart from "./ProfitChart";
import CustomizedSelect from "../../../../Custom/CustomizedSelect";
import {
  TotalSalesPurchaseByYearQuery,
  useTotalSalesPurchaseByYearQuery,
  YearRange,
} from "../../../../../generated/dashboard";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useStateContext } from "../../../../../contexts/auth-context";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type CustomYearSelectProps = {
  selectedYear: YearRange;
  setSelectedYear: Dispatch<SetStateAction<YearRange>>;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined
  ) => Promise<QueryObserverResult<TotalSalesPurchaseByYearQuery, unknown>>;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function CustomYearSelect(props: CustomYearSelectProps) {
  const { selectedYear, setSelectedYear, refetch } = props;
  return (
    <Box width={90}>
      <CustomizedSelect
        value={selectedYear}
        options={[
          {
            label: `${new Date().getFullYear()}`,
            value: YearRange.ThisYear,
          },
          {
            label: `${new Date().getFullYear() - 1}`,
            value: YearRange.LastYear,
          },
        ]}
        onChange={async (e) => {
          const value = e.target.value as YearRange;
          await setSelectedYear(value);
          await refetch();
        }}
      />
    </Box>
  );
}

const TotalSalesPurchaseByYear = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [selectedYear, setSelectedYear] = useState(YearRange.ThisYear);
  const {
    state: { permissions },
  } = useStateContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isFetching, refetch } =
    useTotalSalesPurchaseByYearQuery<TotalSalesPurchaseByYearQuery>(
      graphqlClient,
      {
        year: selectedYear,
      },
      {
        enabled: !!selectedYear,
        staleTime: Infinity,
      }
    );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const isShowSales = permissions && permissions.sales.view === "ALLOW";

  const isShowPurchase = permissions && permissions.purchase.view === "ALLOW";

  const isShowProfit =
    permissions &&
    permissions.sales.view === "ALLOW" &&
    permissions.purchase.view === "ALLOW";

  const formatTabName = () => {
    if (isShowSales && !isShowPurchase) {
      return t("dashboard.total_sales");
    }
    if (isShowPurchase && !isShowSales) {
      return t("dashboard.total_purchase");
    }
    return t("dashboard.total_sales_purchase");
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: isMobile ? "100%" : "fit-content",
          }}
        >
          <Tab label={formatTabName()} />
          {isShowProfit && <Tab label={t("dashboard.profit")} />}
        </Tabs>
        {!isMobile && (
          <CustomYearSelect
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            refetch={refetch}
          />
        )}
      </Box>
      {isMobile && (
        <Box display={"flex"} justifyContent={"flex-end"} pt={2} pb={1}>
          <CustomYearSelect
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            refetch={refetch}
          />
        </Box>
      )}
      <CustomTabPanel value={value} index={0}>
        <CustomizedBox
          height={"100%"}
          border={isMobile ? "unset" : `1px solid #E2E2E2`}
          margin={isMobile ? "unset" : "0.5rem 0"}
          padding={isMobile ? "unset" : "1rem"}
          boxShadow={"unset"}
        >
          <Box
            display={"flex"}
            gap={1}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box flex={1}>
              {isFetching ? (
                <Skeleton
                  variant="rectangular"
                  height={300}
                  animation={"wave"}
                />
              ) : (
                <TotalSalesPurchaseChart
                  data={data?.TotalSalesPurchaseByYear}
                  selectedYear={selectedYear}
                  isShowSales={isShowSales}
                  isShowPurchase={isShowPurchase}
                />
              )}
            </Box>
            {!isMobile && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            <TotalSalesPurchaseAmount
              totalSales={data?.TotalSalesPurchaseByYear?.total_sales}
              totalPurchase={data?.TotalSalesPurchaseByYear?.total_purchase}
              totalProfit={data?.TotalSalesPurchaseByYear?.total_profit}
              isLoading={isFetching}
              isShowSales={isShowSales}
              isShowPurchase={isShowPurchase}
              isShowProfit={isShowProfit}
            />
          </Box>
        </CustomizedBox>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CustomizedBox
          height={"100%"}
          border={isMobile ? "unset" : `1px solid #E2E2E2`}
          margin={isMobile ? "unset" : "0.5rem 0"}
          padding={isMobile ? "unset" : "1rem"}
          boxShadow={"unset"}
        >
          <Box
            display={"flex"}
            gap={1}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box flex={1}>
              {isFetching ? (
                <Skeleton variant="rectangular" height={300} />
              ) : (
                <ProfitChart
                  data={data?.TotalSalesPurchaseByYear}
                  selectedYear={selectedYear}
                />
              )}
            </Box>
            {!isMobile && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            <TotalSalesPurchaseAmount
              totalSales={data?.TotalSalesPurchaseByYear?.total_sales}
              totalPurchase={data?.TotalSalesPurchaseByYear?.total_purchase}
              totalProfit={data?.TotalSalesPurchaseByYear?.total_profit}
              isLoading={isFetching}
              isShowSales={isShowSales}
              isShowPurchase={isShowPurchase}
              isShowProfit={isShowProfit}
            />
          </Box>
        </CustomizedBox>
      </CustomTabPanel>
    </Box>
  );
};

export default TotalSalesPurchaseByYear;
