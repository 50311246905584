import { Fragment, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";

import { useStateContext } from "../../../contexts/auth-context";

import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import {
  Avatar,
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ModalUI from "../ModalUI";
import ButtonLayout from "../ButtonLayout";
import NewConfirmation from "../NewConfirmation";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";

import {
  autoGRDefaultValue,
  autoGRValidation,
} from "../../Form/Purchase/Order/autoGRSchema";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import { EntityTypeEnum } from "../../../generated/rbac";
import {
  Tracability,
  ActivityType,
  GoodsReceiveCreateInput,
  InventoryActivityLogDocumentType,
  InventoryDocumentType,
  InventoryUniqueIdQuery,
  ModelType,
  Status,
  useGoodsReceiveCreateMutation,
  useInventoryDocumentNextStatusMutation,
  useInventoryUniqueIdQuery,
} from "../../../generated/inventory";

import { ITraceEntry } from "../../../types/Inventory";
import { IAutoGRForm } from "../../../types/Inventory/goodsReceive";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";

import { itemListToTraceEntryListFormatter } from "../../../utils/Formatter/Global";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { useActivityLog } from "../../../hooks/use-activity-log";
import { useWarehouseOptions } from "../../../hooks/Inventory/use-warehouse-option";
import {
  useAutoGoodsReceiveHeader,
  useGoodsReceiveCheckboxTable,
} from "../../../hooks/Inventory/use-receive-item-list";
import { autoGoodsReceiveFormatter } from "../../../utils/Formatter/GoodsReceive";
import { useConfirmation } from "../../../hooks/use-confrimation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { PurchaseOrderQuery } from "../../../generated/purchase";

type Props = {
  poData: IPurchaseOrder;
  modal: boolean;
  closeModalHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<PurchaseOrderQuery, unknown>>;
};

const StyledTableRow = styled(TableRow)<{
  selected: boolean;
  scanned?: boolean;
}>(({ theme, selected }) => ({
  backgroundColor: selected ? `#F9F9F9 !important` : undefined,
}));

const AutoGRModal = ({ poData, modal, closeModalHandler, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { createActivityLog } = useActivityLog();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pendingValue, setPendingValue] = useState<
    | {
        value: string;
        field: ControllerRenderProps<IAutoGRForm, "source_warehouse_unique_id">;
      }
    | undefined
  >(undefined);

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const methods = useForm<IAutoGRForm>({
    defaultValues: autoGRDefaultValue,
    resolver: yupResolver(autoGRValidation),
  });

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;

  const currentWarehouse = watch("source_warehouse_unique_id");
  const traceEntry: ITraceEntry[] = useWatch({
    control,
    name: "trace_entry_list",
  });

  const { fields } = useFieldArray({ control, name: "trace_entry_list" });

  const { mutateAsync: create } = useGoodsReceiveCreateMutation<Error>(
    graphQLClientWithHeaderItem
  );

  const { mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem);

  const { refetch: refetchInven } =
    useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
      graphQLClientWithHeaderItem,
      {
        modelType: ModelType.GoodsReceive,
      },
      {
        enabled: false,
        staleTime: Infinity,
      }
    );

  const {
    warehouseOptions,
    renderWarehouseValue,
    binLocationOptions,
    renderBinlocationValue,
  } = useWarehouseOptions(currentWarehouse);

  const { selectedRows, handleSelectAll, handleSelectRow, isRowSelected } =
    useGoodsReceiveCheckboxTable(getValues);

  const headers = useAutoGoodsReceiveHeader();

  useEffect(() => {
    if (poData.item_list && poData.item_list.length > 0) {
      const filterNormalItem = poData.item_list.filter(
        (item) =>
          item.tracability === Tracability.Normal &&
          item.item_type === "normal" &&
          item.qty > (item.qty_received || 0)
      );

      const convertData = itemListToTraceEntryListFormatter(
        filterNormalItem,
        InventoryDocumentType.GoodsReceive,
        EntityTypeEnum.PurchaseOrder
      );

      reset({
        ...autoGRDefaultValue,
        trace_entry_list: convertData,
        source_warehouse_unique_id: poData.destination_warehouse_unique_id,
      });

      handleSelectAll(true);
    }
  }, [
    poData.destination_warehouse_unique_id,
    poData.item_list,
    reset,
    modal,
    handleSelectAll,
  ]);

  useEffect(() => {
    if (!currentWarehouse && warehouseOptions && warehouseOptions.length > 0) {
      setValue(
        "source_warehouse_unique_id",
        warehouseOptions[0].value as string
      );
    }
  }, [currentWarehouse, setValue, warehouseOptions]);

  useEffect(() => {
    if (binLocationOptions && binLocationOptions.length > 0) {
      setValue("source_bin_location_id", binLocationOptions[0].value as number);

      const allItem = getValues("trace_entry_list");

      const newTrace = allItem.map((item) => ({
        ...item,
        source_bin_location_id: binLocationOptions[0].value as number,
      }));

      setValue("trace_entry_list", newTrace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWarehouse, modal]);

  const submitModal = async (data: IAutoGRForm) => {
    if (data) {
      setIsLoading(true);

      if (!validateValue()) return setIsLoading(false);

      try {
        const { data: unique_id } = await refetchInven();

        const formattedGoodsReceive = autoGoodsReceiveFormatter(
          {
            ...data,
            trace_entry_list: data.trace_entry_list.filter((trace) =>
              selectedRows.includes(trace.unique_id)
            ),
            unique_id: unique_id?.utilGetUniqueId,
            reference_unique_id: poData.unique_id,
          },
          authUser
        ) as GoodsReceiveCreateInput;

        const { goodsReceiveCreate } = await create({
          createInput: formattedGoodsReceive,
        });

        await createActivityLog({
          activity_type: ActivityType.Create,
          document_type: InventoryActivityLogDocumentType.GoodsReceive,
          reference_id: goodsReceiveCreate?.id || 0,
          activity_detail: {},
        });

        const { documentNextStatus } = await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsReceive,
            unique_id: goodsReceiveCreate?.unique_id || "",
          },
        });

        if (documentNextStatus)
          createActivityLog({
            activity_type: ActivityType.StatusChange,
            document_type: InventoryActivityLogDocumentType.GoodsReceive,
            reference_id: goodsReceiveCreate?.id || 0,
            activity_detail: {
              curr_status: documentNextStatus.status_name as Status,
            },
          });

        closeModalHandler();

        refetch();

        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      }
      setIsLoading(false);
    }
  };

  const countSelectedItems = useMemo(() => {
    let count = 0;

    traceEntry.forEach((entry) => {
      if (entry.serial_list && entry.serial_list.length > 0) {
        // Count only selected serials in the serial_list
        const selectedSerials = entry.serial_list.filter((serial) =>
          selectedRows.includes(serial.unique_id)
        );
        count += selectedSerials.length; // Add serial count, ignore the parent
      } else if (selectedRows.includes(entry.unique_id)) {
        // Count the parent if it doesn't have a serial_list
        count += 1;
      }
    });

    return count;
  }, [selectedRows, traceEntry]);

  const validateValue = () => {
    let check = true;
    const trace_entry_list = getValues("trace_entry_list").filter((list) =>
      selectedRows.includes(list.unique_id)
    );

    if (!currentWarehouse) {
      enqueueSnackbar("กรุณาระบุคลังปลายทาง", {
        variant: "error",
      });
      check = false;
    }

    if (trace_entry_list.some((trace) => !trace.source_bin_location_id)) {
      enqueueSnackbar("กรุณาระบุสถานที่", {
        variant: "error",
      });
      check = false;
    }

    if (trace_entry_list.some((trace) => trace.qty <= 0)) {
      enqueueSnackbar("กรุณาระบุจำนวนมากกว่า 0", {
        variant: "error",
      });
      check = false;
    }

    trace_entry_list.forEach((trace) => {
      if (
        (trace.document_item_qty || 0) - (trace.posted_qty || 0) <
        trace.qty
      ) {
        enqueueSnackbar(trace.item_unique_id + " เกินจำนวนใบสั่งซื้อ", {
          variant: "error",
        });
        check = false;
      }
    });

    return check;
  };

  const changeWarehouseConfirmation = useConfirmation();

  const handleComboBoxChange = (
    value: string,
    field: ControllerRenderProps<IAutoGRForm, "source_warehouse_unique_id">
  ) => {
    setPendingValue({ value, field }); // Store the value and field temporarily
    changeWarehouseConfirmation.openConfirmationHandler(); // Open the confirmation dialog
  };

  const handleConfirmationAction = () => {
    // Execute the logic only after confirmation
    if (pendingValue?.value) {
      pendingValue.field.onChange(pendingValue.value);
    } else {
      pendingValue?.field.onChange("");
    }
    setPendingValue(undefined); // Clear the pending value
    changeWarehouseConfirmation.closeConfirmationHandler(); // Close the confirmation dialog
  };

  return (
    <ModalUI
      title="นำเข้า"
      status={
        <Box
          bgcolor="#ECF6FD"
          sx={{
            minHeight: "22px",
            padding: "2px 8px 2px 8px",
            gap: "10px",
            borderRadius: "4px",
            opacity: "0px",
          }}
        >
          <Typography
            sx={{
              color: "#333333",
            }}
            fontSize={12}
          >
            เลือก {countSelectedItems} รายการ
          </Typography>
        </Box>
      }
      open={modal}
      handleClose={closeModalHandler}
      action={
        <ButtonLayout>
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeModalHandler}
            disabled={isLoading}
          />
          <CustomizedButton
            title="นำเข้า"
            variant="contained"
            onClick={handleSubmit(submitModal)}
            disabled={isLoading}
          />
        </ButtonLayout>
      }
      maxWidth="lg"
    >
      <FormProvider {...methods}>
        <Box display="flex" alignItems="center" gap={2} width="100%">
          <Box display="flex" gap={2}>
            <Controller
              control={control}
              name="source_warehouse_unique_id"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.list.destinationWarehouse")}
                  options={warehouseOptions}
                  error={Boolean(errors.source_warehouse_unique_id)}
                  helperText={errors.source_warehouse_unique_id?.message?.toString()}
                  onChange={(e, value) => {
                    if (field.value)
                      handleComboBoxChange(value?.value || "", field);
                    else field.onChange(value?.value);
                  }}
                  value={renderWarehouseValue(field.value || "")}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ minWidth: 220 }}
                  required
                  disabled={isLoading}
                />
              )}
            />
            <Controller
              control={control}
              name="source_bin_location_id"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.location")}
                  options={[
                    ...binLocationOptions,
                    { id: -1543, value: -1543, label: "กำหนดเอง" },
                  ]}
                  onChange={(e, value) => {
                    if (value?.value) {
                      field.onChange(value.value);

                      if (value.value !== -1543) {
                        const allItem = getValues("trace_entry_list");

                        const newTrace = allItem.map((item) => ({
                          ...item,
                          source_bin_location_id: value.value as number,
                        }));

                        setValue("trace_entry_list", newTrace);
                      }
                    } else field.onChange(0);
                  }}
                  value={
                    field.value !== -1543
                      ? renderBinlocationValue(field.value)
                      : "กำหนดเอง"
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  error={Boolean(errors.source_bin_location_id)}
                  helperText={errors.source_bin_location_id?.message?.toString()}
                  sx={{ minWidth: 250 }}
                  disabled={isLoading}
                />
              )}
            />
          </Box>
          <Box marginLeft="auto">
            <ControlledDatePicker
              control={control}
              name="posted_date"
              label="วันที่นำเข้า"
              error={Boolean(errors.posted_date)}
              disabled={isLoading}
              required
            />
          </Box>
        </Box>
        <Box my={2}>
          <TableContainer sx={{ maxHeight: 480, mt: 2 }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: (theme) => `${theme.palette.primary.light}`,
                }}
              >
                <TableRow>
                  {fields.length > 0 && (
                    <TableCell
                      padding="checkbox"
                      align="center"
                      sx={{
                        px: 1,
                        backgroundColor: (theme) =>
                          `${theme.palette.primary.light}`,
                      }}
                    >
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < fields.length
                        }
                        checked={
                          selectedRows.length > 0 &&
                          selectedRows.length === fields.length
                        }
                        onChange={(e, checked) => handleSelectAll(checked)}
                        disabled={isLoading}
                      />
                    </TableCell>
                  )}
                  {headers.map((header, index) => (
                    <TableCell
                      align={header.align ? header.align : "center"}
                      key={index}
                      sx={{
                        px: 1,
                        py: 1,
                        backgroundColor: (theme) =>
                          `${theme.palette.primary.light}`,
                      }}
                      width={header.width}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          width: header.width,
                          justifyContent: header.align || "center",
                        }}
                      >
                        <Typography fontSize={14} fontWeight={600}>
                          {header.thaiLabel}
                        </Typography>
                        {header.required && (
                          <Typography fontSize={14} color="error">
                            *
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((trace, index) => (
                  <Fragment key={trace.id}>
                    <StyledTableRow
                      selected={isRowSelected(trace.unique_id)}
                      scanned={trace.status === "is_scan"}
                    >
                      {fields.length > 0 && (
                        <TableCell
                          padding="checkbox"
                          sx={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={isRowSelected(trace.unique_id)} // The checkbox is checked when the row is selected
                            onClick={(e) => e.stopPropagation()} // Prevents row selection when clicking checkbox
                            onChange={() => handleSelectRow(trace.unique_id)} // This only handles the checkbox change
                            disabled={isLoading}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[0]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14} color="#737373">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align={headers[1]?.align}
                        width={headers[1]?.width}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Avatar
                            alt={trace.item_name}
                            src={
                              trace?.item_img_url ? trace.item_img_url[0] : ""
                            }
                            sx={{
                              width: 32,
                              height: 32,
                              border: "1px solid #BEBEBE",
                              borderRadius: "2px",
                            }}
                            variant="square"
                          >
                            <ImageOutlinedIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                              fontSize="medium"
                            />
                          </Avatar>
                          <Box>
                            <Typography fontSize={14} color="#737373">
                              {trace.item_unique_id}
                            </Typography>
                            <Typography fontSize={14} fontWeight={600}>
                              {trace.item_name}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        width={headers[2]?.width}
                        align={headers[2]?.align}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography fontSize={14}>
                          {trace.document_item_qty || 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={headers[3]?.width}
                        align={headers[3]?.align}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography fontSize={14}>
                          {trace.posted_qty || 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={headers[4]?.width}
                        align={headers[4]?.align}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography fontSize={14}>
                          {(trace.document_item_qty || 0) -
                            (trace.posted_qty || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={headers[5]?.width}
                        align={headers[5]?.align}
                      >
                        <ControlledNumberTextField
                          control={control}
                          name={`trace_entry_list[${index}].qty`}
                          error={Boolean(
                            errors?.trace_entry_list?.[index]?.qty
                          )}
                          textAlign="right"
                          decimalScale={0}
                          disabled={
                            !selectedRows.includes(trace.unique_id) || isLoading
                          }
                        />
                      </TableCell>
                      <TableCell
                        align={headers[6]?.align}
                        width={headers[6]?.width}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography align={headers[6]?.align}>
                          {trace.uom?.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align={headers[7]?.align}
                        width={headers[7]?.width}
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography align={headers[7]?.align}>
                          {trace.barcode}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align={headers[8]?.align}
                        width={headers[8]?.width}
                      >
                        <Controller
                          control={control}
                          name={`trace_entry_list.${index}.source_bin_location_id`}
                          render={({ field }) => (
                            <CustomizedComboBox
                              {...field}
                              placeholder={t("inventory.location")}
                              options={binLocationOptions}
                              onChange={(e, value) => {
                                if (value?.value) {
                                  field.onChange(value.value);
                                } else field.onChange(null);
                                setValue("source_bin_location_id", -1543);
                              }}
                              value={renderBinlocationValue(field.value || 0)}
                              error={Boolean(errors.source_bin_location_id)}
                              helperText={errors.source_bin_location_id?.message?.toString()}
                              sx={{ minWidth: 170 }}
                              disabled={
                                !currentWarehouse ||
                                !selectedRows.includes(trace.unique_id) ||
                                isLoading
                              }
                            />
                          )}
                        />
                      </TableCell>
                    </StyledTableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography mb={1}>หมายเหตุ</Typography>
        <ControlledTextField
          control={control}
          placeholder="หมายเหตุ"
          name="remark"
          multiline
          minRows={2}
          disabled={isLoading}
        />
        <NewConfirmation
          title="ยืนยันการเปลี่ยนคลังปลายทาง"
          message={
            <>
              <Box>
                <Typography>หากเปลี่ยนคลังปลายทาง</Typography>
                <Typography fontWeight="bold">
                  สถานที่จะถูกเปลี่ยนทั้งหมด
                </Typography>
              </Box>
            </>
          }
          action={handleConfirmationAction}
          handleClose={changeWarehouseConfirmation.closeConfirmationHandler}
          open={changeWarehouseConfirmation.confirmation}
        />
      </FormProvider>
    </ModalUI>
  );
};

export default AutoGRModal;
