import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IDefaultForm } from "../../../types/global";
import { IGoodsTransfer } from "../../../types/Inventory/goodsTransfer";
import GoodsTransferScanItemList from "../../../components/Table/Inventory/GoodsTransfer/GoodsTransferScanItemList";
import GoodsTransferItemList from "../../../components/Table/Inventory/GoodsTransfer/GoodsTransferItemList";
import { GoodsTransferQuery } from "../../../generated/inventory";
import { IWarehouse } from "../../../types/Setting/inventory";

type Props = IDefaultForm & {
  data?: GoodsTransferQuery;
  allWarehouses?: IWarehouse[];
  fields: FieldArrayWithId<IGoodsTransfer, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsTransfer, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
  renderButton: () => JSX.Element | null;
};

const BarcodeTab = ({
  control,
  getValues,
  setValue,
  disabled,
  errors,
  fields,
  append,
  remove,
  update,
  renderButton,
  data,
  allWarehouses,
}: Props) => {
  return (
    <>
      {data?.goodsTransfer?.sub_status === "wait_transfer" ||
      data?.goodsTransfer?.sub_status === "finished" ? (
        <GoodsTransferScanItemList
          control={control}
          errors={errors}
          fields={fields}
          disabled={disabled}
          allWarehouses={allWarehouses}
          status={data?.goodsTransfer?.aggrid_status}
          update={update}
          getValues={getValues}
          setValue={setValue}
        />
      ) : (
        <GoodsTransferItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
          update={update}
          allWarehouses={allWarehouses}
        />
      )}
      {renderButton()}
    </>
  );
};

export default BarcodeTab;
