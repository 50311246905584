import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
// import { useSelector } from "react-redux";
// import { filterUom } from "../../../utils/dataTransformer";
import { IDefaultForm } from "../../../../types/global";
import { IItemGroup, IUom } from "../../../../types/Inventory/item";
import { Fragment, useEffect, useState } from "react";
import {
  ItemGroupLevel1sQuery,
  ItemGroupLevel2sQuery,
  ItemGroupLevel3sQuery,
  useItemGroupLevel1sQuery,
  useItemGroupLevel2sQuery,
  useItemGroupLevel3sQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
  itemType: string;
  allUoms: IUom[];
};

const General = ({
  control,
  errors,
  setValue,
  getValues,
  itemType,
  disabled,
  isInventoryPage,
  allUoms,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const [itemGroupLv1, setItemGroupLv1] = useState<IItemGroup[]>([]);
  const [itemGroupLv2, setItemGroupLv2] = useState<IItemGroup[]>([]);
  const [itemGroupLv3, setItemGroupLv3] = useState<IItemGroup[]>([]);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: itemGroupData, isSuccess: itemGroupIsSuccess } =
    useItemGroupLevel1sQuery<ItemGroupLevel1sQuery>(
      graphQLClientWithHeaderItem
    );

  const { data: itemGroup2Data, isSuccess: itemGroup2IsSuccess } =
    useItemGroupLevel2sQuery<ItemGroupLevel2sQuery>(
      graphQLClientWithHeaderItem
    );

  const { data: itemGroup3Data, isSuccess: itemGroup3IsSuccess } =
    useItemGroupLevel3sQuery<ItemGroupLevel3sQuery>(
      graphQLClientWithHeaderItem
    );

  useEffect(() => {
    if (itemGroupIsSuccess) {
      const { itemGroupLevel1s } = itemGroupData;
      const itemGroupType = itemGroupLevel1s as IItemGroup[];
      setItemGroupLv1(itemGroupType);
    }
  }, [itemGroupData, itemGroupIsSuccess]);

  useEffect(() => {
    if (itemGroup2IsSuccess) {
      const itemGroupType = itemGroup2Data?.itemGroupLevel2s as IItemGroup[];
      setItemGroupLv2(itemGroupType ?? []);
    }
  }, [itemGroup2Data?.itemGroupLevel2s, itemGroup2IsSuccess]);

  useEffect(() => {
    if (itemGroup3IsSuccess) {
      const itemGroupType = itemGroup3Data?.itemGroupLevel3s as IItemGroup[];
      setItemGroupLv3(itemGroupType ?? []);
    }
  }, [itemGroup3Data?.itemGroupLevel3s, itemGroup3IsSuccess]);

  // const { allCategorys } = useSelector((state) => state.category);
  // const { allUoms } = useSelector((state) => state.uom);

  // const lengthOption = filterUom(allUoms, [
  //   "ไมครอน",
  //   "มิลลิเมตร",
  //   "เซนติเมตร",
  //   "เมตร",
  //   "หลา",
  //   "นิ้ว",
  //   "ฟุต",
  //   "กรัม",
  // ]);

  const dimensionList = [
    {
      testId: "item-width-text-field",
      label: t("inventory.items.width"),
      name: "physical_attribute.width",
      uom: "physical_attribute.width_uom_unique_id",
      error: errors.physical_attribute?.width,
      uomError: errors.physical_attribute?.width_uom_unique_id,
      disabledUom: disabled,
    },
    {
      testId: "item-length-text-field",
      label: t("inventory.items.length"),
      name: "physical_attribute.length",
      uom: "physical_attribute.length_uom_unique_id",
      error: errors.physical_attribute?.length,
      uomError: errors.physical_attribute?.length_uom_unique_id,
      disabledUom: disabled,
    },
    {
      testId: "item-height-text-field",
      label: t("inventory.items.height"),
      name: "physical_attribute.height",
      uom: "physical_attribute.height_uom_unique_id",
      error: errors.physical_attribute?.height,
      uomError: errors.physical_attribute?.height_uom_unique_id,
      disabledUom: disabled,
    },
    {
      testId: "item-weight-text-field",
      label: t("inventory.items.thickness"),
      name: "physical_attribute.thick",
      uom: "physical_attribute.thick_uom_unique_id",
      error: errors.physical_attribute?.thick,
      uomError: errors.physical_attribute?.thick_uom_unique_id,
      disabledUom: disabled,
    },
  ];

  const renderGroupFieldValue = (value: string) =>
    itemGroupLv1.find((category) => category?.unique_id === value)?.name;

  const renderGroup2FieldValue = (value: string) =>
    itemGroupLv2.find((category) => category?.unique_id === value)?.name;

  const renderGroup3FieldValue = (value: string) =>
    itemGroupLv3.find((category) => category?.unique_id === value)?.name;

  const renderUomFieldValue = (value: string) =>
    allUoms.find((uom) => uom?.unique_id === value)?.name;

  return (
    <Box>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.item_group")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            name="item_level_1_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-group-lv-1-field"
                options={itemGroupLv1.map((category) => ({
                  id: category.unique_id,
                  value: category.unique_id,
                  label: category.name,
                }))}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.value : "");
                }}
                label={t("inventory.items.item_group") + " 1"}
                error={Boolean(errors.item_level_1_unique_id)}
                helperText={errors.item_level_1_unique_id?.message}
                value={renderGroupFieldValue(field.value)}
                disabled={!isInventoryPage || disabled}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            name="item_level_2_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-group-lv-2-field"
                options={itemGroupLv2.map((category) => ({
                  id: category.unique_id,
                  value: category.unique_id,
                  label: category.name,
                }))}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                label={t("inventory.items.item_group") + " 2"}
                value={renderGroup2FieldValue(field.value)}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            name="item_level_3_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-group-lv-3-field"
                options={itemGroupLv3.map((category) => ({
                  id: category.unique_id,
                  value: category.unique_id,
                  label: category.name,
                }))}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                label={t("inventory.items.item_group") + " 3"}
                value={renderGroup3FieldValue(field.value)}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      {itemType !== "service" && (
        <Fragment>
          <Typography fontWeight="bold" my={2} ml={1}>
            {t("inventory.items.dimension")}
          </Typography>
          {dimensionList.map((dimension, index) => (
            <Grid container spacing={2} key={index} mb={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                {/* <Controller
                  control={control}
                  name={dimension.name}
                  render={({ field }) => (
                    <CustomizedTextField
                      {...field}
                      label={dimension.label}
                      error={Boolean(dimension.error)}
                      helperText={dimension.error?.message}
                      disabled={!isInventoryPage || disabled}
                      testId={dimension.testId}
                    />
                  )}
                /> */}
                <ControlledNumberTextField
                  fullWidth
                  control={control}
                  name={dimension.name}
                  label={dimension.label}
                  error={Boolean(dimension.error)}
                  helperText={dimension.error?.message}
                  disabled={!isInventoryPage || disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Controller
                  name={`${dimension.uom}`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("inventory.unit")}
                      options={allUoms.map((uom) => ({
                        label: uom.name,
                        value: uom.unique_id,
                        id: uom.unique_id,
                      }))}
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.value : null)
                      }
                      value={renderUomFieldValue(field.value)}
                      error={Boolean(dimension.uomError)}
                      helperText={dimension.uomError?.message}
                      disabled={!isInventoryPage || dimension.disabledUom}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Fragment>
      )}
    </Box>
  );
};

export default General;
