import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Formatter/Date";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { formatNumber } from "../../../../utils/dataTransformer";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { IAvatar } from "../../../../types/global";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";

export const useDeliveryOrderColumnDefs = (t: TFunction, isReport: boolean) => {
  const [columnDef, setColumnDef] = useState<ColDef[]>([]);

  useEffect(() => {
    if (!isReport) {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("sales.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "issue_date",
          headerName: t("date.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "delivery_date",
          headerName: t("date.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          sortable: false,
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IDeliveryOrder, string>
          ) => <CustomizedStatus status={params.value} />,
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 200,
          flex: 1,
          filterParams: {
            values: [
              "draft",
              "wait_deliver",
              "completed",
              "not_completed",
              "cancelled",
            ],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "customer_contact",
          headerName: t("sales.customer_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams<IDeliveryOrder>) =>
            params.value.name,
        },
        {
          field: "total_amount",
          headerName: t("sales.total_amount"),
          filter: "agNumberColumnFilter",
          valueFormatter: (
            params: ValueFormatterParams<IDeliveryOrder, number>
          ) => formatNumber(parseFloat(params.value as any)),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
          minWidth: 225,
          flex: 1,
        },
        {
          field: "created_by",
          headerName: t("sales.created_by"),
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IDeliveryOrder, IAvatar>
          ) => {
            if (params.value) {
              return <CustomizedAvatar avatars={[params.value]} />;
            }
          },
          minWidth: 200,
          flex: 1,
        },
        {
          field: "related_user_list",
          headerName: t("sales.employee_list"),
          filter: "agSetColumnFilter",
          cellRenderer: (
            params: ICellRendererParams<IDeliveryOrder, IAvatar[]>
          ) => {
            if (params.value && params.value.length > 0) {
              return <CustomizedAvatar avatars={params.value} />;
            }
          },
          minWidth: 200,
          flex: 1,
        },
      ]);
    } else {
    }
  }, [isReport, t]);

  return columnDef;
};
