import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
// import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
// import { useSelector } from "react-redux";
// import { filterUom } from "../../../utils/dataTransformer";
import { IDefaultForm } from "../../../../types/global";
import { IUom } from "../../../../types/Inventory/item";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
  allUoms: IUom[];
};

const Logistic = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  isInventoryPage,
  allUoms,
}: ExtendedProps) => {
  const { t } = useTranslation();

  const dimensionList = [
    {
      testId: "item-package-width-text-field",
      label: t("inventory.items.width"),
      name: "package_attribute.width",
      uom: "package_attribute.width_uom_unique_id",
      error: errors.package_attribute?.width,
      uomError: errors.package_attribute?.width_uom_unique_id,
      disabledUom: true,
    },
    {
      testId: "item-package-length-text-field",
      label: t("inventory.items.length"),
      name: "package_attribute.length",
      uom: "package_attribute.length_uom_unique_id",
      error: errors.package_attribute?.length,
      uomError: errors.package_attribute?.length_uom_unique_id,
      disabledUom: true,
    },
    {
      testId: "item-package-height-text-field",
      label: t("inventory.items.height"),
      name: "package_attribute.height",
      uom: "package_attribute.height_uom_unique_id",
      error: errors.package_attribute?.height,
      uomError: errors.package_attribute?.height_uom_unique_id,
      disabledUom: true,
    },
    {
      testId: "item-package-weight-text-field",
      label: t("inventory.items.weight"),
      name: "package_attribute.weight",
      uom: "package_attribute.weight_uom_unique_id",
      error: errors.package_attribute?.weight,
      uomError: errors.package_attribute?.weight_uom_unique_id,
      disabledUom: true,
    },
  ];

  const renderFieldValue = (value: string) =>
    allUoms.find((uom) => uom?.unique_id === value)?.name;

  return (
    <Box>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.deliveryDimension")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {dimensionList.map((dimension, index) => (
            <Grid container spacing={2} key={index} mb={2}>
              <Grid item xs={6}>
                {/* <Controller
                  control={control}
                  name={dimension.name}
                  render={({ field }) => (
                    <CustomizedTextField
                      {...field}
                      label={dimension.label}
                      error={Boolean(dimension.error)}
                      helperText={dimension.error?.message}
                      disabled={!isInventoryPage || disabled}
                      testId={dimension.testId}
                    />
                  )}
                /> */}
                <ControlledNumberTextField
                  fullWidth
                  control={control}
                  name={dimension.name}
                  label={dimension.label}
                  error={Boolean(dimension.error)}
                  helperText={dimension.error?.message}
                  disabled={!isInventoryPage || disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={dimension.uom}
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("inventory.unit")}
                      options={allUoms.map((uom) => ({
                        label: uom.name,
                        value: uom.unique_id,
                        id: uom.unique_id,
                      }))}
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.value : null)
                      }
                      value={renderFieldValue(field.value)}
                      error={Boolean(dimension.uomError)}
                      helperText={dimension.uomError?.message}
                      disabled={!isInventoryPage || dimension.disabledUom}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logistic;
