import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useModal } from "../../../hooks/use-modal";
import { IUser } from "../../../types/Auth/user";
import { IApproval } from "../../../types/global";
import CustomizedButton from "../../Custom/CustomizedButton";
import CheckedUserModal from "../../Table/CheckedUserModal";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { useTranslation } from "react-i18next";

type Props = {
  index: number;
};

const ApprovalTemplate = ({ index }: Props) => {
  const { control } = useFormContext<IApproval>();
  const { fields, replace, remove } = useFieldArray({
    control,
    name: `approval_templates.${index}.approver_list`,
  });
  const { t } = useTranslation();
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const [userIds, setUserIds] = useState<string[]>([]);

  const finishUsersSelect = (data: IUser[]) => {
    let newUsers = fields.filter((user: IUser) =>
      userIds.includes(user.unique_id)
    );
    const remainingUsers = newUsers.map((user: IUser) => user.unique_id);

    data.forEach((user) => {
      if (!remainingUsers.includes(user.unique_id)) {
        newUsers.push(user);
      }
    });
    newUsers = newUsers.sort((a, b) => {
      return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
    });

    const limitedUsers = [...newUsers].slice(0, maxSelectableUsers);

    replace(limitedUsers);
    closeModalHandler();
  };
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);

  const maxSelectableUsers = 5;

  const removeUser = (userIndex: number) => remove(userIndex);

  const renderStatus = (isActive: string | number) => {
    return isActive === "1" || isActive === 1 ? (
      <CustomizedStatus status="active" />
    ) : (
      <CustomizedStatus status="inactive" />
    );
  };

  useEffect(() => {
    const userUniqueId = fields.map((field) => field.unique_id);
    setUserIds(userUniqueId);
    setUserIdsSnapshot(userUniqueId);
  }, [fields]);

  return (
    <Box>
      <Box display="flex" mb={2} mt={2}>
        <Typography fontWeight="bold">{`ผู้มีสิทธิ์อนุมัติ`}</Typography>
        <Box
          sx={{
            width: 37,
            height: 24,
            borderRadius: 1,
            backgroundColor: (theme) => theme.palette.primary.light,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 1,
          }}
        >
          {`${fields.length}/${maxSelectableUsers}`}
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
              height: 48,
            }}
          >
            <TableRow>
              <TableCell sx={{ width: 10, padding: 1 }}></TableCell>
              <TableCell sx={{ width: 50, padding: 1 }}></TableCell>
              <TableCell sx={{ width: 50, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600}>
                  {`${t("user.account.first_name")}`}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 50, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600}>
                  {`${t("user.account.last_name")}`}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 30, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600} align="left">
                  {`${t("user.account.email")}`}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 50, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600}>
                  {`${t("user.account.department")}`}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 50, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600}>
                  {`${t("user.account.position")}`}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 50, whiteSpace: "nowrap", padding: 1 }}>
                <Typography fontSize={14} fontWeight={600} align="center">
                  {`${t("user.account.status")}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((user, idx) => (
              <TableRow key={user.genId}>
                <TableCell align="center">
                  <IconButton
                    sx={{ width: 20, padding: 0 }}
                    aria-label="delete"
                    onClick={() => removeUser(idx)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <CustomizedAvatar
                    avatars={[
                      {
                        img_url: user.img_url,
                        unique_id: user.unique_id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                      },
                    ]}
                  />
                </TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.department}</TableCell>
                <TableCell>{user.position}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    {renderStatus(user.status)}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {fields.length < maxSelectableUsers && (
        <Box mt={2}>
          <CustomizedButton
            title="เพิ่มผู้มีสิทธิ์อนุมัติ"
            variant="outlined"
            color="primary"
            onClick={openModalHandler}
            addStartIcon
          />
        </Box>
      )}
      <CheckedUserModal
        title="เลือกผู้มีสิทธิ์อนุมัติ"
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="multiple"
        maxSelectableUsers={maxSelectableUsers}
      />
    </Box>
  );
};

export default ApprovalTemplate;
