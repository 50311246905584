import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SalesOrderHeader from "../../../components/Form/Sales/Order/Header";
import CustomerInfoForm from "../../../components/Form/CustomerInfo/CustomerInfoForm";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import SalesFooter from "../../../components/Form/Sales/Footer";
import PriceVatType from "../../../components/Form/Sales/PriceVatType";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import {
  salesOrderSchema,
  salesOrderValidation,
} from "../../../components/Form/Sales/Order/schema";
import { EntityTypeEnum } from "../../../generated/creatable";
import {
  ActivityType,
  ApprovalAction,
  SalesActivityLogDocumentType,
  SalesApprovalTemplatesQuery,
  SalesDocumentType,
  SalesOrderCreateInput,
  SalesOrderQuery,
  SalesOrderUpdateInput,
  Status,
  useApproveWorkflowMutation,
  useSalesApprovalTemplatesQuery,
  useSalesDocumentNextStatusMutation,
  useSalesOrderCreateMutation,
  useSalesOrderQuery,
  useSalesOrderUpdateMutation,
} from "../../../generated/sales";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  salesOrderCreatePayloadFormatter,
  salesOrderQueryFormatter,
  salesOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/SalesOrder";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { yupResolver } from "@hookform/resolvers/yup";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";
import { useStateContext } from "../../../contexts/auth-context";
import { ICreatedBy } from "../../../types/global";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    reset,
    resetField,
    handleSubmit,
  } = useForm<ISalesOrder>({
    defaultValues: salesOrderSchema,
    resolver: yupResolver(salesOrderValidation),
    mode: "onChange",
  });

  const [approvalTemplate, setApprovalTemplate] = useState<any[]>([]);

  const {
    state: { authUser },
  } = useStateContext();

  const currentUser: ICreatedBy = {
    user_unique_id: authUser?.unique_id || "",
    email: authUser?.email || "",
    first_name: authUser?.first_name || "",
    last_name: authUser?.last_name,
    img_url: authUser?.img_url,
  };

  const { salesActivityLogCreate } = useActivityLogCreate();

  const createdBy = useWatch({ control, name: "created_by" });

  const relatedUserList = useWatch({ control, name: "related_user_list" });

  useRoleSelfPermission(createdBy, relatedUserList);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data: approvalTemplates } = useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
    graphQLClientWithHeaderSales, {}
  );
  useEffect(() => {
    if (approvalTemplates && approvalTemplates.approvalTemplates && approvalTemplates.approvalTemplates.length > 0) {
      setApprovalTemplate(approvalTemplates.approvalTemplates);
    } else {
      setApprovalTemplate([]);
    }
  }, [approvalTemplates]);
  const { mutateAsync: approveWorkflow } =
    useApproveWorkflowMutation<Error>(graphQLClientWithHeaderSales);
  const { data, isLoading, isSuccess, refetch } =
    useSalesOrderQuery<SalesOrderQuery>(
      graphQLClientWithHeaderSales,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutateAsync: create } =
    useSalesOrderCreateMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: ({ salesOrderCreate }) => {
        if (salesOrderCreate) {
          if (salesOrderCreate.sub_status === "wait_approve") {
            updateStatus({
              documentInput: {
                reference_document_type: SalesDocumentType.SalesOrder,
                unique_id: salesOrderCreate.unique_id,
              },
            });
          }
          navigate(`/sales/order/${salesOrderCreate?.unique_id}`);
          enqueueSnackbar("สร้างใบสั่งขายสำเร็จ", {
            variant: "success",
          });
        }
        if (salesOrderCreate.sub_status === "approved") {
          updateStatus({
            documentInput: {
              reference_document_type: SalesDocumentType.SalesOrder,
              unique_id: salesOrderCreate.unique_id,
            },
          });
        }
        navigate(`/sales/order/${salesOrderCreate?.unique_id}`);
        enqueueSnackbar("สร้างใบสั่งขายสำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบสั่งขายไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: update } =
    useSalesOrderUpdateMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบสั่งขายสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบสั่งขายไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    useSalesDocumentNextStatusMutation<Error>(graphQLClientWithHeaderSales, {
      onSuccess: () => {
        if (id) {
          refetch();
        }
      },
    });

  useEffect(() => {
    if (isSuccess) {
      const { salesOrder } = data;

      const getSalesOrderData = async () => {
        const salesOrderType = salesOrder as ISalesOrder;
        const formattedSalesOrder = await salesOrderQueryFormatter(
          salesOrderType
        );
        reset(formattedSalesOrder);
      };
      getSalesOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      const { copied_id, copied_unique_id, reference_id_list, ...otherState } =
        state;
      reset(otherState);
    }
  }, [reset, state]);

  useEffect(() => {
    if (!isLoading && id && data?.salesOrder?.aggrid_status !== "draft") {
      setDisabled(true);
    }
  }, [data?.salesOrder?.aggrid_status, id, isLoading]);

  const onSalesOrderCreate = async (data: ISalesOrder, status: string) => {
    try {
      if (data) {
        const payload = salesOrderCreatePayloadFormatter(
          data,
          status
        ) as SalesOrderCreateInput;
        const { salesOrderCreate } = await create({
          createInput: payload,
        });

        if (
          state &&
          state.reference_id_list &&
          state.reference_id_list.length > 0
        ) {
          await salesActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: SalesActivityLogDocumentType.Quotation,
            reference_id: state.reference_id_list[0],
            activity_detail: {
              curr_status: Status.Finished,
            },
          });
        }

        if (status === "wait_approve") {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Create,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {},
            });
            if (state && state.copied_id) {
              await salesActivityLogCreate({
                activity_type: ActivityType.Copy,
                document_type: SalesActivityLogDocumentType.SalesOrder,
                reference_id: salesOrderCreate.id,
                activity_detail: {
                  copied_from: {
                    id: state.copied_id,
                    unique_id: state.copied_unique_id,
                  },
                  copied_to: {
                    id: salesOrderCreate.id,
                    unique_id: salesOrderCreate.unique_id,
                  },
                },
              });
            }
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                curr_status: Status.WaitApprove,
              },
            });
          } catch (err) {
            console.log(err);
          }
        } else if (status === "approved") {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Create,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {},
            });
            if (state && state.copied_id) {
              await salesActivityLogCreate({
                activity_type: ActivityType.Copy,
                document_type: SalesActivityLogDocumentType.SalesOrder,
                reference_id: salesOrderCreate.id,
                activity_detail: {
                  copied_from: {
                    id: state.copied_id,
                    unique_id: state.copied_unique_id,
                  },
                  copied_to: {
                    id: salesOrderCreate.id,
                    unique_id: salesOrderCreate.unique_id,
                  },
                },
              });
            }
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                curr_status: Status.Approved,
              },
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          await salesActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {},
          });

          if (state && state.copied_id) {
            await salesActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: salesOrderCreate.id,
                  unique_id: salesOrderCreate.unique_id,
                },
              },
            });
          }

          await salesActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {
              curr_status: Status.Draft,
            },
          });
        }
      }
    } catch (err) { }
  };

  const onSalesOrderUpdate = async (data: ISalesOrder, status: string) => {
    try {
      if (data) {
        const payload = salesOrderUpdatePayloadFormatter(
          data,
          status
        ) as SalesOrderUpdateInput;
        setSnackbarMessage("แก้ไข");
        const { salesOrderUpdate } = await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        const changedData = Object.keys(dirtyFields);
        if (changedData.length > 0) {
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.Edit,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderUpdate.id,
              activity_detail: {},
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (err) { }
  };

  const onSalesOrderSendApprove = async (data: ISalesOrder) => {
    try {
      if (data) {
        const payload = await salesOrderUpdatePayloadFormatter(
          data,
          "wait_approve"
        ) as SalesOrderUpdateInput;

        const isHaveApproval = approvalTemplates?.approvalTemplates?.some(
          (template: any) =>
            template.document_type === "sales_order" &&
            template.is_have_approval === true
        );

        if (!isHaveApproval) {
          const approvedPayload = await salesOrderUpdatePayloadFormatter(
            data,
            "approved"
          ) as SalesOrderUpdateInput;

          if (!id) {
            await onSalesOrderCreate(data, "approved");
          } else {
            setSnackbarMessage("อนุมัติ");
            const { salesOrderUpdate } = await update({
              uniqueInput: {
                unique_id: id,
              },
              updateInput: approvedPayload,
            });
            await updateStatus({
              documentInput: {
                reference_document_type: SalesDocumentType.SalesOrder,
                unique_id: data.unique_id,
              },
            });
            try {
              await salesActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: SalesActivityLogDocumentType.SalesOrder,
                reference_id: salesOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.Approved,
                },
              });
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          if (!id) {
            await onSalesOrderCreate(data, "wait_approve");
          } else {
            setSnackbarMessage("ส่งอนุมัติ");
            const { salesOrderUpdate } = await update({
              uniqueInput: {
                unique_id: id,
              },
              updateInput: payload,
            });
            await updateStatus({
              documentInput: {
                reference_document_type: SalesDocumentType.SalesOrder,
                unique_id: data.unique_id,
              },
            });
            try {
              await salesActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: SalesActivityLogDocumentType.SalesOrder,
                reference_id: salesOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.WaitApprove,
                },
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSalesOrderNotApprove = async (data: ISalesOrder) => {
    try {
      const payload = salesOrderUpdatePayloadFormatter(
        data,
        "wait_approve",
        true
      ) as SalesOrderUpdateInput;
      setSnackbarMessage("ไม่อนุมัติ");
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await approveWorkflow({
        document: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Ejected
      })
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.NotApproved,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) { }
  };

  const onSalesOrderApprove = async (data: ISalesOrder) => {
    try {
      const payload = salesOrderUpdatePayloadFormatter(
        data,
        "approved"
      ) as SalesOrderUpdateInput;
      setSnackbarMessage("อนุมัติ");
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await approveWorkflow({
        document: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Approved
      })
      updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.Approved,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } catch (err) { }
  };

  const onSalesOrderFinish = async (data: ISalesOrder) => {
    try {
      const payload = salesOrderUpdatePayloadFormatter(
        data,
        "finished"
      ) as SalesOrderUpdateInput;
      setSnackbarMessage("เสร็จสิ้น");
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
      });

      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.Finished,
          },
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) { }
  };

  const onSalesOrderWaitApprove = async (data: ISalesOrder) => {
    try {
      if (data) {
        const payload = salesOrderUpdatePayloadFormatter(
          data,
          "wait_approve"
        ) as SalesOrderUpdateInput;

        if (!id) {
          onSalesOrderCreate(data, "wait_approve");
        } else {
          setSnackbarMessage("รออนุมัติ");
          const { salesOrderUpdate } = await update({
            uniqueInput: {
              unique_id: id,
            },
            updateInput: payload,
          });
          try {
            await salesActivityLogCreate({
              activity_type: ActivityType.StatusChange,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderUpdate.id,
              activity_detail: {
                curr_status: Status.WaitApprove,
              },
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (err) { }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onSalesOrderEditHandler = (data: ISalesOrder) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    onSalesOrderUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const isUserAuthorized = approvalTemplate.some((template) =>
    template.document_type === 'sales_order' &&
    template.approval_detail_list.some((detail: { approver_list: { unique_id: string; }[]; }) =>
      detail.approver_list.some(
        (approver: { unique_id: string }) =>
          approver.unique_id === currentUser.user_unique_id
      )
    ) &&
    template.is_have_approval === true
  );

  const renderButton = () => {
    switch (data?.salesOrder?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onSalesOrderUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onSalesOrderSendApprove)}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            isUserAuthorized && (
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  variant="outlined"
                  title={t("button.not_approve")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onSalesOrderNotApprove)}
                />
                <CustomizedButton
                  title={t("button.approve")}
                  variant="contained"
                  onClick={handleSubmit(onSalesOrderApprove)}
                  disabled={isChanging}
                />
              </Stack>
            ));
        };
      case "approved":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.finish")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderFinish)}
                disabled={isChanging}
              />
            </Stack>
          );
        }

      case "not_approved":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save")}
                onClick={handleSubmit((data) =>
                  onSalesOrderUpdate(data, "not_approved")
                )}
              />
              <CustomizedButton
                title={t("button.wait_approve")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderWaitApprove)}
              />
            </Stack>
          );
        }
      case "cancelled":
      case "finished":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onSalesOrderCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onSalesOrderSendApprove)}
            />
          </Stack>
        );
    }
  };

  useSalesError(errors);

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <SalesOrderHeader
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        refetch={refetch}
        editClickHandler={editClickHandler}
        update={update}
        updateStatus={updateStatus}
        handleSubmit={handleSubmit}
        taxInvoiceNumber={data?.salesOrder?.tax_invoice_number}
      />
      <CustomerInfoForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.SalesOrder}
      />
      <PriceVatType control={control} errors={errors} disabled={disabled} />
      <SalesItemList
        documentType={EntityTypeEnum.SalesOrder}
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
      />
      <SalesFooter
        control={control}
        errors={errors}
        disabled={disabled}
        setValue={setValue}
        resetField={resetField}
        documentType={EntityTypeEnum.SalesOrder}
      />
      <Box sx={{ mt: "4rem" }} />
      {(!["cancelled", "finished"].includes(
        data?.salesOrder?.aggrid_status || ""
      ) ||
        (["cancelled", "finished"].includes(
          data?.salesOrder?.aggrid_status || ""
        ) &&
          isEdit)) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default DocumentInfoTab;
