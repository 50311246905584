import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import SalesOrderTable, { SaleOrderTableType } from "../../../components/Table/Sales/Order";
import ButtonLayout from "../../../components/UI/ButtonLayout";
import { useRef } from "react";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import { GraphQLClient } from "graphql-request";
import { useSalesApprovalTemplatesQuery, SalesApprovalTemplatesQuery } from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const SalesOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const tableRef = useRef<ConfigurableAgGridReact<any>>()
  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data: approvalTemplatesData } = useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
    graphQLClientWithHeaderSales,{}
  );
 const isHaveApproval =
    approvalTemplatesData?.approvalTemplates?.some((template) =>
      template?.document_type === "sales_order" && template?.is_have_approval
  ) ?? false;
  
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    ...(isHaveApproval
      ? [
          {
            label: t("status.wait_approve"),
            path: `${pathname}?filter=wait_approve`,
          },
          {
            label: t("status.not_approved"),
            path: `${pathname}?filter=not_approved`,
          },
        ]
      : []),
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.expired"),
      path: `${pathname}?filter=expired`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.order.index")}</Typography>
        <ButtonLayout>
          <CustomizedButton
            title={`${t("button.create")}${t("sales.order.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          <ConfigurableAgGridEditButton tableRef={tableRef}/>
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <SalesOrderTable isFilter={isFilter} ref={tableRef}/>
    </>
  );
};

export default SalesOrder;
